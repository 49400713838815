import {
  UPDATE_OWN_USER,
  IUpdateOwnUserVariables,
  IUpdateOwnUserData,
  IMeUserData,
  ME_USER,
} from '../../pages/Inside/pages/EntryForm/gql'
import {
  updateMonthlyRebateWizardStep,
  updateMonthlyRebateWizardStepVariables,
} from '../../generated/updateMonthlyRebateWizardStep'
import {
  updateAnnualRebateWizardStep,
  updateAnnualRebateWizardStepVariables,
} from '../../generated/updateAnnualRebateWizardStep'
import {
  AnnualWizardStep,
  MonthlyWizardStep,
} from '../../generated/globalTypes'
import { userWorkedWholeYear } from '../../utils'
import { SharedFormSection } from '../../pages/Inside/pages/Profile/PersonalForm'
import { Observer } from 'mobx-react'
import { useMutation, useQuery } from 'react-apollo'
import { UpperHint, Divider, Green, Center } from '../../components/CommonBox'
import { useCountryEnum, useIDTypeEnum } from '../../hooks/useEnumList'
import { useTranslation, Trans } from 'react-i18next'
import { UPDATE_ANNUAL_WIZARD_STEP } from '../../pages/Inside/pages/AnnualRebates/gql/updateAnnualWizardStepMutation'
import { UPDATE_MONTHLY_WIZARD_STEP } from '../../pages/Inside/pages/MonthlyRebates/gql/updateMonthlyWizardStepMutation'
import { IProps as IBackButton } from '../../components/BackButton'
import AnnualStepper from '../../pages/Inside/pages/AnnualRebates/components/Stepper'
import MonthlyStepper from '../../pages/Inside/pages/MonthlyRebates/components/Stepper'
import AppState from '../../components/AppState'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../components/ButtonStepContainer'
import GraphQLErrorSnackbar from '../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../components/layouts/InsideLayout'
import React, { useState } from 'react'
import ScrollToTop from '../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import Select from '../../components/form/Select'
import TextField from '../../components/form/TextField'
import Loader from '../../components/Loader'
import useForm from '../../hooks/useForm'
import useRouter from '../../hooks/useRouter'
import useUser from '../../hooks/useUser'
import useSnackbar from '../../hooks/useSnackbar'
import Help from './Help'
import InfoBox from '../../components/InfoBox'
import { Question } from '../../shared/Questionnaire'
import { BlueButton } from '../../components/BlueButton'
import Expand from '../../components/Expand'
import { RebateConditions } from '../../shared/Rebates/Conditions'


const TaxResidenceWizzardStep: React.FC<{
  type: 'ANNUAL' | 'MONTHLY'
}> = (props) => {
  const { user, refetch: refetchUser } = useUser()
  const { history } = useRouter()
  const { t } = useTranslation()
  const { addMessage } = useSnackbar()
  const { type: formType } = props

  const [isChangeMode, setChangeMode] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [updateAnnualWizardStep] = useMutation<
    updateAnnualRebateWizardStep,
    updateAnnualRebateWizardStepVariables
  >(UPDATE_ANNUAL_WIZARD_STEP)
  const [updateMonthlyWizardStep] = useMutation<
    updateMonthlyRebateWizardStep,
    updateMonthlyRebateWizardStepVariables
  >(UPDATE_MONTHLY_WIZARD_STEP)

  const { data, loading, error } = useQuery<IMeUserData>(ME_USER, {
    onCompleted() {
      if (formType === 'ANNUAL') {
        updateAnnualWizardStep({
          variables: { wizardStep: AnnualWizardStep.TAX_RESIDENCE },
        })
      }
      if (formType === 'MONTHLY') {
        updateMonthlyWizardStep({
          variables: { wizardStep: MonthlyWizardStep.TAX_RESIDENCE },
        })
      }
    },
  })

  let annualRebatesYear = 0
  let workedWholeYear = false
  let monthlyRebatesYear = 0

  if (formType === 'ANNUAL') {
    annualRebatesYear = user.data.annualRebate.year
    workedWholeYear = userWorkedWholeYear(user.data, annualRebatesYear)
  } else {
    monthlyRebatesYear = user.data.monthlyRebate.year
  }

  const { list: countryList, translate: translateCountry } = useCountryEnum()
  const { list: foreignIDTypeList } = useIDTypeEnum()

  // zatím jen příprava, kdyby se mělo řešit.
  // snackbar je totiž vyvolán vždy při ukončení mutace
  // i přes to, že nejsou žádná data k updatu.
  const showSnackbar = false

  const [updateOwnUser] = useMutation<
    IUpdateOwnUserData,
    IUpdateOwnUserVariables
  >(UPDATE_OWN_USER, {
    onCompleted() {
      if (showSnackbar) {
        addMessage(t('common.change', { context: 'SUCCESS' }))
      }
    },
  })

  const lastResidenceStatus = user.getLastResidenceFilesStatus()

  const isConfirmed = lastResidenceStatus === "CONFIRMED" && !isChangeMode
  
  const { bind, form } = useForm<IUpdateOwnUserVariables['data']>(
    data && data.user,
    {
      czechTaxResidence: {
        label: t('annualRebateTaxResidence.czechTaxResidenceLabel', {
          defaultValue: t('common.czechTaxResidence'),
        }),
        placeholder: t('common.czechTaxResidencePlaceholder'),
        rule: 'required'
      },
      nationalIDNumber: {
        label: t('common.nationalIDNumber'),
        placeholder: (data) => {
          const isRequired = data.nationality === 'CZ' || !data.nationality
          return user.isEmployee() && isRequired
            ? t('common.nationalIDNumberPlaceholder')
            : `${t('common.notRequired')} ${t(
                'common.nationalIDNumberPlaceholder',
              )}`
        },
        messages: {
          // TBD lokalizace
          required_if: `:attribute musí být vyplněno pokud :other je ${translateCountry(
            'CZ',
          )}.`,
        },
        help: t('common.nationalIDNumberHelp'),
      },
      permanentAddress: {
        label: t('common.permanentAddress'),
        placeholder: t('common.permanentAddressPlaceholder'),
        help: t('common.permanentAddressHelp'),
      },
      nationality: {
        label: t('common.nationality'),
        placeholder: t('common.nationalityPlaceholder'),
        list: countryList,
        help: t('common.nationalityHelp'),
      },
      foreignIDNumber: {
        label: t('common.foreignIDNumber'),
        placeholder: t('common.foreignIDNumberPlaceholder'),
        rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
            ? 'required'
            : '',
        help: t('common.foreignIDNumberHelp'),
      },
      foreignIDType: {
        label: t('common.foreignIDType'),
        placeholder: t('common.foreignIDTypePlaceholder'),
        rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
            ? 'required'
            : '',
        list: foreignIDTypeList,
        help: t('common.foreignIDTypeHelp'),
      },
      foreignIDCountry: {
        label: t('common.foreignIDCountry'),
        placeholder: t('common.foreignIDCountryPlaceholder'),
        rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
            ? 'required'
            : '',
        list: countryList,
        help: t('common.foreignIDCountryHelp'),
      },
      foreignTaxIdentification: {
        label: t('common.foreignTaxIdentification'),
        placeholder: `${t('common.notRequired')} ${t(
          'common.foreignTaxIdentificationPlaceholder',
        )}`,
        help: t('common.foreignTaxIdentificationHelp'),
      },
      foreignTaxResidentOfState: {
        label: t('common.foreignTaxResidentOfState'),
        placeholder: t('common.foreignTaxResidentOfStatePlaceholder'),
        rule: (data) =>
          user.isEmployee() &&
          data.nationality !== 'CZ' &&
          data.czechTaxResidence === false
            ? 'required'
            : '',
        list: countryList,
        help: t('common.foreignTaxResidentOfStateHelp'),
      },
      taxDomicilFiles: {
        type: 'multi_relation',
        label: t('common.taxDomicil'),
        help: t('common.taxDomicil', { context: 'HELP' }),
        rule: (data, form) => {
          const f = form.getValue('residenceAuthorisationFiles')
          return user.isEmployee() &&
            data.nationality !== 'CZ' &&
            (!f || f.length === 0)
            ? '' // původně bylo 'required'
            : ''
        },
        isFileRelation: true,
      },
      residenceAuthorisationFiles: {
        type: 'multi_relation',
        label: t('annualRebateTaxResidence.residenceAuthorisationLabel'),
        help: t('common.uploadHelp'),
        rule: (data, form) => {
          const f = form.getValue('taxDomicilFiles')
          return user.isEmployee() &&
            data.nationality !== 'CZ' &&
            (!f || f.length === 0)
            ? '' // původně bylo 'required'
            : ''
        },
        isFileRelation: true,
      },
    },
    {
      async onSubmit(formData, form) {
        try {
          const { data } = await updateOwnUser({
            variables: { data: formData },
          })
          if (data && data.updateOwnUser) {
            form.onSuccess(data.updateOwnUser)
            await refetchUser()
            setSubmitLoading(false)
            if (formType === 'ANNUAL') {
              history.push(`/${user.data.id}/annual-rebates/rootMonthly`, {
                from: 'taxResidence',
              })
            } else {
              history.push(`/${user.data.id}/monthly-rebates/root`, {
                from: 'taxResidence',
              })
            }
          }
        } catch (err) {
          form.onFail(err)
          setSubmitLoading(false)
        }
      },
    },
  )

  const handleSubmit = () => {
    form.isValid() ? setSubmitLoading(true) : setSubmitLoading(false)
    // if (
    //   user.isEmployee() &&
    //   form.state.isValid &&
    //   form.getValue('czechTaxResidence') === true &&
    //   form.getValue('nationality') !== 'CZ'
    // ) {
    //   const f1 = form.getValue('residenceAuthorisationFiles')
    //   const f2 = form.getValue('taxDomicilFiles')

    //   if (
    //     (!f1 || f1.length === 0) &&
    //     (!f2 || f2.length === 0) &&
    //     user.noProvidedFiles()
    //   ) {
    //     setSubmitLoading(false)
    //     return
    //   }
    // }
    form.submit()
  }
  
  const handleChange = () => {
    setChangeMode(true)
  }

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading} />
      <GraphQLErrorSnackbar error={error} />
      {data && !loading && (
        <Box
          maxWidth="100%"
          width={1080}
          ml="auto"
          mr="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <UpperHint>
            {t('common.form')} {formType === 'ANNUAL' ? t('common.annualRebates') : t('common.monthlyRebates')} {formType === 'ANNUAL' ? annualRebatesYear : monthlyRebatesYear}
          </UpperHint>

          {formType === 'ANNUAL' ? <AnnualStepper activeStep={3} /> : <MonthlyStepper activeStep={2} />}

          <Box maxWidth="100%" width={600}>
            <Typography variant="h1" align="center" gutterBottom>
              {t('annualRebateTaxResidence.heading')}
            </Typography>

            <Box
              fontFamily="fontFamily"
              textAlign="center"
              maxWidth="100%"
              width="595px"
            >
              <Trans i18nKey="annualRebateTaxResidence.subheading" tOptions={{ context: "2023" }} components={[<Green />]} />
            </Box>
          </Box>

          <InfoBox title={t('annualRebateTaxResidence.info.title')} maxWidth={480} mt={4}>
            <Trans i18nKey='annualRebateTaxResidence.info.description' components={[<strong />]} />
          </InfoBox>

          <Divider my={4} maxWidth={180} />

          {isConfirmed ? (
            <Center>
              <BlueButton
                color="primary"
                size="large"
                variant="contained"
                onClick={handleChange}
              >
                {t('rebateChanges.wantToMakeChange')}
              </BlueButton>
            </Center>
          ) : null}

          <Box maxWidth={680} width="100%" mt={isConfirmed ? 4 : 0}>
            <Observer>
              {() => (
                <>
                  <Question
                    hideDivider
                    {...bind('czechTaxResidence')}
                    disabled={isConfirmed}
                  />
                  {!form.getValue('czechTaxResidence') ? <Divider mt={2} mb={4} /> : null}
                </>
              )}
            </Observer>
          </Box>

          <Box maxWidth={680} width="100%">
            {/* Dynamic fileds */}
            <Observer>
              {() => {
                const nationality = form.getValue('nationality')
                const czechTaxResidence = form.getValue('czechTaxResidence')

                /* nemá daňový domicil v ČR, zobrazují se všechny údaje*/

                return !czechTaxResidence ? (
                  <>
                    <Observer>
                      {() => (
                        <TextField
                          disabled={isConfirmed}
                          inline
                          fullWidth
                          {...bind('foreignIDNumber')}
                          data-dependency={bind('nationality').value}
                        />
                      )}
                    </Observer>

                    <Divider my={4} />

                    <Observer>
                      {() => (
                        <Select
                          disabled={isConfirmed}
                          inline
                          fullWidth
                          {...bind('foreignIDType')}
                          data-dependency={bind('nationality').value}
                        />
                      )}
                    </Observer>

                    <Divider my={4} />

                    <Observer>
                      {() => (
                        <Select
                          disabled={isConfirmed}
                          inline
                          fullWidth
                          {...bind('foreignIDCountry')}
                          data-dependency={bind('nationality').value}
                        />
                      )}
                    </Observer>

                    <Divider my={4} />

                    <Observer>
                      {() => (
                        <TextField
                          disabled={isConfirmed}
                          inline
                          fullWidth
                          {...bind('foreignTaxIdentification')}
                          data-dependency={bind('nationality').value}
                        />
                      )}
                    </Observer>

                    <Divider my={4} />

                    <Observer>
                      {() => (
                        <Select
                          disabled={isConfirmed}
                          inline
                          fullWidth
                          {...bind('foreignTaxResidentOfState')}
                          data-dependency={bind('nationality').value}
                        />
                      )}
                    </Observer>
                    {/* 
                        * pokud není daňový resident, tak nedodává žádné doklady
                      <Divider spacing={6} />
                      <SharedFormSection bind={bind} /> */}
                  </>
                ) : user.isForeignerWithNoFiles() ||
                  user.hasNewResidenceFiles() ||
                  (czechTaxResidence === true && nationality !== 'CZ') ? (
                  <>
                    {/* 
                        Má daňový domicil v ČR, ale nemá státní příslušnost v ČR: 
                        Zobrazí se sekce pro import dokumentů, která je stejná jako
                        u daňového nerezidenta: SharedFormSection               
                      */}
                    <Divider mt={2} mb={4} />

                    <Expand title={t('annualRebateTaxResidence.conditions.title')}>
                      <Divider my={4} />
                      <Green>{t('annualRebateTaxResidence.conditions.subheading')}</Green>
                      <RebateConditions items={[
                        <Trans i18nKey='annualRebateTaxResidence.conditions.detail01' />,
                        <Trans i18nKey='annualRebateTaxResidence.conditions.detail02' />,
                        <Trans i18nKey='annualRebateTaxResidence.conditions.detail03' />,
                        <Trans i18nKey='annualRebateTaxResidence.conditions.detail04' />,
                        <Trans i18nKey='annualRebateTaxResidence.conditions.detail05' />,
                      ]} />
                    </Expand>

                    <Divider my={4} />

                    <SharedFormSection bind={bind} version="TAX_RESIDENCE" isConfirmed={isConfirmed} />
                  </>
                ) : null
              }}
            </Observer>

            <Divider my={4} />

            <Help />
          </Box>

          <Divider my={4} />

          {/* <Dialog
            open={domicilWarningOpen}
            onClose={onCloseDomicilWarning}
            fullWidth
            maxWidth="sm"
            scroll="body"
          >
            <DialogTitle form={form} onClose={onCloseDomicilWarning}>
              {t('entryform.domicilWarningHeading')}
            </DialogTitle>

            <DialogContent>
              <Typography>{t('entryform.domicilWarning')}</Typography>
            </DialogContent>

            <DialogActions dividerProps={{ mt: 1 }} gutterBottom>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={submitDomicilWarning}
              >
                {t('common.understand')}
              </Button>
            </DialogActions>
          </Dialog> */}

          <Observer>
            {() => {
              let backButton: IBackButton | undefined = undefined

              if (formType === 'ANNUAL') {
                backButton = workedWholeYear
                  ? {
                      to: `/${user.data.id}/annual-rebates/questionnaire`,
                      text: t('annualRebateQuestionnaire.heading'),
                    }
                  : {
                      to: `/${user.data.id}/annual-rebates/previousEmployers`,
                      text: t('annualRebateWizard.stepper.previousEmployer'),
                    }
              } else {
                backButton = {
                  to: `/${user.data.id}/monthly-rebates/questionnaire`,
                }
              }

              return (
                <React.Fragment>
                  <ButtonStepContainer backButton={backButton}>
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      {t('common.continue')}
                    </Button>
                  </ButtonStepContainer>

                  <Box mt={2}>
                    <Loader loading={submitLoading} />
                  </Box>
                </React.Fragment>
              )
            }}
          </Observer>
        </Box>
      )}
    </InsideLayout>
  )
}

export default TaxResidenceWizzardStep
