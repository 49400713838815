import {
  findLastMonth,
  getBirthdateFromID,
  isOlderThen,
  maxDate,
  monthToInt,
  months,
  yearRange,
} from '../../utils'
import {
  TPartialChild,
  TNewChild,
  TChildWithPrev,
  isNewChild,
  isConfirmedChild,
} from './utils'
import { NexusGenFieldTypes } from 'kubik-server'
import { IconCircle, Divider, Green } from '../../components/CommonBox'
import { Box } from '@material-ui/core'
import { Observer } from 'mobx-react'
import { useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useChildOrderEnum } from '../../hooks/useEnumList'
import { Trans, useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import DatePicker from '../../components/form/DatePicker'
import DatePickerLabel from '../../components/form/DatePickerLabel'
import Deti from '../../components/icons/Deti'
import Dialog from '../../components/Dialog'
import DialogActions from '../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '../../components/DialogTitle'
import Error from '../../components/form/Error'
import ErrorSnackbar from '../../components/ErrorSnackbar'
import Grid from '@material-ui/core/Grid'
import moment, {Moment} from 'moment'
import MonthPicker from '../../components/form/MonthPicker'
import React, { useMemo, useState } from 'react'
import Select from '../../components/form/Select'
import Switch from '../../components/form/Switch'
import TextField from '../../components/form/TextField'
import Uploader from '../../components/form/Uploader'
import useForm from '../../hooks/useForm'
import useUser, {User} from '../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {getApplyFromInYear} from "../../pages/Inside/pages/MonthlyRebates/utils";

// const SwitchLabel = styled(Typography)(({ theme }) => ({
//   fontWeight: theme.typography.fontWeightBold,
//   fontSize: theme.typography.pxToRem(16),
//   display: 'inline',
//   paddingLeft: '6px',
//   paddingTop: '4px',
// }))

interface IUserCreateDialogProps {
  child: TChildWithPrev | TNewChild | null
  onClose: () => void
  onSubmit: (child: TPartialChild) => void
  year: number
  children: TChildWithPrev[]
  newPeriod?: boolean
  variant: 'MONTHLY' | 'ANNUAL'
  prevRebates?: NexusGenFieldTypes['ChildrenRebate'][]
}

// const someStudentOverEighteen = (
//   children: (TChild & {
//     applyInMonths: { prisma_set?: TChild['applyInMonths'] }
//   })[],
//   year: number,
// ) => {
//   if (Array.isArray(children)) {
//     return children.some(
//       (
//         ch: TChild & {
//           applyInMonths: { prisma_set?: TChild['applyInMonths'] }
//         },
//       ) => {
//         const { applyRebate, birthdate, student } = ch

//         const applyInMonths: TPartialChild['applyInMonths'] =
//           ch.applyInMonths && ch.applyInMonths.prisma_set
//             ? ch.applyInMonths.prisma_set
//             : ch.applyInMonths
//         const lastMonth = findLastMonth(applyInMonths)

//         return Boolean(
//           applyRebate &&
//             birthdate &&
//             lastMonth &&
//             student &&
//             isOlderThen(
//               birthdate,
//               18,
//               moment([year, lastMonth.value]).add(1, 'month'),
//             ),
//         )
//       },
//     )
//   }
//   return false
// }

/**
 * @deprecated  Stará funkcionalita. Počítá ještě s jedním obdobím studia
 * @param param0
 * @returns
 */
const isStudyingOld = ({
  student,
  studentFrom,
  studentTo,
  year,
}: {
  student: boolean
  studentFrom: moment.MomentInput
  studentTo: moment.MomentInput
  year: number
}) => (month: number) => {
  student = student === true
  studentFrom =
    student && !!studentFrom && moment(studentFrom).isValid()
      ? studentFrom
      : undefined
  studentTo =
    student && !!studentTo && moment(studentTo).isValid()
      ? studentTo
      : undefined

  if (studentFrom && studentTo) {
    if (
      moment(studentFrom).isSameOrBefore({ year, month }, 'month') &&
      moment(studentTo).isSameOrAfter({ year, month }, 'month')
    ) {
      return true
    }
  }
  return false
}

/**
 * @param args
 * @deprecated Stará funkcionalita, počítá s pouze s jedním obdobím studenta
 * @returns
 */
// const disableMonthOld = (args: {
//   student: boolean
//   studentFrom: moment.MomentInput
//   studentTo: moment.MomentInput
//   birthdate: moment.MomentInput
//   year: number
//   dateOfEmployment?: moment.MomentInput
// }) => (month: number | NexusGenEnums['Month']) => {
//   const { student, studentFrom, studentTo, year, dateOfEmployment } = args
//   const birthdate =
//     !!args.birthdate && moment(args.birthdate).isValid()
//       ? args.birthdate
//       : undefined
//   if (!!dateOfEmployment) {
//     const employment = moment(dateOfEmployment)
//     if (employment.year() === year && month > employment.month()) {
//       return false
//     }
//   }
//   if (birthdate) {
//     const adultdate = moment(birthdate)
//       .add(18, 'years')
//       .add(1, 'month')
//     if (typeof month === 'string') month = monthToInt(month)

//     if (moment({ year, month }).isBefore(adultdate, 'year')) {
//       return false
//     }
//     if (isStudyingOld({ student, studentFrom, studentTo, year })(month)) {
//       return false
//     }
//     if (adultdate.year() === year && month <= adultdate.month()) {
//       return false
//     }
//   }
//   return true
// }

// /**
//  * Nová funkcionalita bere více období studenta
//  * @param param0
//  * @returns
//  */
// const isStudying = ({
//   student,
//   studentPrevYearPeriods,
//   year,
// }: {
//   student: boolean
//   studentPrevYearPeriods: { from: any; to: any }[]
//   year: number
// }) => (month: number) => {
//   student = student === true

//   let result = false

//   // do funkce nám vstupuje měsíc, u kterého se má rozhodnout
//   // zda bude disabled (v případě, kdy nestuduje) nebo enabled (při studiu)
//   // musíme tedy napřed najít zda existuje období, které obsahuje daný měsíc.

//   for (const pp of studentPrevYearPeriods) {
//     const studentFrom =
//       student && !!pp.from && moment(pp.from).isValid() ? pp.from : undefined

//     const studentTo =
//       student && !!pp.to && moment(pp.to).isValid() ? pp.to : undefined

//     if (studentFrom && studentTo) {
//       if (
//         moment(studentFrom).isSameOrBefore({ year, month }, 'month') &&
//         moment(studentTo).isSameOrAfter({ year, month }, 'month')
//       ) {
//         result = true
//         break
//       }
//     }
//   }

//   return result
// }

/**
 * Nová funkcionalita - počítá s více období studenta
 * @param args
 * @returns
 */
export const canPickMonth = (args: {
  student: boolean
  // studentPrevYearPeriods: { from: any; to: any }[]
  birthdate: moment.MomentInput
  year: number
  variant: 'MONTHLY' | 'ANNUAL',
  dateOfEmployment: moment.MomentInput,
  minimumApplyDate: Moment
}) => (month: number) => {
  const { student, year, variant, dateOfEmployment, minimumApplyDate } = args

  const birthdate =
    !!args.birthdate && moment(args.birthdate).isValid()
      ? args.birthdate
      : undefined

  if (birthdate) {
    // když je dítě mladší
    if(moment({ year, month }).isBefore(moment(birthdate), 'month')) {
      return false
    }
    // NOTE: Výjímka: Pokud je dítě narozené před méně než 30 dny, lze vybrat i měsíc narození (nebo ten po něm).
    if (moment(birthdate).add(30, 'days').isSameOrAfter(moment(), 'days') &&
      moment({ year, month }).isSameOrAfter(birthdate, 'months') && moment(dateOfEmployment).isSameOrBefore(moment(birthdate), 'month')) {
      return true
    }
    const adultdate = moment(birthdate)
      .add(18, 'years')
    const adultdateStudent = moment(birthdate)
      .add(26, 'years')

    if (!student && moment({ year, month }).isAfter(adultdate, 'month')) {
      return false
    }
    if (student && moment({ year, month }).isAfter(adultdateStudent, 'month')) {
      return false
    }
  }

  if (variant === 'ANNUAL') {
    return true
  } else {
    return moment({ year, month }).isSameOrAfter(minimumApplyDate, 'month')
  }
}

const ChildDialog: React.FC<IUserCreateDialogProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)

  const { onClose, child, onSubmit, year, variant, prevRebates = [] } = props

  const [tooOld, setTooOld] = useState(false)

  const isNew = !!child && isNewChild(child)
  const open = !!child
  const newPeriod = Boolean(props.newPeriod && child && isConfirmedChild(child))
  const [yearStart, yearEnd, prevYearStart, nextYearEnd] = yearRange(year)

  const disabled = useMemo(() => {
    if (user.allowAlter()) {
      return false
    }

    if (
      child && "prevExists" in child && child.prevExists
    ) {
      const prevRebate = prevRebates.find(rebate => rebate.status === 'CONFIRMED')

      if (prevRebate && prevRebate.children) {
        const previous = prevRebate.children.find(({ tag }) => child.tag === tag)

        if (!previous) {
          return false
        }
      }
    }

    return newPeriod
  }, [newPeriod, user])

  const { list: childOrderList } = useChildOrderEnum()

  const confirmedAppliedInMonths = useMemo(() => {
    if (user.allowAlter()) {
      return []
    }

    if (
      child && "prevExists" in child && child.prevExists
    ) {
      if (variant === 'ANNUAL') {
        const prevYearRebate = prevRebates.find(r => r.settlementRequest && r.settlementRequest.type === 'MONTHLY_PREV_YEAR')

        if (prevYearRebate && prevYearRebate.children) {
          const previous = prevYearRebate.children.find(({ tag }) => child.tag === tag)
    
          if (previous) {
            return previous.applyInMonths
          }
        }

        return []
      }

      const prevRebate = prevRebates.find(rebate => rebate.status === 'CONFIRMED')

      if (prevRebate && prevRebate.children) {
        const previous = prevRebate.children.find(({ tag }) => child.tag === tag)

        if (previous) {
          return previous.applyInMonths
        }
      }
    }

    return []
  }, [child, prevRebates, newPeriod, user, variant])

  const { bind, form } = useForm<TPartialChild>(
    child,
    {
      firstname: {
        label: t('common.firstname'),
        placeholder: t('common.firstname') + '...',
        rule: 'required',
      },
      lastname: {
        label: t('common.lastname'),
        placeholder: t('common.lastname') + '...',
        rule: 'required',
      },
      nationalIDNumber: {
        label: t('common.nationalIDNumber', {
          context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : undefined,
        }),
        placeholder:
          t('common.nationalIDNumber', {
            context: user.data.nationality !== 'CZ' ? 'FOREIGNER' : undefined,
          }) + '...',
        rule: [
          'required',
          'regex:/^(([0-9]{10})|(((0)[1-9]|[1-2][0-9]|(3)[0-1]).(((0)[1-9])|((1)[0-2])).((20)|(19)){1}[0-9]{2})){1}$/',
        ],
        help:
          user.data.nationality !== 'CZ'
            ? t('common.nationalIDNumberHelp', { context: 'FOREIGNER' })
            : undefined,
      },
      birthdate: {
        label: t('common.birthdate'),
        rule: 'required|date',
      },
      applyRebate: {
        label: t('monthlyChildrenRebate.applyRebate'),
        help: t('monthlyChildrenRebate.applyRebate', { context: 'HELP' }),
        rule: (data) => {
          const lastMonth = findLastMonth(data.applyInMonths) || {
            name: 'JANUARY',
            value: 0,
          }
          if (lastMonth && data.birthdate) {
            const limitDate =
              variant === 'MONTHLY'
                ? moment([year, lastMonth.value])
                : moment([year]).startOf('year')

            return isOlderThen(data.birthdate, 26, limitDate) ? 'denied' : ''
          }
          return ''
        },
        messages: {
          denied: t('monthlyChildrenRebate.applyRebate', { context: 'ERROR' }),
        },
      },
      order: {
        label: t('monthlyChildrenRebate.orderLabel'),
        help: t('monthlyChildrenRebate.orderLabel', { context: 'HELP' }),
        placeholder: t('common.order') + '...',
        list: childOrderList,
        rule: 'required',
      },
      applyInMonths: {
        type: 'list',
        label: t('common.monthOfApply3'),
        placeholder: t('common.monthOfApply') + '...',
        rule: (data) => (data.applyRebate ? 'required' : ''),
      },
      ztpp: {
        label: t('monthlyChildrenRebate.ztppLabel'),
        help: t('monthlyChildrenRebate.ztpp', { context: 'HELP' }),
      },
      ztppFrom: {
        label: t('common.from'),
        rule: (data) =>
          data.applyRebate && data.ztpp ? 'required' : undefined,
        omitOnSubmit: (data) => !data.ztpp,
      },
      ztppTo: {
        label: t('common.to'),
        rule: (data) =>
          data.applyRebate && data.ztpp ? 'required' : undefined,
        omitOnSubmit: (data) => !data.ztpp,
      },
      student: {
        label: t('monthlyChildrenRebate.studentLabel'),
        // help: t('monthlyStudentRebate.validityPeriodHelp'),
        rule: (data) => {
          const lastMonth = findLastMonth(data.applyInMonths)
          return data.applyRebate &&
            lastMonth &&
            isOlderThen(data.birthdate, 18, moment([year, lastMonth.value]))
            ? 'accepted'
            : ''
        },
        messages: {
          accepted: t('monthlyChildrenRebate.student', { context: 'ERROR' }),
        },
      },
      // studentFrom: {
      //   label: t('common.from'),
      //   rule: (data) => {
      //     const lastMonth = findLastMonth(data.applyInMonths)
      //     return data.applyRebate &&
      //       data.student &&
      //       lastMonth &&
      //       isOlderThen(data.birthdate, 18, moment([year, lastMonth.value]))
      //       ? 'required'
      //       : ''
      //   },
      //   omitOnSubmit: (data) => data.student !== true,
      // },
      // studentTo: {
      //   label: t('common.to'),
      //   help: t('monthlyStudentRebate.validityPeriodHelp'),
      //   rule: (data) => {
      //     const lastMonth = findLastMonth(data.applyInMonths)
      //     return data.applyRebate &&
      //       data.student &&
      //       lastMonth &&
      //       isOlderThen(data.birthdate, 18, moment([year, lastMonth.value]))
      //       ? 'required'
      //       : ''
      //   },
      //   omitOnSubmit: (data) => data.student !== true,
      // },
      // studentPrevYearPeriods: {
      //   type: 'multi_relation',
      //   rule: (data) => {
      //     const lastMonth = findLastMonth(data.applyInMonths)
      //     return data.applyRebate &&
      //       data.student &&
      //       lastMonth &&
      //       isOlderThen(data.birthdate, 18, moment([year, lastMonth.value]))
      //       ? 'required'
      //       : ''
      //   },
      //   omitOnSubmit: (data) => data.student !== true,
      // },
      birthCertificateFiles: {
        type: 'multi_relation',
        label: t('monthlyChildrenRebate.birthCertificateLabel'),
        rule: (data) => (data.applyRebate ? 'required' : ''),
        isFileRelation: true,
      },
      studentConfirmationFiles: {
        type: 'multi_relation',
        label: t('monthlyChildrenRebate.studentConfirmationLabel'),
        rule: (data) => (data.applyRebate && data.student ? 'required' : ''),
        isFileRelation: true,
      },
      ztppConfirmationFiles: {
        type: 'multi_relation',
        label: t('common.ztppConfirmation'),
        rule: (data) => (data.applyRebate && data.ztpp ? 'required' : ''),
        isFileRelation: true,
      },
      socialConfirmationFiles: {
        type: 'multi_relation',
        label: t('common.socialConfirmation'),
        rule: (data) => (data.applyRebate && data.ztpp ? 'required' : ''),
        isFileRelation: true,
      },
    },
    {
      skipPrismasetReplace: true,
      submitWholeModel: isNew,
      onSubmit(formData, form) {
        const applyRebate = form.getValue('applyRebate')
        const birthdate = form.getValue('birthdate')
        const applyInMonths: TPartialChild['applyInMonths'] = form.getValue(
          'applyInMonths',
        )
        const lastMonth = findLastMonth(applyInMonths)

        if (
          applyRebate &&
          birthdate &&
          lastMonth &&
          isOlderThen(birthdate, 28, moment([year, lastMonth.value]))
        ) {
          setTooOld(true)
        } else {
          const id = form.initials.id
          onSubmit({ ...formData, ...(id && { id }) })
        }

        form.state.loading = false
      },
      onFieldBlur(fieldPath, value, form, error) {
        if (fieldPath === 'nationalIDNumber' && !error) {
          const birthdate = form.getValue('birthdate')
          const parsedBirthdate = getBirthdateFromID(value)
          if (!birthdate && parsedBirthdate && user.data.nationality === 'CZ') {
            form.setField('birthdate', parsedBirthdate)
          }
        }
      },
      onFieldChange(fieldPath, value, form) {
        if (fieldPath === 'applyRebate' && value === false) {
          form.listSet('applyInMonths', [])
        }

        if (fieldPath === 'birthdate' && moment(value).isValid()) {
          form.listSet('applyInMonths', [])
          const adult = isOlderThen(value, 18, moment([year]).endOf('year'))
          if (
            !isOlderThen(value, 28, moment([year]).endOf('year')) &&
            form.getValue('student') !== adult
          ) {
            form.setField('student', adult)
          }
        }

        if (fieldPath === 'student') {
          const student = form.getValue('student')

          if (!student) {
            form.listSet('applyInMonths', [])
          }
        }
        // if (fieldPath === 'studentFrom' || fieldPath === 'studentTo') {
        //   const studentFrom = form.getValue('studentFrom')
        //   const studentTo = form.getValue('studentTo')
        //   if (moment(studentFrom).isValid() && moment(studentTo).isValid()) {
        //     form.listSet('applyInMonths', [])
        //   }
        // }
        // if (fieldPath === 'studentPrevYearPeriods') {
        //   const studentPrevYearPeriods = form.getValue('studentPrevYearPeriods')
        //   const isValid = studentPrevYearPeriods.every(
        //     (pp: any) => moment(pp.to).isValid() && moment(pp.to).isValid(),
        //   )
        //   if (isValid) {
        //     form.listSet('applyInMonths', [])
        //   }
        // }
      },
    },
  )

  return (
    <>
      <ErrorSnackbar
        errors={tooOld ? [t('monthlyChildrenRebate.tooOldError')] : undefined}
        onClose={() => setTooOld(false)}
      />
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
        transitionDuration={{
          exit: 60,
        }}
      >
        <DialogTitle onClose={onClose}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <IconCircle mb={2} width={100} height={100}>
              <Deti fontSize="small" />
            </IconCircle>
            {isNew
              ? t('monthlyChildrenRebate.createChildHeading')
              : t('monthlyChildrenRebate.editChildHeading')}
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>
            <Grid xs={12} item>
              <Typography align="center">
                {isNew
                  ? <Trans i18nKey="monthlyChildrenRebate.createChildSubheading">Vyplňte a zadejte <Green>údaje o dítěti</Green></Trans>
                  : t('monthlyChildrenRebate.editChildSubheading')}
              </Typography>
            </Grid>

            <Grid xs={12} item>
              <Observer>
                {() => (
                  <TextField
                    fullWidth
                    {...bind('firstname')}
                    disabled={disabled}
                  />
                )}
              </Observer>
            </Grid>

            <Grid xs={12} item>
              <Observer>
                {() => (
                  <TextField
                    fullWidth
                    {...bind('lastname')}
                    disabled={disabled}
                  />
                )}
              </Observer>
            </Grid>

            <Grid xs={12} item>
              <Observer>
                {() => (
                  <TextField
                    fullWidth
                    {...bind('nationalIDNumber')}
                    disabled={disabled}
                  />
                )}
              </Observer>
            </Grid>

            <Grid xs={12} item>
              <Observer>
                {() => (
                  <DatePicker
                    fullWidth
                    autoOk
                    {...bind('birthdate')}
                    disabled={disabled}
                  />
                )}
              </Observer>
            </Grid>

            <Grid xs={12} item>
              <Observer>
                {() => (
                  <Select
                    {...bind('order')}
                    fullWidth
                    hideEmptyValue
                    disabled={disabled}
                  />
                )}
              </Observer>
            </Grid>

            <Grid xs={12} item>
              <Observer>
                {() => (
                  <Error {...bind('applyRebate')} showValidationUntouched>
                    <Grid item container direction="column">
                      <Grid item xs={12}>
                        <Divider my={2} width={160} />
                      </Grid>

                      <Grid xs={12} item>
                        <Switch 
                          disabled={confirmedAppliedInMonths.length > 0}
                          {...bind('applyRebate')}
                        />
                      </Grid>
                    </Grid>
                  </Error>
                )}
              </Observer>
            </Grid>

            <Observer>
              {() =>
                form.getValue('applyRebate') ? (
                  <>
                    <Observer>
                      {() => {
                        const isStudent = form.getValue('student')
                        const birthdate = form.getValue('birthdate')
                        const isOldEnough = isOlderThen(
                          birthdate,
                          18,
                          moment([year]).endOf('year').add(1,'day'),
                        )
                        // const ageLimit = moment(birthdate)
                        //   .add(28, 'year')
                        //   .endOf('month')
                        //   .toDate()

                        // const prevYearLastTo = undefined

                        // const dateLimiters = {
                        //   fromMinDates: [{ date: prevYearStart }],
                        //   toMinDates: [
                        //     {
                        //       date: yearStart,
                        //       message: t('error.minDateAnnualYearStart'),
                        //     },
                        //   ],
                        //   fromMaxDates: [
                        //     {
                        //       date: yearEnd,
                        //       message: t('error.maxDateAnnualYearEnd'),
                        //     },
                        //     {
                        //       date: ageLimit,
                        //       message: t('error.minDateAgeLimit'),
                        //     },
                        //   ],
                        //   toMaxDates: [
                        //     {
                        //       date: nextYearEnd,
                        //     },
                        //     {
                        //       date: ageLimit,
                        //       message: t('error.minDateAgeLimit'),
                        //     },
                        //   ],
                        // }

                        if (!isOldEnough) return null

                        return (
                          <>
                            <Grid
                              xs={12}
                              item
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Error
                                {...bind('student')}
                                data-apply={form.getValue('applyRebate')}
                              >
                                <Grid item container direction="column">
                                  <Grid item xs={12}>
                                    <Divider my={2} width={160} />
                                  </Grid>

                                  <Grid xs={12} item>
                                    <Switch
                                      disabled={confirmedAppliedInMonths.length > 0 && isStudent}
                                      {...bind('student')}
                                    />
                                  </Grid>
                                </Grid>
                              </Error>
                            </Grid>

                            {/* <Collapse
                              in={isStudent}
                              style={{ maxWidth: '100%' }}
                            >
                              <Observer>
                                {() => (
                                  <ChildStudentPrevPeriods
                                    {...bind('studentPrevYearPeriods')}
                                    label={t(
                                      'monthlyBasicRebate.validityPeriodLabel',
                                    )}
                                    dateLimiters={dateLimiters}
                                  />
                                )}
                              </Observer>
                            </Collapse> */}
                          </>
                        )
                      }}
                    </Observer>

                    <Grid xs={12} item>
                      <Grid item xs={12}>
                        <Divider my={2} width={160} />
                      </Grid>
                      <Observer>
                        {() => {
                          return (
                            <MonthPicker
                              {...bind('applyInMonths')}
                              fullWidth
                              header={t('common.monthPickerHeader', { year })}
                              year={year}
                              data-apply={form.getValue('applyRebate')}
                              disableMonth={(monthEnumType, month) => {
                                  if(confirmedAppliedInMonths && confirmedAppliedInMonths.includes(months[month])) {
                                    return true
                                  }
                                  if(form.getValue('applyInMonths').includes(monthEnumType)) {
                                    return false // already picked month is always possible to unpick, unless it was already confirmed
                                  }
                                  const student = form.getValue('student')
                                  const birthdate = form.getValue('birthdate')

                                  return !canPickMonth({
                                    student,
                                    birthdate,
                                    year,
                                    variant,
                                    dateOfEmployment: user.data.dateOfEmployment,
                                    minimumApplyDate: moment(getApplyFromInYear(user, year))
                                  })(month)
                                }
                              }
                            />
                          )
                        }}
                      </Observer>
                    </Grid>

                    <Grid
                      xs={12}
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item container direction="column">
                        <Grid item xs={12}>
                          <Divider my={2} width={160} />
                        </Grid>

                        <Grid xs={12} item>
                          <Observer>
                            {() => {
                              const isZtpp = form.getValue("ztpp")

                              return (
                                <Switch
                                  disabled={confirmedAppliedInMonths.length > 0 && isZtpp}
                                  {...bind('ztpp')}
                                />
                              )
                            }}
                          </Observer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid xs={12} item>
                      <Observer>
                        {() => (
                          <Collapse in={Boolean(form.getValue('ztpp'))}>
                            <Box
                              display="flex"
                              mb={2}
                              flexDirection={compactScreen ? 'column' : 'row'}
                            >
                              <DatePickerLabel first>
                                {t('common.from')}
                              </DatePickerLabel>

                              <DatePicker
                                {...bind('ztppFrom')}
                                maxDate={form.getValue('ztppTo')}
                                hideLabel
                                fullWidth
                                autoOk
                              />

                              <DatePickerLabel>
                                {t('common.to')}
                              </DatePickerLabel>

                              <DatePicker
                                {...bind('ztppTo')}
                                minDate={form.getValue('ztppFrom')}
                                hideLabel
                                fullWidth
                                autoOk
                              />
                            </Box>
                          </Collapse>
                        )}
                      </Observer>
                    </Grid>

                    <Grid xs={12} item>
                      <Divider maxWidth="100%" width={'590px'} my={2} />
                    </Grid>

                    <Grid xs={12} item>
                      <Typography variant="h2" align='center' gutterBottom>
                        {t('monthlyChildrenRebate.text2')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider my={2} width={160} />
                    </Grid>

                    <Grid xs={12} item>
                      <Observer>
                        {() => (
                          <Uploader
                            {...bind('birthCertificateFiles')}
                            deleteButton={!disabled}
                            multiple
                            showLabel
                            itemGridProps={{ sm: 12 }}
                          />
                        )}
                      </Observer>
                    </Grid>

                    <Observer>
                      {() => (
                        <Grid xs={12} item>
                          <Collapse in={Boolean(form.getValue('student'))}>
                            <Divider my={2} width={160} />

                            <Uploader
                              {...bind('studentConfirmationFiles')}
                              deleteButton={!disabled}
                              showLabel
                              multiple
                              itemGridProps={{ sm: 12 }}
                            />
                          </Collapse>
                        </Grid>
                      )}
                    </Observer>

                    <Observer>
                      {() => (
                        <>
                          <Grid xs={12} item>
                            <Collapse in={Boolean(form.getValue('ztpp'))}>
                              <Uploader
                                {...bind('ztppConfirmationFiles')}
                                deleteButton={!disabled}
                                showLabel
                                multiple
                                itemGridProps={{ sm: 12 }}
                              />
                            </Collapse>
                          </Grid>
                          <Grid xs={12} item>
                            <Collapse in={Boolean(form.getValue('ztpp'))}>
                              <Uploader
                                {...bind('socialConfirmationFiles')}
                                deleteButton={!disabled}
                                showLabel
                                multiple
                                itemGridProps={{ sm: 12 }}
                              />
                            </Collapse>
                          </Grid>
                        </>
                      )}
                    </Observer>
                  </>
                ) : (
                  <React.Fragment />
                )
              }
            </Observer>
          </Grid>
        </DialogContent>

        <DialogActions gutterBottom>
          <Button
            onClick={form.submit}
            color="primary"
            size="large"
            variant="contained"
          >
            {isNew
              ? t('monthlyChildrenRebate.createChildSubmitButton')
              : t('monthlyChildrenRebate.editChildSubmitButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChildDialog
