/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountantsStatusInput {
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
}

export enum AnnualRebateNameInput {
  BASIC = "BASIC",
  DISABILITY = "DISABILITY",
  EXAM = "EXAM",
  FORECLOSURE = "FORECLOSURE",
  GIFT = "GIFT",
  INVESTMENT = "INVESTMENT",
  LIFE_INSURANCE = "LIFE_INSURANCE",
  LOAN = "LOAN",
  LONG_TERM_CARE = "LONG_TERM_CARE",
  PENSION_INSURANCE = "PENSION_INSURANCE",
  PRESCHOOL = "PRESCHOOL",
  SPOUSE = "SPOUSE",
  UNION = "UNION",
  ZTPP = "ZTPP",
}

export enum AnnualWizardStep {
  DONE = "DONE",
  PREV_EMPLOYER = "PREV_EMPLOYER",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  REBATE = "REBATE",
  REBATE_MONTHLY = "REBATE_MONTHLY",
  SUMMARY = "SUMMARY",
  TAX_RESIDENCE = "TAX_RESIDENCE",
}

export enum ChildOrder {
  FIRST = "FIRST",
  SECOND = "SECOND",
  THIRD_AND_MORE = "THIRD_AND_MORE",
}

export enum Country {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  XB = "XB",
  XK = "XK",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum CustomerOrderByInput {
  activationAnnualtax_ASC = "activationAnnualtax_ASC",
  activationAnnualtax_DESC = "activationAnnualtax_DESC",
  activationAt_ASC = "activationAt_ASC",
  activationAt_DESC = "activationAt_DESC",
  activationFormDoneAt_ASC = "activationFormDoneAt_ASC",
  activationFormDoneAt_DESC = "activationFormDoneAt_DESC",
  activationFormStep_ASC = "activationFormStep_ASC",
  activationFormStep_DESC = "activationFormStep_DESC",
  activationKeeperIsEmployee_ASC = "activationKeeperIsEmployee_ASC",
  activationKeeperIsEmployee_DESC = "activationKeeperIsEmployee_DESC",
  address_ASC = "address_ASC",
  address_DESC = "address_DESC",
  allowApplyCurrentMonth_ASC = "allowApplyCurrentMonth_ASC",
  allowApplyCurrentMonth_DESC = "allowApplyCurrentMonth_DESC",
  allowDocumentsForViewer_ASC = "allowDocumentsForViewer_ASC",
  allowDocumentsForViewer_DESC = "allowDocumentsForViewer_DESC",
  allowFilesUpload_ASC = "allowFilesUpload_ASC",
  allowFilesUpload_DESC = "allowFilesUpload_DESC",
  allowMessagesForViewer_ASC = "allowMessagesForViewer_ASC",
  allowMessagesForViewer_DESC = "allowMessagesForViewer_DESC",
  allowMessages_ASC = "allowMessages_ASC",
  allowMessages_DESC = "allowMessages_DESC",
  allowOpenID_ASC = "allowOpenID_ASC",
  allowOpenID_DESC = "allowOpenID_DESC",
  allowPayslipsMultiupload_ASC = "allowPayslipsMultiupload_ASC",
  allowPayslipsMultiupload_DESC = "allowPayslipsMultiupload_DESC",
  annualRebatesExportType_ASC = "annualRebatesExportType_ASC",
  annualRebatesExportType_DESC = "annualRebatesExportType_DESC",
  annualRebatesPeriodEnd_ASC = "annualRebatesPeriodEnd_ASC",
  annualRebatesPeriodEnd_DESC = "annualRebatesPeriodEnd_DESC",
  annualRebatesPeriodExtraEnd_ASC = "annualRebatesPeriodExtraEnd_ASC",
  annualRebatesPeriodExtraEnd_DESC = "annualRebatesPeriodExtraEnd_DESC",
  annualRebatesPeriodStart_ASC = "annualRebatesPeriodStart_ASC",
  annualRebatesPeriodStart_DESC = "annualRebatesPeriodStart_DESC",
  blockedAt_ASC = "blockedAt_ASC",
  blockedAt_DESC = "blockedAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  deletedAt_ASC = "deletedAt_ASC",
  deletedAt_DESC = "deletedAt_DESC",
  dic_ASC = "dic_ASC",
  dic_DESC = "dic_DESC",
  divideByUnits_ASC = "divideByUnits_ASC",
  divideByUnits_DESC = "divideByUnits_DESC",
  dontRequireBasicRebateAffidavitFiles_ASC = "dontRequireBasicRebateAffidavitFiles_ASC",
  dontRequireBasicRebateAffidavitFiles_DESC = "dontRequireBasicRebateAffidavitFiles_DESC",
  groupName_ASC = "groupName_ASC",
  groupName_DESC = "groupName_DESC",
  ico_ASC = "ico_ASC",
  ico_DESC = "ico_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  openIDClientId_ASC = "openIDClientId_ASC",
  openIDClientId_DESC = "openIDClientId_DESC",
  openIDClientSecret_ASC = "openIDClientSecret_ASC",
  openIDClientSecret_DESC = "openIDClientSecret_DESC",
  openIDUrl_ASC = "openIDUrl_ASC",
  openIDUrl_DESC = "openIDUrl_DESC",
  rebateEndingsProcessedAt_ASC = "rebateEndingsProcessedAt_ASC",
  rebateEndingsProcessedAt_DESC = "rebateEndingsProcessedAt_DESC",
  requireCheckOfTerms_ASC = "requireCheckOfTerms_ASC",
  requireCheckOfTerms_DESC = "requireCheckOfTerms_DESC",
  requireNoTaxableIncomeAffidavit_ASC = "requireNoTaxableIncomeAffidavit_ASC",
  requireNoTaxableIncomeAffidavit_DESC = "requireNoTaxableIncomeAffidavit_DESC",
  search_ASC = "search_ASC",
  search_DESC = "search_DESC",
  sharedMessages_ASC = "sharedMessages_ASC",
  sharedMessages_DESC = "sharedMessages_DESC",
  ssoClientId_ASC = "ssoClientId_ASC",
  ssoClientId_DESC = "ssoClientId_DESC",
  ssoScopes_ASC = "ssoScopes_ASC",
  ssoScopes_DESC = "ssoScopes_DESC",
  ssoTenantId_ASC = "ssoTenantId_ASC",
  ssoTenantId_DESC = "ssoTenantId_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  vopAgreementAt_ASC = "vopAgreementAt_ASC",
  vopAgreementAt_DESC = "vopAgreementAt_DESC",
  yearOfAnnualRebates_ASC = "yearOfAnnualRebates_ASC",
  yearOfAnnualRebates_DESC = "yearOfAnnualRebates_DESC",
  yearOfMonthlyRebates_ASC = "yearOfMonthlyRebates_ASC",
  yearOfMonthlyRebates_DESC = "yearOfMonthlyRebates_DESC",
}

export enum CustomerTypeEnum {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
}

export enum DisabilityFileType {
  PAYMENT_CONFIRMATION = "PAYMENT_CONFIRMATION",
}

export enum DisabilityLevel {
  FIRST_AND_SECOND = "FIRST_AND_SECOND",
  THIRD = "THIRD",
}

export enum DocumentApprovalStatus {
  APPLY = "APPLY",
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  NEW = "NEW",
}

export enum DocumentApprovalType {
  RESIDENCE_AUTHORISATION = "RESIDENCE_AUTHORISATION",
  TAX_DOMICIL = "TAX_DOMICIL",
}

export enum DocumentTableOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  search_ASC = "search_ASC",
  search_DESC = "search_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  user_firstname_ASC = "user_firstname_ASC",
  user_firstname_DESC = "user_firstname_DESC",
  user_lastname_ASC = "user_lastname_ASC",
  user_lastname_DESC = "user_lastname_DESC",
}

export enum DocumentType {
  ELDP = "ELDP",
  EMPLOYMENT_RECORD = "EMPLOYMENT_RECORD",
  PAYSLIPS = "PAYSLIPS",
  PERSONAL = "PERSONAL",
  POZP = "POZP",
}

export enum EmployeeTypeEnum {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
}

export enum EmploymentType {
  DPC = "DPC",
  DPP = "DPP",
  HPP = "HPP",
  SVF = "SVF",
}

export enum ExportType {
  BYZNYS = "BYZNYS",
  EXPERT = "EXPERT",
  HELIOS = "HELIOS",
  HONEYWELL = "HONEYWELL",
  INFOSYS = "INFOSYS",
  IORGA = "IORGA",
  LORGA = "LORGA",
  NUGGET = "NUGGET",
  OTHER = "OTHER",
  SAP = "SAP",
}

export enum FileProcessingStatus {
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
}

export enum FileProcessingType {
  CUSTOMER_DELETE = "CUSTOMER_DELETE",
  REBATES_EXPORT = "REBATES_EXPORT",
  REBATE_FILE_EXPORT = "REBATE_FILE_EXPORT",
  REBATE_FILE_EXPORT_BULK = "REBATE_FILE_EXPORT_BULK",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum IDType {
  DRIVING_LICENCE = "DRIVING_LICENCE",
  IDENTITY_CARD = "IDENTITY_CARD",
  OTHER = "OTHER",
  PASSPORT = "PASSPORT",
}

export enum ImportLogInputEnum {
  EMPLOYEES = "EMPLOYEES",
}

export enum ImportType {
  EMPLOYEES = "EMPLOYEES",
}

export enum Language {
  CS = "CS",
  EN = "EN",
}

export enum LogsTableOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
}

export enum MessageLocation {
  ARCHIVE = "ARCHIVE",
  DELETED = "DELETED",
  INBOX = "INBOX",
  TRASH = "TRASH",
}

export enum MessageTableOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  location_ASC = "location_ASC",
  location_DESC = "location_DESC",
  subject_ASC = "subject_ASC",
  subject_DESC = "subject_DESC",
  systemMessage_ASC = "systemMessage_ASC",
  systemMessage_DESC = "systemMessage_DESC",
  text_ASC = "text_ASC",
  text_DESC = "text_DESC",
  user_firstname_ASC = "user_firstname_ASC",
  user_firstname_DESC = "user_firstname_DESC",
  user_lastname_ASC = "user_lastname_ASC",
  user_lastname_DESC = "user_lastname_DESC",
}

export enum MessageTypeEnum {
  ARCHIVED = "ARCHIVED",
  RECEIVED = "RECEIVED",
  SENT = "SENT",
  TRASH = "TRASH",
}

export enum Month {
  APRIL = "APRIL",
  AUGUST = "AUGUST",
  DECEMBER = "DECEMBER",
  FEBRUARY = "FEBRUARY",
  JANUARY = "JANUARY",
  JULY = "JULY",
  JUNE = "JUNE",
  MARCH = "MARCH",
  MAY = "MAY",
  NOVEMBER = "NOVEMBER",
  OCTOBER = "OCTOBER",
  SEPTEMBER = "SEPTEMBER",
}

export enum MonthlyRebateVariant {
  HISTORY = "HISTORY",
  NORMAL = "NORMAL",
}

export enum MonthlyWizardStep {
  DONE = "DONE",
  QUESTIONNAIRE = "QUESTIONNAIRE",
  REBATE = "REBATE",
  SUMMARY = "SUMMARY",
  TAX_RESIDENCE = "TAX_RESIDENCE",
}

export enum OwnRebateRequestTypeInput {
  ALTER = "ALTER",
  CLOSE_PERIOD = "CLOSE_PERIOD",
  NEW = "NEW",
  NEW_PERIOD = "NEW_PERIOD",
  REMOVE = "REMOVE",
}

export enum OwnRebateStatusInput {
  APPLY = "APPLY",
  NEW = "NEW",
}

export enum PrevEmployerStatus {
  APPLY = "APPLY",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
}

export enum PreviousEmployerStatus {
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  NEW = "NEW",
}

export enum RebateNameInput {
  BASIC = "BASIC",
  DISABILITY = "DISABILITY",
  ZTPP = "ZTPP",
}

export enum RebateOrderByInput {
  year_ASC = "year_ASC",
  year_DESC = "year_DESC",
}

/**
 * RebateStatus rozšířený o status UNFINISHED - jedná se o stav kdy user o něco žádá na slevě, ale ještě žádost neodeslal
 */
export enum RebateOverviewStatus {
  APPLY = "APPLY",
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  NEW = "NEW",
  UNFINISHED = "UNFINISHED",
}

export enum RebateStatus {
  APPLY = "APPLY",
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  NEW = "NEW",
}

export enum RebateTypeEnum {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
}

export enum RequestType {
  ALTER = "ALTER",
  CLOSE_PERIOD = "CLOSE_PERIOD",
  NEW = "NEW",
  NEW_PERIOD = "NEW_PERIOD",
  REMOVE = "REMOVE",
}

export enum Role {
  ACCOUNTANT = "ACCOUNTANT",
  EMPLOYEE = "EMPLOYEE",
  IMPLEMENTATOR = "IMPLEMENTATOR",
  KEEPER = "KEEPER",
  SUPERADMIN = "SUPERADMIN",
  SUPPORT = "SUPPORT",
  VIEWER = "VIEWER",
}

export enum SettlementRequestStatus {
  APPLY = "APPLY",
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
  NEW = "NEW",
}

export enum SettlementRequestTableOrderByInput {
  canceledAt_ASC = "canceledAt_ASC",
  canceledAt_DESC = "canceledAt_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  employee_dateOfEmployment_ASC = "employee_dateOfEmployment_ASC",
  employee_dateOfEmployment_DESC = "employee_dateOfEmployment_DESC",
  employee_firstname_ASC = "employee_firstname_ASC",
  employee_firstname_DESC = "employee_firstname_DESC",
  employee_lastname_ASC = "employee_lastname_ASC",
  employee_lastname_DESC = "employee_lastname_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  requestedAt_ASC = "requestedAt_ASC",
  requestedAt_DESC = "requestedAt_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  submittedAt_ASC = "submittedAt_ASC",
  submittedAt_DESC = "submittedAt_DESC",
  type_ASC = "type_ASC",
  type_DESC = "type_DESC",
  type_annual_FIRST = "type_annual_FIRST",
  type_history_FIRST = "type_history_FIRST",
  type_monthly_FIRST = "type_monthly_FIRST",
  type_pozp_FIRST = "type_pozp_FIRST",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  year_ASC = "year_ASC",
  year_DESC = "year_DESC",
}

export enum SettlementRequestType {
  ANNUAL = "ANNUAL",
  ANNUAL_CHANGE = "ANNUAL_CHANGE",
  ANNUAL_POZP = "ANNUAL_POZP",
  MONTHLY = "MONTHLY",
  MONTHLY_CHANGE = "MONTHLY_CHANGE",
  MONTHLY_DONT_CREATE = "MONTHLY_DONT_CREATE",
  MONTHLY_HISTORY = "MONTHLY_HISTORY",
  MONTHLY_HISTORY_CHANGE = "MONTHLY_HISTORY_CHANGE",
  MONTHLY_PREV_YEAR = "MONTHLY_PREV_YEAR",
}

export enum TaxQuestionnaireStatus {
  ALTER = "ALTER",
  APPLY = "APPLY",
  CONFIRMED = "CONFIRMED",
  DENIED = "DENIED",
}

export enum UserLogAction {
  DOCUMENT_CREATED = "DOCUMENT_CREATED",
  DOCUMENT_DELETED = "DOCUMENT_DELETED",
  DOCUMENT_UPDATED = "DOCUMENT_UPDATED",
  USER_UPDATED = "USER_UPDATED",
}

export enum UserLogSource {
  API = "API",
  APP = "APP",
}

export enum UserOrderByInput {
  dateOfEmployment_ASC = "dateOfEmployment_ASC",
  dateOfEmployment_DESC = "dateOfEmployment_DESC",
  lastname_ASC = "lastname_ASC",
  lastname_DESC = "lastname_DESC",
}

export enum UserStatusEnum {
  BLOCKED = "BLOCKED",
  DELETED = "DELETED",
  INVITED = "INVITED",
  REGISTERED = "REGISTERED",
}

export enum Work {
  EMPLOYER = "EMPLOYER",
  OSVC = "OSVC",
  UNENPLOYED = "UNENPLOYED",
}

export interface AccountCreateOneWithoutUsersInput {
  connect?: AccountWhereUniqueInput | null;
  create?: AccountCreateWithoutUsersInput | null;
}

export interface AccountCreateWithoutUsersInput {
  email: string;
  id?: string | null;
  language?: Language | null;
  lastLoginAt?: any | null;
  loginBlockedAt?: any | null;
  password?: string | null;
  registeredAt?: any | null;
  registrationToken?: string | null;
  requestLoginAttempts?: LoginRequestCreateManyWithoutAccountInput | null;
  requestLoginCode?: string | null;
  requestLoginExpiration?: any | null;
  requestLoginToken?: string | null;
  requestSSOLoginExpiration?: any | null;
  requestSSOLoginToken?: string | null;
  resetPasswordExpiration?: any | null;
  resetPasswordToken?: string | null;
  verificationToken?: string | null;
}

export interface AccountUpdateOneRequiredWithoutUsersInput {
  connect?: AccountWhereUniqueInput | null;
  create?: AccountCreateWithoutUsersInput | null;
  update?: AccountUpdateWithoutUsersDataInput | null;
  upsert?: AccountUpsertWithoutUsersInput | null;
}

export interface AccountUpdateWithoutUsersDataInput {
  email?: string | null;
  language?: Language | null;
  lastLoginAt?: any | null;
  loginBlockedAt?: any | null;
  password?: string | null;
  registeredAt?: any | null;
  registrationToken?: string | null;
  requestLoginAttempts?: LoginRequestUpdateManyWithoutAccountInput | null;
  requestLoginCode?: string | null;
  requestLoginExpiration?: any | null;
  requestLoginToken?: string | null;
  requestSSOLoginExpiration?: any | null;
  requestSSOLoginToken?: string | null;
  resetPasswordExpiration?: any | null;
  resetPasswordToken?: string | null;
  verificationToken?: string | null;
}

export interface AccountUpsertWithoutUsersInput {
  create: AccountCreateWithoutUsersInput;
  update: AccountUpdateWithoutUsersDataInput;
}

export interface AccountWhereInput {
  AND?: AccountWhereInput[] | null;
  email?: string | null;
  email_contains?: string | null;
  email_ends_with?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_not?: string | null;
  email_not_contains?: string | null;
  email_not_ends_with?: string | null;
  email_not_in?: string[] | null;
  email_not_starts_with?: string | null;
  email_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  language?: Language | null;
  language_in?: Language[] | null;
  language_not?: Language | null;
  language_not_in?: Language[] | null;
  lastLoginAt?: any | null;
  lastLoginAt_gt?: any | null;
  lastLoginAt_gte?: any | null;
  lastLoginAt_in?: any[] | null;
  lastLoginAt_lt?: any | null;
  lastLoginAt_lte?: any | null;
  lastLoginAt_not?: any | null;
  lastLoginAt_not_in?: any[] | null;
  loginBlockedAt?: any | null;
  loginBlockedAt_gt?: any | null;
  loginBlockedAt_gte?: any | null;
  loginBlockedAt_in?: any[] | null;
  loginBlockedAt_lt?: any | null;
  loginBlockedAt_lte?: any | null;
  loginBlockedAt_not?: any | null;
  loginBlockedAt_not_in?: any[] | null;
  NOT?: AccountWhereInput[] | null;
  OR?: AccountWhereInput[] | null;
  password?: string | null;
  password_contains?: string | null;
  password_ends_with?: string | null;
  password_gt?: string | null;
  password_gte?: string | null;
  password_in?: string[] | null;
  password_lt?: string | null;
  password_lte?: string | null;
  password_not?: string | null;
  password_not_contains?: string | null;
  password_not_ends_with?: string | null;
  password_not_in?: string[] | null;
  password_not_starts_with?: string | null;
  password_starts_with?: string | null;
  registeredAt?: any | null;
  registeredAt_gt?: any | null;
  registeredAt_gte?: any | null;
  registeredAt_in?: any[] | null;
  registeredAt_lt?: any | null;
  registeredAt_lte?: any | null;
  registeredAt_not?: any | null;
  registeredAt_not_in?: any[] | null;
  registrationToken?: string | null;
  registrationToken_contains?: string | null;
  registrationToken_ends_with?: string | null;
  registrationToken_gt?: string | null;
  registrationToken_gte?: string | null;
  registrationToken_in?: string[] | null;
  registrationToken_lt?: string | null;
  registrationToken_lte?: string | null;
  registrationToken_not?: string | null;
  registrationToken_not_contains?: string | null;
  registrationToken_not_ends_with?: string | null;
  registrationToken_not_in?: string[] | null;
  registrationToken_not_starts_with?: string | null;
  registrationToken_starts_with?: string | null;
  requestLoginAttempts_every?: LoginRequestWhereInput | null;
  requestLoginAttempts_none?: LoginRequestWhereInput | null;
  requestLoginAttempts_some?: LoginRequestWhereInput | null;
  requestLoginCode?: string | null;
  requestLoginCode_contains?: string | null;
  requestLoginCode_ends_with?: string | null;
  requestLoginCode_gt?: string | null;
  requestLoginCode_gte?: string | null;
  requestLoginCode_in?: string[] | null;
  requestLoginCode_lt?: string | null;
  requestLoginCode_lte?: string | null;
  requestLoginCode_not?: string | null;
  requestLoginCode_not_contains?: string | null;
  requestLoginCode_not_ends_with?: string | null;
  requestLoginCode_not_in?: string[] | null;
  requestLoginCode_not_starts_with?: string | null;
  requestLoginCode_starts_with?: string | null;
  requestLoginExpiration?: any | null;
  requestLoginExpiration_gt?: any | null;
  requestLoginExpiration_gte?: any | null;
  requestLoginExpiration_in?: any[] | null;
  requestLoginExpiration_lt?: any | null;
  requestLoginExpiration_lte?: any | null;
  requestLoginExpiration_not?: any | null;
  requestLoginExpiration_not_in?: any[] | null;
  requestLoginToken?: string | null;
  requestLoginToken_contains?: string | null;
  requestLoginToken_ends_with?: string | null;
  requestLoginToken_gt?: string | null;
  requestLoginToken_gte?: string | null;
  requestLoginToken_in?: string[] | null;
  requestLoginToken_lt?: string | null;
  requestLoginToken_lte?: string | null;
  requestLoginToken_not?: string | null;
  requestLoginToken_not_contains?: string | null;
  requestLoginToken_not_ends_with?: string | null;
  requestLoginToken_not_in?: string[] | null;
  requestLoginToken_not_starts_with?: string | null;
  requestLoginToken_starts_with?: string | null;
  requestSSOLoginExpiration?: any | null;
  requestSSOLoginExpiration_gt?: any | null;
  requestSSOLoginExpiration_gte?: any | null;
  requestSSOLoginExpiration_in?: any[] | null;
  requestSSOLoginExpiration_lt?: any | null;
  requestSSOLoginExpiration_lte?: any | null;
  requestSSOLoginExpiration_not?: any | null;
  requestSSOLoginExpiration_not_in?: any[] | null;
  requestSSOLoginToken?: string | null;
  requestSSOLoginToken_contains?: string | null;
  requestSSOLoginToken_ends_with?: string | null;
  requestSSOLoginToken_gt?: string | null;
  requestSSOLoginToken_gte?: string | null;
  requestSSOLoginToken_in?: string[] | null;
  requestSSOLoginToken_lt?: string | null;
  requestSSOLoginToken_lte?: string | null;
  requestSSOLoginToken_not?: string | null;
  requestSSOLoginToken_not_contains?: string | null;
  requestSSOLoginToken_not_ends_with?: string | null;
  requestSSOLoginToken_not_in?: string[] | null;
  requestSSOLoginToken_not_starts_with?: string | null;
  requestSSOLoginToken_starts_with?: string | null;
  resetPasswordExpiration?: any | null;
  resetPasswordExpiration_gt?: any | null;
  resetPasswordExpiration_gte?: any | null;
  resetPasswordExpiration_in?: any[] | null;
  resetPasswordExpiration_lt?: any | null;
  resetPasswordExpiration_lte?: any | null;
  resetPasswordExpiration_not?: any | null;
  resetPasswordExpiration_not_in?: any[] | null;
  resetPasswordToken?: string | null;
  resetPasswordToken_contains?: string | null;
  resetPasswordToken_ends_with?: string | null;
  resetPasswordToken_gt?: string | null;
  resetPasswordToken_gte?: string | null;
  resetPasswordToken_in?: string[] | null;
  resetPasswordToken_lt?: string | null;
  resetPasswordToken_lte?: string | null;
  resetPasswordToken_not?: string | null;
  resetPasswordToken_not_contains?: string | null;
  resetPasswordToken_not_ends_with?: string | null;
  resetPasswordToken_not_in?: string[] | null;
  resetPasswordToken_not_starts_with?: string | null;
  resetPasswordToken_starts_with?: string | null;
  users_every?: UserWhereInput | null;
  users_none?: UserWhereInput | null;
  users_some?: UserWhereInput | null;
  verificationToken?: string | null;
  verificationToken_contains?: string | null;
  verificationToken_ends_with?: string | null;
  verificationToken_gt?: string | null;
  verificationToken_gte?: string | null;
  verificationToken_in?: string[] | null;
  verificationToken_lt?: string | null;
  verificationToken_lte?: string | null;
  verificationToken_not?: string | null;
  verificationToken_not_contains?: string | null;
  verificationToken_not_ends_with?: string | null;
  verificationToken_not_in?: string[] | null;
  verificationToken_not_starts_with?: string | null;
  verificationToken_starts_with?: string | null;
}

export interface AccountWhereUniqueInput {
  email?: string | null;
  id?: string | null;
}

export interface AccountantsChildrenRebateInput {
  children?: AccountantsRebateSubInput[] | null;
  commentOfDenied?: string | null;
  otherParentCommentOfDenied?: string | null;
  otherParentStatus?: AccountantsStatusInput | null;
  status: AccountantsStatusInput;
}

export interface AccountantsDocumentApprovalInput {
  commentOfDenied?: string | null;
  documentsIds?: string[] | null;
  status: AccountantsStatusInput;
}

export interface AccountantsRebateInput {
  commentOfDenied?: string | null;
  status: AccountantsStatusInput;
}

export interface AccountantsRebateSubInput {
  commentOfDenied?: string | null;
  id: string;
  status: AccountantsStatusInput;
}

export interface AccountantsSettlementRequestUpdateInput {
  basicRebate?: AccountantsRebateInput | null;
  childrenRebate?: AccountantsChildrenRebateInput | null;
  disabilityRebate?: AccountantsRebateInput | null;
  examRebate?: AccountantsRebateInput | null;
  foreclosureRebate?: AccountantsRebateInput | null;
  giftRebate?: AccountantsRebateInput | null;
  investmentRebate?: AccountantsRebateInput | null;
  lifeInsuranceRebate?: AccountantsRebateInput | null;
  loanRebate?: AccountantsRebateInput | null;
  longTermCareRebate?: AccountantsRebateInput | null;
  monthlyTaxQuestionnaire?: AccountantsRebateInput | null;
  pensionInsuranceRebate?: AccountantsRebateInput | null;
  preschoolRebate?: AccountantsRebateInput | null;
  prevEmployers?: AccountantsRebateSubInput[] | null;
  residenceAuthorisation?: AccountantsDocumentApprovalInput | null;
  spouseRebate?: AccountantsRebateInput | null;
  studentRebate?: AccountantsRebateInput | null;
  taxDomicil?: AccountantsDocumentApprovalInput | null;
  taxQuestionnaire?: AccountantsRebateInput | null;
  unionRebate?: AccountantsRebateInput | null;
  ztppRebate?: AccountantsRebateInput | null;
}

export interface AdminStatisticsWhereInput {
  customers?: string[] | null;
  yearAnnual?: string | null;
  yearMonthly?: string | null;
}

export interface AnnualPreviousEmployersCreateInput {
  employers?: PreviousEmployerCreateManyInput | null;
  hadPreviousEmployers?: boolean | null;
  id?: string | null;
}

export interface AnnualPreviousEmployersCreateManyInput {
  connect?: AnnualPreviousEmployersWhereUniqueInput[] | null;
  create?: AnnualPreviousEmployersCreateInput[] | null;
}

export interface AnnualPreviousEmployersCreateOneInput {
  connect?: AnnualPreviousEmployersWhereUniqueInput | null;
  create?: AnnualPreviousEmployersCreateInput | null;
}

export interface AnnualPreviousEmployersScalarWhereInput {
  AND?: AnnualPreviousEmployersScalarWhereInput[] | null;
  hadPreviousEmployers?: boolean | null;
  hadPreviousEmployers_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: AnnualPreviousEmployersScalarWhereInput[] | null;
  OR?: AnnualPreviousEmployersScalarWhereInput[] | null;
}

export interface AnnualPreviousEmployersUpdateDataInput {
  employers?: PreviousEmployerUpdateManyInput | null;
  hadPreviousEmployers?: boolean | null;
}

export interface AnnualPreviousEmployersUpdateManyDataInput {
  hadPreviousEmployers?: boolean | null;
}

export interface AnnualPreviousEmployersUpdateManyInput {
  connect?: AnnualPreviousEmployersWhereUniqueInput[] | null;
  create?: AnnualPreviousEmployersCreateInput[] | null;
  delete?: AnnualPreviousEmployersWhereUniqueInput[] | null;
  deleteMany?: AnnualPreviousEmployersScalarWhereInput[] | null;
  disconnect?: AnnualPreviousEmployersWhereUniqueInput[] | null;
  set?: AnnualPreviousEmployersWhereUniqueInput[] | null;
  update?: AnnualPreviousEmployersUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: AnnualPreviousEmployersUpdateManyWithWhereNestedInput[] | null;
  upsert?: AnnualPreviousEmployersUpsertWithWhereUniqueNestedInput[] | null;
}

export interface AnnualPreviousEmployersUpdateManyWithWhereNestedInput {
  data: AnnualPreviousEmployersUpdateManyDataInput;
  where: AnnualPreviousEmployersScalarWhereInput;
}

export interface AnnualPreviousEmployersUpdateOneRequiredInput {
  connect?: AnnualPreviousEmployersWhereUniqueInput | null;
  create?: AnnualPreviousEmployersCreateInput | null;
  update?: AnnualPreviousEmployersUpdateDataInput | null;
  upsert?: AnnualPreviousEmployersUpsertNestedInput | null;
}

export interface AnnualPreviousEmployersUpdateWithWhereUniqueNestedInput {
  data: AnnualPreviousEmployersUpdateDataInput;
  where: AnnualPreviousEmployersWhereUniqueInput;
}

export interface AnnualPreviousEmployersUpsertNestedInput {
  create: AnnualPreviousEmployersCreateInput;
  update: AnnualPreviousEmployersUpdateDataInput;
}

export interface AnnualPreviousEmployersUpsertWithWhereUniqueNestedInput {
  create: AnnualPreviousEmployersCreateInput;
  update: AnnualPreviousEmployersUpdateDataInput;
  where: AnnualPreviousEmployersWhereUniqueInput;
}

export interface AnnualPreviousEmployersWhereInput {
  AND?: AnnualPreviousEmployersWhereInput[] | null;
  employers_every?: PreviousEmployerWhereInput | null;
  employers_none?: PreviousEmployerWhereInput | null;
  employers_some?: PreviousEmployerWhereInput | null;
  hadPreviousEmployers?: boolean | null;
  hadPreviousEmployers_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: AnnualPreviousEmployersWhereInput[] | null;
  OR?: AnnualPreviousEmployersWhereInput[] | null;
}

export interface AnnualPreviousEmployersWhereUniqueInput {
  id?: string | null;
}

export interface AnnualQuestionnaireCreateInput {
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  id?: string | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileCreateManyInput | null;
  laborOfficeMonths?: AnnualQuestionnaireCreatelaborOfficeMonthsInput | null;
  noTaxableIncomeFiles?: FileCreateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
}

export interface AnnualQuestionnaireCreateManyInput {
  connect?: AnnualQuestionnaireWhereUniqueInput[] | null;
  create?: AnnualQuestionnaireCreateInput[] | null;
}

export interface AnnualQuestionnaireCreateOneInput {
  connect?: AnnualQuestionnaireWhereUniqueInput | null;
  create?: AnnualQuestionnaireCreateInput | null;
}

export interface AnnualQuestionnaireCreatelaborOfficeMonthsInput {
  set?: Month[] | null;
}

export interface AnnualQuestionnaireScalarWhereInput {
  AND?: AnnualQuestionnaireScalarWhereInput[] | null;
  concurrentEmployers?: boolean | null;
  concurrentEmployers_not?: boolean | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  foreignIncome?: boolean | null;
  foreignIncome_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  laborOffice?: boolean | null;
  laborOffice_not?: boolean | null;
  NOT?: AnnualQuestionnaireScalarWhereInput[] | null;
  OR?: AnnualQuestionnaireScalarWhereInput[] | null;
  otherIncomes?: boolean | null;
  otherIncomes_not?: boolean | null;
  samePreviousEmployer?: boolean | null;
  samePreviousEmployer_not?: boolean | null;
  selfEmployed?: boolean | null;
  selfEmployed_not?: boolean | null;
  solidarityTax?: boolean | null;
  solidarityTax_not?: boolean | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface AnnualQuestionnaireUpdateDataInput {
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileUpdateManyInput | null;
  laborOfficeMonths?: AnnualQuestionnaireUpdatelaborOfficeMonthsInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
}

export interface AnnualQuestionnaireUpdateManyDataInput {
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeMonths?: AnnualQuestionnaireUpdatelaborOfficeMonthsInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
}

export interface AnnualQuestionnaireUpdateManyInput {
  connect?: AnnualQuestionnaireWhereUniqueInput[] | null;
  create?: AnnualQuestionnaireCreateInput[] | null;
  delete?: AnnualQuestionnaireWhereUniqueInput[] | null;
  deleteMany?: AnnualQuestionnaireScalarWhereInput[] | null;
  disconnect?: AnnualQuestionnaireWhereUniqueInput[] | null;
  set?: AnnualQuestionnaireWhereUniqueInput[] | null;
  update?: AnnualQuestionnaireUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: AnnualQuestionnaireUpdateManyWithWhereNestedInput[] | null;
  upsert?: AnnualQuestionnaireUpsertWithWhereUniqueNestedInput[] | null;
}

export interface AnnualQuestionnaireUpdateManyWithWhereNestedInput {
  data: AnnualQuestionnaireUpdateManyDataInput;
  where: AnnualQuestionnaireScalarWhereInput;
}

export interface AnnualQuestionnaireUpdateOneRequiredInput {
  connect?: AnnualQuestionnaireWhereUniqueInput | null;
  create?: AnnualQuestionnaireCreateInput | null;
  update?: AnnualQuestionnaireUpdateDataInput | null;
  upsert?: AnnualQuestionnaireUpsertNestedInput | null;
}

export interface AnnualQuestionnaireUpdateWithWhereUniqueNestedInput {
  data: AnnualQuestionnaireUpdateDataInput;
  where: AnnualQuestionnaireWhereUniqueInput;
}

export interface AnnualQuestionnaireUpdatelaborOfficeMonthsInput {
  set?: Month[] | null;
}

export interface AnnualQuestionnaireUpsertNestedInput {
  create: AnnualQuestionnaireCreateInput;
  update: AnnualQuestionnaireUpdateDataInput;
}

export interface AnnualQuestionnaireUpsertWithWhereUniqueNestedInput {
  create: AnnualQuestionnaireCreateInput;
  update: AnnualQuestionnaireUpdateDataInput;
  where: AnnualQuestionnaireWhereUniqueInput;
}

export interface AnnualQuestionnaireWhereInput {
  AND?: AnnualQuestionnaireWhereInput[] | null;
  concurrentEmployers?: boolean | null;
  concurrentEmployers_not?: boolean | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  foreignIncome?: boolean | null;
  foreignIncome_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  laborOffice?: boolean | null;
  laborOffice_not?: boolean | null;
  laborOfficeFiles_every?: FileWhereInput | null;
  laborOfficeFiles_none?: FileWhereInput | null;
  laborOfficeFiles_some?: FileWhereInput | null;
  NOT?: AnnualQuestionnaireWhereInput[] | null;
  noTaxableIncomeFiles_every?: FileWhereInput | null;
  noTaxableIncomeFiles_none?: FileWhereInput | null;
  noTaxableIncomeFiles_some?: FileWhereInput | null;
  OR?: AnnualQuestionnaireWhereInput[] | null;
  otherIncomes?: boolean | null;
  otherIncomes_not?: boolean | null;
  samePreviousEmployer?: boolean | null;
  samePreviousEmployer_not?: boolean | null;
  selfEmployed?: boolean | null;
  selfEmployed_not?: boolean | null;
  solidarityTax?: boolean | null;
  solidarityTax_not?: boolean | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface AnnualQuestionnaireWhereUniqueInput {
  id?: string | null;
}

export interface AnnualRebateCreateInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateManyWithoutUserInput {
  connect?: AnnualRebateWhereUniqueInput[] | null;
  create?: AnnualRebateCreateWithoutUserInput[] | null;
}

export interface AnnualRebateCreateOneInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateInput | null;
}

export interface AnnualRebateCreateOneWithoutBasicRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutBasicRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutChildrenRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutChildrenRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutDisabilityRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutDisabilityRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutExamRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutExamRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutForeclosureRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutForeclosureRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutGiftRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutGiftRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutInvestmentRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutInvestmentRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutLifeInsuranceRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutLifeInsuranceRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutLoanRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutLoanRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutLongTermCareRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutLongTermCareRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutPensionInsuranceRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutPensionInsuranceRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutPreschoolRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutPreschoolRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutPrevEmployersInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutPrevEmployersInput | null;
}

export interface AnnualRebateCreateOneWithoutSettlementRequestInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutSettlementRequestInput | null;
}

export interface AnnualRebateCreateOneWithoutSettlementRequestsInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutSettlementRequestsInput | null;
}

export interface AnnualRebateCreateOneWithoutSpouseRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutSpouseRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutStudentRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutStudentRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutTaxQuestionnaireInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutTaxQuestionnaireInput | null;
}

export interface AnnualRebateCreateOneWithoutUnionRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutUnionRebatesInput | null;
}

export interface AnnualRebateCreateOneWithoutZtppRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutZtppRebatesInput | null;
}

export interface AnnualRebateCreateWithoutBasicRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutChildrenRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutDisabilityRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutExamRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutForeclosureRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutGiftRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutInvestmentRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutLifeInsuranceRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutLoanRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutLongTermCareRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutPensionInsuranceRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutPreschoolRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutPrevEmployersInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutSettlementRequestInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutSettlementRequestsInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutSpouseRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutStudentRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutTaxQuestionnaireInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutUnionRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutUserInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateCreateWithoutZtppRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutAnnualRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutAnnualRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutAnnualRebateInput | null;
  examRebate: ExamRebateCreateOneInput;
  examRebates?: ExamRebateCreateManyWithoutAnnualRebateInput | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  fileMonthly?: FileCreateOneInput | null;
  foreclosureRebate: ForeclosureRebateCreateOneInput;
  foreclosureRebates?: ForeclosureRebateCreateManyWithoutAnnualRebateInput | null;
  giftRebate: GiftRebateCreateOneInput;
  giftRebates?: GiftRebateCreateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneInput | null;
  investmentRebates?: InvestmentRebateCreateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate: LifeInsuranceRebateCreateOneInput;
  lifeInsuranceRebates?: LifeInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  loanRebate: LoanRebateCreateOneInput;
  loanRebates?: LoanRebateCreateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneInput | null;
  longTermCareRebates?: LongTermCareRebateCreateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate: PensionInsuranceRebateCreateOneInput;
  pensionInsuranceRebates?: PensionInsuranceRebateCreateManyWithoutAnnualRebateInput | null;
  preschoolRebate: PreschoolRebateCreateOneInput;
  preschoolRebates?: PreschoolRebateCreateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerCreateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo: PrevEmployersSummaryInfoCreateOneInput;
  prevEmployersTouched?: boolean | null;
  previousEmployer: AnnualPreviousEmployersCreateOneInput;
  previousEmployers?: AnnualPreviousEmployersCreateManyInput | null;
  questionnaire: AnnualQuestionnaireCreateOneInput;
  questionnaires?: AnnualQuestionnaireCreateManyInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate: SpouseRebateCreateOneInput;
  spouseRebates?: SpouseRebateCreateManyWithoutAnnualRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: TaxQuestionnaireCreateOneWithoutAnnualRebateInput;
  unionRebate: UnionRebateCreateOneInput;
  unionRebates?: UnionRebateCreateManyWithoutAnnualRebateInput | null;
  user: UserCreateOneWithoutAnnualRebatesInput;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
}

export interface AnnualRebateScalarWhereInput {
  agreementAt?: any | null;
  agreementAt_gt?: any | null;
  agreementAt_gte?: any | null;
  agreementAt_in?: any[] | null;
  agreementAt_lt?: any | null;
  agreementAt_lte?: any | null;
  agreementAt_not?: any | null;
  agreementAt_not_in?: any[] | null;
  allowSendAnythingOnce?: boolean | null;
  allowSendAnythingOnce_not?: boolean | null;
  AND?: AnnualRebateScalarWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  hasPrevEmployers?: boolean | null;
  hasPrevEmployers_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: AnnualRebateScalarWhereInput[] | null;
  OR?: AnnualRebateScalarWhereInput[] | null;
  prevEmployersTouched?: boolean | null;
  prevEmployersTouched_not?: boolean | null;
  submitedAt?: any | null;
  submitedAt_gt?: any | null;
  submitedAt_gte?: any | null;
  submitedAt_in?: any[] | null;
  submitedAt_lt?: any | null;
  submitedAt_lte?: any | null;
  submitedAt_not?: any | null;
  submitedAt_not_in?: any[] | null;
  wizardFinishedAt?: any | null;
  wizardFinishedAt_gt?: any | null;
  wizardFinishedAt_gte?: any | null;
  wizardFinishedAt_in?: any[] | null;
  wizardFinishedAt_lt?: any | null;
  wizardFinishedAt_lte?: any | null;
  wizardFinishedAt_not?: any | null;
  wizardFinishedAt_not_in?: any[] | null;
  wizardStartedAt?: any | null;
  wizardStartedAt_gt?: any | null;
  wizardStartedAt_gte?: any | null;
  wizardStartedAt_in?: any[] | null;
  wizardStartedAt_lt?: any | null;
  wizardStartedAt_lte?: any | null;
  wizardStartedAt_not?: any | null;
  wizardStartedAt_not_in?: any[] | null;
  wizardStep?: AnnualWizardStep | null;
  wizardStep_in?: AnnualWizardStep[] | null;
  wizardStep_not?: AnnualWizardStep | null;
  wizardStep_not_in?: AnnualWizardStep[] | null;
  year?: number | null;
  year_gt?: number | null;
  year_gte?: number | null;
  year_in?: number[] | null;
  year_lt?: number | null;
  year_lte?: number | null;
  year_not?: number | null;
  year_not_in?: number[] | null;
}

export interface AnnualRebateUpdateDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateManyDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  hasPrevEmployers?: boolean | null;
  prevEmployersTouched?: boolean | null;
  submitedAt?: any | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
}

export interface AnnualRebateUpdateManyWithWhereNestedInput {
  data: AnnualRebateUpdateManyDataInput;
  where: AnnualRebateScalarWhereInput;
}

export interface AnnualRebateUpdateManyWithoutUserInput {
  connect?: AnnualRebateWhereUniqueInput[] | null;
  create?: AnnualRebateCreateWithoutUserInput[] | null;
  delete?: AnnualRebateWhereUniqueInput[] | null;
  deleteMany?: AnnualRebateScalarWhereInput[] | null;
  disconnect?: AnnualRebateWhereUniqueInput[] | null;
  set?: AnnualRebateWhereUniqueInput[] | null;
  update?: AnnualRebateUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: AnnualRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: AnnualRebateUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface AnnualRebateUpdateOneRequiredInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateInput | null;
  update?: AnnualRebateUpdateDataInput | null;
  upsert?: AnnualRebateUpsertNestedInput | null;
}

export interface AnnualRebateUpdateOneRequiredWithoutPrevEmployersInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutPrevEmployersInput | null;
  update?: AnnualRebateUpdateWithoutPrevEmployersDataInput | null;
  upsert?: AnnualRebateUpsertWithoutPrevEmployersInput | null;
}

export interface AnnualRebateUpdateOneRequiredWithoutTaxQuestionnaireInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutTaxQuestionnaireInput | null;
  update?: AnnualRebateUpdateWithoutTaxQuestionnaireDataInput | null;
  upsert?: AnnualRebateUpsertWithoutTaxQuestionnaireInput | null;
}

export interface AnnualRebateUpdateOneWithoutBasicRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutBasicRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutBasicRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutBasicRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutChildrenRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutChildrenRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutChildrenRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutChildrenRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutDisabilityRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutDisabilityRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutDisabilityRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutDisabilityRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutExamRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutExamRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutExamRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutExamRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutForeclosureRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutForeclosureRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutForeclosureRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutForeclosureRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutGiftRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutGiftRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutGiftRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutGiftRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutInvestmentRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutInvestmentRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutInvestmentRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutInvestmentRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutLifeInsuranceRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutLifeInsuranceRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutLifeInsuranceRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutLifeInsuranceRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutLoanRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutLoanRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutLoanRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutLoanRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutLongTermCareRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutLongTermCareRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutLongTermCareRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutLongTermCareRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutPensionInsuranceRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutPensionInsuranceRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutPensionInsuranceRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutPensionInsuranceRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutPreschoolRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutPreschoolRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutPreschoolRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutPreschoolRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutSettlementRequestInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: AnnualRebateUpsertWithoutSettlementRequestInput | null;
}

export interface AnnualRebateUpdateOneWithoutSettlementRequestsInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutSettlementRequestsInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutSettlementRequestsDataInput | null;
  upsert?: AnnualRebateUpsertWithoutSettlementRequestsInput | null;
}

export interface AnnualRebateUpdateOneWithoutSpouseRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutSpouseRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutSpouseRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutSpouseRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutStudentRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutStudentRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutStudentRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutStudentRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutUnionRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutUnionRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutUnionRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutUnionRebatesInput | null;
}

export interface AnnualRebateUpdateOneWithoutZtppRebatesInput {
  connect?: AnnualRebateWhereUniqueInput | null;
  create?: AnnualRebateCreateWithoutZtppRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualRebateUpdateWithoutZtppRebatesDataInput | null;
  upsert?: AnnualRebateUpsertWithoutZtppRebatesInput | null;
}

export interface AnnualRebateUpdateWithWhereUniqueWithoutUserInput {
  data: AnnualRebateUpdateWithoutUserDataInput;
  where: AnnualRebateWhereUniqueInput;
}

export interface AnnualRebateUpdateWithoutBasicRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutChildrenRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutDisabilityRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutExamRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutForeclosureRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutGiftRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutInvestmentRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutLifeInsuranceRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutLoanRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutLongTermCareRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutPensionInsuranceRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutPreschoolRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutPrevEmployersDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutSettlementRequestDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutSettlementRequestsDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutSpouseRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutStudentRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutTaxQuestionnaireDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutUnionRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutUserDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutAnnualRebateInput | null;
}

export interface AnnualRebateUpdateWithoutZtppRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutAnnualRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutAnnualRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutAnnualRebateInput | null;
  examRebate?: ExamRebateUpdateOneRequiredInput | null;
  examRebates?: ExamRebateUpdateManyWithoutAnnualRebateInput | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  fileMonthly?: FileUpdateOneInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneRequiredInput | null;
  foreclosureRebates?: ForeclosureRebateUpdateManyWithoutAnnualRebateInput | null;
  giftRebate?: GiftRebateUpdateOneRequiredInput | null;
  giftRebates?: GiftRebateUpdateManyWithoutAnnualRebateInput | null;
  hasPrevEmployers?: boolean | null;
  investmentRebate?: InvestmentRebateUpdateOneInput | null;
  investmentRebates?: InvestmentRebateUpdateManyWithoutAnnualRebateInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneRequiredInput | null;
  lifeInsuranceRebates?: LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  loanRebate?: LoanRebateUpdateOneRequiredInput | null;
  loanRebates?: LoanRebateUpdateManyWithoutAnnualRebateInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneInput | null;
  longTermCareRebates?: LongTermCareRebateUpdateManyWithoutAnnualRebateInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneRequiredInput | null;
  pensionInsuranceRebates?: PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneRequiredInput | null;
  preschoolRebates?: PreschoolRebateUpdateManyWithoutAnnualRebateInput | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoUpdateOneRequiredInput | null;
  prevEmployersTouched?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersUpdateOneRequiredInput | null;
  previousEmployers?: AnnualPreviousEmployersUpdateManyInput | null;
  questionnaire?: AnnualQuestionnaireUpdateOneRequiredInput | null;
  questionnaires?: AnnualQuestionnaireUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutAnnualRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput | null;
  spouseRebate?: SpouseRebateUpdateOneRequiredInput | null;
  spouseRebates?: SpouseRebateUpdateManyWithoutAnnualRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutAnnualRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput | null;
  unionRebate?: UnionRebateUpdateOneRequiredInput | null;
  unionRebates?: UnionRebateUpdateManyWithoutAnnualRebateInput | null;
  user?: UserUpdateOneRequiredWithoutAnnualRebatesInput | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: AnnualWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
}

export interface AnnualRebateUpsertNestedInput {
  create: AnnualRebateCreateInput;
  update: AnnualRebateUpdateDataInput;
}

export interface AnnualRebateUpsertWithWhereUniqueWithoutUserInput {
  create: AnnualRebateCreateWithoutUserInput;
  update: AnnualRebateUpdateWithoutUserDataInput;
  where: AnnualRebateWhereUniqueInput;
}

export interface AnnualRebateUpsertWithoutBasicRebatesInput {
  create: AnnualRebateCreateWithoutBasicRebatesInput;
  update: AnnualRebateUpdateWithoutBasicRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutChildrenRebatesInput {
  create: AnnualRebateCreateWithoutChildrenRebatesInput;
  update: AnnualRebateUpdateWithoutChildrenRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutDisabilityRebatesInput {
  create: AnnualRebateCreateWithoutDisabilityRebatesInput;
  update: AnnualRebateUpdateWithoutDisabilityRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutExamRebatesInput {
  create: AnnualRebateCreateWithoutExamRebatesInput;
  update: AnnualRebateUpdateWithoutExamRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutForeclosureRebatesInput {
  create: AnnualRebateCreateWithoutForeclosureRebatesInput;
  update: AnnualRebateUpdateWithoutForeclosureRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutGiftRebatesInput {
  create: AnnualRebateCreateWithoutGiftRebatesInput;
  update: AnnualRebateUpdateWithoutGiftRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutInvestmentRebatesInput {
  create: AnnualRebateCreateWithoutInvestmentRebatesInput;
  update: AnnualRebateUpdateWithoutInvestmentRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutLifeInsuranceRebatesInput {
  create: AnnualRebateCreateWithoutLifeInsuranceRebatesInput;
  update: AnnualRebateUpdateWithoutLifeInsuranceRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutLoanRebatesInput {
  create: AnnualRebateCreateWithoutLoanRebatesInput;
  update: AnnualRebateUpdateWithoutLoanRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutLongTermCareRebatesInput {
  create: AnnualRebateCreateWithoutLongTermCareRebatesInput;
  update: AnnualRebateUpdateWithoutLongTermCareRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutPensionInsuranceRebatesInput {
  create: AnnualRebateCreateWithoutPensionInsuranceRebatesInput;
  update: AnnualRebateUpdateWithoutPensionInsuranceRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutPreschoolRebatesInput {
  create: AnnualRebateCreateWithoutPreschoolRebatesInput;
  update: AnnualRebateUpdateWithoutPreschoolRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutPrevEmployersInput {
  create: AnnualRebateCreateWithoutPrevEmployersInput;
  update: AnnualRebateUpdateWithoutPrevEmployersDataInput;
}

export interface AnnualRebateUpsertWithoutSettlementRequestInput {
  create: AnnualRebateCreateWithoutSettlementRequestInput;
  update: AnnualRebateUpdateWithoutSettlementRequestDataInput;
}

export interface AnnualRebateUpsertWithoutSettlementRequestsInput {
  create: AnnualRebateCreateWithoutSettlementRequestsInput;
  update: AnnualRebateUpdateWithoutSettlementRequestsDataInput;
}

export interface AnnualRebateUpsertWithoutSpouseRebatesInput {
  create: AnnualRebateCreateWithoutSpouseRebatesInput;
  update: AnnualRebateUpdateWithoutSpouseRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutStudentRebatesInput {
  create: AnnualRebateCreateWithoutStudentRebatesInput;
  update: AnnualRebateUpdateWithoutStudentRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutTaxQuestionnaireInput {
  create: AnnualRebateCreateWithoutTaxQuestionnaireInput;
  update: AnnualRebateUpdateWithoutTaxQuestionnaireDataInput;
}

export interface AnnualRebateUpsertWithoutUnionRebatesInput {
  create: AnnualRebateCreateWithoutUnionRebatesInput;
  update: AnnualRebateUpdateWithoutUnionRebatesDataInput;
}

export interface AnnualRebateUpsertWithoutZtppRebatesInput {
  create: AnnualRebateCreateWithoutZtppRebatesInput;
  update: AnnualRebateUpdateWithoutZtppRebatesDataInput;
}

export interface AnnualRebateWhereInput {
  agreementAt?: any | null;
  agreementAt_gt?: any | null;
  agreementAt_gte?: any | null;
  agreementAt_in?: any[] | null;
  agreementAt_lt?: any | null;
  agreementAt_lte?: any | null;
  agreementAt_not?: any | null;
  agreementAt_not_in?: any[] | null;
  allowSendAnythingOnce?: boolean | null;
  allowSendAnythingOnce_not?: boolean | null;
  AND?: AnnualRebateWhereInput[] | null;
  basicRebate?: BasicRebateWhereInput | null;
  basicRebates_every?: BasicRebateWhereInput | null;
  basicRebates_none?: BasicRebateWhereInput | null;
  basicRebates_some?: BasicRebateWhereInput | null;
  childrenRebate?: ChildrenRebateWhereInput | null;
  childrenRebates_every?: ChildrenRebateWhereInput | null;
  childrenRebates_none?: ChildrenRebateWhereInput | null;
  childrenRebates_some?: ChildrenRebateWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  disabilityRebate?: DisabilityRebateWhereInput | null;
  disabilityRebates_every?: DisabilityRebateWhereInput | null;
  disabilityRebates_none?: DisabilityRebateWhereInput | null;
  disabilityRebates_some?: DisabilityRebateWhereInput | null;
  examRebate?: ExamRebateWhereInput | null;
  examRebates_every?: ExamRebateWhereInput | null;
  examRebates_none?: ExamRebateWhereInput | null;
  examRebates_some?: ExamRebateWhereInput | null;
  file?: FileWhereInput | null;
  fileExports_every?: FileProcessingWhereInput | null;
  fileExports_none?: FileProcessingWhereInput | null;
  fileExports_some?: FileProcessingWhereInput | null;
  fileMonthly?: FileWhereInput | null;
  foreclosureRebate?: ForeclosureRebateWhereInput | null;
  foreclosureRebates_every?: ForeclosureRebateWhereInput | null;
  foreclosureRebates_none?: ForeclosureRebateWhereInput | null;
  foreclosureRebates_some?: ForeclosureRebateWhereInput | null;
  giftRebate?: GiftRebateWhereInput | null;
  giftRebates_every?: GiftRebateWhereInput | null;
  giftRebates_none?: GiftRebateWhereInput | null;
  giftRebates_some?: GiftRebateWhereInput | null;
  hasPrevEmployers?: boolean | null;
  hasPrevEmployers_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  investmentRebate?: InvestmentRebateWhereInput | null;
  investmentRebates_every?: InvestmentRebateWhereInput | null;
  investmentRebates_none?: InvestmentRebateWhereInput | null;
  investmentRebates_some?: InvestmentRebateWhereInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateWhereInput | null;
  lifeInsuranceRebates_every?: LifeInsuranceRebateWhereInput | null;
  lifeInsuranceRebates_none?: LifeInsuranceRebateWhereInput | null;
  lifeInsuranceRebates_some?: LifeInsuranceRebateWhereInput | null;
  loanRebate?: LoanRebateWhereInput | null;
  loanRebates_every?: LoanRebateWhereInput | null;
  loanRebates_none?: LoanRebateWhereInput | null;
  loanRebates_some?: LoanRebateWhereInput | null;
  longTermCareRebate?: LongTermCareRebateWhereInput | null;
  longTermCareRebates_every?: LongTermCareRebateWhereInput | null;
  longTermCareRebates_none?: LongTermCareRebateWhereInput | null;
  longTermCareRebates_some?: LongTermCareRebateWhereInput | null;
  NOT?: AnnualRebateWhereInput[] | null;
  OR?: AnnualRebateWhereInput[] | null;
  pensionInsuranceRebate?: PensionInsuranceRebateWhereInput | null;
  pensionInsuranceRebates_every?: PensionInsuranceRebateWhereInput | null;
  pensionInsuranceRebates_none?: PensionInsuranceRebateWhereInput | null;
  pensionInsuranceRebates_some?: PensionInsuranceRebateWhereInput | null;
  preschoolRebate?: PreschoolRebateWhereInput | null;
  preschoolRebates_every?: PreschoolRebateWhereInput | null;
  preschoolRebates_none?: PreschoolRebateWhereInput | null;
  preschoolRebates_some?: PreschoolRebateWhereInput | null;
  prevEmployers_every?: PrevEmployerWhereInput | null;
  prevEmployers_none?: PrevEmployerWhereInput | null;
  prevEmployers_some?: PrevEmployerWhereInput | null;
  prevEmployersSummaryInfo?: PrevEmployersSummaryInfoWhereInput | null;
  prevEmployersTouched?: boolean | null;
  prevEmployersTouched_not?: boolean | null;
  previousEmployer?: AnnualPreviousEmployersWhereInput | null;
  previousEmployers_every?: AnnualPreviousEmployersWhereInput | null;
  previousEmployers_none?: AnnualPreviousEmployersWhereInput | null;
  previousEmployers_some?: AnnualPreviousEmployersWhereInput | null;
  questionnaire?: AnnualQuestionnaireWhereInput | null;
  questionnaires_every?: AnnualQuestionnaireWhereInput | null;
  questionnaires_none?: AnnualQuestionnaireWhereInput | null;
  questionnaires_some?: AnnualQuestionnaireWhereInput | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  settlementRequests_every?: SettlementRequestWhereInput | null;
  settlementRequests_none?: SettlementRequestWhereInput | null;
  settlementRequests_some?: SettlementRequestWhereInput | null;
  spouseRebate?: SpouseRebateWhereInput | null;
  spouseRebates_every?: SpouseRebateWhereInput | null;
  spouseRebates_none?: SpouseRebateWhereInput | null;
  spouseRebates_some?: SpouseRebateWhereInput | null;
  studentRebate?: StudentRebateWhereInput | null;
  studentRebates_every?: StudentRebateWhereInput | null;
  studentRebates_none?: StudentRebateWhereInput | null;
  studentRebates_some?: StudentRebateWhereInput | null;
  submitedAt?: any | null;
  submitedAt_gt?: any | null;
  submitedAt_gte?: any | null;
  submitedAt_in?: any[] | null;
  submitedAt_lt?: any | null;
  submitedAt_lte?: any | null;
  submitedAt_not?: any | null;
  submitedAt_not_in?: any[] | null;
  taxQuestionnaire?: TaxQuestionnaireWhereInput | null;
  unionRebate?: UnionRebateWhereInput | null;
  unionRebates_every?: UnionRebateWhereInput | null;
  unionRebates_none?: UnionRebateWhereInput | null;
  unionRebates_some?: UnionRebateWhereInput | null;
  user?: UserWhereInput | null;
  wizardFinishedAt?: any | null;
  wizardFinishedAt_gt?: any | null;
  wizardFinishedAt_gte?: any | null;
  wizardFinishedAt_in?: any[] | null;
  wizardFinishedAt_lt?: any | null;
  wizardFinishedAt_lte?: any | null;
  wizardFinishedAt_not?: any | null;
  wizardFinishedAt_not_in?: any[] | null;
  wizardStartedAt?: any | null;
  wizardStartedAt_gt?: any | null;
  wizardStartedAt_gte?: any | null;
  wizardStartedAt_in?: any[] | null;
  wizardStartedAt_lt?: any | null;
  wizardStartedAt_lte?: any | null;
  wizardStartedAt_not?: any | null;
  wizardStartedAt_not_in?: any[] | null;
  wizardStep?: AnnualWizardStep | null;
  wizardStep_in?: AnnualWizardStep[] | null;
  wizardStep_not?: AnnualWizardStep | null;
  wizardStep_not_in?: AnnualWizardStep[] | null;
  year?: number | null;
  year_gt?: number | null;
  year_gte?: number | null;
  year_in?: number[] | null;
  year_lt?: number | null;
  year_lte?: number | null;
  year_not?: number | null;
  year_not_in?: number[] | null;
  ztppRebate?: ZtppRebateWhereInput | null;
  ztppRebates_every?: ZtppRebateWhereInput | null;
  ztppRebates_none?: ZtppRebateWhereInput | null;
  ztppRebates_some?: ZtppRebateWhereInput | null;
}

export interface AnnualRebateWhereUniqueInput {
  id?: string | null;
}

export interface AnnualtaxCreateInput {
  customer: CustomerCreateOneInput;
  id?: string | null;
  year?: number | null;
}

export interface AnnualtaxCreateOneInput {
  connect?: AnnualtaxWhereUniqueInput | null;
  create?: AnnualtaxCreateInput | null;
}

export interface AnnualtaxUpdateDataInput {
  customer?: CustomerUpdateOneRequiredInput | null;
  year?: number | null;
}

export interface AnnualtaxUpdateOneInput {
  connect?: AnnualtaxWhereUniqueInput | null;
  create?: AnnualtaxCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: AnnualtaxUpdateDataInput | null;
  upsert?: AnnualtaxUpsertNestedInput | null;
}

export interface AnnualtaxUpsertNestedInput {
  create: AnnualtaxCreateInput;
  update: AnnualtaxUpdateDataInput;
}

export interface AnnualtaxWhereInput {
  AND?: AnnualtaxWhereInput[] | null;
  customer?: CustomerWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: AnnualtaxWhereInput[] | null;
  OR?: AnnualtaxWhereInput[] | null;
  year?: number | null;
  year_gt?: number | null;
  year_gte?: number | null;
  year_in?: number[] | null;
  year_lt?: number | null;
  year_lte?: number | null;
  year_not?: number | null;
  year_not_in?: number[] | null;
}

export interface AnnualtaxWhereUniqueInput {
  id?: string | null;
}

export interface ApiKeyUserWhereInput {
  customer: string;
}

export interface BasicRebateCreateInput {
  affidavitFiles?: FileCreateManyInput | null;
  annualRebate?: AnnualRebateCreateOneWithoutBasicRebatesInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutBasicRebatesInput | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutBasicRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface BasicRebateCreateManyWithoutAnnualRebateInput {
  connect?: BasicRebateWhereUniqueInput[] | null;
  create?: BasicRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface BasicRebateCreateManyWithoutMonthlyRebateInput {
  connect?: BasicRebateWhereUniqueInput[] | null;
  create?: BasicRebateCreateWithoutMonthlyRebateInput[] | null;
}

export interface BasicRebateCreateOneInput {
  connect?: BasicRebateWhereUniqueInput | null;
  create?: BasicRebateCreateInput | null;
}

export interface BasicRebateCreateOneWithoutSettlementRequestInput {
  connect?: BasicRebateWhereUniqueInput | null;
  create?: BasicRebateCreateWithoutSettlementRequestInput | null;
}

export interface BasicRebateCreateWithoutAnnualRebateInput {
  affidavitFiles?: FileCreateManyInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutBasicRebatesInput | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutBasicRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface BasicRebateCreateWithoutMonthlyRebateInput {
  affidavitFiles?: FileCreateManyInput | null;
  annualRebate?: AnnualRebateCreateOneWithoutBasicRebatesInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  id?: string | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutBasicRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface BasicRebateCreateWithoutSettlementRequestInput {
  affidavitFiles?: FileCreateManyInput | null;
  annualRebate?: AnnualRebateCreateOneWithoutBasicRebatesInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutBasicRebatesInput | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface BasicRebatePrevYearPeriodCreateManyWithoutRebateInput {
  connect?: BasicRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: BasicRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
}

export interface BasicRebatePrevYearPeriodCreateWithoutRebateInput {
  from?: any | null;
  id?: string | null;
  to?: any | null;
}

export interface BasicRebatePrevYearPeriodScalarWhereInput {
  AND?: BasicRebatePrevYearPeriodScalarWhereInput[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: BasicRebatePrevYearPeriodScalarWhereInput[] | null;
  OR?: BasicRebatePrevYearPeriodScalarWhereInput[] | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface BasicRebatePrevYearPeriodUpdateManyDataInput {
  from?: any | null;
  to?: any | null;
}

export interface BasicRebatePrevYearPeriodUpdateManyWithWhereNestedInput {
  data: BasicRebatePrevYearPeriodUpdateManyDataInput;
  where: BasicRebatePrevYearPeriodScalarWhereInput;
}

export interface BasicRebatePrevYearPeriodUpdateManyWithoutRebateInput {
  connect?: BasicRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: BasicRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
  delete?: BasicRebatePrevYearPeriodWhereUniqueInput[] | null;
  deleteMany?: BasicRebatePrevYearPeriodScalarWhereInput[] | null;
  disconnect?: BasicRebatePrevYearPeriodWhereUniqueInput[] | null;
  set?: BasicRebatePrevYearPeriodWhereUniqueInput[] | null;
  update?: BasicRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput[] | null;
  updateMany?: BasicRebatePrevYearPeriodUpdateManyWithWhereNestedInput[] | null;
  upsert?: BasicRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput[] | null;
}

export interface BasicRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput {
  data: BasicRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: BasicRebatePrevYearPeriodWhereUniqueInput;
}

export interface BasicRebatePrevYearPeriodUpdateWithoutRebateDataInput {
  from?: any | null;
  to?: any | null;
}

export interface BasicRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput {
  create: BasicRebatePrevYearPeriodCreateWithoutRebateInput;
  update: BasicRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: BasicRebatePrevYearPeriodWhereUniqueInput;
}

export interface BasicRebatePrevYearPeriodWhereInput {
  AND?: BasicRebatePrevYearPeriodWhereInput[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: BasicRebatePrevYearPeriodWhereInput[] | null;
  OR?: BasicRebatePrevYearPeriodWhereInput[] | null;
  rebate?: BasicRebateWhereInput | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface BasicRebatePrevYearPeriodWhereUniqueInput {
  id?: string | null;
}

export interface BasicRebateScalarWhereInput {
  AND?: BasicRebateScalarWhereInput[] | null;
  applyFrom?: any | null;
  applyFrom_gt?: any | null;
  applyFrom_gte?: any | null;
  applyFrom_in?: any[] | null;
  applyFrom_lt?: any | null;
  applyFrom_lte?: any | null;
  applyFrom_not?: any | null;
  applyFrom_not_in?: any[] | null;
  applyTo?: any | null;
  applyTo_gt?: any | null;
  applyTo_gte?: any | null;
  applyTo_in?: any[] | null;
  applyTo_lt?: any | null;
  applyTo_lte?: any | null;
  applyTo_not?: any | null;
  applyTo_not_in?: any[] | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: BasicRebateScalarWhereInput[] | null;
  OR?: BasicRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface BasicRebateUpdateDataInput {
  affidavitFiles?: FileUpdateManyInput | null;
  annualRebate?: AnnualRebateUpdateOneWithoutBasicRebatesInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutBasicRebatesInput | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutBasicRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface BasicRebateUpdateManyDataInput {
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface BasicRebateUpdateManyWithWhereNestedInput {
  data: BasicRebateUpdateManyDataInput;
  where: BasicRebateScalarWhereInput;
}

export interface BasicRebateUpdateManyWithoutAnnualRebateInput {
  connect?: BasicRebateWhereUniqueInput[] | null;
  create?: BasicRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: BasicRebateWhereUniqueInput[] | null;
  deleteMany?: BasicRebateScalarWhereInput[] | null;
  disconnect?: BasicRebateWhereUniqueInput[] | null;
  set?: BasicRebateWhereUniqueInput[] | null;
  update?: BasicRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: BasicRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: BasicRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface BasicRebateUpdateManyWithoutMonthlyRebateInput {
  connect?: BasicRebateWhereUniqueInput[] | null;
  create?: BasicRebateCreateWithoutMonthlyRebateInput[] | null;
  delete?: BasicRebateWhereUniqueInput[] | null;
  deleteMany?: BasicRebateScalarWhereInput[] | null;
  disconnect?: BasicRebateWhereUniqueInput[] | null;
  set?: BasicRebateWhereUniqueInput[] | null;
  update?: BasicRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput[] | null;
  updateMany?: BasicRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: BasicRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput[] | null;
}

export interface BasicRebateUpdateOneRequiredInput {
  connect?: BasicRebateWhereUniqueInput | null;
  create?: BasicRebateCreateInput | null;
  update?: BasicRebateUpdateDataInput | null;
  upsert?: BasicRebateUpsertNestedInput | null;
}

export interface BasicRebateUpdateOneWithoutSettlementRequestInput {
  connect?: BasicRebateWhereUniqueInput | null;
  create?: BasicRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: BasicRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: BasicRebateUpsertWithoutSettlementRequestInput | null;
}

export interface BasicRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: BasicRebateUpdateWithoutAnnualRebateDataInput;
  where: BasicRebateWhereUniqueInput;
}

export interface BasicRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput {
  data: BasicRebateUpdateWithoutMonthlyRebateDataInput;
  where: BasicRebateWhereUniqueInput;
}

export interface BasicRebateUpdateWithoutAnnualRebateDataInput {
  affidavitFiles?: FileUpdateManyInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutBasicRebatesInput | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutBasicRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface BasicRebateUpdateWithoutMonthlyRebateDataInput {
  affidavitFiles?: FileUpdateManyInput | null;
  annualRebate?: AnnualRebateUpdateOneWithoutBasicRebatesInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutBasicRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface BasicRebateUpdateWithoutSettlementRequestDataInput {
  affidavitFiles?: FileUpdateManyInput | null;
  annualRebate?: AnnualRebateUpdateOneWithoutBasicRebatesInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutBasicRebatesInput | null;
  prevYearPeriods?: BasicRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface BasicRebateUpsertNestedInput {
  create: BasicRebateCreateInput;
  update: BasicRebateUpdateDataInput;
}

export interface BasicRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: BasicRebateCreateWithoutAnnualRebateInput;
  update: BasicRebateUpdateWithoutAnnualRebateDataInput;
  where: BasicRebateWhereUniqueInput;
}

export interface BasicRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput {
  create: BasicRebateCreateWithoutMonthlyRebateInput;
  update: BasicRebateUpdateWithoutMonthlyRebateDataInput;
  where: BasicRebateWhereUniqueInput;
}

export interface BasicRebateUpsertWithoutSettlementRequestInput {
  create: BasicRebateCreateWithoutSettlementRequestInput;
  update: BasicRebateUpdateWithoutSettlementRequestDataInput;
}

export interface BasicRebateWhereInput {
  affidavitFiles_every?: FileWhereInput | null;
  affidavitFiles_none?: FileWhereInput | null;
  affidavitFiles_some?: FileWhereInput | null;
  AND?: BasicRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  applyFrom?: any | null;
  applyFrom_gt?: any | null;
  applyFrom_gte?: any | null;
  applyFrom_in?: any[] | null;
  applyFrom_lt?: any | null;
  applyFrom_lte?: any | null;
  applyFrom_not?: any | null;
  applyFrom_not_in?: any[] | null;
  applyTo?: any | null;
  applyTo_gt?: any | null;
  applyTo_gte?: any | null;
  applyTo_in?: any[] | null;
  applyTo_lt?: any | null;
  applyTo_lte?: any | null;
  applyTo_not?: any | null;
  applyTo_not_in?: any[] | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  NOT?: BasicRebateWhereInput[] | null;
  OR?: BasicRebateWhereInput[] | null;
  prevYearPeriods_every?: BasicRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_none?: BasicRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_some?: BasicRebatePrevYearPeriodWhereInput | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface BasicRebateWhereUniqueInput {
  id?: string | null;
}

export interface ChildCreateManyWithoutChildrenRebateInput {
  connect?: ChildWhereUniqueInput[] | null;
  create?: ChildCreateWithoutChildrenRebateInput[] | null;
}

export interface ChildCreateWithoutChildrenRebateInput {
  applyInMonths?: ChildCreateapplyInMonthsInput | null;
  applyRebate: boolean;
  birthCertificateFiles?: FileUpdateManyInput | null;
  birthdate?: any | null;
  firstname: string;
  lastname: string;
  nationalIDNumber: string;
  order?: ChildOrder | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  student?: boolean | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  studentFrom?: any | null;
  studentPrevYearPeriods?: StudentRebatePrevYearPeriodCreateManyInput | null;
  studentTo?: any | null;
  type?: OwnRebateRequestTypeInput | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
  ztppFrom?: any | null;
  ztppTo?: any | null;
}

export interface ChildCreateapplyInMonthsInput {
  set?: Month[] | null;
}

export interface ChildUpdateManyWithoutChildrenRebateInput {
  create?: ChildCreateWithoutChildrenRebateInput[] | null;
  delete?: ChildWhereUniqueInput[] | null;
  update?: ChildUpdateWithWhereUniqueWithoutChildrenRebateInput[] | null;
}

export interface ChildUpdateWithWhereUniqueWithoutChildrenRebateInput {
  data: ChildUpdateWithoutChildrenRebateDataInput;
  where: ChildWhereUniqueInput;
}

export interface ChildUpdateWithoutChildrenRebateDataInput {
  applyInMonths?: ChildCreateapplyInMonthsInput | null;
  applyRebate?: boolean | null;
  birthCertificateFiles?: FileUpdateManyInput | null;
  birthdate?: any | null;
  closePeriodAt?: any | null;
  firstname?: string | null;
  lastname?: string | null;
  nationalIDNumber?: string | null;
  order?: ChildOrder | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  student?: boolean | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  studentFrom?: any | null;
  studentPrevYearPeriods?: StudentRebatePrevYearPeriodUpdateManyInput | null;
  studentTo?: any | null;
  type?: OwnRebateRequestTypeInput | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
  ztppFrom?: any | null;
  ztppTo?: any | null;
}

export interface ChildWhereInput {
  AND?: ChildWhereInput[] | null;
  applyRebate?: boolean | null;
  applyRebate_not?: boolean | null;
  birthCertificateFiles_every?: FileWhereInput | null;
  birthCertificateFiles_none?: FileWhereInput | null;
  birthCertificateFiles_some?: FileWhereInput | null;
  birthdate?: any | null;
  birthdate_gt?: any | null;
  birthdate_gte?: any | null;
  birthdate_in?: any[] | null;
  birthdate_lt?: any | null;
  birthdate_lte?: any | null;
  birthdate_not?: any | null;
  birthdate_not_in?: any[] | null;
  childrenRebate?: ChildrenRebateWhereInput | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  firstname?: string | null;
  firstname_contains?: string | null;
  firstname_ends_with?: string | null;
  firstname_gt?: string | null;
  firstname_gte?: string | null;
  firstname_in?: string[] | null;
  firstname_lt?: string | null;
  firstname_lte?: string | null;
  firstname_not?: string | null;
  firstname_not_contains?: string | null;
  firstname_not_ends_with?: string | null;
  firstname_not_in?: string[] | null;
  firstname_not_starts_with?: string | null;
  firstname_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  lastname?: string | null;
  lastname_contains?: string | null;
  lastname_ends_with?: string | null;
  lastname_gt?: string | null;
  lastname_gte?: string | null;
  lastname_in?: string[] | null;
  lastname_lt?: string | null;
  lastname_lte?: string | null;
  lastname_not?: string | null;
  lastname_not_contains?: string | null;
  lastname_not_ends_with?: string | null;
  lastname_not_in?: string[] | null;
  lastname_not_starts_with?: string | null;
  lastname_starts_with?: string | null;
  nationalIDNumber?: string | null;
  nationalIDNumber_contains?: string | null;
  nationalIDNumber_ends_with?: string | null;
  nationalIDNumber_gt?: string | null;
  nationalIDNumber_gte?: string | null;
  nationalIDNumber_in?: string[] | null;
  nationalIDNumber_lt?: string | null;
  nationalIDNumber_lte?: string | null;
  nationalIDNumber_not?: string | null;
  nationalIDNumber_not_contains?: string | null;
  nationalIDNumber_not_ends_with?: string | null;
  nationalIDNumber_not_in?: string[] | null;
  nationalIDNumber_not_starts_with?: string | null;
  nationalIDNumber_starts_with?: string | null;
  NOT?: ChildWhereInput[] | null;
  OR?: ChildWhereInput[] | null;
  order?: ChildOrder | null;
  order_in?: ChildOrder[] | null;
  order_not?: ChildOrder | null;
  order_not_in?: ChildOrder[] | null;
  socialConfirmationFiles_every?: FileWhereInput | null;
  socialConfirmationFiles_none?: FileWhereInput | null;
  socialConfirmationFiles_some?: FileWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  student?: boolean | null;
  student_not?: boolean | null;
  studentConfirmationFiles_every?: FileWhereInput | null;
  studentConfirmationFiles_none?: FileWhereInput | null;
  studentConfirmationFiles_some?: FileWhereInput | null;
  studentFrom?: any | null;
  studentFrom_gt?: any | null;
  studentFrom_gte?: any | null;
  studentFrom_in?: any[] | null;
  studentFrom_lt?: any | null;
  studentFrom_lte?: any | null;
  studentFrom_not?: any | null;
  studentFrom_not_in?: any[] | null;
  studentPrevYearPeriods_every?: StudentRebatePrevYearPeriodWhereInput | null;
  studentPrevYearPeriods_none?: StudentRebatePrevYearPeriodWhereInput | null;
  studentPrevYearPeriods_some?: StudentRebatePrevYearPeriodWhereInput | null;
  studentTo?: any | null;
  studentTo_gt?: any | null;
  studentTo_gte?: any | null;
  studentTo_in?: any[] | null;
  studentTo_lt?: any | null;
  studentTo_lte?: any | null;
  studentTo_not?: any | null;
  studentTo_not_in?: any[] | null;
  tag?: string | null;
  tag_contains?: string | null;
  tag_ends_with?: string | null;
  tag_gt?: string | null;
  tag_gte?: string | null;
  tag_in?: string[] | null;
  tag_lt?: string | null;
  tag_lte?: string | null;
  tag_not?: string | null;
  tag_not_contains?: string | null;
  tag_not_ends_with?: string | null;
  tag_not_in?: string[] | null;
  tag_not_starts_with?: string | null;
  tag_starts_with?: string | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
  ztpp?: boolean | null;
  ztpp_not?: boolean | null;
  ztppConfirmationFiles_every?: FileWhereInput | null;
  ztppConfirmationFiles_none?: FileWhereInput | null;
  ztppConfirmationFiles_some?: FileWhereInput | null;
  ztppFrom?: any | null;
  ztppFrom_gt?: any | null;
  ztppFrom_gte?: any | null;
  ztppFrom_in?: any[] | null;
  ztppFrom_lt?: any | null;
  ztppFrom_lte?: any | null;
  ztppFrom_not?: any | null;
  ztppFrom_not_in?: any[] | null;
  ztppTo?: any | null;
  ztppTo_gt?: any | null;
  ztppTo_gte?: any | null;
  ztppTo_in?: any[] | null;
  ztppTo_lt?: any | null;
  ztppTo_lte?: any | null;
  ztppTo_not?: any | null;
  ztppTo_not_in?: any[] | null;
}

export interface ChildWhereUniqueInput {
  id: string;
}

export interface ChildrenRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutChildrenRebatesInput | null;
  birthCertificateFiles?: FileCreateManyInput | null;
  children?: ChildCreateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutChildrenRebatesInput | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileCreateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutChildrenRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ChildrenRebateCreateManyWithoutAnnualRebateInput {
  connect?: ChildrenRebateWhereUniqueInput[] | null;
  create?: ChildrenRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface ChildrenRebateCreateManyWithoutMonthlyRebateInput {
  connect?: ChildrenRebateWhereUniqueInput[] | null;
  create?: ChildrenRebateCreateWithoutMonthlyRebateInput[] | null;
}

export interface ChildrenRebateCreateOneInput {
  connect?: ChildrenRebateWhereUniqueInput | null;
  create?: ChildrenRebateCreateInput | null;
}

export interface ChildrenRebateCreateOneWithoutSettlementRequestInput {
  connect?: ChildrenRebateWhereUniqueInput | null;
  create?: ChildrenRebateCreateWithoutSettlementRequestInput | null;
}

export interface ChildrenRebateCreateWithoutAnnualRebateInput {
  birthCertificateFiles?: FileCreateManyInput | null;
  children?: ChildCreateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutChildrenRebatesInput | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileCreateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutChildrenRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ChildrenRebateCreateWithoutMonthlyRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutChildrenRebatesInput | null;
  birthCertificateFiles?: FileCreateManyInput | null;
  children?: ChildCreateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  id?: string | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileCreateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutChildrenRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ChildrenRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutChildrenRebatesInput | null;
  birthCertificateFiles?: FileCreateManyInput | null;
  children?: ChildCreateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutChildrenRebatesInput | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileCreateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ChildrenRebateScalarWhereInput {
  AND?: ChildrenRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ChildrenRebateScalarWhereInput[] | null;
  OR?: ChildrenRebateScalarWhereInput[] | null;
  otherParentAddress?: string | null;
  otherParentAddress_contains?: string | null;
  otherParentAddress_ends_with?: string | null;
  otherParentAddress_gt?: string | null;
  otherParentAddress_gte?: string | null;
  otherParentAddress_in?: string[] | null;
  otherParentAddress_lt?: string | null;
  otherParentAddress_lte?: string | null;
  otherParentAddress_not?: string | null;
  otherParentAddress_not_contains?: string | null;
  otherParentAddress_not_ends_with?: string | null;
  otherParentAddress_not_in?: string[] | null;
  otherParentAddress_not_starts_with?: string | null;
  otherParentAddress_starts_with?: string | null;
  otherParentApplying?: boolean | null;
  otherParentApplying_not?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentCommentOfDenied_contains?: string | null;
  otherParentCommentOfDenied_ends_with?: string | null;
  otherParentCommentOfDenied_gt?: string | null;
  otherParentCommentOfDenied_gte?: string | null;
  otherParentCommentOfDenied_in?: string[] | null;
  otherParentCommentOfDenied_lt?: string | null;
  otherParentCommentOfDenied_lte?: string | null;
  otherParentCommentOfDenied_not?: string | null;
  otherParentCommentOfDenied_not_contains?: string | null;
  otherParentCommentOfDenied_not_ends_with?: string | null;
  otherParentCommentOfDenied_not_in?: string[] | null;
  otherParentCommentOfDenied_not_starts_with?: string | null;
  otherParentCommentOfDenied_starts_with?: string | null;
  otherParentFirstname?: string | null;
  otherParentFirstname_contains?: string | null;
  otherParentFirstname_ends_with?: string | null;
  otherParentFirstname_gt?: string | null;
  otherParentFirstname_gte?: string | null;
  otherParentFirstname_in?: string[] | null;
  otherParentFirstname_lt?: string | null;
  otherParentFirstname_lte?: string | null;
  otherParentFirstname_not?: string | null;
  otherParentFirstname_not_contains?: string | null;
  otherParentFirstname_not_ends_with?: string | null;
  otherParentFirstname_not_in?: string[] | null;
  otherParentFirstname_not_starts_with?: string | null;
  otherParentFirstname_starts_with?: string | null;
  otherParentLastname?: string | null;
  otherParentLastname_contains?: string | null;
  otherParentLastname_ends_with?: string | null;
  otherParentLastname_gt?: string | null;
  otherParentLastname_gte?: string | null;
  otherParentLastname_in?: string[] | null;
  otherParentLastname_lt?: string | null;
  otherParentLastname_lte?: string | null;
  otherParentLastname_not?: string | null;
  otherParentLastname_not_contains?: string | null;
  otherParentLastname_not_ends_with?: string | null;
  otherParentLastname_not_in?: string[] | null;
  otherParentLastname_not_starts_with?: string | null;
  otherParentLastname_starts_with?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNationalIDNumber_contains?: string | null;
  otherParentNationalIDNumber_ends_with?: string | null;
  otherParentNationalIDNumber_gt?: string | null;
  otherParentNationalIDNumber_gte?: string | null;
  otherParentNationalIDNumber_in?: string[] | null;
  otherParentNationalIDNumber_lt?: string | null;
  otherParentNationalIDNumber_lte?: string | null;
  otherParentNationalIDNumber_not?: string | null;
  otherParentNationalIDNumber_not_contains?: string | null;
  otherParentNationalIDNumber_not_ends_with?: string | null;
  otherParentNationalIDNumber_not_in?: string[] | null;
  otherParentNationalIDNumber_not_starts_with?: string | null;
  otherParentNationalIDNumber_starts_with?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentNourishes_not?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentStatus_in?: RebateStatus[] | null;
  otherParentStatus_not?: RebateStatus | null;
  otherParentStatus_not_in?: RebateStatus[] | null;
  otherParentWork?: Work | null;
  otherParentWork_in?: Work[] | null;
  otherParentWork_not?: Work | null;
  otherParentWork_not_in?: Work[] | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkAddress_contains?: string | null;
  otherParentWorkAddress_ends_with?: string | null;
  otherParentWorkAddress_gt?: string | null;
  otherParentWorkAddress_gte?: string | null;
  otherParentWorkAddress_in?: string[] | null;
  otherParentWorkAddress_lt?: string | null;
  otherParentWorkAddress_lte?: string | null;
  otherParentWorkAddress_not?: string | null;
  otherParentWorkAddress_not_contains?: string | null;
  otherParentWorkAddress_not_ends_with?: string | null;
  otherParentWorkAddress_not_in?: string[] | null;
  otherParentWorkAddress_not_starts_with?: string | null;
  otherParentWorkAddress_starts_with?: string | null;
  otherParentWorkName?: string | null;
  otherParentWorkName_contains?: string | null;
  otherParentWorkName_ends_with?: string | null;
  otherParentWorkName_gt?: string | null;
  otherParentWorkName_gte?: string | null;
  otherParentWorkName_in?: string[] | null;
  otherParentWorkName_lt?: string | null;
  otherParentWorkName_lte?: string | null;
  otherParentWorkName_not?: string | null;
  otherParentWorkName_not_contains?: string | null;
  otherParentWorkName_not_ends_with?: string | null;
  otherParentWorkName_not_in?: string[] | null;
  otherParentWorkName_not_starts_with?: string | null;
  otherParentWorkName_starts_with?: string | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface ChildrenRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutChildrenRebatesInput | null;
  birthCertificateFiles?: FileUpdateManyInput | null;
  children?: ChildUpdateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutChildrenRebatesInput | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileUpdateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutChildrenRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ChildrenRebateUpdateManyDataInput {
  commentOfDenied?: string | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ChildrenRebateUpdateManyWithWhereNestedInput {
  data: ChildrenRebateUpdateManyDataInput;
  where: ChildrenRebateScalarWhereInput;
}

export interface ChildrenRebateUpdateManyWithoutAnnualRebateInput {
  connect?: ChildrenRebateWhereUniqueInput[] | null;
  create?: ChildrenRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: ChildrenRebateWhereUniqueInput[] | null;
  deleteMany?: ChildrenRebateScalarWhereInput[] | null;
  disconnect?: ChildrenRebateWhereUniqueInput[] | null;
  set?: ChildrenRebateWhereUniqueInput[] | null;
  update?: ChildrenRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: ChildrenRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: ChildrenRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface ChildrenRebateUpdateManyWithoutMonthlyRebateInput {
  connect?: ChildrenRebateWhereUniqueInput[] | null;
  create?: ChildrenRebateCreateWithoutMonthlyRebateInput[] | null;
  delete?: ChildrenRebateWhereUniqueInput[] | null;
  deleteMany?: ChildrenRebateScalarWhereInput[] | null;
  disconnect?: ChildrenRebateWhereUniqueInput[] | null;
  set?: ChildrenRebateWhereUniqueInput[] | null;
  update?: ChildrenRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput[] | null;
  updateMany?: ChildrenRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: ChildrenRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput[] | null;
}

export interface ChildrenRebateUpdateOneRequiredInput {
  connect?: ChildrenRebateWhereUniqueInput | null;
  create?: ChildrenRebateCreateInput | null;
  update?: ChildrenRebateUpdateDataInput | null;
  upsert?: ChildrenRebateUpsertNestedInput | null;
}

export interface ChildrenRebateUpdateOneWithoutSettlementRequestInput {
  connect?: ChildrenRebateWhereUniqueInput | null;
  create?: ChildrenRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ChildrenRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: ChildrenRebateUpsertWithoutSettlementRequestInput | null;
}

export interface ChildrenRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: ChildrenRebateUpdateWithoutAnnualRebateDataInput;
  where: ChildrenRebateWhereUniqueInput;
}

export interface ChildrenRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput {
  data: ChildrenRebateUpdateWithoutMonthlyRebateDataInput;
  where: ChildrenRebateWhereUniqueInput;
}

export interface ChildrenRebateUpdateWithoutAnnualRebateDataInput {
  birthCertificateFiles?: FileUpdateManyInput | null;
  children?: ChildUpdateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutChildrenRebatesInput | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileUpdateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutChildrenRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ChildrenRebateUpdateWithoutMonthlyRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutChildrenRebatesInput | null;
  birthCertificateFiles?: FileUpdateManyInput | null;
  children?: ChildUpdateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileUpdateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutChildrenRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ChildrenRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutChildrenRebatesInput | null;
  birthCertificateFiles?: FileUpdateManyInput | null;
  children?: ChildUpdateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutChildrenRebatesInput | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentConfirmationFiles?: FileUpdateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ChildrenRebateUpsertNestedInput {
  create: ChildrenRebateCreateInput;
  update: ChildrenRebateUpdateDataInput;
}

export interface ChildrenRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: ChildrenRebateCreateWithoutAnnualRebateInput;
  update: ChildrenRebateUpdateWithoutAnnualRebateDataInput;
  where: ChildrenRebateWhereUniqueInput;
}

export interface ChildrenRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput {
  create: ChildrenRebateCreateWithoutMonthlyRebateInput;
  update: ChildrenRebateUpdateWithoutMonthlyRebateDataInput;
  where: ChildrenRebateWhereUniqueInput;
}

export interface ChildrenRebateUpsertWithoutSettlementRequestInput {
  create: ChildrenRebateCreateWithoutSettlementRequestInput;
  update: ChildrenRebateUpdateWithoutSettlementRequestDataInput;
}

export interface ChildrenRebateWhereInput {
  AND?: ChildrenRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  birthCertificateFiles_every?: FileWhereInput | null;
  birthCertificateFiles_none?: FileWhereInput | null;
  birthCertificateFiles_some?: FileWhereInput | null;
  children_every?: ChildWhereInput | null;
  children_none?: ChildWhereInput | null;
  children_some?: ChildWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  NOT?: ChildrenRebateWhereInput[] | null;
  OR?: ChildrenRebateWhereInput[] | null;
  otherParentAddress?: string | null;
  otherParentAddress_contains?: string | null;
  otherParentAddress_ends_with?: string | null;
  otherParentAddress_gt?: string | null;
  otherParentAddress_gte?: string | null;
  otherParentAddress_in?: string[] | null;
  otherParentAddress_lt?: string | null;
  otherParentAddress_lte?: string | null;
  otherParentAddress_not?: string | null;
  otherParentAddress_not_contains?: string | null;
  otherParentAddress_not_ends_with?: string | null;
  otherParentAddress_not_in?: string[] | null;
  otherParentAddress_not_starts_with?: string | null;
  otherParentAddress_starts_with?: string | null;
  otherParentApplying?: boolean | null;
  otherParentApplying_not?: boolean | null;
  otherParentCommentOfDenied?: string | null;
  otherParentCommentOfDenied_contains?: string | null;
  otherParentCommentOfDenied_ends_with?: string | null;
  otherParentCommentOfDenied_gt?: string | null;
  otherParentCommentOfDenied_gte?: string | null;
  otherParentCommentOfDenied_in?: string[] | null;
  otherParentCommentOfDenied_lt?: string | null;
  otherParentCommentOfDenied_lte?: string | null;
  otherParentCommentOfDenied_not?: string | null;
  otherParentCommentOfDenied_not_contains?: string | null;
  otherParentCommentOfDenied_not_ends_with?: string | null;
  otherParentCommentOfDenied_not_in?: string[] | null;
  otherParentCommentOfDenied_not_starts_with?: string | null;
  otherParentCommentOfDenied_starts_with?: string | null;
  otherParentConfirmationFiles_every?: FileWhereInput | null;
  otherParentConfirmationFiles_none?: FileWhereInput | null;
  otherParentConfirmationFiles_some?: FileWhereInput | null;
  otherParentFirstname?: string | null;
  otherParentFirstname_contains?: string | null;
  otherParentFirstname_ends_with?: string | null;
  otherParentFirstname_gt?: string | null;
  otherParentFirstname_gte?: string | null;
  otherParentFirstname_in?: string[] | null;
  otherParentFirstname_lt?: string | null;
  otherParentFirstname_lte?: string | null;
  otherParentFirstname_not?: string | null;
  otherParentFirstname_not_contains?: string | null;
  otherParentFirstname_not_ends_with?: string | null;
  otherParentFirstname_not_in?: string[] | null;
  otherParentFirstname_not_starts_with?: string | null;
  otherParentFirstname_starts_with?: string | null;
  otherParentLastname?: string | null;
  otherParentLastname_contains?: string | null;
  otherParentLastname_ends_with?: string | null;
  otherParentLastname_gt?: string | null;
  otherParentLastname_gte?: string | null;
  otherParentLastname_in?: string[] | null;
  otherParentLastname_lt?: string | null;
  otherParentLastname_lte?: string | null;
  otherParentLastname_not?: string | null;
  otherParentLastname_not_contains?: string | null;
  otherParentLastname_not_ends_with?: string | null;
  otherParentLastname_not_in?: string[] | null;
  otherParentLastname_not_starts_with?: string | null;
  otherParentLastname_starts_with?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNationalIDNumber_contains?: string | null;
  otherParentNationalIDNumber_ends_with?: string | null;
  otherParentNationalIDNumber_gt?: string | null;
  otherParentNationalIDNumber_gte?: string | null;
  otherParentNationalIDNumber_in?: string[] | null;
  otherParentNationalIDNumber_lt?: string | null;
  otherParentNationalIDNumber_lte?: string | null;
  otherParentNationalIDNumber_not?: string | null;
  otherParentNationalIDNumber_not_contains?: string | null;
  otherParentNationalIDNumber_not_ends_with?: string | null;
  otherParentNationalIDNumber_not_in?: string[] | null;
  otherParentNationalIDNumber_not_starts_with?: string | null;
  otherParentNationalIDNumber_starts_with?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentNourishes_not?: boolean | null;
  otherParentStatus?: RebateStatus | null;
  otherParentStatus_in?: RebateStatus[] | null;
  otherParentStatus_not?: RebateStatus | null;
  otherParentStatus_not_in?: RebateStatus[] | null;
  otherParentWork?: Work | null;
  otherParentWork_in?: Work[] | null;
  otherParentWork_not?: Work | null;
  otherParentWork_not_in?: Work[] | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkAddress_contains?: string | null;
  otherParentWorkAddress_ends_with?: string | null;
  otherParentWorkAddress_gt?: string | null;
  otherParentWorkAddress_gte?: string | null;
  otherParentWorkAddress_in?: string[] | null;
  otherParentWorkAddress_lt?: string | null;
  otherParentWorkAddress_lte?: string | null;
  otherParentWorkAddress_not?: string | null;
  otherParentWorkAddress_not_contains?: string | null;
  otherParentWorkAddress_not_ends_with?: string | null;
  otherParentWorkAddress_not_in?: string[] | null;
  otherParentWorkAddress_not_starts_with?: string | null;
  otherParentWorkAddress_starts_with?: string | null;
  otherParentWorkName?: string | null;
  otherParentWorkName_contains?: string | null;
  otherParentWorkName_ends_with?: string | null;
  otherParentWorkName_gt?: string | null;
  otherParentWorkName_gte?: string | null;
  otherParentWorkName_in?: string[] | null;
  otherParentWorkName_lt?: string | null;
  otherParentWorkName_lte?: string | null;
  otherParentWorkName_not?: string | null;
  otherParentWorkName_not_contains?: string | null;
  otherParentWorkName_not_ends_with?: string | null;
  otherParentWorkName_not_in?: string[] | null;
  otherParentWorkName_not_starts_with?: string | null;
  otherParentWorkName_starts_with?: string | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  socialConfirmationFiles_every?: FileWhereInput | null;
  socialConfirmationFiles_none?: FileWhereInput | null;
  socialConfirmationFiles_some?: FileWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  studentConfirmationFiles_every?: FileWhereInput | null;
  studentConfirmationFiles_none?: FileWhereInput | null;
  studentConfirmationFiles_some?: FileWhereInput | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
  ztppConfirmationFiles_every?: FileWhereInput | null;
  ztppConfirmationFiles_none?: FileWhereInput | null;
  ztppConfirmationFiles_some?: FileWhereInput | null;
}

export interface ChildrenRebateWhereUniqueInput {
  id?: string | null;
}

export interface ConfirmedRebatesWhereInput {
  search?: string | null;
  year?: number | null;
}

export interface CustomerCreateInput {
  activationFormStep: number;
  address: string;
  dic: string;
  groupName?: string | null;
  ico: string;
  keeperEmail: string;
  keeperFirstName: string;
  keeperLastName: string;
  name: string;
}

export interface CustomerCreateOneInput {
  connect?: CustomerWhereUniqueInput | null;
  create?: CustomerCreateInput | null;
}

export interface CustomerCreateOneWithoutCreatedByInput {
  connect?: CustomerWhereUniqueInput | null;
  create?: CustomerCreateWithoutCreatedByInput | null;
}

export interface CustomerCreateWithoutCreatedByInput {
  activationAnnualtax?: boolean | null;
  activationAt?: any | null;
  activationFormDoneAt?: any | null;
  activationFormStep?: number | null;
  activationKeeperIsEmployee?: boolean | null;
  address?: string | null;
  allowApplyCurrentMonth?: boolean | null;
  allowDocumentsForViewer?: boolean | null;
  allowFilesUpload?: boolean | null;
  allowMessages?: boolean | null;
  allowMessagesForViewer?: boolean | null;
  allowOpenID?: boolean | null;
  allowPayslipsMultiupload?: boolean | null;
  annualRebatesExportType: ExportType;
  annualRebatesPeriodEnd: any;
  annualRebatesPeriodExtraEnd?: any | null;
  annualRebatesPeriodStart: any;
  blockedAt?: any | null;
  deletedAt?: any | null;
  dic?: string | null;
  divideByUnits?: boolean | null;
  dontRequireBasicRebateAffidavitFiles?: boolean | null;
  employeesImportFiles?: FileCreateManyInput | null;
  groupName?: string | null;
  ico?: string | null;
  id?: string | null;
  name: string;
  openIDClientId?: string | null;
  openIDClientSecret?: string | null;
  openIDUrl?: string | null;
  rebateEndingsProcessedAt?: any | null;
  requireCheckOfTerms?: boolean | null;
  requireNoTaxableIncomeAffidavit?: boolean | null;
  search: string;
  sharedMessages?: boolean | null;
  ssoClientId?: string | null;
  ssoScopes?: string | null;
  ssoTenantId?: string | null;
  units?: UnitCreateManyWithoutCustomerInput | null;
  vopAgreementAt?: any | null;
  yearOfAnnualRebates: number;
  yearOfMonthlyRebates: number;
}

export interface CustomerSettingUpsertInput {
  annualRebatesPeriodEnd?: any | null;
  annualRebatesPeriodStart?: any | null;
  id?: string | null;
  invitationPeriodEnd?: any | null;
  invitationPeriodStart?: any | null;
  previousYear?: number | null;
  yearOfAnnualRebates: number;
  yearOfMonthlyRebates: number;
}

export interface CustomerUpdateDataInput {
  activationAnnualtax?: boolean | null;
  activationAt?: any | null;
  activationFormDoneAt?: any | null;
  activationFormStep?: number | null;
  activationKeeperIsEmployee?: boolean | null;
  address?: string | null;
  allowApplyCurrentMonth?: boolean | null;
  allowDocumentsForViewer?: boolean | null;
  allowFilesUpload?: boolean | null;
  allowMessages?: boolean | null;
  allowMessagesForViewer?: boolean | null;
  allowOpenID?: boolean | null;
  allowPayslipsMultiupload?: boolean | null;
  annualRebatesExportType?: ExportType | null;
  annualRebatesPeriodEnd?: any | null;
  annualRebatesPeriodExtraEnd?: any | null;
  annualRebatesPeriodStart?: any | null;
  blockedAt?: any | null;
  createdBy?: UserUpdateOneWithoutCustomerInput | null;
  deletedAt?: any | null;
  dic?: string | null;
  divideByUnits?: boolean | null;
  dontRequireBasicRebateAffidavitFiles?: boolean | null;
  employeesImportFiles?: FileUpdateManyInput | null;
  groupName?: string | null;
  ico?: string | null;
  name?: string | null;
  openIDClientId?: string | null;
  openIDClientSecret?: string | null;
  openIDUrl?: string | null;
  rebateEndingsProcessedAt?: any | null;
  requireCheckOfTerms?: boolean | null;
  requireNoTaxableIncomeAffidavit?: boolean | null;
  search?: string | null;
  sharedMessages?: boolean | null;
  ssoClientId?: string | null;
  ssoScopes?: string | null;
  ssoTenantId?: string | null;
  units?: UnitUpdateManyWithoutCustomerInput | null;
  vopAgreementAt?: any | null;
  yearOfAnnualRebates?: number | null;
  yearOfMonthlyRebates?: number | null;
}

export interface CustomerUpdateInput {
  activationAnnualtax?: boolean | null;
  activationAt?: any | null;
  activationFormStep?: number | null;
  activationKeeperIsEmployee?: boolean | null;
  address?: string | null;
  allowApplyCurrentMonth?: boolean | null;
  allowDocumentsForViewer?: boolean | null;
  allowFilesUpload?: boolean | null;
  allowMessages?: boolean | null;
  allowMessagesForViewer?: boolean | null;
  allowOpenID?: boolean | null;
  allowPayslipsMultiupload?: boolean | null;
  annualRebatesExportType?: string | null;
  annualRebatesPeriodEnd?: any | null;
  annualRebatesPeriodExtraEnd?: any | null;
  annualRebatesPeriodStart?: any | null;
  dic?: string | null;
  divideByUnits?: boolean | null;
  dontRequireBasicRebateAffidavitFiles?: boolean | null;
  groupName?: string | null;
  ico?: string | null;
  name?: string | null;
  openIDClientId?: string | null;
  openIDClientSecret?: string | null;
  openIDUrl?: string | null;
  requireCheckOfTerms?: boolean | null;
  requireNoTaxableIncomeAffidavit?: boolean | null;
  sharedMessages?: boolean | null;
  ssoClientId?: string | null;
  ssoScopes?: string | null;
  ssoTenantId?: string | null;
  vopAgreementAt?: any | null;
}

export interface CustomerUpdateOneInput {
  connect?: CustomerWhereUniqueInput | null;
  create?: CustomerCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: CustomerUpdateDataInput | null;
  upsert?: CustomerUpsertNestedInput | null;
}

export interface CustomerUpdateOneRequiredInput {
  connect?: CustomerWhereUniqueInput | null;
  create?: CustomerCreateInput | null;
  update?: CustomerUpdateDataInput | null;
  upsert?: CustomerUpsertNestedInput | null;
}

export interface CustomerUpdateOneRequiredWithoutCreatedByInput {
  connect?: CustomerWhereUniqueInput | null;
  create?: CustomerCreateWithoutCreatedByInput | null;
  update?: CustomerUpdateWithoutCreatedByDataInput | null;
  upsert?: CustomerUpsertWithoutCreatedByInput | null;
}

export interface CustomerUpdateWithoutCreatedByDataInput {
  activationAnnualtax?: boolean | null;
  activationAt?: any | null;
  activationFormDoneAt?: any | null;
  activationFormStep?: number | null;
  activationKeeperIsEmployee?: boolean | null;
  address?: string | null;
  allowApplyCurrentMonth?: boolean | null;
  allowDocumentsForViewer?: boolean | null;
  allowFilesUpload?: boolean | null;
  allowMessages?: boolean | null;
  allowMessagesForViewer?: boolean | null;
  allowOpenID?: boolean | null;
  allowPayslipsMultiupload?: boolean | null;
  annualRebatesExportType?: ExportType | null;
  annualRebatesPeriodEnd?: any | null;
  annualRebatesPeriodExtraEnd?: any | null;
  annualRebatesPeriodStart?: any | null;
  blockedAt?: any | null;
  deletedAt?: any | null;
  dic?: string | null;
  divideByUnits?: boolean | null;
  dontRequireBasicRebateAffidavitFiles?: boolean | null;
  employeesImportFiles?: FileUpdateManyInput | null;
  groupName?: string | null;
  ico?: string | null;
  name?: string | null;
  openIDClientId?: string | null;
  openIDClientSecret?: string | null;
  openIDUrl?: string | null;
  rebateEndingsProcessedAt?: any | null;
  requireCheckOfTerms?: boolean | null;
  requireNoTaxableIncomeAffidavit?: boolean | null;
  search?: string | null;
  sharedMessages?: boolean | null;
  ssoClientId?: string | null;
  ssoScopes?: string | null;
  ssoTenantId?: string | null;
  units?: UnitUpdateManyWithoutCustomerInput | null;
  vopAgreementAt?: any | null;
  yearOfAnnualRebates?: number | null;
  yearOfMonthlyRebates?: number | null;
}

export interface CustomerUpsertNestedInput {
  create: CustomerCreateInput;
  update: CustomerUpdateDataInput;
}

export interface CustomerUpsertWithoutCreatedByInput {
  create: CustomerCreateWithoutCreatedByInput;
  update: CustomerUpdateWithoutCreatedByDataInput;
}

export interface CustomerWhereInput {
  activationAnnualtax?: boolean | null;
  activationAnnualtax_not?: boolean | null;
  activationAt?: any | null;
  activationAt_gt?: any | null;
  activationAt_gte?: any | null;
  activationAt_in?: any[] | null;
  activationAt_lt?: any | null;
  activationAt_lte?: any | null;
  activationAt_not?: any | null;
  activationAt_not_in?: any[] | null;
  activationFormDoneAt?: any | null;
  activationFormDoneAt_gt?: any | null;
  activationFormDoneAt_gte?: any | null;
  activationFormDoneAt_in?: any[] | null;
  activationFormDoneAt_lt?: any | null;
  activationFormDoneAt_lte?: any | null;
  activationFormDoneAt_not?: any | null;
  activationFormDoneAt_not_in?: any[] | null;
  activationFormStep?: number | null;
  activationFormStep_gt?: number | null;
  activationFormStep_gte?: number | null;
  activationFormStep_in?: number[] | null;
  activationFormStep_lt?: number | null;
  activationFormStep_lte?: number | null;
  activationFormStep_not?: number | null;
  activationFormStep_not_in?: number[] | null;
  activationKeeperIsEmployee?: boolean | null;
  activationKeeperIsEmployee_not?: boolean | null;
  address?: string | null;
  address_contains?: string | null;
  address_ends_with?: string | null;
  address_gt?: string | null;
  address_gte?: string | null;
  address_in?: string[] | null;
  address_lt?: string | null;
  address_lte?: string | null;
  address_not?: string | null;
  address_not_contains?: string | null;
  address_not_ends_with?: string | null;
  address_not_in?: string[] | null;
  address_not_starts_with?: string | null;
  address_starts_with?: string | null;
  allowApplyCurrentMonth?: boolean | null;
  allowApplyCurrentMonth_not?: boolean | null;
  allowDocumentsForViewer?: boolean | null;
  allowDocumentsForViewer_not?: boolean | null;
  allowFilesUpload?: boolean | null;
  allowFilesUpload_not?: boolean | null;
  allowMessages?: boolean | null;
  allowMessages_not?: boolean | null;
  allowMessagesForViewer?: boolean | null;
  allowMessagesForViewer_not?: boolean | null;
  allowOpenID?: boolean | null;
  allowOpenID_not?: boolean | null;
  allowPayslipsMultiupload?: boolean | null;
  allowPayslipsMultiupload_not?: boolean | null;
  AND?: CustomerWhereInput[] | null;
  annualRebatesExportType?: ExportType | null;
  annualRebatesExportType_in?: ExportType[] | null;
  annualRebatesExportType_not?: ExportType | null;
  annualRebatesExportType_not_in?: ExportType[] | null;
  annualRebatesPeriodEnd?: any | null;
  annualRebatesPeriodEnd_gt?: any | null;
  annualRebatesPeriodEnd_gte?: any | null;
  annualRebatesPeriodEnd_in?: any[] | null;
  annualRebatesPeriodEnd_lt?: any | null;
  annualRebatesPeriodEnd_lte?: any | null;
  annualRebatesPeriodEnd_not?: any | null;
  annualRebatesPeriodEnd_not_in?: any[] | null;
  annualRebatesPeriodExtraEnd?: any | null;
  annualRebatesPeriodExtraEnd_gt?: any | null;
  annualRebatesPeriodExtraEnd_gte?: any | null;
  annualRebatesPeriodExtraEnd_in?: any[] | null;
  annualRebatesPeriodExtraEnd_lt?: any | null;
  annualRebatesPeriodExtraEnd_lte?: any | null;
  annualRebatesPeriodExtraEnd_not?: any | null;
  annualRebatesPeriodExtraEnd_not_in?: any[] | null;
  annualRebatesPeriodStart?: any | null;
  annualRebatesPeriodStart_gt?: any | null;
  annualRebatesPeriodStart_gte?: any | null;
  annualRebatesPeriodStart_in?: any[] | null;
  annualRebatesPeriodStart_lt?: any | null;
  annualRebatesPeriodStart_lte?: any | null;
  annualRebatesPeriodStart_not?: any | null;
  annualRebatesPeriodStart_not_in?: any[] | null;
  blockedAt?: any | null;
  blockedAt_gt?: any | null;
  blockedAt_gte?: any | null;
  blockedAt_in?: any[] | null;
  blockedAt_lt?: any | null;
  blockedAt_lte?: any | null;
  blockedAt_not?: any | null;
  blockedAt_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  createdBy?: UserWhereInput | null;
  deletedAt?: any | null;
  deletedAt_gt?: any | null;
  deletedAt_gte?: any | null;
  deletedAt_in?: any[] | null;
  deletedAt_lt?: any | null;
  deletedAt_lte?: any | null;
  deletedAt_not?: any | null;
  deletedAt_not_in?: any[] | null;
  dic?: string | null;
  dic_contains?: string | null;
  dic_ends_with?: string | null;
  dic_gt?: string | null;
  dic_gte?: string | null;
  dic_in?: string[] | null;
  dic_lt?: string | null;
  dic_lte?: string | null;
  dic_not?: string | null;
  dic_not_contains?: string | null;
  dic_not_ends_with?: string | null;
  dic_not_in?: string[] | null;
  dic_not_starts_with?: string | null;
  dic_starts_with?: string | null;
  divideByUnits?: boolean | null;
  divideByUnits_not?: boolean | null;
  dontRequireBasicRebateAffidavitFiles?: boolean | null;
  dontRequireBasicRebateAffidavitFiles_not?: boolean | null;
  employeesImportFiles_every?: FileWhereInput | null;
  employeesImportFiles_none?: FileWhereInput | null;
  employeesImportFiles_some?: FileWhereInput | null;
  groupName?: string | null;
  groupName_contains?: string | null;
  groupName_ends_with?: string | null;
  groupName_gt?: string | null;
  groupName_gte?: string | null;
  groupName_in?: string[] | null;
  groupName_lt?: string | null;
  groupName_lte?: string | null;
  groupName_not?: string | null;
  groupName_not_contains?: string | null;
  groupName_not_ends_with?: string | null;
  groupName_not_in?: string[] | null;
  groupName_not_starts_with?: string | null;
  groupName_starts_with?: string | null;
  ico?: string | null;
  ico_contains?: string | null;
  ico_ends_with?: string | null;
  ico_gt?: string | null;
  ico_gte?: string | null;
  ico_in?: string[] | null;
  ico_lt?: string | null;
  ico_lte?: string | null;
  ico_not?: string | null;
  ico_not_contains?: string | null;
  ico_not_ends_with?: string | null;
  ico_not_in?: string[] | null;
  ico_not_starts_with?: string | null;
  ico_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  name?: string | null;
  name_contains?: string | null;
  name_ends_with?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_not?: string | null;
  name_not_contains?: string | null;
  name_not_ends_with?: string | null;
  name_not_in?: string[] | null;
  name_not_starts_with?: string | null;
  name_starts_with?: string | null;
  NOT?: CustomerWhereInput[] | null;
  openIDClientId?: string | null;
  openIDClientId_contains?: string | null;
  openIDClientId_ends_with?: string | null;
  openIDClientId_gt?: string | null;
  openIDClientId_gte?: string | null;
  openIDClientId_in?: string[] | null;
  openIDClientId_lt?: string | null;
  openIDClientId_lte?: string | null;
  openIDClientId_not?: string | null;
  openIDClientId_not_contains?: string | null;
  openIDClientId_not_ends_with?: string | null;
  openIDClientId_not_in?: string[] | null;
  openIDClientId_not_starts_with?: string | null;
  openIDClientId_starts_with?: string | null;
  openIDClientSecret?: string | null;
  openIDClientSecret_contains?: string | null;
  openIDClientSecret_ends_with?: string | null;
  openIDClientSecret_gt?: string | null;
  openIDClientSecret_gte?: string | null;
  openIDClientSecret_in?: string[] | null;
  openIDClientSecret_lt?: string | null;
  openIDClientSecret_lte?: string | null;
  openIDClientSecret_not?: string | null;
  openIDClientSecret_not_contains?: string | null;
  openIDClientSecret_not_ends_with?: string | null;
  openIDClientSecret_not_in?: string[] | null;
  openIDClientSecret_not_starts_with?: string | null;
  openIDClientSecret_starts_with?: string | null;
  openIDUrl?: string | null;
  openIDUrl_contains?: string | null;
  openIDUrl_ends_with?: string | null;
  openIDUrl_gt?: string | null;
  openIDUrl_gte?: string | null;
  openIDUrl_in?: string[] | null;
  openIDUrl_lt?: string | null;
  openIDUrl_lte?: string | null;
  openIDUrl_not?: string | null;
  openIDUrl_not_contains?: string | null;
  openIDUrl_not_ends_with?: string | null;
  openIDUrl_not_in?: string[] | null;
  openIDUrl_not_starts_with?: string | null;
  openIDUrl_starts_with?: string | null;
  OR?: CustomerWhereInput[] | null;
  rebateEndingsProcessedAt?: any | null;
  rebateEndingsProcessedAt_gt?: any | null;
  rebateEndingsProcessedAt_gte?: any | null;
  rebateEndingsProcessedAt_in?: any[] | null;
  rebateEndingsProcessedAt_lt?: any | null;
  rebateEndingsProcessedAt_lte?: any | null;
  rebateEndingsProcessedAt_not?: any | null;
  rebateEndingsProcessedAt_not_in?: any[] | null;
  requireCheckOfTerms?: boolean | null;
  requireCheckOfTerms_not?: boolean | null;
  requireNoTaxableIncomeAffidavit?: boolean | null;
  requireNoTaxableIncomeAffidavit_not?: boolean | null;
  search?: string | null;
  search_contains?: string | null;
  search_ends_with?: string | null;
  search_gt?: string | null;
  search_gte?: string | null;
  search_in?: string[] | null;
  search_lt?: string | null;
  search_lte?: string | null;
  search_not?: string | null;
  search_not_contains?: string | null;
  search_not_ends_with?: string | null;
  search_not_in?: string[] | null;
  search_not_starts_with?: string | null;
  search_starts_with?: string | null;
  sharedMessages?: boolean | null;
  sharedMessages_not?: boolean | null;
  ssoClientId?: string | null;
  ssoClientId_contains?: string | null;
  ssoClientId_ends_with?: string | null;
  ssoClientId_gt?: string | null;
  ssoClientId_gte?: string | null;
  ssoClientId_in?: string[] | null;
  ssoClientId_lt?: string | null;
  ssoClientId_lte?: string | null;
  ssoClientId_not?: string | null;
  ssoClientId_not_contains?: string | null;
  ssoClientId_not_ends_with?: string | null;
  ssoClientId_not_in?: string[] | null;
  ssoClientId_not_starts_with?: string | null;
  ssoClientId_starts_with?: string | null;
  ssoScopes?: string | null;
  ssoScopes_contains?: string | null;
  ssoScopes_ends_with?: string | null;
  ssoScopes_gt?: string | null;
  ssoScopes_gte?: string | null;
  ssoScopes_in?: string[] | null;
  ssoScopes_lt?: string | null;
  ssoScopes_lte?: string | null;
  ssoScopes_not?: string | null;
  ssoScopes_not_contains?: string | null;
  ssoScopes_not_ends_with?: string | null;
  ssoScopes_not_in?: string[] | null;
  ssoScopes_not_starts_with?: string | null;
  ssoScopes_starts_with?: string | null;
  ssoTenantId?: string | null;
  ssoTenantId_contains?: string | null;
  ssoTenantId_ends_with?: string | null;
  ssoTenantId_gt?: string | null;
  ssoTenantId_gte?: string | null;
  ssoTenantId_in?: string[] | null;
  ssoTenantId_lt?: string | null;
  ssoTenantId_lte?: string | null;
  ssoTenantId_not?: string | null;
  ssoTenantId_not_contains?: string | null;
  ssoTenantId_not_ends_with?: string | null;
  ssoTenantId_not_in?: string[] | null;
  ssoTenantId_not_starts_with?: string | null;
  ssoTenantId_starts_with?: string | null;
  units_every?: UnitWhereInput | null;
  units_none?: UnitWhereInput | null;
  units_some?: UnitWhereInput | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
  vopAgreementAt?: any | null;
  vopAgreementAt_gt?: any | null;
  vopAgreementAt_gte?: any | null;
  vopAgreementAt_in?: any[] | null;
  vopAgreementAt_lt?: any | null;
  vopAgreementAt_lte?: any | null;
  vopAgreementAt_not?: any | null;
  vopAgreementAt_not_in?: any[] | null;
  yearOfAnnualRebates?: number | null;
  yearOfAnnualRebates_gt?: number | null;
  yearOfAnnualRebates_gte?: number | null;
  yearOfAnnualRebates_in?: number[] | null;
  yearOfAnnualRebates_lt?: number | null;
  yearOfAnnualRebates_lte?: number | null;
  yearOfAnnualRebates_not?: number | null;
  yearOfAnnualRebates_not_in?: number[] | null;
  yearOfMonthlyRebates?: number | null;
  yearOfMonthlyRebates_gt?: number | null;
  yearOfMonthlyRebates_gte?: number | null;
  yearOfMonthlyRebates_in?: number[] | null;
  yearOfMonthlyRebates_lt?: number | null;
  yearOfMonthlyRebates_lte?: number | null;
  yearOfMonthlyRebates_not?: number | null;
  yearOfMonthlyRebates_not_in?: number[] | null;
}

export interface CustomerWhereUniqueInput {
  id?: string | null;
  name?: string | null;
}

export interface DisabilityOtherFileCreateManyWithoutDisabilityRebateInput {
  connect?: DisabilityOtherFileWhereUniqueInput[] | null;
  create?: DisabilityOtherFileCreateWithoutDisabilityRebateInput[] | null;
}

export interface DisabilityOtherFileCreateWithoutDisabilityRebateInput {
  file: FileCreateOneInput;
  id?: string | null;
  type: DisabilityFileType;
}

export interface DisabilityOtherFileScalarWhereInput {
  AND?: DisabilityOtherFileScalarWhereInput[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: DisabilityOtherFileScalarWhereInput[] | null;
  OR?: DisabilityOtherFileScalarWhereInput[] | null;
  type?: DisabilityFileType | null;
  type_in?: DisabilityFileType[] | null;
  type_not?: DisabilityFileType | null;
  type_not_in?: DisabilityFileType[] | null;
}

export interface DisabilityOtherFileUpdateManyDataInput {
  type?: DisabilityFileType | null;
}

export interface DisabilityOtherFileUpdateManyWithWhereNestedInput {
  data: DisabilityOtherFileUpdateManyDataInput;
  where: DisabilityOtherFileScalarWhereInput;
}

export interface DisabilityOtherFileUpdateManyWithoutDisabilityRebateInput {
  connect?: DisabilityOtherFileWhereUniqueInput[] | null;
  create?: DisabilityOtherFileCreateWithoutDisabilityRebateInput[] | null;
  delete?: DisabilityOtherFileWhereUniqueInput[] | null;
  deleteMany?: DisabilityOtherFileScalarWhereInput[] | null;
  disconnect?: DisabilityOtherFileWhereUniqueInput[] | null;
  set?: DisabilityOtherFileWhereUniqueInput[] | null;
  update?: DisabilityOtherFileUpdateWithWhereUniqueWithoutDisabilityRebateInput[] | null;
  updateMany?: DisabilityOtherFileUpdateManyWithWhereNestedInput[] | null;
  upsert?: DisabilityOtherFileUpsertWithWhereUniqueWithoutDisabilityRebateInput[] | null;
}

export interface DisabilityOtherFileUpdateWithWhereUniqueWithoutDisabilityRebateInput {
  data: DisabilityOtherFileUpdateWithoutDisabilityRebateDataInput;
  where: DisabilityOtherFileWhereUniqueInput;
}

export interface DisabilityOtherFileUpdateWithoutDisabilityRebateDataInput {
  file?: FileUpdateOneRequiredInput | null;
  type?: DisabilityFileType | null;
}

export interface DisabilityOtherFileUpsertWithWhereUniqueWithoutDisabilityRebateInput {
  create: DisabilityOtherFileCreateWithoutDisabilityRebateInput;
  update: DisabilityOtherFileUpdateWithoutDisabilityRebateDataInput;
  where: DisabilityOtherFileWhereUniqueInput;
}

export interface DisabilityOtherFileWhereInput {
  AND?: DisabilityOtherFileWhereInput[] | null;
  disabilityRebate?: DisabilityRebateWhereInput | null;
  file?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: DisabilityOtherFileWhereInput[] | null;
  OR?: DisabilityOtherFileWhereInput[] | null;
  type?: DisabilityFileType | null;
  type_in?: DisabilityFileType[] | null;
  type_not?: DisabilityFileType | null;
  type_not_in?: DisabilityFileType[] | null;
}

export interface DisabilityOtherFileWhereUniqueInput {
  id?: string | null;
}

export interface DisabilityRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutDisabilityRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileCreateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileCreateManyWithoutDisabilityRebateInput | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutDisabilityRebatesInput | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutDisabilityRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface DisabilityRebateCreateManyWithoutAnnualRebateInput {
  connect?: DisabilityRebateWhereUniqueInput[] | null;
  create?: DisabilityRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface DisabilityRebateCreateManyWithoutMonthlyRebateInput {
  connect?: DisabilityRebateWhereUniqueInput[] | null;
  create?: DisabilityRebateCreateWithoutMonthlyRebateInput[] | null;
}

export interface DisabilityRebateCreateOneInput {
  connect?: DisabilityRebateWhereUniqueInput | null;
  create?: DisabilityRebateCreateInput | null;
}

export interface DisabilityRebateCreateOneWithoutSettlementRequestInput {
  connect?: DisabilityRebateWhereUniqueInput | null;
  create?: DisabilityRebateCreateWithoutSettlementRequestInput | null;
}

export interface DisabilityRebateCreateWithoutAnnualRebateInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileCreateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileCreateManyWithoutDisabilityRebateInput | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutDisabilityRebatesInput | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutDisabilityRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface DisabilityRebateCreateWithoutMonthlyRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutDisabilityRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileCreateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileCreateManyWithoutDisabilityRebateInput | null;
  id?: string | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutDisabilityRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface DisabilityRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutDisabilityRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileCreateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileCreateManyWithoutDisabilityRebateInput | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutDisabilityRebatesInput | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface DisabilityRebatePrevYearPeriodCreateManyWithoutRebateInput {
  connect?: DisabilityRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: DisabilityRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
}

export interface DisabilityRebatePrevYearPeriodCreateWithoutRebateInput {
  disabilityLevel?: DisabilityLevel | null;
  from?: any | null;
  id?: string | null;
  to?: any | null;
}

export interface DisabilityRebatePrevYearPeriodScalarWhereInput {
  AND?: DisabilityRebatePrevYearPeriodScalarWhereInput[] | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityLevel_in?: DisabilityLevel[] | null;
  disabilityLevel_not?: DisabilityLevel | null;
  disabilityLevel_not_in?: DisabilityLevel[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: DisabilityRebatePrevYearPeriodScalarWhereInput[] | null;
  OR?: DisabilityRebatePrevYearPeriodScalarWhereInput[] | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface DisabilityRebatePrevYearPeriodUpdateManyDataInput {
  disabilityLevel?: DisabilityLevel | null;
  from?: any | null;
  to?: any | null;
}

export interface DisabilityRebatePrevYearPeriodUpdateManyWithWhereNestedInput {
  data: DisabilityRebatePrevYearPeriodUpdateManyDataInput;
  where: DisabilityRebatePrevYearPeriodScalarWhereInput;
}

export interface DisabilityRebatePrevYearPeriodUpdateManyWithoutRebateInput {
  connect?: DisabilityRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: DisabilityRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
  delete?: DisabilityRebatePrevYearPeriodWhereUniqueInput[] | null;
  deleteMany?: DisabilityRebatePrevYearPeriodScalarWhereInput[] | null;
  disconnect?: DisabilityRebatePrevYearPeriodWhereUniqueInput[] | null;
  set?: DisabilityRebatePrevYearPeriodWhereUniqueInput[] | null;
  update?: DisabilityRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput[] | null;
  updateMany?: DisabilityRebatePrevYearPeriodUpdateManyWithWhereNestedInput[] | null;
  upsert?: DisabilityRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput[] | null;
}

export interface DisabilityRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput {
  data: DisabilityRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: DisabilityRebatePrevYearPeriodWhereUniqueInput;
}

export interface DisabilityRebatePrevYearPeriodUpdateWithoutRebateDataInput {
  disabilityLevel?: DisabilityLevel | null;
  from?: any | null;
  to?: any | null;
}

export interface DisabilityRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput {
  create: DisabilityRebatePrevYearPeriodCreateWithoutRebateInput;
  update: DisabilityRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: DisabilityRebatePrevYearPeriodWhereUniqueInput;
}

export interface DisabilityRebatePrevYearPeriodWhereInput {
  AND?: DisabilityRebatePrevYearPeriodWhereInput[] | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityLevel_in?: DisabilityLevel[] | null;
  disabilityLevel_not?: DisabilityLevel | null;
  disabilityLevel_not_in?: DisabilityLevel[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: DisabilityRebatePrevYearPeriodWhereInput[] | null;
  OR?: DisabilityRebatePrevYearPeriodWhereInput[] | null;
  rebate?: DisabilityRebateWhereInput | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface DisabilityRebatePrevYearPeriodWhereUniqueInput {
  id?: string | null;
}

export interface DisabilityRebateScalarWhereInput {
  AND?: DisabilityRebateScalarWhereInput[] | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityFrom_gt?: any | null;
  confirmationValidityFrom_gte?: any | null;
  confirmationValidityFrom_in?: any[] | null;
  confirmationValidityFrom_lt?: any | null;
  confirmationValidityFrom_lte?: any | null;
  confirmationValidityFrom_not?: any | null;
  confirmationValidityFrom_not_in?: any[] | null;
  confirmationValidityTo?: any | null;
  confirmationValidityTo_gt?: any | null;
  confirmationValidityTo_gte?: any | null;
  confirmationValidityTo_in?: any[] | null;
  confirmationValidityTo_lt?: any | null;
  confirmationValidityTo_lte?: any | null;
  confirmationValidityTo_not?: any | null;
  confirmationValidityTo_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityLevel_in?: DisabilityLevel[] | null;
  disabilityLevel_not?: DisabilityLevel | null;
  disabilityLevel_not_in?: DisabilityLevel[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: DisabilityRebateScalarWhereInput[] | null;
  OR?: DisabilityRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface DisabilityRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutDisabilityRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileUpdateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileUpdateManyWithoutDisabilityRebateInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutDisabilityRebatesInput | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutDisabilityRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface DisabilityRebateUpdateManyDataInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityLevel?: DisabilityLevel | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface DisabilityRebateUpdateManyWithWhereNestedInput {
  data: DisabilityRebateUpdateManyDataInput;
  where: DisabilityRebateScalarWhereInput;
}

export interface DisabilityRebateUpdateManyWithoutAnnualRebateInput {
  connect?: DisabilityRebateWhereUniqueInput[] | null;
  create?: DisabilityRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: DisabilityRebateWhereUniqueInput[] | null;
  deleteMany?: DisabilityRebateScalarWhereInput[] | null;
  disconnect?: DisabilityRebateWhereUniqueInput[] | null;
  set?: DisabilityRebateWhereUniqueInput[] | null;
  update?: DisabilityRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: DisabilityRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: DisabilityRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface DisabilityRebateUpdateManyWithoutMonthlyRebateInput {
  connect?: DisabilityRebateWhereUniqueInput[] | null;
  create?: DisabilityRebateCreateWithoutMonthlyRebateInput[] | null;
  delete?: DisabilityRebateWhereUniqueInput[] | null;
  deleteMany?: DisabilityRebateScalarWhereInput[] | null;
  disconnect?: DisabilityRebateWhereUniqueInput[] | null;
  set?: DisabilityRebateWhereUniqueInput[] | null;
  update?: DisabilityRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput[] | null;
  updateMany?: DisabilityRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: DisabilityRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput[] | null;
}

export interface DisabilityRebateUpdateOneRequiredInput {
  connect?: DisabilityRebateWhereUniqueInput | null;
  create?: DisabilityRebateCreateInput | null;
  update?: DisabilityRebateUpdateDataInput | null;
  upsert?: DisabilityRebateUpsertNestedInput | null;
}

export interface DisabilityRebateUpdateOneWithoutSettlementRequestInput {
  connect?: DisabilityRebateWhereUniqueInput | null;
  create?: DisabilityRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: DisabilityRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: DisabilityRebateUpsertWithoutSettlementRequestInput | null;
}

export interface DisabilityRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: DisabilityRebateUpdateWithoutAnnualRebateDataInput;
  where: DisabilityRebateWhereUniqueInput;
}

export interface DisabilityRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput {
  data: DisabilityRebateUpdateWithoutMonthlyRebateDataInput;
  where: DisabilityRebateWhereUniqueInput;
}

export interface DisabilityRebateUpdateWithoutAnnualRebateDataInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileUpdateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileUpdateManyWithoutDisabilityRebateInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutDisabilityRebatesInput | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutDisabilityRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface DisabilityRebateUpdateWithoutMonthlyRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutDisabilityRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileUpdateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileUpdateManyWithoutDisabilityRebateInput | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutDisabilityRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface DisabilityRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutDisabilityRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileUpdateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: DisabilityOtherFileUpdateManyWithoutDisabilityRebateInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutDisabilityRebatesInput | null;
  prevYearPeriods?: DisabilityRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface DisabilityRebateUpsertNestedInput {
  create: DisabilityRebateCreateInput;
  update: DisabilityRebateUpdateDataInput;
}

export interface DisabilityRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: DisabilityRebateCreateWithoutAnnualRebateInput;
  update: DisabilityRebateUpdateWithoutAnnualRebateDataInput;
  where: DisabilityRebateWhereUniqueInput;
}

export interface DisabilityRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput {
  create: DisabilityRebateCreateWithoutMonthlyRebateInput;
  update: DisabilityRebateUpdateWithoutMonthlyRebateDataInput;
  where: DisabilityRebateWhereUniqueInput;
}

export interface DisabilityRebateUpsertWithoutSettlementRequestInput {
  create: DisabilityRebateCreateWithoutSettlementRequestInput;
  update: DisabilityRebateUpdateWithoutSettlementRequestDataInput;
}

export interface DisabilityRebateWhereInput {
  AND?: DisabilityRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityFrom_gt?: any | null;
  confirmationValidityFrom_gte?: any | null;
  confirmationValidityFrom_in?: any[] | null;
  confirmationValidityFrom_lt?: any | null;
  confirmationValidityFrom_lte?: any | null;
  confirmationValidityFrom_not?: any | null;
  confirmationValidityFrom_not_in?: any[] | null;
  confirmationValidityTo?: any | null;
  confirmationValidityTo_gt?: any | null;
  confirmationValidityTo_gte?: any | null;
  confirmationValidityTo_in?: any[] | null;
  confirmationValidityTo_lt?: any | null;
  confirmationValidityTo_lte?: any | null;
  confirmationValidityTo_not?: any | null;
  confirmationValidityTo_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  disabilityConfirmationFiles_every?: FileWhereInput | null;
  disabilityConfirmationFiles_none?: FileWhereInput | null;
  disabilityConfirmationFiles_some?: FileWhereInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityLevel_in?: DisabilityLevel[] | null;
  disabilityLevel_not?: DisabilityLevel | null;
  disabilityLevel_not_in?: DisabilityLevel[] | null;
  disabilityOtherFiles_every?: DisabilityOtherFileWhereInput | null;
  disabilityOtherFiles_none?: DisabilityOtherFileWhereInput | null;
  disabilityOtherFiles_some?: DisabilityOtherFileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  NOT?: DisabilityRebateWhereInput[] | null;
  OR?: DisabilityRebateWhereInput[] | null;
  prevYearPeriods_every?: DisabilityRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_none?: DisabilityRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_some?: DisabilityRebatePrevYearPeriodWhereInput | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface DisabilityRebateWhereUniqueInput {
  id?: string | null;
}

export interface DocumentApprovalCreateInput {
  commentOfDenied?: string | null;
  employee?: UserCreateOneInput | null;
  files?: FileCreateManyInput | null;
  id?: string | null;
  solvedAt?: any | null;
  solvedBy?: UserCreateOneInput | null;
  status?: DocumentApprovalStatus | null;
  type: DocumentApprovalType;
}

export interface DocumentApprovalCreateManyInput {
  connect?: DocumentApprovalWhereUniqueInput[] | null;
  create?: DocumentApprovalCreateInput[] | null;
}

export interface DocumentApprovalCreateOneInput {
  connect?: DocumentApprovalWhereUniqueInput | null;
  create?: DocumentApprovalCreateInput | null;
}

export interface DocumentApprovalScalarWhereInput {
  AND?: DocumentApprovalScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: DocumentApprovalScalarWhereInput[] | null;
  OR?: DocumentApprovalScalarWhereInput[] | null;
  solvedAt?: any | null;
  solvedAt_gt?: any | null;
  solvedAt_gte?: any | null;
  solvedAt_in?: any[] | null;
  solvedAt_lt?: any | null;
  solvedAt_lte?: any | null;
  solvedAt_not?: any | null;
  solvedAt_not_in?: any[] | null;
  status?: DocumentApprovalStatus | null;
  status_in?: DocumentApprovalStatus[] | null;
  status_not?: DocumentApprovalStatus | null;
  status_not_in?: DocumentApprovalStatus[] | null;
  type?: DocumentApprovalType | null;
  type_in?: DocumentApprovalType[] | null;
  type_not?: DocumentApprovalType | null;
  type_not_in?: DocumentApprovalType[] | null;
}

export interface DocumentApprovalUpdateDataInput {
  commentOfDenied?: string | null;
  employee?: UserUpdateOneInput | null;
  files?: FileUpdateManyInput | null;
  solvedAt?: any | null;
  solvedBy?: UserUpdateOneInput | null;
  status?: DocumentApprovalStatus | null;
  type?: DocumentApprovalType | null;
}

export interface DocumentApprovalUpdateManyDataInput {
  commentOfDenied?: string | null;
  solvedAt?: any | null;
  status?: DocumentApprovalStatus | null;
  type?: DocumentApprovalType | null;
}

export interface DocumentApprovalUpdateManyInput {
  connect?: DocumentApprovalWhereUniqueInput[] | null;
  create?: DocumentApprovalCreateInput[] | null;
  delete?: DocumentApprovalWhereUniqueInput[] | null;
  deleteMany?: DocumentApprovalScalarWhereInput[] | null;
  disconnect?: DocumentApprovalWhereUniqueInput[] | null;
  set?: DocumentApprovalWhereUniqueInput[] | null;
  update?: DocumentApprovalUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: DocumentApprovalUpdateManyWithWhereNestedInput[] | null;
  upsert?: DocumentApprovalUpsertWithWhereUniqueNestedInput[] | null;
}

export interface DocumentApprovalUpdateManyWithWhereNestedInput {
  data: DocumentApprovalUpdateManyDataInput;
  where: DocumentApprovalScalarWhereInput;
}

export interface DocumentApprovalUpdateOneInput {
  connect?: DocumentApprovalWhereUniqueInput | null;
  create?: DocumentApprovalCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: DocumentApprovalUpdateDataInput | null;
  upsert?: DocumentApprovalUpsertNestedInput | null;
}

export interface DocumentApprovalUpdateWithWhereUniqueNestedInput {
  data: DocumentApprovalUpdateDataInput;
  where: DocumentApprovalWhereUniqueInput;
}

export interface DocumentApprovalUpsertNestedInput {
  create: DocumentApprovalCreateInput;
  update: DocumentApprovalUpdateDataInput;
}

export interface DocumentApprovalUpsertWithWhereUniqueNestedInput {
  create: DocumentApprovalCreateInput;
  update: DocumentApprovalUpdateDataInput;
  where: DocumentApprovalWhereUniqueInput;
}

export interface DocumentApprovalWhereInput {
  AND?: DocumentApprovalWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  employee?: UserWhereInput | null;
  files_every?: FileWhereInput | null;
  files_none?: FileWhereInput | null;
  files_some?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: DocumentApprovalWhereInput[] | null;
  OR?: DocumentApprovalWhereInput[] | null;
  solvedAt?: any | null;
  solvedAt_gt?: any | null;
  solvedAt_gte?: any | null;
  solvedAt_in?: any[] | null;
  solvedAt_lt?: any | null;
  solvedAt_lte?: any | null;
  solvedAt_not?: any | null;
  solvedAt_not_in?: any[] | null;
  solvedBy?: UserWhereInput | null;
  status?: DocumentApprovalStatus | null;
  status_in?: DocumentApprovalStatus[] | null;
  status_not?: DocumentApprovalStatus | null;
  status_not_in?: DocumentApprovalStatus[] | null;
  type?: DocumentApprovalType | null;
  type_in?: DocumentApprovalType[] | null;
  type_not?: DocumentApprovalType | null;
  type_not_in?: DocumentApprovalType[] | null;
}

export interface DocumentApprovalWhereUniqueInput {
  id?: string | null;
}

export interface DocumentCreateInput {
  files?: FileCreateManyInput | null;
  name: string;
  type?: DocumentType | null;
  user?: UserCreateOneInput | null;
}

export interface DocumentUpdateInput {
  name?: string | null;
  type?: DocumentType | null;
}

export interface DocumentWhereInput {
  AND?: DocumentWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  files_every?: FileWhereInput | null;
  files_none?: FileWhereInput | null;
  files_some?: FileWhereInput | null;
  id?: string | null;
  id_in?: string[] | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_in?: string[] | null;
  name?: string | null;
  name_contains?: string | null;
  name_in?: string[] | null;
  name_not?: string | null;
  name_not_contains?: string | null;
  name_not_in?: string[] | null;
  NOT?: DocumentWhereInput[] | null;
  OR?: DocumentWhereInput[] | null;
  search_contains?: string | null;
  type?: DocumentType | null;
  user?: UserWhereInput | null;
}

export interface DocumentWhereUniqueInput {
  id?: string | null;
}

export interface EmailWhereInput {
  AND?: EmailWhereInput[] | null;
  body?: string | null;
  body_contains?: string | null;
  body_ends_with?: string | null;
  body_gt?: string | null;
  body_gte?: string | null;
  body_in?: string[] | null;
  body_lt?: string | null;
  body_lte?: string | null;
  body_not?: string | null;
  body_not_contains?: string | null;
  body_not_ends_with?: string | null;
  body_not_in?: string[] | null;
  body_not_starts_with?: string | null;
  body_starts_with?: string | null;
  code?: string | null;
  code_contains?: string | null;
  code_ends_with?: string | null;
  code_gt?: string | null;
  code_gte?: string | null;
  code_in?: string[] | null;
  code_lt?: string | null;
  code_lte?: string | null;
  code_not?: string | null;
  code_not_contains?: string | null;
  code_not_ends_with?: string | null;
  code_not_in?: string[] | null;
  code_not_starts_with?: string | null;
  code_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  customer?: CustomerWhereInput | null;
  data?: string | null;
  data_contains?: string | null;
  data_ends_with?: string | null;
  data_gt?: string | null;
  data_gte?: string | null;
  data_in?: string[] | null;
  data_lt?: string | null;
  data_lte?: string | null;
  data_not?: string | null;
  data_not_contains?: string | null;
  data_not_ends_with?: string | null;
  data_not_in?: string[] | null;
  data_not_starts_with?: string | null;
  data_starts_with?: string | null;
  from?: string | null;
  from_contains?: string | null;
  from_ends_with?: string | null;
  from_gt?: string | null;
  from_gte?: string | null;
  from_in?: string[] | null;
  from_lt?: string | null;
  from_lte?: string | null;
  from_not?: string | null;
  from_not_contains?: string | null;
  from_not_ends_with?: string | null;
  from_not_in?: string[] | null;
  from_not_starts_with?: string | null;
  from_starts_with?: string | null;
  html?: string | null;
  html_contains?: string | null;
  html_ends_with?: string | null;
  html_gt?: string | null;
  html_gte?: string | null;
  html_in?: string[] | null;
  html_lt?: string | null;
  html_lte?: string | null;
  html_not?: string | null;
  html_not_contains?: string | null;
  html_not_ends_with?: string | null;
  html_not_in?: string[] | null;
  html_not_starts_with?: string | null;
  html_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  language?: Language | null;
  language_in?: Language[] | null;
  language_not?: Language | null;
  language_not_in?: Language[] | null;
  message?: MessageWhereInput | null;
  NOT?: EmailWhereInput[] | null;
  OR?: EmailWhereInput[] | null;
  postpone?: any | null;
  postpone_gt?: any | null;
  postpone_gte?: any | null;
  postpone_in?: any[] | null;
  postpone_lt?: any | null;
  postpone_lte?: any | null;
  postpone_not?: any | null;
  postpone_not_in?: any[] | null;
  rendered?: boolean | null;
  rendered_not?: boolean | null;
  sent?: boolean | null;
  sent_not?: boolean | null;
  sentAt?: any | null;
  sentAt_gt?: any | null;
  sentAt_gte?: any | null;
  sentAt_in?: any[] | null;
  sentAt_lt?: any | null;
  sentAt_lte?: any | null;
  sentAt_not?: any | null;
  sentAt_not_in?: any[] | null;
  subject?: string | null;
  subject_contains?: string | null;
  subject_ends_with?: string | null;
  subject_gt?: string | null;
  subject_gte?: string | null;
  subject_in?: string[] | null;
  subject_lt?: string | null;
  subject_lte?: string | null;
  subject_not?: string | null;
  subject_not_contains?: string | null;
  subject_not_ends_with?: string | null;
  subject_not_in?: string[] | null;
  subject_not_starts_with?: string | null;
  subject_starts_with?: string | null;
  templateName?: string | null;
  templateName_contains?: string | null;
  templateName_ends_with?: string | null;
  templateName_gt?: string | null;
  templateName_gte?: string | null;
  templateName_in?: string[] | null;
  templateName_lt?: string | null;
  templateName_lte?: string | null;
  templateName_not?: string | null;
  templateName_not_contains?: string | null;
  templateName_not_ends_with?: string | null;
  templateName_not_in?: string[] | null;
  templateName_not_starts_with?: string | null;
  templateName_starts_with?: string | null;
  text?: string | null;
  text_contains?: string | null;
  text_ends_with?: string | null;
  text_gt?: string | null;
  text_gte?: string | null;
  text_in?: string[] | null;
  text_lt?: string | null;
  text_lte?: string | null;
  text_not?: string | null;
  text_not_contains?: string | null;
  text_not_ends_with?: string | null;
  text_not_in?: string[] | null;
  text_not_starts_with?: string | null;
  text_starts_with?: string | null;
  to?: string | null;
  to_contains?: string | null;
  to_ends_with?: string | null;
  to_gt?: string | null;
  to_gte?: string | null;
  to_in?: string[] | null;
  to_lt?: string | null;
  to_lte?: string | null;
  to_not?: string | null;
  to_not_contains?: string | null;
  to_not_ends_with?: string | null;
  to_not_in?: string[] | null;
  to_not_starts_with?: string | null;
  to_starts_with?: string | null;
  user?: UserWhereInput | null;
}

export interface ExamRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutExamRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutExamRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface ExamRebateCreateManyWithoutAnnualRebateInput {
  connect?: ExamRebateWhereUniqueInput[] | null;
  create?: ExamRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface ExamRebateCreateOneInput {
  connect?: ExamRebateWhereUniqueInput | null;
  create?: ExamRebateCreateInput | null;
}

export interface ExamRebateCreateOneWithoutSettlementRequestInput {
  connect?: ExamRebateWhereUniqueInput | null;
  create?: ExamRebateCreateWithoutSettlementRequestInput | null;
}

export interface ExamRebateCreateWithoutAnnualRebateInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutExamRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface ExamRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutExamRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface ExamRebateScalarWhereInput {
  AND?: ExamRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ExamRebateScalarWhereInput[] | null;
  OR?: ExamRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface ExamRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutExamRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutExamRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ExamRebateUpdateManyDataInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ExamRebateUpdateManyWithWhereNestedInput {
  data: ExamRebateUpdateManyDataInput;
  where: ExamRebateScalarWhereInput;
}

export interface ExamRebateUpdateManyWithoutAnnualRebateInput {
  connect?: ExamRebateWhereUniqueInput[] | null;
  create?: ExamRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: ExamRebateWhereUniqueInput[] | null;
  deleteMany?: ExamRebateScalarWhereInput[] | null;
  disconnect?: ExamRebateWhereUniqueInput[] | null;
  set?: ExamRebateWhereUniqueInput[] | null;
  update?: ExamRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: ExamRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: ExamRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface ExamRebateUpdateOneRequiredInput {
  connect?: ExamRebateWhereUniqueInput | null;
  create?: ExamRebateCreateInput | null;
  update?: ExamRebateUpdateDataInput | null;
  upsert?: ExamRebateUpsertNestedInput | null;
}

export interface ExamRebateUpdateOneWithoutSettlementRequestInput {
  connect?: ExamRebateWhereUniqueInput | null;
  create?: ExamRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ExamRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: ExamRebateUpsertWithoutSettlementRequestInput | null;
}

export interface ExamRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: ExamRebateUpdateWithoutAnnualRebateDataInput;
  where: ExamRebateWhereUniqueInput;
}

export interface ExamRebateUpdateWithoutAnnualRebateDataInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutExamRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ExamRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutExamRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ExamRebateUpsertNestedInput {
  create: ExamRebateCreateInput;
  update: ExamRebateUpdateDataInput;
}

export interface ExamRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: ExamRebateCreateWithoutAnnualRebateInput;
  update: ExamRebateUpdateWithoutAnnualRebateDataInput;
  where: ExamRebateWhereUniqueInput;
}

export interface ExamRebateUpsertWithoutSettlementRequestInput {
  create: ExamRebateCreateWithoutSettlementRequestInput;
  update: ExamRebateUpdateWithoutSettlementRequestDataInput;
}

export interface ExamRebateWhereInput {
  AND?: ExamRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  feeConfirmationFiles_every?: FileWhereInput | null;
  feeConfirmationFiles_none?: FileWhereInput | null;
  feeConfirmationFiles_some?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ExamRebateWhereInput[] | null;
  OR?: ExamRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface ExamRebateWhereUniqueInput {
  id?: string | null;
}

export interface FileCreateInput {
  createdBy: UserCreateOneInput;
  encoding: string;
  filename: string;
  filepath: string;
  id?: string | null;
  mimetype: string;
  ownedBy: UserCreateOneInput;
  size: number;
}

export interface FileCreateManyInput {
  connect?: FileWhereUniqueInput[] | null;
  create?: any[] | null;
  delete?: FileWhereUniqueInput[] | null;
}

export interface FileCreateOneInput {
  connect?: FileWhereUniqueInput | null;
  create?: FileCreateInput | null;
}

export interface FileProcessingCreateInput {
  createdBy: UserCreateOneInput;
  expirationAt: any;
  file?: FileCreateOneInput | null;
  id?: string | null;
  metaArgs?: string | null;
  notifyToBrowser?: boolean | null;
  notifyToEmail?: string | null;
  progress?: number | null;
  result?: string | null;
  status: FileProcessingStatus;
  type: FileProcessingType;
}

export interface FileProcessingCreateManyInput {
  connect?: FileProcessingWhereUniqueInput[] | null;
  create?: FileProcessingCreateInput[] | null;
}

export interface FileProcessingScalarWhereInput {
  AND?: FileProcessingScalarWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  expirationAt?: any | null;
  expirationAt_gt?: any | null;
  expirationAt_gte?: any | null;
  expirationAt_in?: any[] | null;
  expirationAt_lt?: any | null;
  expirationAt_lte?: any | null;
  expirationAt_not?: any | null;
  expirationAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  metaArgs?: string | null;
  metaArgs_contains?: string | null;
  metaArgs_ends_with?: string | null;
  metaArgs_gt?: string | null;
  metaArgs_gte?: string | null;
  metaArgs_in?: string[] | null;
  metaArgs_lt?: string | null;
  metaArgs_lte?: string | null;
  metaArgs_not?: string | null;
  metaArgs_not_contains?: string | null;
  metaArgs_not_ends_with?: string | null;
  metaArgs_not_in?: string[] | null;
  metaArgs_not_starts_with?: string | null;
  metaArgs_starts_with?: string | null;
  NOT?: FileProcessingScalarWhereInput[] | null;
  notifyToBrowser?: boolean | null;
  notifyToBrowser_not?: boolean | null;
  notifyToEmail?: string | null;
  notifyToEmail_contains?: string | null;
  notifyToEmail_ends_with?: string | null;
  notifyToEmail_gt?: string | null;
  notifyToEmail_gte?: string | null;
  notifyToEmail_in?: string[] | null;
  notifyToEmail_lt?: string | null;
  notifyToEmail_lte?: string | null;
  notifyToEmail_not?: string | null;
  notifyToEmail_not_contains?: string | null;
  notifyToEmail_not_ends_with?: string | null;
  notifyToEmail_not_in?: string[] | null;
  notifyToEmail_not_starts_with?: string | null;
  notifyToEmail_starts_with?: string | null;
  OR?: FileProcessingScalarWhereInput[] | null;
  progress?: number | null;
  progress_gt?: number | null;
  progress_gte?: number | null;
  progress_in?: number[] | null;
  progress_lt?: number | null;
  progress_lte?: number | null;
  progress_not?: number | null;
  progress_not_in?: number[] | null;
  result?: string | null;
  result_contains?: string | null;
  result_ends_with?: string | null;
  result_gt?: string | null;
  result_gte?: string | null;
  result_in?: string[] | null;
  result_lt?: string | null;
  result_lte?: string | null;
  result_not?: string | null;
  result_not_contains?: string | null;
  result_not_ends_with?: string | null;
  result_not_in?: string[] | null;
  result_not_starts_with?: string | null;
  result_starts_with?: string | null;
  status?: FileProcessingStatus | null;
  status_in?: FileProcessingStatus[] | null;
  status_not?: FileProcessingStatus | null;
  status_not_in?: FileProcessingStatus[] | null;
  type?: FileProcessingType | null;
  type_in?: FileProcessingType[] | null;
  type_not?: FileProcessingType | null;
  type_not_in?: FileProcessingType[] | null;
}

export interface FileProcessingUpdateDataInput {
  createdBy?: UserUpdateOneRequiredInput | null;
  expirationAt?: any | null;
  file?: FileUpdateOneInput | null;
  metaArgs?: string | null;
  notifyToBrowser?: boolean | null;
  notifyToEmail?: string | null;
  progress?: number | null;
  result?: string | null;
  status?: FileProcessingStatus | null;
  type?: FileProcessingType | null;
}

export interface FileProcessingUpdateManyDataInput {
  expirationAt?: any | null;
  metaArgs?: string | null;
  notifyToBrowser?: boolean | null;
  notifyToEmail?: string | null;
  progress?: number | null;
  result?: string | null;
  status?: FileProcessingStatus | null;
  type?: FileProcessingType | null;
}

export interface FileProcessingUpdateManyInput {
  connect?: FileProcessingWhereUniqueInput[] | null;
  create?: FileProcessingCreateInput[] | null;
  delete?: FileProcessingWhereUniqueInput[] | null;
  deleteMany?: FileProcessingScalarWhereInput[] | null;
  disconnect?: FileProcessingWhereUniqueInput[] | null;
  set?: FileProcessingWhereUniqueInput[] | null;
  update?: FileProcessingUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: FileProcessingUpdateManyWithWhereNestedInput[] | null;
  upsert?: FileProcessingUpsertWithWhereUniqueNestedInput[] | null;
}

export interface FileProcessingUpdateManyWithWhereNestedInput {
  data: FileProcessingUpdateManyDataInput;
  where: FileProcessingScalarWhereInput;
}

export interface FileProcessingUpdateWithWhereUniqueNestedInput {
  data: FileProcessingUpdateDataInput;
  where: FileProcessingWhereUniqueInput;
}

export interface FileProcessingUpsertWithWhereUniqueNestedInput {
  create: FileProcessingCreateInput;
  update: FileProcessingUpdateDataInput;
  where: FileProcessingWhereUniqueInput;
}

export interface FileProcessingWhereInput {
  AND?: FileProcessingWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  createdBy?: UserWhereInput | null;
  expirationAt?: any | null;
  expirationAt_gt?: any | null;
  expirationAt_gte?: any | null;
  expirationAt_in?: any[] | null;
  expirationAt_lt?: any | null;
  expirationAt_lte?: any | null;
  expirationAt_not?: any | null;
  expirationAt_not_in?: any[] | null;
  file?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  metaArgs?: string | null;
  metaArgs_contains?: string | null;
  metaArgs_ends_with?: string | null;
  metaArgs_gt?: string | null;
  metaArgs_gte?: string | null;
  metaArgs_in?: string[] | null;
  metaArgs_lt?: string | null;
  metaArgs_lte?: string | null;
  metaArgs_not?: string | null;
  metaArgs_not_contains?: string | null;
  metaArgs_not_ends_with?: string | null;
  metaArgs_not_in?: string[] | null;
  metaArgs_not_starts_with?: string | null;
  metaArgs_starts_with?: string | null;
  NOT?: FileProcessingWhereInput[] | null;
  notifyToBrowser?: boolean | null;
  notifyToBrowser_not?: boolean | null;
  notifyToEmail?: string | null;
  notifyToEmail_contains?: string | null;
  notifyToEmail_ends_with?: string | null;
  notifyToEmail_gt?: string | null;
  notifyToEmail_gte?: string | null;
  notifyToEmail_in?: string[] | null;
  notifyToEmail_lt?: string | null;
  notifyToEmail_lte?: string | null;
  notifyToEmail_not?: string | null;
  notifyToEmail_not_contains?: string | null;
  notifyToEmail_not_ends_with?: string | null;
  notifyToEmail_not_in?: string[] | null;
  notifyToEmail_not_starts_with?: string | null;
  notifyToEmail_starts_with?: string | null;
  OR?: FileProcessingWhereInput[] | null;
  progress?: number | null;
  progress_gt?: number | null;
  progress_gte?: number | null;
  progress_in?: number[] | null;
  progress_lt?: number | null;
  progress_lte?: number | null;
  progress_not?: number | null;
  progress_not_in?: number[] | null;
  result?: string | null;
  result_contains?: string | null;
  result_ends_with?: string | null;
  result_gt?: string | null;
  result_gte?: string | null;
  result_in?: string[] | null;
  result_lt?: string | null;
  result_lte?: string | null;
  result_not?: string | null;
  result_not_contains?: string | null;
  result_not_ends_with?: string | null;
  result_not_in?: string[] | null;
  result_not_starts_with?: string | null;
  result_starts_with?: string | null;
  status?: FileProcessingStatus | null;
  status_in?: FileProcessingStatus[] | null;
  status_not?: FileProcessingStatus | null;
  status_not_in?: FileProcessingStatus[] | null;
  type?: FileProcessingType | null;
  type_in?: FileProcessingType[] | null;
  type_not?: FileProcessingType | null;
  type_not_in?: FileProcessingType[] | null;
}

export interface FileProcessingWhereUniqueInput {
  id?: string | null;
}

export interface FileUpdateDataInput {
  createdBy?: UserUpdateOneRequiredInput | null;
  encoding?: string | null;
  filename?: string | null;
  filepath?: string | null;
  mimetype?: string | null;
  ownedBy?: UserUpdateOneRequiredInput | null;
  size?: number | null;
}

export interface FileUpdateManyInput {
  connect?: FileWhereUniqueInput[] | null;
  create?: any[] | null;
  delete?: FileWhereUniqueInput[] | null;
}

export interface FileUpdateOneInput {
  connect?: FileWhereUniqueInput | null;
  create?: FileCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: FileUpdateDataInput | null;
  upsert?: FileUpsertNestedInput | null;
}

export interface FileUpdateOneRequiredInput {
  connect?: FileWhereUniqueInput | null;
  create?: FileCreateInput | null;
  update?: FileUpdateDataInput | null;
  upsert?: FileUpsertNestedInput | null;
}

export interface FileUpsertNestedInput {
  create: FileCreateInput;
  update: FileUpdateDataInput;
}

export interface FileWhereInput {
  AND?: FileWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  createdBy?: UserWhereInput | null;
  encoding?: string | null;
  encoding_contains?: string | null;
  encoding_ends_with?: string | null;
  encoding_gt?: string | null;
  encoding_gte?: string | null;
  encoding_in?: string[] | null;
  encoding_lt?: string | null;
  encoding_lte?: string | null;
  encoding_not?: string | null;
  encoding_not_contains?: string | null;
  encoding_not_ends_with?: string | null;
  encoding_not_in?: string[] | null;
  encoding_not_starts_with?: string | null;
  encoding_starts_with?: string | null;
  filename?: string | null;
  filename_contains?: string | null;
  filename_ends_with?: string | null;
  filename_gt?: string | null;
  filename_gte?: string | null;
  filename_in?: string[] | null;
  filename_lt?: string | null;
  filename_lte?: string | null;
  filename_not?: string | null;
  filename_not_contains?: string | null;
  filename_not_ends_with?: string | null;
  filename_not_in?: string[] | null;
  filename_not_starts_with?: string | null;
  filename_starts_with?: string | null;
  filepath?: string | null;
  filepath_contains?: string | null;
  filepath_ends_with?: string | null;
  filepath_gt?: string | null;
  filepath_gte?: string | null;
  filepath_in?: string[] | null;
  filepath_lt?: string | null;
  filepath_lte?: string | null;
  filepath_not?: string | null;
  filepath_not_contains?: string | null;
  filepath_not_ends_with?: string | null;
  filepath_not_in?: string[] | null;
  filepath_not_starts_with?: string | null;
  filepath_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  mimetype?: string | null;
  mimetype_contains?: string | null;
  mimetype_ends_with?: string | null;
  mimetype_gt?: string | null;
  mimetype_gte?: string | null;
  mimetype_in?: string[] | null;
  mimetype_lt?: string | null;
  mimetype_lte?: string | null;
  mimetype_not?: string | null;
  mimetype_not_contains?: string | null;
  mimetype_not_ends_with?: string | null;
  mimetype_not_in?: string[] | null;
  mimetype_not_starts_with?: string | null;
  mimetype_starts_with?: string | null;
  NOT?: FileWhereInput[] | null;
  OR?: FileWhereInput[] | null;
  ownedBy?: UserWhereInput | null;
  size?: number | null;
  size_gt?: number | null;
  size_gte?: number | null;
  size_in?: number[] | null;
  size_lt?: number | null;
  size_lte?: number | null;
  size_not?: number | null;
  size_not_in?: number[] | null;
}

export interface FileWhereUniqueInput {
  id: string;
}

export interface ForeclosureRebateCreateInput {
  amount?: number | null;
  annualRebate?: AnnualRebateCreateOneWithoutForeclosureRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutForeclosureRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface ForeclosureRebateCreateManyWithoutAnnualRebateInput {
  connect?: ForeclosureRebateWhereUniqueInput[] | null;
  create?: ForeclosureRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface ForeclosureRebateCreateOneInput {
  connect?: ForeclosureRebateWhereUniqueInput | null;
  create?: ForeclosureRebateCreateInput | null;
}

export interface ForeclosureRebateCreateOneWithoutSettlementRequestInput {
  connect?: ForeclosureRebateWhereUniqueInput | null;
  create?: ForeclosureRebateCreateWithoutSettlementRequestInput | null;
}

export interface ForeclosureRebateCreateWithoutAnnualRebateInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  confirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutForeclosureRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface ForeclosureRebateCreateWithoutSettlementRequestInput {
  amount?: number | null;
  annualRebate?: AnnualRebateCreateOneWithoutForeclosureRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface ForeclosureRebateScalarWhereInput {
  amount?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  amount_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_not?: number | null;
  amount_not_in?: number[] | null;
  AND?: ForeclosureRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ForeclosureRebateScalarWhereInput[] | null;
  OR?: ForeclosureRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface ForeclosureRebateUpdateDataInput {
  amount?: number | null;
  annualRebate?: AnnualRebateUpdateOneWithoutForeclosureRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutForeclosureRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ForeclosureRebateUpdateManyDataInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ForeclosureRebateUpdateManyWithWhereNestedInput {
  data: ForeclosureRebateUpdateManyDataInput;
  where: ForeclosureRebateScalarWhereInput;
}

export interface ForeclosureRebateUpdateManyWithoutAnnualRebateInput {
  connect?: ForeclosureRebateWhereUniqueInput[] | null;
  create?: ForeclosureRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: ForeclosureRebateWhereUniqueInput[] | null;
  deleteMany?: ForeclosureRebateScalarWhereInput[] | null;
  disconnect?: ForeclosureRebateWhereUniqueInput[] | null;
  set?: ForeclosureRebateWhereUniqueInput[] | null;
  update?: ForeclosureRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: ForeclosureRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: ForeclosureRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface ForeclosureRebateUpdateOneRequiredInput {
  connect?: ForeclosureRebateWhereUniqueInput | null;
  create?: ForeclosureRebateCreateInput | null;
  update?: ForeclosureRebateUpdateDataInput | null;
  upsert?: ForeclosureRebateUpsertNestedInput | null;
}

export interface ForeclosureRebateUpdateOneWithoutSettlementRequestInput {
  connect?: ForeclosureRebateWhereUniqueInput | null;
  create?: ForeclosureRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ForeclosureRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: ForeclosureRebateUpsertWithoutSettlementRequestInput | null;
}

export interface ForeclosureRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: ForeclosureRebateUpdateWithoutAnnualRebateDataInput;
  where: ForeclosureRebateWhereUniqueInput;
}

export interface ForeclosureRebateUpdateWithoutAnnualRebateDataInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  confirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutForeclosureRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ForeclosureRebateUpdateWithoutSettlementRequestDataInput {
  amount?: number | null;
  annualRebate?: AnnualRebateUpdateOneWithoutForeclosureRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ForeclosureRebateUpsertNestedInput {
  create: ForeclosureRebateCreateInput;
  update: ForeclosureRebateUpdateDataInput;
}

export interface ForeclosureRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: ForeclosureRebateCreateWithoutAnnualRebateInput;
  update: ForeclosureRebateUpdateWithoutAnnualRebateDataInput;
  where: ForeclosureRebateWhereUniqueInput;
}

export interface ForeclosureRebateUpsertWithoutSettlementRequestInput {
  create: ForeclosureRebateCreateWithoutSettlementRequestInput;
  update: ForeclosureRebateUpdateWithoutSettlementRequestDataInput;
}

export interface ForeclosureRebateWhereInput {
  amount?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  amount_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_not?: number | null;
  amount_not_in?: number[] | null;
  AND?: ForeclosureRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationFiles_every?: FileWhereInput | null;
  confirmationFiles_none?: FileWhereInput | null;
  confirmationFiles_some?: FileWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ForeclosureRebateWhereInput[] | null;
  OR?: ForeclosureRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface ForeclosureRebateWhereUniqueInput {
  id?: string | null;
}

export interface GiftCreateManyWithoutGiftRebateInput {
  connect?: GiftWhereUniqueInput[] | null;
  create?: GiftCreateWithoutGiftRebateInput[] | null;
}

export interface GiftCreateWithoutGiftRebateInput {
  amount?: number | null;
  description?: string | null;
  id?: string | null;
}

export interface GiftRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutGiftRebatesInput | null;
  commentOfDenied?: string | null;
  giftConfirmationFiles?: FileCreateManyInput | null;
  gifts?: GiftCreateManyWithoutGiftRebateInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutGiftRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface GiftRebateCreateManyWithoutAnnualRebateInput {
  connect?: GiftRebateWhereUniqueInput[] | null;
  create?: GiftRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface GiftRebateCreateOneInput {
  connect?: GiftRebateWhereUniqueInput | null;
  create?: GiftRebateCreateInput | null;
}

export interface GiftRebateCreateOneWithoutSettlementRequestInput {
  connect?: GiftRebateWhereUniqueInput | null;
  create?: GiftRebateCreateWithoutSettlementRequestInput | null;
}

export interface GiftRebateCreateWithoutAnnualRebateInput {
  commentOfDenied?: string | null;
  giftConfirmationFiles?: FileCreateManyInput | null;
  gifts?: GiftCreateManyWithoutGiftRebateInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutGiftRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface GiftRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutGiftRebatesInput | null;
  commentOfDenied?: string | null;
  giftConfirmationFiles?: FileCreateManyInput | null;
  gifts?: GiftCreateManyWithoutGiftRebateInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface GiftRebateScalarWhereInput {
  AND?: GiftRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: GiftRebateScalarWhereInput[] | null;
  OR?: GiftRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface GiftRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutGiftRebatesInput | null;
  commentOfDenied?: string | null;
  giftConfirmationFiles?: FileUpdateManyInput | null;
  gifts?: GiftUpdateManyWithoutGiftRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutGiftRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface GiftRebateUpdateManyDataInput {
  commentOfDenied?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface GiftRebateUpdateManyWithWhereNestedInput {
  data: GiftRebateUpdateManyDataInput;
  where: GiftRebateScalarWhereInput;
}

export interface GiftRebateUpdateManyWithoutAnnualRebateInput {
  connect?: GiftRebateWhereUniqueInput[] | null;
  create?: GiftRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: GiftRebateWhereUniqueInput[] | null;
  deleteMany?: GiftRebateScalarWhereInput[] | null;
  disconnect?: GiftRebateWhereUniqueInput[] | null;
  set?: GiftRebateWhereUniqueInput[] | null;
  update?: GiftRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: GiftRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: GiftRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface GiftRebateUpdateOneRequiredInput {
  connect?: GiftRebateWhereUniqueInput | null;
  create?: GiftRebateCreateInput | null;
  update?: GiftRebateUpdateDataInput | null;
  upsert?: GiftRebateUpsertNestedInput | null;
}

export interface GiftRebateUpdateOneWithoutSettlementRequestInput {
  connect?: GiftRebateWhereUniqueInput | null;
  create?: GiftRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: GiftRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: GiftRebateUpsertWithoutSettlementRequestInput | null;
}

export interface GiftRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: GiftRebateUpdateWithoutAnnualRebateDataInput;
  where: GiftRebateWhereUniqueInput;
}

export interface GiftRebateUpdateWithoutAnnualRebateDataInput {
  commentOfDenied?: string | null;
  giftConfirmationFiles?: FileUpdateManyInput | null;
  gifts?: GiftUpdateManyWithoutGiftRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutGiftRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface GiftRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutGiftRebatesInput | null;
  commentOfDenied?: string | null;
  giftConfirmationFiles?: FileUpdateManyInput | null;
  gifts?: GiftUpdateManyWithoutGiftRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface GiftRebateUpsertNestedInput {
  create: GiftRebateCreateInput;
  update: GiftRebateUpdateDataInput;
}

export interface GiftRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: GiftRebateCreateWithoutAnnualRebateInput;
  update: GiftRebateUpdateWithoutAnnualRebateDataInput;
  where: GiftRebateWhereUniqueInput;
}

export interface GiftRebateUpsertWithoutSettlementRequestInput {
  create: GiftRebateCreateWithoutSettlementRequestInput;
  update: GiftRebateUpdateWithoutSettlementRequestDataInput;
}

export interface GiftRebateWhereInput {
  AND?: GiftRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  giftConfirmationFiles_every?: FileWhereInput | null;
  giftConfirmationFiles_none?: FileWhereInput | null;
  giftConfirmationFiles_some?: FileWhereInput | null;
  gifts_every?: GiftWhereInput | null;
  gifts_none?: GiftWhereInput | null;
  gifts_some?: GiftWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: GiftRebateWhereInput[] | null;
  OR?: GiftRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface GiftRebateWhereUniqueInput {
  id?: string | null;
}

export interface GiftUpdateManyWithoutGiftRebateInput {
  create?: GiftCreateWithoutGiftRebateInput[] | null;
  delete?: GiftWhereUniqueInput[] | null;
  update?: GiftUpdateWithWhereUniqueWithoutGiftRebateInput[] | null;
}

export interface GiftUpdateWithWhereUniqueWithoutGiftRebateInput {
  data: GiftUpdateWithoutGiftRebateDataInput;
  where: GiftWhereUniqueInput;
}

export interface GiftUpdateWithoutGiftRebateDataInput {
  amount?: number | null;
  description?: string | null;
}

export interface GiftWhereInput {
  amount?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  amount_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_not?: number | null;
  amount_not_in?: number[] | null;
  AND?: GiftWhereInput[] | null;
  description?: string | null;
  description_contains?: string | null;
  description_ends_with?: string | null;
  description_gt?: string | null;
  description_gte?: string | null;
  description_in?: string[] | null;
  description_lt?: string | null;
  description_lte?: string | null;
  description_not?: string | null;
  description_not_contains?: string | null;
  description_not_ends_with?: string | null;
  description_not_in?: string[] | null;
  description_not_starts_with?: string | null;
  description_starts_with?: string | null;
  giftRebate?: GiftRebateWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: GiftWhereInput[] | null;
  OR?: GiftWhereInput[] | null;
}

export interface GiftWhereUniqueInput {
  id: string;
}

export interface ImportLogCreateOneWithoutImportedByInput {
  connect?: ImportLogWhereUniqueInput | null;
  create?: ImportLogCreateWithoutImportedByInput | null;
}

export interface ImportLogCreateWithoutImportedByInput {
  customer: CustomerCreateOneInput;
  file: FileCreateOneInput;
  finishedAt?: any | null;
  id?: string | null;
  type: ImportType;
}

export interface ImportLogCustomerWhereInput {
  customerId?: string | null;
}

export interface ImportLogUpdateOneWithoutImportedByInput {
  connect?: ImportLogWhereUniqueInput | null;
  create?: ImportLogCreateWithoutImportedByInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ImportLogUpdateWithoutImportedByDataInput | null;
  upsert?: ImportLogUpsertWithoutImportedByInput | null;
}

export interface ImportLogUpdateWithoutImportedByDataInput {
  customer?: CustomerUpdateOneRequiredInput | null;
  file?: FileUpdateOneRequiredInput | null;
  finishedAt?: any | null;
  type?: ImportType | null;
}

export interface ImportLogUpsertWithoutImportedByInput {
  create: ImportLogCreateWithoutImportedByInput;
  update: ImportLogUpdateWithoutImportedByDataInput;
}

export interface ImportLogWhereInput {
  AND?: ImportLogWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  customer?: CustomerWhereInput | null;
  file?: FileWhereInput | null;
  finishedAt?: any | null;
  finishedAt_gt?: any | null;
  finishedAt_gte?: any | null;
  finishedAt_in?: any[] | null;
  finishedAt_lt?: any | null;
  finishedAt_lte?: any | null;
  finishedAt_not?: any | null;
  finishedAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  importedBy?: UserWhereInput | null;
  NOT?: ImportLogWhereInput[] | null;
  OR?: ImportLogWhereInput[] | null;
  type?: ImportType | null;
  type_in?: ImportType[] | null;
  type_not?: ImportType | null;
  type_not_in?: ImportType[] | null;
}

export interface ImportLogWhereUniqueInput {
  id?: string | null;
}

export interface InvestmentRebateCreateInput {
  amount?: number | null;
  annualRebate?: AnnualRebateCreateOneWithoutInvestmentRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileCreateManyInput | null;
  confirmationPaymentFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutInvestmentRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface InvestmentRebateCreateManyWithoutAnnualRebateInput {
  connect?: InvestmentRebateWhereUniqueInput[] | null;
  create?: InvestmentRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface InvestmentRebateCreateOneInput {
  connect?: InvestmentRebateWhereUniqueInput | null;
  create?: InvestmentRebateCreateInput | null;
}

export interface InvestmentRebateCreateOneWithoutSettlementRequestInput {
  connect?: InvestmentRebateWhereUniqueInput | null;
  create?: InvestmentRebateCreateWithoutSettlementRequestInput | null;
}

export interface InvestmentRebateCreateWithoutAnnualRebateInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileCreateManyInput | null;
  confirmationPaymentFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutInvestmentRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface InvestmentRebateCreateWithoutSettlementRequestInput {
  amount?: number | null;
  annualRebate?: AnnualRebateCreateOneWithoutInvestmentRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileCreateManyInput | null;
  confirmationPaymentFiles?: FileCreateManyInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface InvestmentRebateScalarWhereInput {
  amount?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  amount_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_not?: number | null;
  amount_not_in?: number[] | null;
  AND?: InvestmentRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: InvestmentRebateScalarWhereInput[] | null;
  OR?: InvestmentRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface InvestmentRebateUpdateDataInput {
  amount?: number | null;
  annualRebate?: AnnualRebateUpdateOneWithoutInvestmentRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutInvestmentRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface InvestmentRebateUpdateManyDataInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface InvestmentRebateUpdateManyWithWhereNestedInput {
  data: InvestmentRebateUpdateManyDataInput;
  where: InvestmentRebateScalarWhereInput;
}

export interface InvestmentRebateUpdateManyWithoutAnnualRebateInput {
  connect?: InvestmentRebateWhereUniqueInput[] | null;
  create?: InvestmentRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: InvestmentRebateWhereUniqueInput[] | null;
  deleteMany?: InvestmentRebateScalarWhereInput[] | null;
  disconnect?: InvestmentRebateWhereUniqueInput[] | null;
  set?: InvestmentRebateWhereUniqueInput[] | null;
  update?: InvestmentRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: InvestmentRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: InvestmentRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface InvestmentRebateUpdateOneInput {
  connect?: InvestmentRebateWhereUniqueInput | null;
  create?: InvestmentRebateCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: InvestmentRebateUpdateDataInput | null;
  upsert?: InvestmentRebateUpsertNestedInput | null;
}

export interface InvestmentRebateUpdateOneWithoutSettlementRequestInput {
  connect?: InvestmentRebateWhereUniqueInput | null;
  create?: InvestmentRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: InvestmentRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: InvestmentRebateUpsertWithoutSettlementRequestInput | null;
}

export interface InvestmentRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: InvestmentRebateUpdateWithoutAnnualRebateDataInput;
  where: InvestmentRebateWhereUniqueInput;
}

export interface InvestmentRebateUpdateWithoutAnnualRebateDataInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutInvestmentRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface InvestmentRebateUpdateWithoutSettlementRequestDataInput {
  amount?: number | null;
  annualRebate?: AnnualRebateUpdateOneWithoutInvestmentRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface InvestmentRebateUpsertNestedInput {
  create: InvestmentRebateCreateInput;
  update: InvestmentRebateUpdateDataInput;
}

export interface InvestmentRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: InvestmentRebateCreateWithoutAnnualRebateInput;
  update: InvestmentRebateUpdateWithoutAnnualRebateDataInput;
  where: InvestmentRebateWhereUniqueInput;
}

export interface InvestmentRebateUpsertWithoutSettlementRequestInput {
  create: InvestmentRebateCreateWithoutSettlementRequestInput;
  update: InvestmentRebateUpdateWithoutSettlementRequestDataInput;
}

export interface InvestmentRebateWhereInput {
  amount?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  amount_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_not?: number | null;
  amount_not_in?: number[] | null;
  AND?: InvestmentRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationAgreementFiles_every?: FileWhereInput | null;
  confirmationAgreementFiles_none?: FileWhereInput | null;
  confirmationAgreementFiles_some?: FileWhereInput | null;
  confirmationPaymentFiles_every?: FileWhereInput | null;
  confirmationPaymentFiles_none?: FileWhereInput | null;
  confirmationPaymentFiles_some?: FileWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: InvestmentRebateWhereInput[] | null;
  OR?: InvestmentRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface InvestmentRebateWhereUniqueInput {
  id?: string | null;
}

export interface KeeperChangeInput {
  email: string;
  firstname: string;
  lastname: string;
}

export interface LifeInsuranceRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutLifeInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileCreateManyInput | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutLifeInsuranceRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LifeInsuranceRebateCreateManyWithoutAnnualRebateInput {
  connect?: LifeInsuranceRebateWhereUniqueInput[] | null;
  create?: LifeInsuranceRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface LifeInsuranceRebateCreateOneInput {
  connect?: LifeInsuranceRebateWhereUniqueInput | null;
  create?: LifeInsuranceRebateCreateInput | null;
}

export interface LifeInsuranceRebateCreateOneWithoutSettlementRequestInput {
  connect?: LifeInsuranceRebateWhereUniqueInput | null;
  create?: LifeInsuranceRebateCreateWithoutSettlementRequestInput | null;
}

export interface LifeInsuranceRebateCreateWithoutAnnualRebateInput {
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileCreateManyInput | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutLifeInsuranceRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LifeInsuranceRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutLifeInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileCreateManyInput | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LifeInsuranceRebateScalarWhereInput {
  AND?: LifeInsuranceRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: LifeInsuranceRebateScalarWhereInput[] | null;
  OR?: LifeInsuranceRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface LifeInsuranceRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutLifeInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutLifeInsuranceRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LifeInsuranceRebateUpdateManyDataInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LifeInsuranceRebateUpdateManyWithWhereNestedInput {
  data: LifeInsuranceRebateUpdateManyDataInput;
  where: LifeInsuranceRebateScalarWhereInput;
}

export interface LifeInsuranceRebateUpdateManyWithoutAnnualRebateInput {
  connect?: LifeInsuranceRebateWhereUniqueInput[] | null;
  create?: LifeInsuranceRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: LifeInsuranceRebateWhereUniqueInput[] | null;
  deleteMany?: LifeInsuranceRebateScalarWhereInput[] | null;
  disconnect?: LifeInsuranceRebateWhereUniqueInput[] | null;
  set?: LifeInsuranceRebateWhereUniqueInput[] | null;
  update?: LifeInsuranceRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: LifeInsuranceRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: LifeInsuranceRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface LifeInsuranceRebateUpdateOneRequiredInput {
  connect?: LifeInsuranceRebateWhereUniqueInput | null;
  create?: LifeInsuranceRebateCreateInput | null;
  update?: LifeInsuranceRebateUpdateDataInput | null;
  upsert?: LifeInsuranceRebateUpsertNestedInput | null;
}

export interface LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput {
  connect?: LifeInsuranceRebateWhereUniqueInput | null;
  create?: LifeInsuranceRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: LifeInsuranceRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: LifeInsuranceRebateUpsertWithoutSettlementRequestInput | null;
}

export interface LifeInsuranceRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: LifeInsuranceRebateUpdateWithoutAnnualRebateDataInput;
  where: LifeInsuranceRebateWhereUniqueInput;
}

export interface LifeInsuranceRebateUpdateWithoutAnnualRebateDataInput {
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutLifeInsuranceRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LifeInsuranceRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutLifeInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LifeInsuranceRebateUpsertNestedInput {
  create: LifeInsuranceRebateCreateInput;
  update: LifeInsuranceRebateUpdateDataInput;
}

export interface LifeInsuranceRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: LifeInsuranceRebateCreateWithoutAnnualRebateInput;
  update: LifeInsuranceRebateUpdateWithoutAnnualRebateDataInput;
  where: LifeInsuranceRebateWhereUniqueInput;
}

export interface LifeInsuranceRebateUpsertWithoutSettlementRequestInput {
  create: LifeInsuranceRebateCreateWithoutSettlementRequestInput;
  update: LifeInsuranceRebateUpdateWithoutSettlementRequestDataInput;
}

export interface LifeInsuranceRebateWhereInput {
  AND?: LifeInsuranceRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  contractConfirmationFiles_every?: FileWhereInput | null;
  contractConfirmationFiles_none?: FileWhereInput | null;
  contractConfirmationFiles_some?: FileWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  feeConfirmationFiles_every?: FileWhereInput | null;
  feeConfirmationFiles_none?: FileWhereInput | null;
  feeConfirmationFiles_some?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: LifeInsuranceRebateWhereInput[] | null;
  OR?: LifeInsuranceRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface LifeInsuranceRebateWhereUniqueInput {
  id?: string | null;
}

export interface LoanRebateCreateInput {
  addresses?: LoanRebateCreateaddressesInput | null;
  annualRebate?: AnnualRebateCreateOneWithoutLoanRebatesInput | null;
  applyInMonths?: LoanRebateCreateapplyInMonthsInput | null;
  commentOfDenied?: string | null;
  id?: string | null;
  interestAmount?: number | null;
  interestAmountConfirmationFiles?: FileCreateManyInput | null;
  loanContractFiles?: FileCreateManyInput | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  propertyConfirmationFiles?: FileCreateManyInput | null;
  purchaseDate?: any | null;
  settlementRequest?: SettlementRequestCreateOneWithoutLoanRebateInput | null;
  startConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LoanRebateCreateManyWithoutAnnualRebateInput {
  connect?: LoanRebateWhereUniqueInput[] | null;
  create?: LoanRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface LoanRebateCreateOneInput {
  connect?: LoanRebateWhereUniqueInput | null;
  create?: LoanRebateCreateInput | null;
}

export interface LoanRebateCreateOneWithoutSettlementRequestInput {
  connect?: LoanRebateWhereUniqueInput | null;
  create?: LoanRebateCreateWithoutSettlementRequestInput | null;
}

export interface LoanRebateCreateWithoutAnnualRebateInput {
  addresses?: LoanRebateCreateaddressesInput | null;
  applyInMonths?: LoanRebateCreateapplyInMonthsInput | null;
  commentOfDenied?: string | null;
  id?: string | null;
  interestAmount?: number | null;
  interestAmountConfirmationFiles?: FileCreateManyInput | null;
  loanContractFiles?: FileCreateManyInput | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  propertyConfirmationFiles?: FileCreateManyInput | null;
  purchaseDate?: any | null;
  settlementRequest?: SettlementRequestCreateOneWithoutLoanRebateInput | null;
  startConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LoanRebateCreateWithoutSettlementRequestInput {
  addresses?: LoanRebateCreateaddressesInput | null;
  annualRebate?: AnnualRebateCreateOneWithoutLoanRebatesInput | null;
  applyInMonths?: LoanRebateCreateapplyInMonthsInput | null;
  commentOfDenied?: string | null;
  id?: string | null;
  interestAmount?: number | null;
  interestAmountConfirmationFiles?: FileCreateManyInput | null;
  loanContractFiles?: FileCreateManyInput | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  propertyConfirmationFiles?: FileCreateManyInput | null;
  purchaseDate?: any | null;
  startConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LoanRebateCreateaddressesInput {
  set?: string[] | null;
}

export interface LoanRebateCreateapplyInMonthsInput {
  set?: Month[] | null;
}

export interface LoanRebateScalarWhereInput {
  AND?: LoanRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  interestAmount?: number | null;
  interestAmount_gt?: number | null;
  interestAmount_gte?: number | null;
  interestAmount_in?: number[] | null;
  interestAmount_lt?: number | null;
  interestAmount_lte?: number | null;
  interestAmount_not?: number | null;
  interestAmount_not_in?: number[] | null;
  NOT?: LoanRebateScalarWhereInput[] | null;
  OR?: LoanRebateScalarWhereInput[] | null;
  otherAddress?: string | null;
  otherAddress_contains?: string | null;
  otherAddress_ends_with?: string | null;
  otherAddress_gt?: string | null;
  otherAddress_gte?: string | null;
  otherAddress_in?: string[] | null;
  otherAddress_lt?: string | null;
  otherAddress_lte?: string | null;
  otherAddress_not?: string | null;
  otherAddress_not_contains?: string | null;
  otherAddress_not_ends_with?: string | null;
  otherAddress_not_in?: string[] | null;
  otherAddress_not_starts_with?: string | null;
  otherAddress_starts_with?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonApplying_not?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonFullname_contains?: string | null;
  otherPersonFullname_ends_with?: string | null;
  otherPersonFullname_gt?: string | null;
  otherPersonFullname_gte?: string | null;
  otherPersonFullname_in?: string[] | null;
  otherPersonFullname_lt?: string | null;
  otherPersonFullname_lte?: string | null;
  otherPersonFullname_not?: string | null;
  otherPersonFullname_not_contains?: string | null;
  otherPersonFullname_not_ends_with?: string | null;
  otherPersonFullname_not_in?: string[] | null;
  otherPersonFullname_not_starts_with?: string | null;
  otherPersonFullname_starts_with?: string | null;
  otherPersonNationalIDNumber?: string | null;
  otherPersonNationalIDNumber_contains?: string | null;
  otherPersonNationalIDNumber_ends_with?: string | null;
  otherPersonNationalIDNumber_gt?: string | null;
  otherPersonNationalIDNumber_gte?: string | null;
  otherPersonNationalIDNumber_in?: string[] | null;
  otherPersonNationalIDNumber_lt?: string | null;
  otherPersonNationalIDNumber_lte?: string | null;
  otherPersonNationalIDNumber_not?: string | null;
  otherPersonNationalIDNumber_not_contains?: string | null;
  otherPersonNationalIDNumber_not_ends_with?: string | null;
  otherPersonNationalIDNumber_not_in?: string[] | null;
  otherPersonNationalIDNumber_not_starts_with?: string | null;
  otherPersonNationalIDNumber_starts_with?: string | null;
  purchaseDate?: any | null;
  purchaseDate_gt?: any | null;
  purchaseDate_gte?: any | null;
  purchaseDate_in?: any[] | null;
  purchaseDate_lt?: any | null;
  purchaseDate_lte?: any | null;
  purchaseDate_not?: any | null;
  purchaseDate_not_in?: any[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface LoanRebateUpdateDataInput {
  addresses?: LoanRebateUpdateaddressesInput | null;
  annualRebate?: AnnualRebateUpdateOneWithoutLoanRebatesInput | null;
  applyInMonths?: LoanRebateUpdateapplyInMonthsInput | null;
  commentOfDenied?: string | null;
  interestAmount?: number | null;
  interestAmountConfirmationFiles?: FileUpdateManyInput | null;
  loanContractFiles?: FileUpdateManyInput | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  propertyConfirmationFiles?: FileUpdateManyInput | null;
  purchaseDate?: any | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutLoanRebateInput | null;
  startConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LoanRebateUpdateManyDataInput {
  addresses?: LoanRebateUpdateaddressesInput | null;
  applyInMonths?: LoanRebateUpdateapplyInMonthsInput | null;
  commentOfDenied?: string | null;
  interestAmount?: number | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  purchaseDate?: any | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LoanRebateUpdateManyWithWhereNestedInput {
  data: LoanRebateUpdateManyDataInput;
  where: LoanRebateScalarWhereInput;
}

export interface LoanRebateUpdateManyWithoutAnnualRebateInput {
  connect?: LoanRebateWhereUniqueInput[] | null;
  create?: LoanRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: LoanRebateWhereUniqueInput[] | null;
  deleteMany?: LoanRebateScalarWhereInput[] | null;
  disconnect?: LoanRebateWhereUniqueInput[] | null;
  set?: LoanRebateWhereUniqueInput[] | null;
  update?: LoanRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: LoanRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: LoanRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface LoanRebateUpdateOneRequiredInput {
  connect?: LoanRebateWhereUniqueInput | null;
  create?: LoanRebateCreateInput | null;
  update?: LoanRebateUpdateDataInput | null;
  upsert?: LoanRebateUpsertNestedInput | null;
}

export interface LoanRebateUpdateOneWithoutSettlementRequestInput {
  connect?: LoanRebateWhereUniqueInput | null;
  create?: LoanRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: LoanRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: LoanRebateUpsertWithoutSettlementRequestInput | null;
}

export interface LoanRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: LoanRebateUpdateWithoutAnnualRebateDataInput;
  where: LoanRebateWhereUniqueInput;
}

export interface LoanRebateUpdateWithoutAnnualRebateDataInput {
  addresses?: LoanRebateUpdateaddressesInput | null;
  applyInMonths?: LoanRebateUpdateapplyInMonthsInput | null;
  commentOfDenied?: string | null;
  interestAmount?: number | null;
  interestAmountConfirmationFiles?: FileUpdateManyInput | null;
  loanContractFiles?: FileUpdateManyInput | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  propertyConfirmationFiles?: FileUpdateManyInput | null;
  purchaseDate?: any | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutLoanRebateInput | null;
  startConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LoanRebateUpdateWithoutSettlementRequestDataInput {
  addresses?: LoanRebateUpdateaddressesInput | null;
  annualRebate?: AnnualRebateUpdateOneWithoutLoanRebatesInput | null;
  applyInMonths?: LoanRebateUpdateapplyInMonthsInput | null;
  commentOfDenied?: string | null;
  interestAmount?: number | null;
  interestAmountConfirmationFiles?: FileUpdateManyInput | null;
  loanContractFiles?: FileUpdateManyInput | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  propertyConfirmationFiles?: FileUpdateManyInput | null;
  purchaseDate?: any | null;
  startConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LoanRebateUpdateaddressesInput {
  set?: string[] | null;
}

export interface LoanRebateUpdateapplyInMonthsInput {
  set?: Month[] | null;
}

export interface LoanRebateUpsertNestedInput {
  create: LoanRebateCreateInput;
  update: LoanRebateUpdateDataInput;
}

export interface LoanRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: LoanRebateCreateWithoutAnnualRebateInput;
  update: LoanRebateUpdateWithoutAnnualRebateDataInput;
  where: LoanRebateWhereUniqueInput;
}

export interface LoanRebateUpsertWithoutSettlementRequestInput {
  create: LoanRebateCreateWithoutSettlementRequestInput;
  update: LoanRebateUpdateWithoutSettlementRequestDataInput;
}

export interface LoanRebateWhereInput {
  AND?: LoanRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  interestAmount?: number | null;
  interestAmount_gt?: number | null;
  interestAmount_gte?: number | null;
  interestAmount_in?: number[] | null;
  interestAmount_lt?: number | null;
  interestAmount_lte?: number | null;
  interestAmount_not?: number | null;
  interestAmount_not_in?: number[] | null;
  interestAmountConfirmationFiles_every?: FileWhereInput | null;
  interestAmountConfirmationFiles_none?: FileWhereInput | null;
  interestAmountConfirmationFiles_some?: FileWhereInput | null;
  loanContractFiles_every?: FileWhereInput | null;
  loanContractFiles_none?: FileWhereInput | null;
  loanContractFiles_some?: FileWhereInput | null;
  NOT?: LoanRebateWhereInput[] | null;
  OR?: LoanRebateWhereInput[] | null;
  otherAddress?: string | null;
  otherAddress_contains?: string | null;
  otherAddress_ends_with?: string | null;
  otherAddress_gt?: string | null;
  otherAddress_gte?: string | null;
  otherAddress_in?: string[] | null;
  otherAddress_lt?: string | null;
  otherAddress_lte?: string | null;
  otherAddress_not?: string | null;
  otherAddress_not_contains?: string | null;
  otherAddress_not_ends_with?: string | null;
  otherAddress_not_in?: string[] | null;
  otherAddress_not_starts_with?: string | null;
  otherAddress_starts_with?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonApplying_not?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonFullname_contains?: string | null;
  otherPersonFullname_ends_with?: string | null;
  otherPersonFullname_gt?: string | null;
  otherPersonFullname_gte?: string | null;
  otherPersonFullname_in?: string[] | null;
  otherPersonFullname_lt?: string | null;
  otherPersonFullname_lte?: string | null;
  otherPersonFullname_not?: string | null;
  otherPersonFullname_not_contains?: string | null;
  otherPersonFullname_not_ends_with?: string | null;
  otherPersonFullname_not_in?: string[] | null;
  otherPersonFullname_not_starts_with?: string | null;
  otherPersonFullname_starts_with?: string | null;
  otherPersonNationalIDNumber?: string | null;
  otherPersonNationalIDNumber_contains?: string | null;
  otherPersonNationalIDNumber_ends_with?: string | null;
  otherPersonNationalIDNumber_gt?: string | null;
  otherPersonNationalIDNumber_gte?: string | null;
  otherPersonNationalIDNumber_in?: string[] | null;
  otherPersonNationalIDNumber_lt?: string | null;
  otherPersonNationalIDNumber_lte?: string | null;
  otherPersonNationalIDNumber_not?: string | null;
  otherPersonNationalIDNumber_not_contains?: string | null;
  otherPersonNationalIDNumber_not_ends_with?: string | null;
  otherPersonNationalIDNumber_not_in?: string[] | null;
  otherPersonNationalIDNumber_not_starts_with?: string | null;
  otherPersonNationalIDNumber_starts_with?: string | null;
  propertyConfirmationFiles_every?: FileWhereInput | null;
  propertyConfirmationFiles_none?: FileWhereInput | null;
  propertyConfirmationFiles_some?: FileWhereInput | null;
  purchaseDate?: any | null;
  purchaseDate_gt?: any | null;
  purchaseDate_gte?: any | null;
  purchaseDate_in?: any[] | null;
  purchaseDate_lt?: any | null;
  purchaseDate_lte?: any | null;
  purchaseDate_not?: any | null;
  purchaseDate_not_in?: any[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  startConfirmationFiles_every?: FileWhereInput | null;
  startConfirmationFiles_none?: FileWhereInput | null;
  startConfirmationFiles_some?: FileWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface LoanRebateWhereUniqueInput {
  id?: string | null;
}

export interface LoginRequestCreateManyWithoutAccountInput {
  connect?: LoginRequestWhereUniqueInput[] | null;
  create?: LoginRequestCreateWithoutAccountInput[] | null;
}

export interface LoginRequestCreateWithoutAccountInput {
  failed: boolean;
  id?: string | null;
}

export interface LoginRequestScalarWhereInput {
  AND?: LoginRequestScalarWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  failed?: boolean | null;
  failed_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: LoginRequestScalarWhereInput[] | null;
  OR?: LoginRequestScalarWhereInput[] | null;
}

export interface LoginRequestUpdateManyDataInput {
  failed?: boolean | null;
}

export interface LoginRequestUpdateManyWithWhereNestedInput {
  data: LoginRequestUpdateManyDataInput;
  where: LoginRequestScalarWhereInput;
}

export interface LoginRequestUpdateManyWithoutAccountInput {
  connect?: LoginRequestWhereUniqueInput[] | null;
  create?: LoginRequestCreateWithoutAccountInput[] | null;
  delete?: LoginRequestWhereUniqueInput[] | null;
  deleteMany?: LoginRequestScalarWhereInput[] | null;
  disconnect?: LoginRequestWhereUniqueInput[] | null;
  set?: LoginRequestWhereUniqueInput[] | null;
  update?: LoginRequestUpdateWithWhereUniqueWithoutAccountInput[] | null;
  updateMany?: LoginRequestUpdateManyWithWhereNestedInput[] | null;
  upsert?: LoginRequestUpsertWithWhereUniqueWithoutAccountInput[] | null;
}

export interface LoginRequestUpdateWithWhereUniqueWithoutAccountInput {
  data: LoginRequestUpdateWithoutAccountDataInput;
  where: LoginRequestWhereUniqueInput;
}

export interface LoginRequestUpdateWithoutAccountDataInput {
  failed?: boolean | null;
}

export interface LoginRequestUpsertWithWhereUniqueWithoutAccountInput {
  create: LoginRequestCreateWithoutAccountInput;
  update: LoginRequestUpdateWithoutAccountDataInput;
  where: LoginRequestWhereUniqueInput;
}

export interface LoginRequestWhereInput {
  account?: AccountWhereInput | null;
  AND?: LoginRequestWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  failed?: boolean | null;
  failed_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: LoginRequestWhereInput[] | null;
  OR?: LoginRequestWhereInput[] | null;
}

export interface LoginRequestWhereUniqueInput {
  id?: string | null;
}

export interface LongTermCareRebateCreateInput {
  amount?: number | null;
  annualRebate?: AnnualRebateCreateOneWithoutLongTermCareRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileCreateManyInput | null;
  confirmationPaymentFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutLongTermCareRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LongTermCareRebateCreateManyWithoutAnnualRebateInput {
  connect?: LongTermCareRebateWhereUniqueInput[] | null;
  create?: LongTermCareRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface LongTermCareRebateCreateOneInput {
  connect?: LongTermCareRebateWhereUniqueInput | null;
  create?: LongTermCareRebateCreateInput | null;
}

export interface LongTermCareRebateCreateOneWithoutSettlementRequestInput {
  connect?: LongTermCareRebateWhereUniqueInput | null;
  create?: LongTermCareRebateCreateWithoutSettlementRequestInput | null;
}

export interface LongTermCareRebateCreateWithoutAnnualRebateInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileCreateManyInput | null;
  confirmationPaymentFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutLongTermCareRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LongTermCareRebateCreateWithoutSettlementRequestInput {
  amount?: number | null;
  annualRebate?: AnnualRebateCreateOneWithoutLongTermCareRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileCreateManyInput | null;
  confirmationPaymentFiles?: FileCreateManyInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface LongTermCareRebateScalarWhereInput {
  amount?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  amount_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_not?: number | null;
  amount_not_in?: number[] | null;
  AND?: LongTermCareRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: LongTermCareRebateScalarWhereInput[] | null;
  OR?: LongTermCareRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface LongTermCareRebateUpdateDataInput {
  amount?: number | null;
  annualRebate?: AnnualRebateUpdateOneWithoutLongTermCareRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutLongTermCareRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LongTermCareRebateUpdateManyDataInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LongTermCareRebateUpdateManyWithWhereNestedInput {
  data: LongTermCareRebateUpdateManyDataInput;
  where: LongTermCareRebateScalarWhereInput;
}

export interface LongTermCareRebateUpdateManyWithoutAnnualRebateInput {
  connect?: LongTermCareRebateWhereUniqueInput[] | null;
  create?: LongTermCareRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: LongTermCareRebateWhereUniqueInput[] | null;
  deleteMany?: LongTermCareRebateScalarWhereInput[] | null;
  disconnect?: LongTermCareRebateWhereUniqueInput[] | null;
  set?: LongTermCareRebateWhereUniqueInput[] | null;
  update?: LongTermCareRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: LongTermCareRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: LongTermCareRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface LongTermCareRebateUpdateOneInput {
  connect?: LongTermCareRebateWhereUniqueInput | null;
  create?: LongTermCareRebateCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: LongTermCareRebateUpdateDataInput | null;
  upsert?: LongTermCareRebateUpsertNestedInput | null;
}

export interface LongTermCareRebateUpdateOneWithoutSettlementRequestInput {
  connect?: LongTermCareRebateWhereUniqueInput | null;
  create?: LongTermCareRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: LongTermCareRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: LongTermCareRebateUpsertWithoutSettlementRequestInput | null;
}

export interface LongTermCareRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: LongTermCareRebateUpdateWithoutAnnualRebateDataInput;
  where: LongTermCareRebateWhereUniqueInput;
}

export interface LongTermCareRebateUpdateWithoutAnnualRebateDataInput {
  amount?: number | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutLongTermCareRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LongTermCareRebateUpdateWithoutSettlementRequestDataInput {
  amount?: number | null;
  annualRebate?: AnnualRebateUpdateOneWithoutLongTermCareRebatesInput | null;
  commentOfDenied?: string | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface LongTermCareRebateUpsertNestedInput {
  create: LongTermCareRebateCreateInput;
  update: LongTermCareRebateUpdateDataInput;
}

export interface LongTermCareRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: LongTermCareRebateCreateWithoutAnnualRebateInput;
  update: LongTermCareRebateUpdateWithoutAnnualRebateDataInput;
  where: LongTermCareRebateWhereUniqueInput;
}

export interface LongTermCareRebateUpsertWithoutSettlementRequestInput {
  create: LongTermCareRebateCreateWithoutSettlementRequestInput;
  update: LongTermCareRebateUpdateWithoutSettlementRequestDataInput;
}

export interface LongTermCareRebateWhereInput {
  amount?: number | null;
  amount_gt?: number | null;
  amount_gte?: number | null;
  amount_in?: number[] | null;
  amount_lt?: number | null;
  amount_lte?: number | null;
  amount_not?: number | null;
  amount_not_in?: number[] | null;
  AND?: LongTermCareRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationAgreementFiles_every?: FileWhereInput | null;
  confirmationAgreementFiles_none?: FileWhereInput | null;
  confirmationAgreementFiles_some?: FileWhereInput | null;
  confirmationPaymentFiles_every?: FileWhereInput | null;
  confirmationPaymentFiles_none?: FileWhereInput | null;
  confirmationPaymentFiles_some?: FileWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: LongTermCareRebateWhereInput[] | null;
  OR?: LongTermCareRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface LongTermCareRebateWhereUniqueInput {
  id?: string | null;
}

export interface MessageCreateInput {
  copy?: MessageUserInput | null;
  subject: string;
  text: string;
  to: MessageUserInput;
}

export interface MessageUserInput {
  connect: UserWhereUniqueInput;
}

export interface MessageWhereInput {
  AND?: MessageWhereInput[] | null;
  copy?: UserWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  email?: EmailWhereInput | null;
  from?: UserWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  location?: MessageLocation | null;
  location_in?: MessageLocation[] | null;
  location_not?: MessageLocation | null;
  location_not_in?: MessageLocation[] | null;
  NOT?: MessageWhereInput[] | null;
  OR?: MessageWhereInput[] | null;
  subject?: string | null;
  subject_contains?: string | null;
  subject_ends_with?: string | null;
  subject_gt?: string | null;
  subject_gte?: string | null;
  subject_in?: string[] | null;
  subject_lt?: string | null;
  subject_lte?: string | null;
  subject_not?: string | null;
  subject_not_contains?: string | null;
  subject_not_ends_with?: string | null;
  subject_not_in?: string[] | null;
  subject_not_starts_with?: string | null;
  subject_starts_with?: string | null;
  systemMessage?: boolean | null;
  systemMessage_not?: boolean | null;
  text?: string | null;
  text_contains?: string | null;
  text_ends_with?: string | null;
  text_gt?: string | null;
  text_gte?: string | null;
  text_in?: string[] | null;
  text_lt?: string | null;
  text_lte?: string | null;
  text_not?: string | null;
  text_not_contains?: string | null;
  text_not_ends_with?: string | null;
  text_not_in?: string[] | null;
  text_not_starts_with?: string | null;
  text_starts_with?: string | null;
  to?: UserWhereInput | null;
  user?: UserWhereInput | null;
}

export interface MessageWhereUniqueInput {
  id?: string | null;
}

export interface MonthlyRebateCreateInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateManyWithoutUserInput {
  connect?: MonthlyRebateWhereUniqueInput[] | null;
  create?: MonthlyRebateCreateWithoutUserInput[] | null;
}

export interface MonthlyRebateCreateOneInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateInput | null;
}

export interface MonthlyRebateCreateOneWithoutBasicRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutBasicRebatesInput | null;
}

export interface MonthlyRebateCreateOneWithoutChildrenRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutChildrenRebatesInput | null;
}

export interface MonthlyRebateCreateOneWithoutDisabilityRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutDisabilityRebatesInput | null;
}

export interface MonthlyRebateCreateOneWithoutSettlementRequestInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutSettlementRequestInput | null;
}

export interface MonthlyRebateCreateOneWithoutSettlementRequestsInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutSettlementRequestsInput | null;
}

export interface MonthlyRebateCreateOneWithoutStudentRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutStudentRebatesInput | null;
}

export interface MonthlyRebateCreateOneWithoutTaxQuestionnaireInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutTaxQuestionnaireInput | null;
}

export interface MonthlyRebateCreateOneWithoutZtppRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutZtppRebatesInput | null;
}

export interface MonthlyRebateCreateWithoutBasicRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutChildrenRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutDisabilityRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutSettlementRequestInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutSettlementRequestsInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutStudentRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutTaxQuestionnaireInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutUserInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
  ztppRebates?: ZtppRebateCreateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateCreateWithoutZtppRebatesInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate: BasicRebateCreateOneInput;
  basicRebates?: BasicRebateCreateManyWithoutMonthlyRebateInput | null;
  childrenRebate: ChildrenRebateCreateOneInput;
  childrenRebates?: ChildrenRebateCreateManyWithoutMonthlyRebateInput | null;
  disabilityRebate: DisabilityRebateCreateOneInput;
  disabilityRebates?: DisabilityRebateCreateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileCreateOneInput | null;
  fileExports?: FileProcessingCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate: StudentRebateCreateOneInput;
  studentRebates?: StudentRebateCreateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire: MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput;
  user: UserCreateOneWithoutMonthlyRebatesInput;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year: number;
  ztppRebate: ZtppRebateCreateOneInput;
}

export interface MonthlyRebateScalarWhereInput {
  agreementAt?: any | null;
  agreementAt_gt?: any | null;
  agreementAt_gte?: any | null;
  agreementAt_in?: any[] | null;
  agreementAt_lt?: any | null;
  agreementAt_lte?: any | null;
  agreementAt_not?: any | null;
  agreementAt_not_in?: any[] | null;
  allowSendAnythingOnce?: boolean | null;
  allowSendAnythingOnce_not?: boolean | null;
  AND?: MonthlyRebateScalarWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  doNotGenerateFile?: boolean | null;
  doNotGenerateFile_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: MonthlyRebateScalarWhereInput[] | null;
  OR?: MonthlyRebateScalarWhereInput[] | null;
  submitedAt?: any | null;
  submitedAt_gt?: any | null;
  submitedAt_gte?: any | null;
  submitedAt_in?: any[] | null;
  submitedAt_lt?: any | null;
  submitedAt_lte?: any | null;
  submitedAt_not?: any | null;
  submitedAt_not_in?: any[] | null;
  variant?: MonthlyRebateVariant | null;
  variant_in?: MonthlyRebateVariant[] | null;
  variant_not?: MonthlyRebateVariant | null;
  variant_not_in?: MonthlyRebateVariant[] | null;
  wizardFinishedAt?: any | null;
  wizardFinishedAt_gt?: any | null;
  wizardFinishedAt_gte?: any | null;
  wizardFinishedAt_in?: any[] | null;
  wizardFinishedAt_lt?: any | null;
  wizardFinishedAt_lte?: any | null;
  wizardFinishedAt_not?: any | null;
  wizardFinishedAt_not_in?: any[] | null;
  wizardStartedAt?: any | null;
  wizardStartedAt_gt?: any | null;
  wizardStartedAt_gte?: any | null;
  wizardStartedAt_in?: any[] | null;
  wizardStartedAt_lt?: any | null;
  wizardStartedAt_lte?: any | null;
  wizardStartedAt_not?: any | null;
  wizardStartedAt_not_in?: any[] | null;
  wizardStep?: MonthlyWizardStep | null;
  wizardStep_in?: MonthlyWizardStep[] | null;
  wizardStep_not?: MonthlyWizardStep | null;
  wizardStep_not_in?: MonthlyWizardStep[] | null;
  year?: number | null;
  year_gt?: number | null;
  year_gte?: number | null;
  year_in?: number[] | null;
  year_lt?: number | null;
  year_lte?: number | null;
  year_not?: number | null;
  year_not_in?: number[] | null;
}

export interface MonthlyRebateUpdateDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateManyDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  doNotGenerateFile?: boolean | null;
  submitedAt?: any | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
}

export interface MonthlyRebateUpdateManyWithWhereNestedInput {
  data: MonthlyRebateUpdateManyDataInput;
  where: MonthlyRebateScalarWhereInput;
}

export interface MonthlyRebateUpdateManyWithoutUserInput {
  connect?: MonthlyRebateWhereUniqueInput[] | null;
  create?: MonthlyRebateCreateWithoutUserInput[] | null;
  delete?: MonthlyRebateWhereUniqueInput[] | null;
  deleteMany?: MonthlyRebateScalarWhereInput[] | null;
  disconnect?: MonthlyRebateWhereUniqueInput[] | null;
  set?: MonthlyRebateWhereUniqueInput[] | null;
  update?: MonthlyRebateUpdateWithWhereUniqueWithoutUserInput[] | null;
  updateMany?: MonthlyRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: MonthlyRebateUpsertWithWhereUniqueWithoutUserInput[] | null;
}

export interface MonthlyRebateUpdateOneRequiredInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateInput | null;
  update?: MonthlyRebateUpdateDataInput | null;
  upsert?: MonthlyRebateUpsertNestedInput | null;
}

export interface MonthlyRebateUpdateOneRequiredWithoutTaxQuestionnaireInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutTaxQuestionnaireInput | null;
  update?: MonthlyRebateUpdateWithoutTaxQuestionnaireDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutTaxQuestionnaireInput | null;
}

export interface MonthlyRebateUpdateOneWithoutBasicRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutBasicRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyRebateUpdateWithoutBasicRebatesDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutBasicRebatesInput | null;
}

export interface MonthlyRebateUpdateOneWithoutChildrenRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutChildrenRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyRebateUpdateWithoutChildrenRebatesDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutChildrenRebatesInput | null;
}

export interface MonthlyRebateUpdateOneWithoutDisabilityRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutDisabilityRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyRebateUpdateWithoutDisabilityRebatesDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutDisabilityRebatesInput | null;
}

export interface MonthlyRebateUpdateOneWithoutSettlementRequestInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutSettlementRequestInput | null;
}

export interface MonthlyRebateUpdateOneWithoutSettlementRequestsInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutSettlementRequestsInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyRebateUpdateWithoutSettlementRequestsDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutSettlementRequestsInput | null;
}

export interface MonthlyRebateUpdateOneWithoutStudentRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutStudentRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyRebateUpdateWithoutStudentRebatesDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutStudentRebatesInput | null;
}

export interface MonthlyRebateUpdateOneWithoutZtppRebatesInput {
  connect?: MonthlyRebateWhereUniqueInput | null;
  create?: MonthlyRebateCreateWithoutZtppRebatesInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyRebateUpdateWithoutZtppRebatesDataInput | null;
  upsert?: MonthlyRebateUpsertWithoutZtppRebatesInput | null;
}

export interface MonthlyRebateUpdateWithWhereUniqueWithoutUserInput {
  data: MonthlyRebateUpdateWithoutUserDataInput;
  where: MonthlyRebateWhereUniqueInput;
}

export interface MonthlyRebateUpdateWithoutBasicRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutChildrenRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutDisabilityRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutSettlementRequestDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutSettlementRequestsDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutStudentRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutTaxQuestionnaireDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutUserDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
  ztppRebates?: ZtppRebateUpdateManyWithoutMonthlyRebateInput | null;
}

export interface MonthlyRebateUpdateWithoutZtppRebatesDataInput {
  agreementAt?: any | null;
  allowSendAnythingOnce?: boolean | null;
  basicRebate?: BasicRebateUpdateOneRequiredInput | null;
  basicRebates?: BasicRebateUpdateManyWithoutMonthlyRebateInput | null;
  childrenRebate?: ChildrenRebateUpdateOneRequiredInput | null;
  childrenRebates?: ChildrenRebateUpdateManyWithoutMonthlyRebateInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneRequiredInput | null;
  disabilityRebates?: DisabilityRebateUpdateManyWithoutMonthlyRebateInput | null;
  doNotGenerateFile?: boolean | null;
  file?: FileUpdateOneInput | null;
  fileExports?: FileProcessingUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutMonthlyRebateInput | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput | null;
  studentRebate?: StudentRebateUpdateOneRequiredInput | null;
  studentRebates?: StudentRebateUpdateManyWithoutMonthlyRebateInput | null;
  submitedAt?: any | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput | null;
  user?: UserUpdateOneRequiredWithoutMonthlyRebatesInput | null;
  variant?: MonthlyRebateVariant | null;
  wizardFinishedAt?: any | null;
  wizardStartedAt?: any | null;
  wizardStep?: MonthlyWizardStep | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneRequiredInput | null;
}

export interface MonthlyRebateUpsertNestedInput {
  create: MonthlyRebateCreateInput;
  update: MonthlyRebateUpdateDataInput;
}

export interface MonthlyRebateUpsertWithWhereUniqueWithoutUserInput {
  create: MonthlyRebateCreateWithoutUserInput;
  update: MonthlyRebateUpdateWithoutUserDataInput;
  where: MonthlyRebateWhereUniqueInput;
}

export interface MonthlyRebateUpsertWithoutBasicRebatesInput {
  create: MonthlyRebateCreateWithoutBasicRebatesInput;
  update: MonthlyRebateUpdateWithoutBasicRebatesDataInput;
}

export interface MonthlyRebateUpsertWithoutChildrenRebatesInput {
  create: MonthlyRebateCreateWithoutChildrenRebatesInput;
  update: MonthlyRebateUpdateWithoutChildrenRebatesDataInput;
}

export interface MonthlyRebateUpsertWithoutDisabilityRebatesInput {
  create: MonthlyRebateCreateWithoutDisabilityRebatesInput;
  update: MonthlyRebateUpdateWithoutDisabilityRebatesDataInput;
}

export interface MonthlyRebateUpsertWithoutSettlementRequestInput {
  create: MonthlyRebateCreateWithoutSettlementRequestInput;
  update: MonthlyRebateUpdateWithoutSettlementRequestDataInput;
}

export interface MonthlyRebateUpsertWithoutSettlementRequestsInput {
  create: MonthlyRebateCreateWithoutSettlementRequestsInput;
  update: MonthlyRebateUpdateWithoutSettlementRequestsDataInput;
}

export interface MonthlyRebateUpsertWithoutStudentRebatesInput {
  create: MonthlyRebateCreateWithoutStudentRebatesInput;
  update: MonthlyRebateUpdateWithoutStudentRebatesDataInput;
}

export interface MonthlyRebateUpsertWithoutTaxQuestionnaireInput {
  create: MonthlyRebateCreateWithoutTaxQuestionnaireInput;
  update: MonthlyRebateUpdateWithoutTaxQuestionnaireDataInput;
}

export interface MonthlyRebateUpsertWithoutZtppRebatesInput {
  create: MonthlyRebateCreateWithoutZtppRebatesInput;
  update: MonthlyRebateUpdateWithoutZtppRebatesDataInput;
}

export interface MonthlyRebateWhereInput {
  agreementAt?: any | null;
  agreementAt_gt?: any | null;
  agreementAt_gte?: any | null;
  agreementAt_in?: any[] | null;
  agreementAt_lt?: any | null;
  agreementAt_lte?: any | null;
  agreementAt_not?: any | null;
  agreementAt_not_in?: any[] | null;
  allowSendAnythingOnce?: boolean | null;
  allowSendAnythingOnce_not?: boolean | null;
  AND?: MonthlyRebateWhereInput[] | null;
  basicRebate?: BasicRebateWhereInput | null;
  basicRebates_every?: BasicRebateWhereInput | null;
  basicRebates_none?: BasicRebateWhereInput | null;
  basicRebates_some?: BasicRebateWhereInput | null;
  childrenRebate?: ChildrenRebateWhereInput | null;
  childrenRebates_every?: ChildrenRebateWhereInput | null;
  childrenRebates_none?: ChildrenRebateWhereInput | null;
  childrenRebates_some?: ChildrenRebateWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  disabilityRebate?: DisabilityRebateWhereInput | null;
  disabilityRebates_every?: DisabilityRebateWhereInput | null;
  disabilityRebates_none?: DisabilityRebateWhereInput | null;
  disabilityRebates_some?: DisabilityRebateWhereInput | null;
  doNotGenerateFile?: boolean | null;
  doNotGenerateFile_not?: boolean | null;
  file?: FileWhereInput | null;
  fileExports_every?: FileProcessingWhereInput | null;
  fileExports_none?: FileProcessingWhereInput | null;
  fileExports_some?: FileProcessingWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: MonthlyRebateWhereInput[] | null;
  OR?: MonthlyRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  settlementRequests_every?: SettlementRequestWhereInput | null;
  settlementRequests_none?: SettlementRequestWhereInput | null;
  settlementRequests_some?: SettlementRequestWhereInput | null;
  studentRebate?: StudentRebateWhereInput | null;
  studentRebates_every?: StudentRebateWhereInput | null;
  studentRebates_none?: StudentRebateWhereInput | null;
  studentRebates_some?: StudentRebateWhereInput | null;
  submitedAt?: any | null;
  submitedAt_gt?: any | null;
  submitedAt_gte?: any | null;
  submitedAt_in?: any[] | null;
  submitedAt_lt?: any | null;
  submitedAt_lte?: any | null;
  submitedAt_not?: any | null;
  submitedAt_not_in?: any[] | null;
  taxQuestionnaire?: MonthlyTaxQuestionnaireWhereInput | null;
  user?: UserWhereInput | null;
  variant?: MonthlyRebateVariant | null;
  variant_in?: MonthlyRebateVariant[] | null;
  variant_not?: MonthlyRebateVariant | null;
  variant_not_in?: MonthlyRebateVariant[] | null;
  wizardFinishedAt?: any | null;
  wizardFinishedAt_gt?: any | null;
  wizardFinishedAt_gte?: any | null;
  wizardFinishedAt_in?: any[] | null;
  wizardFinishedAt_lt?: any | null;
  wizardFinishedAt_lte?: any | null;
  wizardFinishedAt_not?: any | null;
  wizardFinishedAt_not_in?: any[] | null;
  wizardStartedAt?: any | null;
  wizardStartedAt_gt?: any | null;
  wizardStartedAt_gte?: any | null;
  wizardStartedAt_in?: any[] | null;
  wizardStartedAt_lt?: any | null;
  wizardStartedAt_lte?: any | null;
  wizardStartedAt_not?: any | null;
  wizardStartedAt_not_in?: any[] | null;
  wizardStep?: MonthlyWizardStep | null;
  wizardStep_in?: MonthlyWizardStep[] | null;
  wizardStep_not?: MonthlyWizardStep | null;
  wizardStep_not_in?: MonthlyWizardStep[] | null;
  year?: number | null;
  year_gt?: number | null;
  year_gte?: number | null;
  year_in?: number[] | null;
  year_lt?: number | null;
  year_lte?: number | null;
  year_not?: number | null;
  year_not_in?: number[] | null;
  ztppRebate?: ZtppRebateWhereInput | null;
  ztppRebates_every?: ZtppRebateWhereInput | null;
  ztppRebates_none?: ZtppRebateWhereInput | null;
  ztppRebates_some?: ZtppRebateWhereInput | null;
}

export interface MonthlyRebateWhereUniqueInput {
  id?: string | null;
}

export interface MonthlyTaxQuestionnaireCreateOneWithoutHistoryInput {
  connect?: MonthlyTaxQuestionnaireWhereUniqueInput | null;
  create?: MonthlyTaxQuestionnaireCreateWithoutHistoryInput | null;
}

export interface MonthlyTaxQuestionnaireCreateOneWithoutMonthlyRebateInput {
  connect?: MonthlyTaxQuestionnaireWhereUniqueInput | null;
  create?: MonthlyTaxQuestionnaireCreateWithoutMonthlyRebateInput | null;
}

export interface MonthlyTaxQuestionnaireCreateWithoutHistoryInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  id?: string | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate: MonthlyRebateCreateOneWithoutTaxQuestionnaireInput;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireCreateWithoutMonthlyRebateInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  history?: MonthlyTaxQuestionnaireSnapCreateManyWithoutSnapRootInput | null;
  id?: string | null;
  madeByAnotherEmployer?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapCreateManyWithoutSnapHistoryInput {
  connect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: MonthlyTaxQuestionnaireSnapCreateWithoutSnapHistoryInput[] | null;
}

export interface MonthlyTaxQuestionnaireSnapCreateManyWithoutSnapRootInput {
  connect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: MonthlyTaxQuestionnaireSnapCreateWithoutSnapRootInput[] | null;
}

export interface MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput {
  connect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput | null;
  create?: MonthlyTaxQuestionnaireSnapCreateWithoutSettlementRequestInput | null;
}

export interface MonthlyTaxQuestionnaireSnapCreateWithoutSettlementRequestInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  id?: string | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate: MonthlyRebateCreateOneInput;
  snapHistory?: MonthlyTaxQuestionnaireSnapCreateManyWithoutSnapHistoryInput | null;
  snapRoot?: MonthlyTaxQuestionnaireCreateOneWithoutHistoryInput | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapCreateWithoutSnapHistoryInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  id?: string | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate: MonthlyRebateCreateOneInput;
  settlementRequest: SettlementRequestCreateOneWithoutMonthlyTaxQuestionnaireInput;
  snapRoot?: MonthlyTaxQuestionnaireCreateOneWithoutHistoryInput | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapCreateWithoutSnapRootInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  id?: string | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate: MonthlyRebateCreateOneInput;
  settlementRequest: SettlementRequestCreateOneWithoutMonthlyTaxQuestionnaireInput;
  snapHistory?: MonthlyTaxQuestionnaireSnapCreateManyWithoutSnapHistoryInput | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapScalarWhereInput {
  AND?: MonthlyTaxQuestionnaireSnapScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  madeByAnotherEmployer?: boolean | null;
  madeByAnotherEmployer_not?: boolean | null;
  NOT?: MonthlyTaxQuestionnaireSnapScalarWhereInput[] | null;
  OR?: MonthlyTaxQuestionnaireSnapScalarWhereInput[] | null;
  status?: TaxQuestionnaireStatus | null;
  status_in?: TaxQuestionnaireStatus[] | null;
  status_not?: TaxQuestionnaireStatus | null;
  status_not_in?: TaxQuestionnaireStatus[] | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface MonthlyTaxQuestionnaireSnapUpdateManyDataInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  madeByAnotherEmployer?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapUpdateManyWithWhereNestedInput {
  data: MonthlyTaxQuestionnaireSnapUpdateManyDataInput;
  where: MonthlyTaxQuestionnaireSnapScalarWhereInput;
}

export interface MonthlyTaxQuestionnaireSnapUpdateManyWithoutSnapHistoryInput {
  connect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: MonthlyTaxQuestionnaireSnapCreateWithoutSnapHistoryInput[] | null;
  delete?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  deleteMany?: MonthlyTaxQuestionnaireSnapScalarWhereInput[] | null;
  disconnect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  set?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  update?: MonthlyTaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapHistoryInput[] | null;
  updateMany?: MonthlyTaxQuestionnaireSnapUpdateManyWithWhereNestedInput[] | null;
  upsert?: MonthlyTaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapHistoryInput[] | null;
}

export interface MonthlyTaxQuestionnaireSnapUpdateManyWithoutSnapRootInput {
  connect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: MonthlyTaxQuestionnaireSnapCreateWithoutSnapRootInput[] | null;
  delete?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  deleteMany?: MonthlyTaxQuestionnaireSnapScalarWhereInput[] | null;
  disconnect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  set?: MonthlyTaxQuestionnaireSnapWhereUniqueInput[] | null;
  update?: MonthlyTaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapRootInput[] | null;
  updateMany?: MonthlyTaxQuestionnaireSnapUpdateManyWithWhereNestedInput[] | null;
  upsert?: MonthlyTaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapRootInput[] | null;
}

export interface MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput {
  connect?: MonthlyTaxQuestionnaireSnapWhereUniqueInput | null;
  create?: MonthlyTaxQuestionnaireSnapCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyTaxQuestionnaireSnapUpdateWithoutSettlementRequestDataInput | null;
  upsert?: MonthlyTaxQuestionnaireSnapUpsertWithoutSettlementRequestInput | null;
}

export interface MonthlyTaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapHistoryInput {
  data: MonthlyTaxQuestionnaireSnapUpdateWithoutSnapHistoryDataInput;
  where: MonthlyTaxQuestionnaireSnapWhereUniqueInput;
}

export interface MonthlyTaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapRootInput {
  data: MonthlyTaxQuestionnaireSnapUpdateWithoutSnapRootDataInput;
  where: MonthlyTaxQuestionnaireSnapWhereUniqueInput;
}

export interface MonthlyTaxQuestionnaireSnapUpdateWithoutSettlementRequestDataInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate?: MonthlyRebateUpdateOneRequiredInput | null;
  snapHistory?: MonthlyTaxQuestionnaireSnapUpdateManyWithoutSnapHistoryInput | null;
  snapRoot?: MonthlyTaxQuestionnaireUpdateOneWithoutHistoryInput | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapUpdateWithoutSnapHistoryDataInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate?: MonthlyRebateUpdateOneRequiredInput | null;
  settlementRequest?: SettlementRequestUpdateOneRequiredWithoutMonthlyTaxQuestionnaireInput | null;
  snapRoot?: MonthlyTaxQuestionnaireUpdateOneWithoutHistoryInput | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapUpdateWithoutSnapRootDataInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate?: MonthlyRebateUpdateOneRequiredInput | null;
  settlementRequest?: SettlementRequestUpdateOneRequiredWithoutMonthlyTaxQuestionnaireInput | null;
  snapHistory?: MonthlyTaxQuestionnaireSnapUpdateManyWithoutSnapHistoryInput | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapHistoryInput {
  create: MonthlyTaxQuestionnaireSnapCreateWithoutSnapHistoryInput;
  update: MonthlyTaxQuestionnaireSnapUpdateWithoutSnapHistoryDataInput;
  where: MonthlyTaxQuestionnaireSnapWhereUniqueInput;
}

export interface MonthlyTaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapRootInput {
  create: MonthlyTaxQuestionnaireSnapCreateWithoutSnapRootInput;
  update: MonthlyTaxQuestionnaireSnapUpdateWithoutSnapRootDataInput;
  where: MonthlyTaxQuestionnaireSnapWhereUniqueInput;
}

export interface MonthlyTaxQuestionnaireSnapUpsertWithoutSettlementRequestInput {
  create: MonthlyTaxQuestionnaireSnapCreateWithoutSettlementRequestInput;
  update: MonthlyTaxQuestionnaireSnapUpdateWithoutSettlementRequestDataInput;
}

export interface MonthlyTaxQuestionnaireSnapWhereInput {
  AND?: MonthlyTaxQuestionnaireSnapWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  madeByAnotherEmployer?: boolean | null;
  madeByAnotherEmployer_not?: boolean | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  NOT?: MonthlyTaxQuestionnaireSnapWhereInput[] | null;
  OR?: MonthlyTaxQuestionnaireSnapWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  snapHistory_every?: MonthlyTaxQuestionnaireSnapWhereInput | null;
  snapHistory_none?: MonthlyTaxQuestionnaireSnapWhereInput | null;
  snapHistory_some?: MonthlyTaxQuestionnaireSnapWhereInput | null;
  snapRoot?: MonthlyTaxQuestionnaireWhereInput | null;
  status?: TaxQuestionnaireStatus | null;
  status_in?: TaxQuestionnaireStatus[] | null;
  status_not?: TaxQuestionnaireStatus | null;
  status_not_in?: TaxQuestionnaireStatus[] | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface MonthlyTaxQuestionnaireSnapWhereUniqueInput {
  id?: string | null;
}

export interface MonthlyTaxQuestionnaireUpdateInput {
  createByActualEmployer?: boolean | null;
  madeByAnotherEmployer?: boolean | null;
}

export interface MonthlyTaxQuestionnaireUpdateOneRequiredWithoutMonthlyRebateInput {
  connect?: MonthlyTaxQuestionnaireWhereUniqueInput | null;
  create?: MonthlyTaxQuestionnaireCreateWithoutMonthlyRebateInput | null;
  update?: MonthlyTaxQuestionnaireUpdateWithoutMonthlyRebateDataInput | null;
  upsert?: MonthlyTaxQuestionnaireUpsertWithoutMonthlyRebateInput | null;
}

export interface MonthlyTaxQuestionnaireUpdateOneWithoutHistoryInput {
  connect?: MonthlyTaxQuestionnaireWhereUniqueInput | null;
  create?: MonthlyTaxQuestionnaireCreateWithoutHistoryInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: MonthlyTaxQuestionnaireUpdateWithoutHistoryDataInput | null;
  upsert?: MonthlyTaxQuestionnaireUpsertWithoutHistoryInput | null;
}

export interface MonthlyTaxQuestionnaireUpdateWithoutHistoryDataInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  madeByAnotherEmployer?: boolean | null;
  monthlyRebate?: MonthlyRebateUpdateOneRequiredWithoutTaxQuestionnaireInput | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireUpdateWithoutMonthlyRebateDataInput {
  commentOfDenied?: string | null;
  createByActualEmployer?: boolean | null;
  history?: MonthlyTaxQuestionnaireSnapUpdateManyWithoutSnapRootInput | null;
  madeByAnotherEmployer?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface MonthlyTaxQuestionnaireUpsertWithoutHistoryInput {
  create: MonthlyTaxQuestionnaireCreateWithoutHistoryInput;
  update: MonthlyTaxQuestionnaireUpdateWithoutHistoryDataInput;
}

export interface MonthlyTaxQuestionnaireUpsertWithoutMonthlyRebateInput {
  create: MonthlyTaxQuestionnaireCreateWithoutMonthlyRebateInput;
  update: MonthlyTaxQuestionnaireUpdateWithoutMonthlyRebateDataInput;
}

export interface MonthlyTaxQuestionnaireWhereInput {
  AND?: MonthlyTaxQuestionnaireWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  history_every?: MonthlyTaxQuestionnaireSnapWhereInput | null;
  history_none?: MonthlyTaxQuestionnaireSnapWhereInput | null;
  history_some?: MonthlyTaxQuestionnaireSnapWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  madeByAnotherEmployer?: boolean | null;
  madeByAnotherEmployer_not?: boolean | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  NOT?: MonthlyTaxQuestionnaireWhereInput[] | null;
  OR?: MonthlyTaxQuestionnaireWhereInput[] | null;
  status?: TaxQuestionnaireStatus | null;
  status_in?: TaxQuestionnaireStatus[] | null;
  status_not?: TaxQuestionnaireStatus | null;
  status_not_in?: TaxQuestionnaireStatus[] | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface MonthlyTaxQuestionnaireWhereUniqueInput {
  id?: string | null;
}

export interface OwnBasicAnnualRebateUpdateInput {
  affidavitFiles?: FileUpdateManyInput | null;
  prevYearPeriods?: PrevYearPeriodInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnBasicMonthlyRebateUpdateInput {
  affidavitFiles?: FileUpdateManyInput | null;
  applyFrom?: any | null;
  applyTo?: any | null;
  closePeriodAt?: any | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnChildrenRebateUpdateInput {
  birthCertificateFiles?: FileUpdateManyInput | null;
  children?: ChildUpdateManyWithoutChildrenRebateInput | null;
  commentOfDenied?: string | null;
  otherParentAddress?: string | null;
  otherParentApplying?: boolean | null;
  otherParentConfirmationFiles?: FileUpdateManyInput | null;
  otherParentFirstname?: string | null;
  otherParentLastname?: string | null;
  otherParentNationalIDNumber?: string | null;
  otherParentNourishes?: boolean | null;
  otherParentStatus?: OwnRebateStatusInput | null;
  otherParentWork?: Work | null;
  otherParentWorkAddress?: string | null;
  otherParentWorkName?: string | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  studentPrevYearPeriods?: StudentRebatePrevYearPeriodUpdateManyInput | null;
  type?: OwnRebateRequestTypeInput | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface OwnDisabilityAnnualRebateUpdateInput {
  closePeriodAt?: any | null;
  disabilityConfirmationFiles?: FileUpdateManyInput | null;
  disabilityOtherFiles?: FileUpdateManyInput | null;
  prevYearPeriods?: PrevYearPeriodDisabilityInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnDisabilityMonthlyRebateUpdateInput {
  closePeriodAt?: any | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  disabilityConfirmationFiles?: FileUpdateManyInput | null;
  disabilityLevel?: DisabilityLevel | null;
  disabilityOtherFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnExamRebateUpdateInput {
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnForeclosureRebateUpdateInput {
  amount?: number | null;
  confirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnGiftRebateUpdateInput {
  giftConfirmationFiles?: FileUpdateManyInput | null;
  gifts?: GiftUpdateManyWithoutGiftRebateInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnInvestmentRebateUpdateInput {
  amount?: number | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnLifeInsuranceRebateUpdateInput {
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnLoanRebateUpdateInput {
  addresses?: LoanRebateUpdateaddressesInput | null;
  applyInMonths?: LoanRebateUpdateapplyInMonthsInput | null;
  interestAmount?: number | null;
  interestAmountConfirmationFiles?: FileUpdateManyInput | null;
  loanContractFiles?: FileUpdateManyInput | null;
  otherAddress?: string | null;
  otherPersonApplying?: boolean | null;
  otherPersonFullname?: string | null;
  otherPersonNationalIDNumber?: string | null;
  propertyConfirmationFiles?: FileUpdateManyInput | null;
  purchaseDate?: any | null;
  startConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnLongTermCareRebateUpdateInput {
  amount?: number | null;
  confirmationAgreementFiles?: FileUpdateManyInput | null;
  confirmationPaymentFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnPensionInsuranceRebateUpdateInput {
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnPreschoolRebateUpdateInput {
  feeConfirmationFiles?: FileUpdateManyInput | null;
  preschools?: PreschoolUpdateManyWithoutPreschoolRebateInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnSpouseRebateUpdateInput {
  applyInMonths?: SpouseRebateUpdateapplyInMonthsInput | null;
  childBirthCertificateFiles?: FileUpdateManyInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  marriageCertificateFiles?: FileUpdateManyInput | null;
  nationalIDNumber?: string | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
  ztppMonths?: SpouseRebateUpdateztppMonthsInput | null;
}

export interface OwnStudentAnnualRebateUpdateInput {
  closePeriodAt?: any | null;
  confirmationDoctoralStudies?: boolean | null;
  prevYearPeriods?: PrevYearPeriodInput | null;
  status?: OwnRebateStatusInput | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnStudentMonthlyRebateUpdateInput {
  closePeriodAt?: any | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  status?: OwnRebateStatusInput | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnUnionRebateUpdateInput {
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
}

export interface OwnZtppAnnualRebateUpdateInput {
  closePeriodAt?: any | null;
  prevYearPeriods?: PrevYearPeriodInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface OwnZtppMonthlyRebateUpdateInput {
  closePeriodAt?: any | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: OwnRebateStatusInput | null;
  type?: OwnRebateRequestTypeInput | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface PensionInsuranceRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutPensionInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileCreateManyInput | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutPensionInsuranceRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface PensionInsuranceRebateCreateManyWithoutAnnualRebateInput {
  connect?: PensionInsuranceRebateWhereUniqueInput[] | null;
  create?: PensionInsuranceRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface PensionInsuranceRebateCreateOneInput {
  connect?: PensionInsuranceRebateWhereUniqueInput | null;
  create?: PensionInsuranceRebateCreateInput | null;
}

export interface PensionInsuranceRebateCreateOneWithoutSettlementRequestInput {
  connect?: PensionInsuranceRebateWhereUniqueInput | null;
  create?: PensionInsuranceRebateCreateWithoutSettlementRequestInput | null;
}

export interface PensionInsuranceRebateCreateWithoutAnnualRebateInput {
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileCreateManyInput | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutPensionInsuranceRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface PensionInsuranceRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutPensionInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileCreateManyInput | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface PensionInsuranceRebateScalarWhereInput {
  AND?: PensionInsuranceRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountAdditionalPart_gt?: number | null;
  feeAmountAdditionalPart_gte?: number | null;
  feeAmountAdditionalPart_in?: number[] | null;
  feeAmountAdditionalPart_lt?: number | null;
  feeAmountAdditionalPart_lte?: number | null;
  feeAmountAdditionalPart_not?: number | null;
  feeAmountAdditionalPart_not_in?: number[] | null;
  feeAmountContributionPart?: number | null;
  feeAmountContributionPart_gt?: number | null;
  feeAmountContributionPart_gte?: number | null;
  feeAmountContributionPart_in?: number[] | null;
  feeAmountContributionPart_lt?: number | null;
  feeAmountContributionPart_lte?: number | null;
  feeAmountContributionPart_not?: number | null;
  feeAmountContributionPart_not_in?: number[] | null;
  feeAmountSpecializedPart?: number | null;
  feeAmountSpecializedPart_gt?: number | null;
  feeAmountSpecializedPart_gte?: number | null;
  feeAmountSpecializedPart_in?: number[] | null;
  feeAmountSpecializedPart_lt?: number | null;
  feeAmountSpecializedPart_lte?: number | null;
  feeAmountSpecializedPart_not?: number | null;
  feeAmountSpecializedPart_not_in?: number[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: PensionInsuranceRebateScalarWhereInput[] | null;
  OR?: PensionInsuranceRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface PensionInsuranceRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutPensionInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutPensionInsuranceRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PensionInsuranceRebateUpdateManyDataInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PensionInsuranceRebateUpdateManyWithWhereNestedInput {
  data: PensionInsuranceRebateUpdateManyDataInput;
  where: PensionInsuranceRebateScalarWhereInput;
}

export interface PensionInsuranceRebateUpdateManyWithoutAnnualRebateInput {
  connect?: PensionInsuranceRebateWhereUniqueInput[] | null;
  create?: PensionInsuranceRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: PensionInsuranceRebateWhereUniqueInput[] | null;
  deleteMany?: PensionInsuranceRebateScalarWhereInput[] | null;
  disconnect?: PensionInsuranceRebateWhereUniqueInput[] | null;
  set?: PensionInsuranceRebateWhereUniqueInput[] | null;
  update?: PensionInsuranceRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: PensionInsuranceRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: PensionInsuranceRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface PensionInsuranceRebateUpdateOneRequiredInput {
  connect?: PensionInsuranceRebateWhereUniqueInput | null;
  create?: PensionInsuranceRebateCreateInput | null;
  update?: PensionInsuranceRebateUpdateDataInput | null;
  upsert?: PensionInsuranceRebateUpsertNestedInput | null;
}

export interface PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput {
  connect?: PensionInsuranceRebateWhereUniqueInput | null;
  create?: PensionInsuranceRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: PensionInsuranceRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: PensionInsuranceRebateUpsertWithoutSettlementRequestInput | null;
}

export interface PensionInsuranceRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: PensionInsuranceRebateUpdateWithoutAnnualRebateDataInput;
  where: PensionInsuranceRebateWhereUniqueInput;
}

export interface PensionInsuranceRebateUpdateWithoutAnnualRebateDataInput {
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutPensionInsuranceRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PensionInsuranceRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutPensionInsuranceRebatesInput | null;
  commentOfDenied?: string | null;
  contractConfirmationFiles?: FileUpdateManyInput | null;
  feeAmount?: number | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountContributionPart?: number | null;
  feeAmountSpecializedPart?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PensionInsuranceRebateUpsertNestedInput {
  create: PensionInsuranceRebateCreateInput;
  update: PensionInsuranceRebateUpdateDataInput;
}

export interface PensionInsuranceRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: PensionInsuranceRebateCreateWithoutAnnualRebateInput;
  update: PensionInsuranceRebateUpdateWithoutAnnualRebateDataInput;
  where: PensionInsuranceRebateWhereUniqueInput;
}

export interface PensionInsuranceRebateUpsertWithoutSettlementRequestInput {
  create: PensionInsuranceRebateCreateWithoutSettlementRequestInput;
  update: PensionInsuranceRebateUpdateWithoutSettlementRequestDataInput;
}

export interface PensionInsuranceRebateWhereInput {
  AND?: PensionInsuranceRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  contractConfirmationFiles_every?: FileWhereInput | null;
  contractConfirmationFiles_none?: FileWhereInput | null;
  contractConfirmationFiles_some?: FileWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  feeAmountAdditionalPart?: number | null;
  feeAmountAdditionalPart_gt?: number | null;
  feeAmountAdditionalPart_gte?: number | null;
  feeAmountAdditionalPart_in?: number[] | null;
  feeAmountAdditionalPart_lt?: number | null;
  feeAmountAdditionalPart_lte?: number | null;
  feeAmountAdditionalPart_not?: number | null;
  feeAmountAdditionalPart_not_in?: number[] | null;
  feeAmountContributionPart?: number | null;
  feeAmountContributionPart_gt?: number | null;
  feeAmountContributionPart_gte?: number | null;
  feeAmountContributionPart_in?: number[] | null;
  feeAmountContributionPart_lt?: number | null;
  feeAmountContributionPart_lte?: number | null;
  feeAmountContributionPart_not?: number | null;
  feeAmountContributionPart_not_in?: number[] | null;
  feeAmountSpecializedPart?: number | null;
  feeAmountSpecializedPart_gt?: number | null;
  feeAmountSpecializedPart_gte?: number | null;
  feeAmountSpecializedPart_in?: number[] | null;
  feeAmountSpecializedPart_lt?: number | null;
  feeAmountSpecializedPart_lte?: number | null;
  feeAmountSpecializedPart_not?: number | null;
  feeAmountSpecializedPart_not_in?: number[] | null;
  feeConfirmationFiles_every?: FileWhereInput | null;
  feeConfirmationFiles_none?: FileWhereInput | null;
  feeConfirmationFiles_some?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: PensionInsuranceRebateWhereInput[] | null;
  OR?: PensionInsuranceRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface PensionInsuranceRebateWhereUniqueInput {
  id?: string | null;
}

export interface PreschoolCreateManyWithoutPreschoolRebateInput {
  connect?: PreschoolWhereUniqueInput[] | null;
  create?: PreschoolCreateWithoutPreschoolRebateInput[] | null;
}

export interface PreschoolCreateWithoutPreschoolRebateInput {
  feeAmount?: number | null;
  firstname?: string | null;
  id?: string | null;
  lastname?: string | null;
  nationalIDNumber?: string | null;
}

export interface PreschoolRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutPreschoolRebatesInput | null;
  commentOfDenied?: string | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  preschools?: PreschoolCreateManyWithoutPreschoolRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutPreschoolRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface PreschoolRebateCreateManyWithoutAnnualRebateInput {
  connect?: PreschoolRebateWhereUniqueInput[] | null;
  create?: PreschoolRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface PreschoolRebateCreateOneInput {
  connect?: PreschoolRebateWhereUniqueInput | null;
  create?: PreschoolRebateCreateInput | null;
}

export interface PreschoolRebateCreateOneWithoutSettlementRequestInput {
  connect?: PreschoolRebateWhereUniqueInput | null;
  create?: PreschoolRebateCreateWithoutSettlementRequestInput | null;
}

export interface PreschoolRebateCreateWithoutAnnualRebateInput {
  commentOfDenied?: string | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  preschools?: PreschoolCreateManyWithoutPreschoolRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutPreschoolRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface PreschoolRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutPreschoolRebatesInput | null;
  commentOfDenied?: string | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  preschools?: PreschoolCreateManyWithoutPreschoolRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface PreschoolRebateScalarWhereInput {
  AND?: PreschoolRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: PreschoolRebateScalarWhereInput[] | null;
  OR?: PreschoolRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface PreschoolRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutPreschoolRebatesInput | null;
  commentOfDenied?: string | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  preschools?: PreschoolUpdateManyWithoutPreschoolRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutPreschoolRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PreschoolRebateUpdateManyDataInput {
  commentOfDenied?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PreschoolRebateUpdateManyWithWhereNestedInput {
  data: PreschoolRebateUpdateManyDataInput;
  where: PreschoolRebateScalarWhereInput;
}

export interface PreschoolRebateUpdateManyWithoutAnnualRebateInput {
  connect?: PreschoolRebateWhereUniqueInput[] | null;
  create?: PreschoolRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: PreschoolRebateWhereUniqueInput[] | null;
  deleteMany?: PreschoolRebateScalarWhereInput[] | null;
  disconnect?: PreschoolRebateWhereUniqueInput[] | null;
  set?: PreschoolRebateWhereUniqueInput[] | null;
  update?: PreschoolRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: PreschoolRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: PreschoolRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface PreschoolRebateUpdateOneRequiredInput {
  connect?: PreschoolRebateWhereUniqueInput | null;
  create?: PreschoolRebateCreateInput | null;
  update?: PreschoolRebateUpdateDataInput | null;
  upsert?: PreschoolRebateUpsertNestedInput | null;
}

export interface PreschoolRebateUpdateOneWithoutSettlementRequestInput {
  connect?: PreschoolRebateWhereUniqueInput | null;
  create?: PreschoolRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: PreschoolRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: PreschoolRebateUpsertWithoutSettlementRequestInput | null;
}

export interface PreschoolRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: PreschoolRebateUpdateWithoutAnnualRebateDataInput;
  where: PreschoolRebateWhereUniqueInput;
}

export interface PreschoolRebateUpdateWithoutAnnualRebateDataInput {
  commentOfDenied?: string | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  preschools?: PreschoolUpdateManyWithoutPreschoolRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutPreschoolRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PreschoolRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutPreschoolRebatesInput | null;
  commentOfDenied?: string | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  preschools?: PreschoolUpdateManyWithoutPreschoolRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface PreschoolRebateUpsertNestedInput {
  create: PreschoolRebateCreateInput;
  update: PreschoolRebateUpdateDataInput;
}

export interface PreschoolRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: PreschoolRebateCreateWithoutAnnualRebateInput;
  update: PreschoolRebateUpdateWithoutAnnualRebateDataInput;
  where: PreschoolRebateWhereUniqueInput;
}

export interface PreschoolRebateUpsertWithoutSettlementRequestInput {
  create: PreschoolRebateCreateWithoutSettlementRequestInput;
  update: PreschoolRebateUpdateWithoutSettlementRequestDataInput;
}

export interface PreschoolRebateWhereInput {
  AND?: PreschoolRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeConfirmationFiles_every?: FileWhereInput | null;
  feeConfirmationFiles_none?: FileWhereInput | null;
  feeConfirmationFiles_some?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: PreschoolRebateWhereInput[] | null;
  OR?: PreschoolRebateWhereInput[] | null;
  preschools_every?: PreschoolWhereInput | null;
  preschools_none?: PreschoolWhereInput | null;
  preschools_some?: PreschoolWhereInput | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface PreschoolRebateWhereUniqueInput {
  id?: string | null;
}

export interface PreschoolUpdateManyWithoutPreschoolRebateInput {
  create?: PreschoolCreateWithoutPreschoolRebateInput[] | null;
  delete?: PreschoolWhereUniqueInput[] | null;
  update?: PreschoolUpdateWithWhereUniqueWithoutPreschoolRebateInput[] | null;
}

export interface PreschoolUpdateWithWhereUniqueWithoutPreschoolRebateInput {
  data: PreschoolUpdateWithoutPreschoolRebateDataInput;
  where: PreschoolWhereUniqueInput;
}

export interface PreschoolUpdateWithoutPreschoolRebateDataInput {
  feeAmount?: number | null;
  firstname?: string | null;
  lastname?: string | null;
  nationalIDNumber?: string | null;
}

export interface PreschoolWhereInput {
  AND?: PreschoolWhereInput[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  firstname?: string | null;
  firstname_contains?: string | null;
  firstname_ends_with?: string | null;
  firstname_gt?: string | null;
  firstname_gte?: string | null;
  firstname_in?: string[] | null;
  firstname_lt?: string | null;
  firstname_lte?: string | null;
  firstname_not?: string | null;
  firstname_not_contains?: string | null;
  firstname_not_ends_with?: string | null;
  firstname_not_in?: string[] | null;
  firstname_not_starts_with?: string | null;
  firstname_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  lastname?: string | null;
  lastname_contains?: string | null;
  lastname_ends_with?: string | null;
  lastname_gt?: string | null;
  lastname_gte?: string | null;
  lastname_in?: string[] | null;
  lastname_lt?: string | null;
  lastname_lte?: string | null;
  lastname_not?: string | null;
  lastname_not_contains?: string | null;
  lastname_not_ends_with?: string | null;
  lastname_not_in?: string[] | null;
  lastname_not_starts_with?: string | null;
  lastname_starts_with?: string | null;
  nationalIDNumber?: string | null;
  nationalIDNumber_contains?: string | null;
  nationalIDNumber_ends_with?: string | null;
  nationalIDNumber_gt?: string | null;
  nationalIDNumber_gte?: string | null;
  nationalIDNumber_in?: string[] | null;
  nationalIDNumber_lt?: string | null;
  nationalIDNumber_lte?: string | null;
  nationalIDNumber_not?: string | null;
  nationalIDNumber_not_contains?: string | null;
  nationalIDNumber_not_ends_with?: string | null;
  nationalIDNumber_not_in?: string[] | null;
  nationalIDNumber_not_starts_with?: string | null;
  nationalIDNumber_starts_with?: string | null;
  NOT?: PreschoolWhereInput[] | null;
  OR?: PreschoolWhereInput[] | null;
  preschoolRebate?: PreschoolRebateWhereInput | null;
}

export interface PreschoolWhereUniqueInput {
  id: string;
}

export interface PrevEmployerCreateManyWithoutAnnualRebateInput {
  connect?: PrevEmployerWhereUniqueInput[] | null;
  create?: PrevEmployerCreateWithoutAnnualRebateInput[] | null;
}

export interface PrevEmployerCreateOneWithoutHistoryInput {
  connect?: PrevEmployerWhereUniqueInput | null;
  create?: PrevEmployerCreateWithoutHistoryInput | null;
}

export interface PrevEmployerCreateWithoutAnnualRebateInput {
  actualDeductedTax?: number | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  incomeConfirmationFiles?: FileCreateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerCreateWithoutHistoryInput {
  actualDeductedTax?: number | null;
  annualRebate: AnnualRebateCreateOneWithoutPrevEmployersInput;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  id?: string | null;
  incomeConfirmationFiles?: FileCreateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  status?: PrevEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerSnapCreateManyWithoutSettlementRequestInput {
  connect?: PrevEmployerSnapWhereUniqueInput[] | null;
  create?: PrevEmployerSnapCreateWithoutSettlementRequestInput[] | null;
}

export interface PrevEmployerSnapCreateManyWithoutSnapHistoryInput {
  connect?: PrevEmployerSnapWhereUniqueInput[] | null;
  create?: PrevEmployerSnapCreateWithoutSnapHistoryInput[] | null;
}

export interface PrevEmployerSnapCreateWithoutSettlementRequestInput {
  actualDeductedTax?: number | null;
  annualRebate: AnnualRebateCreateOneInput;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  id?: string | null;
  incomeConfirmationFiles?: FileCreateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  snapHistory?: PrevEmployerSnapCreateManyWithoutSnapHistoryInput | null;
  snapRoot?: PrevEmployerCreateOneWithoutHistoryInput | null;
  snapRootId: string;
  status?: PrevEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerSnapCreateWithoutSnapHistoryInput {
  actualDeductedTax?: number | null;
  annualRebate: AnnualRebateCreateOneInput;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  id?: string | null;
  incomeConfirmationFiles?: FileCreateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  settlementRequest?: SettlementRequestCreateOneWithoutPrevEmployersInput | null;
  snapRoot?: PrevEmployerCreateOneWithoutHistoryInput | null;
  snapRootId: string;
  status?: PrevEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerSnapScalarWhereInput {
  actualDeductedTax?: number | null;
  actualDeductedTax_gt?: number | null;
  actualDeductedTax_gte?: number | null;
  actualDeductedTax_in?: number[] | null;
  actualDeductedTax_lt?: number | null;
  actualDeductedTax_lte?: number | null;
  actualDeductedTax_not?: number | null;
  actualDeductedTax_not_in?: number[] | null;
  AND?: PrevEmployerSnapScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  companyName?: string | null;
  companyName_contains?: string | null;
  companyName_ends_with?: string | null;
  companyName_gt?: string | null;
  companyName_gte?: string | null;
  companyName_in?: string[] | null;
  companyName_lt?: string | null;
  companyName_lte?: string | null;
  companyName_not?: string | null;
  companyName_not_contains?: string | null;
  companyName_not_ends_with?: string | null;
  companyName_not_in?: string[] | null;
  companyName_not_starts_with?: string | null;
  companyName_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  dateOfEmployment?: any | null;
  dateOfEmployment_gt?: any | null;
  dateOfEmployment_gte?: any | null;
  dateOfEmployment_in?: any[] | null;
  dateOfEmployment_lt?: any | null;
  dateOfEmployment_lte?: any | null;
  dateOfEmployment_not?: any | null;
  dateOfEmployment_not_in?: any[] | null;
  dateOfQuit?: any | null;
  dateOfQuit_gt?: any | null;
  dateOfQuit_gte?: any | null;
  dateOfQuit_in?: any[] | null;
  dateOfQuit_lt?: any | null;
  dateOfQuit_lte?: any | null;
  dateOfQuit_not?: any | null;
  dateOfQuit_not_in?: any[] | null;
  deleteFlag?: boolean | null;
  deleteFlag_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  incomeInsuranceSum?: number | null;
  incomeInsuranceSum_gt?: number | null;
  incomeInsuranceSum_gte?: number | null;
  incomeInsuranceSum_in?: number[] | null;
  incomeInsuranceSum_lt?: number | null;
  incomeInsuranceSum_lte?: number | null;
  incomeInsuranceSum_not?: number | null;
  incomeInsuranceSum_not_in?: number[] | null;
  lifeInsuranceContribution?: number | null;
  lifeInsuranceContribution_gt?: number | null;
  lifeInsuranceContribution_gte?: number | null;
  lifeInsuranceContribution_in?: number[] | null;
  lifeInsuranceContribution_lt?: number | null;
  lifeInsuranceContribution_lte?: number | null;
  lifeInsuranceContribution_not?: number | null;
  lifeInsuranceContribution_not_in?: number[] | null;
  NOT?: PrevEmployerSnapScalarWhereInput[] | null;
  OR?: PrevEmployerSnapScalarWhereInput[] | null;
  snapRootId?: string | null;
  snapRootId_contains?: string | null;
  snapRootId_ends_with?: string | null;
  snapRootId_gt?: string | null;
  snapRootId_gte?: string | null;
  snapRootId_in?: string[] | null;
  snapRootId_lt?: string | null;
  snapRootId_lte?: string | null;
  snapRootId_not?: string | null;
  snapRootId_not_contains?: string | null;
  snapRootId_not_ends_with?: string | null;
  snapRootId_not_in?: string[] | null;
  snapRootId_not_starts_with?: string | null;
  snapRootId_starts_with?: string | null;
  status?: PrevEmployerStatus | null;
  status_in?: PrevEmployerStatus[] | null;
  status_not?: PrevEmployerStatus | null;
  status_not_in?: PrevEmployerStatus[] | null;
  totalMonthlyTaxBonuses?: number | null;
  totalMonthlyTaxBonuses_gt?: number | null;
  totalMonthlyTaxBonuses_gte?: number | null;
  totalMonthlyTaxBonuses_in?: number[] | null;
  totalMonthlyTaxBonuses_lt?: number | null;
  totalMonthlyTaxBonuses_lte?: number | null;
  totalMonthlyTaxBonuses_not?: number | null;
  totalMonthlyTaxBonuses_not_in?: number[] | null;
  totalRevenue?: number | null;
  totalRevenue_gt?: number | null;
  totalRevenue_gte?: number | null;
  totalRevenue_in?: number[] | null;
  totalRevenue_lt?: number | null;
  totalRevenue_lte?: number | null;
  totalRevenue_not?: number | null;
  totalRevenue_not_in?: number[] | null;
  totalTaxAdvance?: number | null;
  totalTaxAdvance_gt?: number | null;
  totalTaxAdvance_gte?: number | null;
  totalTaxAdvance_in?: number[] | null;
  totalTaxAdvance_lt?: number | null;
  totalTaxAdvance_lte?: number | null;
  totalTaxAdvance_not?: number | null;
  totalTaxAdvance_not_in?: number[] | null;
}

export interface PrevEmployerSnapUpdateManyDataInput {
  actualDeductedTax?: number | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  snapRootId?: string | null;
  status?: PrevEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerSnapUpdateManyWithWhereNestedInput {
  data: PrevEmployerSnapUpdateManyDataInput;
  where: PrevEmployerSnapScalarWhereInput;
}

export interface PrevEmployerSnapUpdateManyWithoutSettlementRequestInput {
  connect?: PrevEmployerSnapWhereUniqueInput[] | null;
  create?: PrevEmployerSnapCreateWithoutSettlementRequestInput[] | null;
  delete?: PrevEmployerSnapWhereUniqueInput[] | null;
  deleteMany?: PrevEmployerSnapScalarWhereInput[] | null;
  disconnect?: PrevEmployerSnapWhereUniqueInput[] | null;
  set?: PrevEmployerSnapWhereUniqueInput[] | null;
  update?: PrevEmployerSnapUpdateWithWhereUniqueWithoutSettlementRequestInput[] | null;
  updateMany?: PrevEmployerSnapUpdateManyWithWhereNestedInput[] | null;
  upsert?: PrevEmployerSnapUpsertWithWhereUniqueWithoutSettlementRequestInput[] | null;
}

export interface PrevEmployerSnapUpdateManyWithoutSnapHistoryInput {
  connect?: PrevEmployerSnapWhereUniqueInput[] | null;
  create?: PrevEmployerSnapCreateWithoutSnapHistoryInput[] | null;
  delete?: PrevEmployerSnapWhereUniqueInput[] | null;
  deleteMany?: PrevEmployerSnapScalarWhereInput[] | null;
  disconnect?: PrevEmployerSnapWhereUniqueInput[] | null;
  set?: PrevEmployerSnapWhereUniqueInput[] | null;
  update?: PrevEmployerSnapUpdateWithWhereUniqueWithoutSnapHistoryInput[] | null;
  updateMany?: PrevEmployerSnapUpdateManyWithWhereNestedInput[] | null;
  upsert?: PrevEmployerSnapUpsertWithWhereUniqueWithoutSnapHistoryInput[] | null;
}

export interface PrevEmployerSnapUpdateWithWhereUniqueWithoutSettlementRequestInput {
  data: PrevEmployerSnapUpdateWithoutSettlementRequestDataInput;
  where: PrevEmployerSnapWhereUniqueInput;
}

export interface PrevEmployerSnapUpdateWithWhereUniqueWithoutSnapHistoryInput {
  data: PrevEmployerSnapUpdateWithoutSnapHistoryDataInput;
  where: PrevEmployerSnapWhereUniqueInput;
}

export interface PrevEmployerSnapUpdateWithoutSettlementRequestDataInput {
  actualDeductedTax?: number | null;
  annualRebate?: AnnualRebateUpdateOneRequiredInput | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  incomeConfirmationFiles?: FileUpdateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  snapHistory?: PrevEmployerSnapUpdateManyWithoutSnapHistoryInput | null;
  snapRoot?: PrevEmployerUpdateOneWithoutHistoryInput | null;
  snapRootId?: string | null;
  status?: PrevEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerSnapUpdateWithoutSnapHistoryDataInput {
  actualDeductedTax?: number | null;
  annualRebate?: AnnualRebateUpdateOneRequiredInput | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  incomeConfirmationFiles?: FileUpdateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutPrevEmployersInput | null;
  snapRoot?: PrevEmployerUpdateOneWithoutHistoryInput | null;
  snapRootId?: string | null;
  status?: PrevEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerSnapUpsertWithWhereUniqueWithoutSettlementRequestInput {
  create: PrevEmployerSnapCreateWithoutSettlementRequestInput;
  update: PrevEmployerSnapUpdateWithoutSettlementRequestDataInput;
  where: PrevEmployerSnapWhereUniqueInput;
}

export interface PrevEmployerSnapUpsertWithWhereUniqueWithoutSnapHistoryInput {
  create: PrevEmployerSnapCreateWithoutSnapHistoryInput;
  update: PrevEmployerSnapUpdateWithoutSnapHistoryDataInput;
  where: PrevEmployerSnapWhereUniqueInput;
}

export interface PrevEmployerSnapWhereInput {
  actualDeductedTax?: number | null;
  actualDeductedTax_gt?: number | null;
  actualDeductedTax_gte?: number | null;
  actualDeductedTax_in?: number[] | null;
  actualDeductedTax_lt?: number | null;
  actualDeductedTax_lte?: number | null;
  actualDeductedTax_not?: number | null;
  actualDeductedTax_not_in?: number[] | null;
  AND?: PrevEmployerSnapWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  companyName?: string | null;
  companyName_contains?: string | null;
  companyName_ends_with?: string | null;
  companyName_gt?: string | null;
  companyName_gte?: string | null;
  companyName_in?: string[] | null;
  companyName_lt?: string | null;
  companyName_lte?: string | null;
  companyName_not?: string | null;
  companyName_not_contains?: string | null;
  companyName_not_ends_with?: string | null;
  companyName_not_in?: string[] | null;
  companyName_not_starts_with?: string | null;
  companyName_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  dateOfEmployment?: any | null;
  dateOfEmployment_gt?: any | null;
  dateOfEmployment_gte?: any | null;
  dateOfEmployment_in?: any[] | null;
  dateOfEmployment_lt?: any | null;
  dateOfEmployment_lte?: any | null;
  dateOfEmployment_not?: any | null;
  dateOfEmployment_not_in?: any[] | null;
  dateOfQuit?: any | null;
  dateOfQuit_gt?: any | null;
  dateOfQuit_gte?: any | null;
  dateOfQuit_in?: any[] | null;
  dateOfQuit_lt?: any | null;
  dateOfQuit_lte?: any | null;
  dateOfQuit_not?: any | null;
  dateOfQuit_not_in?: any[] | null;
  deleteFlag?: boolean | null;
  deleteFlag_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  incomeConfirmationFiles_every?: FileWhereInput | null;
  incomeConfirmationFiles_none?: FileWhereInput | null;
  incomeConfirmationFiles_some?: FileWhereInput | null;
  incomeInsuranceSum?: number | null;
  incomeInsuranceSum_gt?: number | null;
  incomeInsuranceSum_gte?: number | null;
  incomeInsuranceSum_in?: number[] | null;
  incomeInsuranceSum_lt?: number | null;
  incomeInsuranceSum_lte?: number | null;
  incomeInsuranceSum_not?: number | null;
  incomeInsuranceSum_not_in?: number[] | null;
  lifeInsuranceContribution?: number | null;
  lifeInsuranceContribution_gt?: number | null;
  lifeInsuranceContribution_gte?: number | null;
  lifeInsuranceContribution_in?: number[] | null;
  lifeInsuranceContribution_lt?: number | null;
  lifeInsuranceContribution_lte?: number | null;
  lifeInsuranceContribution_not?: number | null;
  lifeInsuranceContribution_not_in?: number[] | null;
  NOT?: PrevEmployerSnapWhereInput[] | null;
  OR?: PrevEmployerSnapWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  snapHistory_every?: PrevEmployerSnapWhereInput | null;
  snapHistory_none?: PrevEmployerSnapWhereInput | null;
  snapHistory_some?: PrevEmployerSnapWhereInput | null;
  snapRoot?: PrevEmployerWhereInput | null;
  snapRootId?: string | null;
  snapRootId_contains?: string | null;
  snapRootId_ends_with?: string | null;
  snapRootId_gt?: string | null;
  snapRootId_gte?: string | null;
  snapRootId_in?: string[] | null;
  snapRootId_lt?: string | null;
  snapRootId_lte?: string | null;
  snapRootId_not?: string | null;
  snapRootId_not_contains?: string | null;
  snapRootId_not_ends_with?: string | null;
  snapRootId_not_in?: string[] | null;
  snapRootId_not_starts_with?: string | null;
  snapRootId_starts_with?: string | null;
  status?: PrevEmployerStatus | null;
  status_in?: PrevEmployerStatus[] | null;
  status_not?: PrevEmployerStatus | null;
  status_not_in?: PrevEmployerStatus[] | null;
  totalMonthlyTaxBonuses?: number | null;
  totalMonthlyTaxBonuses_gt?: number | null;
  totalMonthlyTaxBonuses_gte?: number | null;
  totalMonthlyTaxBonuses_in?: number[] | null;
  totalMonthlyTaxBonuses_lt?: number | null;
  totalMonthlyTaxBonuses_lte?: number | null;
  totalMonthlyTaxBonuses_not?: number | null;
  totalMonthlyTaxBonuses_not_in?: number[] | null;
  totalRevenue?: number | null;
  totalRevenue_gt?: number | null;
  totalRevenue_gte?: number | null;
  totalRevenue_in?: number[] | null;
  totalRevenue_lt?: number | null;
  totalRevenue_lte?: number | null;
  totalRevenue_not?: number | null;
  totalRevenue_not_in?: number[] | null;
  totalTaxAdvance?: number | null;
  totalTaxAdvance_gt?: number | null;
  totalTaxAdvance_gte?: number | null;
  totalTaxAdvance_in?: number[] | null;
  totalTaxAdvance_lt?: number | null;
  totalTaxAdvance_lte?: number | null;
  totalTaxAdvance_not?: number | null;
  totalTaxAdvance_not_in?: number[] | null;
}

export interface PrevEmployerSnapWhereUniqueInput {
  id?: string | null;
}

export interface PrevEmployerUpdateManyWithoutAnnualRebateInput {
  create?: PrevEmployerCreateWithoutAnnualRebateInput[] | null;
  delete?: PrevEmployerWhereUniqueInput[] | null;
  update?: PrevEmployerUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface PrevEmployerUpdateOneWithoutHistoryInput {
  connect?: PrevEmployerWhereUniqueInput | null;
  create?: PrevEmployerCreateWithoutHistoryInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: PrevEmployerUpdateWithoutHistoryDataInput | null;
  upsert?: PrevEmployerUpsertWithoutHistoryInput | null;
}

export interface PrevEmployerUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: PrevEmployerUpdateWithoutAnnualRebateDataInput;
  where: PrevEmployerWhereUniqueInput;
}

export interface PrevEmployerUpdateWithoutAnnualRebateDataInput {
  actualDeductedTax?: number | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  incomeConfirmationFiles?: FileUpdateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerUpdateWithoutHistoryDataInput {
  actualDeductedTax?: number | null;
  annualRebate?: AnnualRebateUpdateOneRequiredWithoutPrevEmployersInput | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deleteFlag?: boolean | null;
  incomeConfirmationFiles?: FileUpdateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  status?: PrevEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PrevEmployerUpsertWithoutHistoryInput {
  create: PrevEmployerCreateWithoutHistoryInput;
  update: PrevEmployerUpdateWithoutHistoryDataInput;
}

export interface PrevEmployerWhereInput {
  actualDeductedTax?: number | null;
  actualDeductedTax_gt?: number | null;
  actualDeductedTax_gte?: number | null;
  actualDeductedTax_in?: number[] | null;
  actualDeductedTax_lt?: number | null;
  actualDeductedTax_lte?: number | null;
  actualDeductedTax_not?: number | null;
  actualDeductedTax_not_in?: number[] | null;
  AND?: PrevEmployerWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  companyName?: string | null;
  companyName_contains?: string | null;
  companyName_ends_with?: string | null;
  companyName_gt?: string | null;
  companyName_gte?: string | null;
  companyName_in?: string[] | null;
  companyName_lt?: string | null;
  companyName_lte?: string | null;
  companyName_not?: string | null;
  companyName_not_contains?: string | null;
  companyName_not_ends_with?: string | null;
  companyName_not_in?: string[] | null;
  companyName_not_starts_with?: string | null;
  companyName_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  dateOfEmployment?: any | null;
  dateOfEmployment_gt?: any | null;
  dateOfEmployment_gte?: any | null;
  dateOfEmployment_in?: any[] | null;
  dateOfEmployment_lt?: any | null;
  dateOfEmployment_lte?: any | null;
  dateOfEmployment_not?: any | null;
  dateOfEmployment_not_in?: any[] | null;
  dateOfQuit?: any | null;
  dateOfQuit_gt?: any | null;
  dateOfQuit_gte?: any | null;
  dateOfQuit_in?: any[] | null;
  dateOfQuit_lt?: any | null;
  dateOfQuit_lte?: any | null;
  dateOfQuit_not?: any | null;
  dateOfQuit_not_in?: any[] | null;
  deleteFlag?: boolean | null;
  deleteFlag_not?: boolean | null;
  history_every?: PrevEmployerSnapWhereInput | null;
  history_none?: PrevEmployerSnapWhereInput | null;
  history_some?: PrevEmployerSnapWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  incomeConfirmationFiles_every?: FileWhereInput | null;
  incomeConfirmationFiles_none?: FileWhereInput | null;
  incomeConfirmationFiles_some?: FileWhereInput | null;
  incomeInsuranceSum?: number | null;
  incomeInsuranceSum_gt?: number | null;
  incomeInsuranceSum_gte?: number | null;
  incomeInsuranceSum_in?: number[] | null;
  incomeInsuranceSum_lt?: number | null;
  incomeInsuranceSum_lte?: number | null;
  incomeInsuranceSum_not?: number | null;
  incomeInsuranceSum_not_in?: number[] | null;
  lifeInsuranceContribution?: number | null;
  lifeInsuranceContribution_gt?: number | null;
  lifeInsuranceContribution_gte?: number | null;
  lifeInsuranceContribution_in?: number[] | null;
  lifeInsuranceContribution_lt?: number | null;
  lifeInsuranceContribution_lte?: number | null;
  lifeInsuranceContribution_not?: number | null;
  lifeInsuranceContribution_not_in?: number[] | null;
  NOT?: PrevEmployerWhereInput[] | null;
  OR?: PrevEmployerWhereInput[] | null;
  status?: PrevEmployerStatus | null;
  status_in?: PrevEmployerStatus[] | null;
  status_not?: PrevEmployerStatus | null;
  status_not_in?: PrevEmployerStatus[] | null;
  totalMonthlyTaxBonuses?: number | null;
  totalMonthlyTaxBonuses_gt?: number | null;
  totalMonthlyTaxBonuses_gte?: number | null;
  totalMonthlyTaxBonuses_in?: number[] | null;
  totalMonthlyTaxBonuses_lt?: number | null;
  totalMonthlyTaxBonuses_lte?: number | null;
  totalMonthlyTaxBonuses_not?: number | null;
  totalMonthlyTaxBonuses_not_in?: number[] | null;
  totalRevenue?: number | null;
  totalRevenue_gt?: number | null;
  totalRevenue_gte?: number | null;
  totalRevenue_in?: number[] | null;
  totalRevenue_lt?: number | null;
  totalRevenue_lte?: number | null;
  totalRevenue_not?: number | null;
  totalRevenue_not_in?: number[] | null;
  totalTaxAdvance?: number | null;
  totalTaxAdvance_gt?: number | null;
  totalTaxAdvance_gte?: number | null;
  totalTaxAdvance_in?: number[] | null;
  totalTaxAdvance_lt?: number | null;
  totalTaxAdvance_lte?: number | null;
  totalTaxAdvance_not?: number | null;
  totalTaxAdvance_not_in?: number[] | null;
}

export interface PrevEmployerWhereUniqueInput {
  id: string;
}

export interface PrevEmployersSummaryInfoCreateInput {
  id?: string | null;
  usedBasicRebate?: boolean | null;
  usedBasicRebateInMonths?: PrevEmployersSummaryInfoCreateusedBasicRebateInMonthsInput | null;
  usedChildren?: PreviousChildInfoCreateManyInput | null;
  usedChildrenRebate?: boolean | null;
  usedDisabilityRebate?: boolean | null;
  usedDisabilityRebateInMonths?: PrevEmployersSummaryInfoCreateusedDisabilityRebateInMonthsInput | null;
  usedDisabilityRebateLevel?: DisabilityLevel | null;
  usedStudentRebate?: boolean | null;
  usedStudentRebateInMonths?: PrevEmployersSummaryInfoCreateusedStudentRebateInMonthsInput | null;
  usedZtppRebate?: boolean | null;
  usedZtppRebateInMonths?: PrevEmployersSummaryInfoCreateusedZtppRebateInMonthsInput | null;
}

export interface PrevEmployersSummaryInfoCreateOneInput {
  connect?: PrevEmployersSummaryInfoWhereUniqueInput | null;
  create?: PrevEmployersSummaryInfoCreateInput | null;
}

export interface PrevEmployersSummaryInfoCreateusedBasicRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoCreateusedDisabilityRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoCreateusedStudentRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoCreateusedZtppRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoUpdateDataInput {
  usedBasicRebate?: boolean | null;
  usedBasicRebateInMonths?: PrevEmployersSummaryInfoUpdateusedBasicRebateInMonthsInput | null;
  usedChildren?: PreviousChildInfoUpdateManyInput | null;
  usedChildrenRebate?: boolean | null;
  usedDisabilityRebate?: boolean | null;
  usedDisabilityRebateInMonths?: PrevEmployersSummaryInfoUpdateusedDisabilityRebateInMonthsInput | null;
  usedDisabilityRebateLevel?: DisabilityLevel | null;
  usedStudentRebate?: boolean | null;
  usedStudentRebateInMonths?: PrevEmployersSummaryInfoUpdateusedStudentRebateInMonthsInput | null;
  usedZtppRebate?: boolean | null;
  usedZtppRebateInMonths?: PrevEmployersSummaryInfoUpdateusedZtppRebateInMonthsInput | null;
}

export interface PrevEmployersSummaryInfoUpdateInput {
  usedBasicRebate?: boolean | null;
  usedBasicRebateInMonths?: PrevEmployersSummaryInfoUpdateusedBasicRebateInMonthsInput | null;
  usedChildren?: PreviousChildInfoUpdateManyInput | null;
  usedChildrenRebate?: boolean | null;
  usedDisabilityRebate?: boolean | null;
  usedDisabilityRebateInMonths?: PrevEmployersSummaryInfoUpdateusedDisabilityRebateInMonthsInput | null;
  usedDisabilityRebateLevel?: DisabilityLevel | null;
  usedStudentRebate?: boolean | null;
  usedStudentRebateInMonths?: PrevEmployersSummaryInfoUpdateusedStudentRebateInMonthsInput | null;
  usedZtppRebate?: boolean | null;
  usedZtppRebateInMonths?: PrevEmployersSummaryInfoUpdateusedZtppRebateInMonthsInput | null;
}

export interface PrevEmployersSummaryInfoUpdateOneRequiredInput {
  connect?: PrevEmployersSummaryInfoWhereUniqueInput | null;
  create?: PrevEmployersSummaryInfoCreateInput | null;
  update?: PrevEmployersSummaryInfoUpdateDataInput | null;
  upsert?: PrevEmployersSummaryInfoUpsertNestedInput | null;
}

export interface PrevEmployersSummaryInfoUpdateusedBasicRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoUpdateusedDisabilityRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoUpdateusedStudentRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoUpdateusedZtppRebateInMonthsInput {
  set?: Month[] | null;
}

export interface PrevEmployersSummaryInfoUpsertNestedInput {
  create: PrevEmployersSummaryInfoCreateInput;
  update: PrevEmployersSummaryInfoUpdateDataInput;
}

export interface PrevEmployersSummaryInfoWhereInput {
  AND?: PrevEmployersSummaryInfoWhereInput[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: PrevEmployersSummaryInfoWhereInput[] | null;
  OR?: PrevEmployersSummaryInfoWhereInput[] | null;
  usedBasicRebate?: boolean | null;
  usedBasicRebate_not?: boolean | null;
  usedChildren_every?: PreviousChildInfoWhereInput | null;
  usedChildren_none?: PreviousChildInfoWhereInput | null;
  usedChildren_some?: PreviousChildInfoWhereInput | null;
  usedChildrenRebate?: boolean | null;
  usedChildrenRebate_not?: boolean | null;
  usedDisabilityRebate?: boolean | null;
  usedDisabilityRebate_not?: boolean | null;
  usedDisabilityRebateLevel?: DisabilityLevel | null;
  usedDisabilityRebateLevel_in?: DisabilityLevel[] | null;
  usedDisabilityRebateLevel_not?: DisabilityLevel | null;
  usedDisabilityRebateLevel_not_in?: DisabilityLevel[] | null;
  usedStudentRebate?: boolean | null;
  usedStudentRebate_not?: boolean | null;
  usedZtppRebate?: boolean | null;
  usedZtppRebate_not?: boolean | null;
}

export interface PrevEmployersSummaryInfoWhereUniqueInput {
  id?: string | null;
}

export interface PrevEmployersUpdateInput {
  hasPrevEmployers?: boolean | null;
  prevEmployers?: PrevEmployerUpdateManyWithoutAnnualRebateInput | null;
  prevEmployersTouched?: boolean | null;
}

export interface PrevYearPeriodCreateInput {
  from?: any | null;
  to?: any | null;
}

export interface PrevYearPeriodDisabilityCreateInput {
  disabilityLevel: DisabilityLevel;
  from?: any | null;
  to?: any | null;
}

export interface PrevYearPeriodDisabilityInput {
  create?: PrevYearPeriodDisabilityCreateInput[] | null;
  delete?: WhereIdInput[] | null;
  update?: PrevYearPeriodDisabilityWithWhereIdUpdateInput[] | null;
}

export interface PrevYearPeriodDisabilityUpdateInput {
  disabilityLevel?: DisabilityLevel | null;
  from?: any | null;
  to?: any | null;
}

export interface PrevYearPeriodDisabilityWithWhereIdUpdateInput {
  data: PrevYearPeriodDisabilityUpdateInput;
  where: WhereIdInput;
}

export interface PrevYearPeriodInput {
  create?: PrevYearPeriodCreateInput[] | null;
  delete?: WhereIdInput[] | null;
  update?: PrevYearPeriodWithWhereIdUpdateInput[] | null;
}

export interface PrevYearPeriodUpdateInput {
  from?: any | null;
  to?: any | null;
}

export interface PrevYearPeriodWithWhereIdUpdateInput {
  data: PrevYearPeriodUpdateInput;
  where: WhereIdInput;
}

export interface PreviousChildInfoCreateInput {
  id?: string | null;
  usedInMonths?: PreviousChildInfoCreateusedInMonthsInput | null;
  ztpp?: boolean | null;
}

export interface PreviousChildInfoCreateManyInput {
  connect?: PreviousChildInfoWhereUniqueInput[] | null;
  create?: PreviousChildInfoCreateInput[] | null;
}

export interface PreviousChildInfoCreateusedInMonthsInput {
  set?: Month[] | null;
}

export interface PreviousChildInfoScalarWhereInput {
  AND?: PreviousChildInfoScalarWhereInput[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: PreviousChildInfoScalarWhereInput[] | null;
  OR?: PreviousChildInfoScalarWhereInput[] | null;
  ztpp?: boolean | null;
  ztpp_not?: boolean | null;
}

export interface PreviousChildInfoUpdateDataInput {
  usedInMonths?: PreviousChildInfoUpdateusedInMonthsInput | null;
  ztpp?: boolean | null;
}

export interface PreviousChildInfoUpdateManyDataInput {
  usedInMonths?: PreviousChildInfoUpdateusedInMonthsInput | null;
  ztpp?: boolean | null;
}

export interface PreviousChildInfoUpdateManyInput {
  connect?: PreviousChildInfoWhereUniqueInput[] | null;
  create?: PreviousChildInfoCreateInput[] | null;
  delete?: PreviousChildInfoWhereUniqueInput[] | null;
  deleteMany?: PreviousChildInfoScalarWhereInput[] | null;
  disconnect?: PreviousChildInfoWhereUniqueInput[] | null;
  set?: PreviousChildInfoWhereUniqueInput[] | null;
  update?: PreviousChildInfoUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: PreviousChildInfoUpdateManyWithWhereNestedInput[] | null;
  upsert?: PreviousChildInfoUpsertWithWhereUniqueNestedInput[] | null;
}

export interface PreviousChildInfoUpdateManyWithWhereNestedInput {
  data: PreviousChildInfoUpdateManyDataInput;
  where: PreviousChildInfoScalarWhereInput;
}

export interface PreviousChildInfoUpdateWithWhereUniqueNestedInput {
  data: PreviousChildInfoUpdateDataInput;
  where: PreviousChildInfoWhereUniqueInput;
}

export interface PreviousChildInfoUpdateusedInMonthsInput {
  set?: Month[] | null;
}

export interface PreviousChildInfoUpsertWithWhereUniqueNestedInput {
  create: PreviousChildInfoCreateInput;
  update: PreviousChildInfoUpdateDataInput;
  where: PreviousChildInfoWhereUniqueInput;
}

export interface PreviousChildInfoWhereInput {
  AND?: PreviousChildInfoWhereInput[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: PreviousChildInfoWhereInput[] | null;
  OR?: PreviousChildInfoWhereInput[] | null;
  ztpp?: boolean | null;
  ztpp_not?: boolean | null;
}

export interface PreviousChildInfoWhereUniqueInput {
  id?: string | null;
}

export interface PreviousEmployerCreateInput {
  actualDeductedTax?: number | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  id?: string | null;
  incomeConfirmationFiles?: FileCreateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  settlementRequest?: SettlementRequestCreateOneWithoutEmployersInput | null;
  status?: PreviousEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PreviousEmployerCreateManyInput {
  connect?: PreviousEmployerWhereUniqueInput[] | null;
  create?: PreviousEmployerCreateInput[] | null;
}

export interface PreviousEmployerCreateManyWithoutSettlementRequestInput {
  connect?: PreviousEmployerWhereUniqueInput[] | null;
  create?: PreviousEmployerCreateWithoutSettlementRequestInput[] | null;
}

export interface PreviousEmployerCreateWithoutSettlementRequestInput {
  actualDeductedTax?: number | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  id?: string | null;
  incomeConfirmationFiles?: FileCreateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  status?: PreviousEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PreviousEmployerScalarWhereInput {
  actualDeductedTax?: number | null;
  actualDeductedTax_gt?: number | null;
  actualDeductedTax_gte?: number | null;
  actualDeductedTax_in?: number[] | null;
  actualDeductedTax_lt?: number | null;
  actualDeductedTax_lte?: number | null;
  actualDeductedTax_not?: number | null;
  actualDeductedTax_not_in?: number[] | null;
  AND?: PreviousEmployerScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  companyName?: string | null;
  companyName_contains?: string | null;
  companyName_ends_with?: string | null;
  companyName_gt?: string | null;
  companyName_gte?: string | null;
  companyName_in?: string[] | null;
  companyName_lt?: string | null;
  companyName_lte?: string | null;
  companyName_not?: string | null;
  companyName_not_contains?: string | null;
  companyName_not_ends_with?: string | null;
  companyName_not_in?: string[] | null;
  companyName_not_starts_with?: string | null;
  companyName_starts_with?: string | null;
  dateOfEmployment?: any | null;
  dateOfEmployment_gt?: any | null;
  dateOfEmployment_gte?: any | null;
  dateOfEmployment_in?: any[] | null;
  dateOfEmployment_lt?: any | null;
  dateOfEmployment_lte?: any | null;
  dateOfEmployment_not?: any | null;
  dateOfEmployment_not_in?: any[] | null;
  dateOfQuit?: any | null;
  dateOfQuit_gt?: any | null;
  dateOfQuit_gte?: any | null;
  dateOfQuit_in?: any[] | null;
  dateOfQuit_lt?: any | null;
  dateOfQuit_lte?: any | null;
  dateOfQuit_not?: any | null;
  dateOfQuit_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  incomeInsuranceSum?: number | null;
  incomeInsuranceSum_gt?: number | null;
  incomeInsuranceSum_gte?: number | null;
  incomeInsuranceSum_in?: number[] | null;
  incomeInsuranceSum_lt?: number | null;
  incomeInsuranceSum_lte?: number | null;
  incomeInsuranceSum_not?: number | null;
  incomeInsuranceSum_not_in?: number[] | null;
  lifeInsuranceContribution?: number | null;
  lifeInsuranceContribution_gt?: number | null;
  lifeInsuranceContribution_gte?: number | null;
  lifeInsuranceContribution_in?: number[] | null;
  lifeInsuranceContribution_lt?: number | null;
  lifeInsuranceContribution_lte?: number | null;
  lifeInsuranceContribution_not?: number | null;
  lifeInsuranceContribution_not_in?: number[] | null;
  NOT?: PreviousEmployerScalarWhereInput[] | null;
  OR?: PreviousEmployerScalarWhereInput[] | null;
  status?: PreviousEmployerStatus | null;
  status_in?: PreviousEmployerStatus[] | null;
  status_not?: PreviousEmployerStatus | null;
  status_not_in?: PreviousEmployerStatus[] | null;
  totalMonthlyTaxBonuses?: number | null;
  totalMonthlyTaxBonuses_gt?: number | null;
  totalMonthlyTaxBonuses_gte?: number | null;
  totalMonthlyTaxBonuses_in?: number[] | null;
  totalMonthlyTaxBonuses_lt?: number | null;
  totalMonthlyTaxBonuses_lte?: number | null;
  totalMonthlyTaxBonuses_not?: number | null;
  totalMonthlyTaxBonuses_not_in?: number[] | null;
  totalRevenue?: number | null;
  totalRevenue_gt?: number | null;
  totalRevenue_gte?: number | null;
  totalRevenue_in?: number[] | null;
  totalRevenue_lt?: number | null;
  totalRevenue_lte?: number | null;
  totalRevenue_not?: number | null;
  totalRevenue_not_in?: number[] | null;
  totalTaxAdvance?: number | null;
  totalTaxAdvance_gt?: number | null;
  totalTaxAdvance_gte?: number | null;
  totalTaxAdvance_in?: number[] | null;
  totalTaxAdvance_lt?: number | null;
  totalTaxAdvance_lte?: number | null;
  totalTaxAdvance_not?: number | null;
  totalTaxAdvance_not_in?: number[] | null;
}

export interface PreviousEmployerUpdateDataInput {
  actualDeductedTax?: number | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  incomeConfirmationFiles?: FileUpdateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutEmployersInput | null;
  status?: PreviousEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PreviousEmployerUpdateManyDataInput {
  actualDeductedTax?: number | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  status?: PreviousEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PreviousEmployerUpdateManyInput {
  connect?: PreviousEmployerWhereUniqueInput[] | null;
  create?: PreviousEmployerCreateInput[] | null;
  delete?: PreviousEmployerWhereUniqueInput[] | null;
  deleteMany?: PreviousEmployerScalarWhereInput[] | null;
  disconnect?: PreviousEmployerWhereUniqueInput[] | null;
  set?: PreviousEmployerWhereUniqueInput[] | null;
  update?: PreviousEmployerUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: PreviousEmployerUpdateManyWithWhereNestedInput[] | null;
  upsert?: PreviousEmployerUpsertWithWhereUniqueNestedInput[] | null;
}

export interface PreviousEmployerUpdateManyWithWhereNestedInput {
  data: PreviousEmployerUpdateManyDataInput;
  where: PreviousEmployerScalarWhereInput;
}

export interface PreviousEmployerUpdateManyWithoutSettlementRequestInput {
  connect?: PreviousEmployerWhereUniqueInput[] | null;
  create?: PreviousEmployerCreateWithoutSettlementRequestInput[] | null;
  delete?: PreviousEmployerWhereUniqueInput[] | null;
  deleteMany?: PreviousEmployerScalarWhereInput[] | null;
  disconnect?: PreviousEmployerWhereUniqueInput[] | null;
  set?: PreviousEmployerWhereUniqueInput[] | null;
  update?: PreviousEmployerUpdateWithWhereUniqueWithoutSettlementRequestInput[] | null;
  updateMany?: PreviousEmployerUpdateManyWithWhereNestedInput[] | null;
  upsert?: PreviousEmployerUpsertWithWhereUniqueWithoutSettlementRequestInput[] | null;
}

export interface PreviousEmployerUpdateWithWhereUniqueNestedInput {
  data: PreviousEmployerUpdateDataInput;
  where: PreviousEmployerWhereUniqueInput;
}

export interface PreviousEmployerUpdateWithWhereUniqueWithoutSettlementRequestInput {
  data: PreviousEmployerUpdateWithoutSettlementRequestDataInput;
  where: PreviousEmployerWhereUniqueInput;
}

export interface PreviousEmployerUpdateWithoutSettlementRequestDataInput {
  actualDeductedTax?: number | null;
  commentOfDenied?: string | null;
  companyName?: string | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  incomeConfirmationFiles?: FileUpdateManyInput | null;
  incomeInsuranceSum?: number | null;
  lifeInsuranceContribution?: number | null;
  status?: PreviousEmployerStatus | null;
  totalMonthlyTaxBonuses?: number | null;
  totalRevenue?: number | null;
  totalTaxAdvance?: number | null;
}

export interface PreviousEmployerUpsertWithWhereUniqueNestedInput {
  create: PreviousEmployerCreateInput;
  update: PreviousEmployerUpdateDataInput;
  where: PreviousEmployerWhereUniqueInput;
}

export interface PreviousEmployerUpsertWithWhereUniqueWithoutSettlementRequestInput {
  create: PreviousEmployerCreateWithoutSettlementRequestInput;
  update: PreviousEmployerUpdateWithoutSettlementRequestDataInput;
  where: PreviousEmployerWhereUniqueInput;
}

export interface PreviousEmployerWhereInput {
  actualDeductedTax?: number | null;
  actualDeductedTax_gt?: number | null;
  actualDeductedTax_gte?: number | null;
  actualDeductedTax_in?: number[] | null;
  actualDeductedTax_lt?: number | null;
  actualDeductedTax_lte?: number | null;
  actualDeductedTax_not?: number | null;
  actualDeductedTax_not_in?: number[] | null;
  AND?: PreviousEmployerWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  companyName?: string | null;
  companyName_contains?: string | null;
  companyName_ends_with?: string | null;
  companyName_gt?: string | null;
  companyName_gte?: string | null;
  companyName_in?: string[] | null;
  companyName_lt?: string | null;
  companyName_lte?: string | null;
  companyName_not?: string | null;
  companyName_not_contains?: string | null;
  companyName_not_ends_with?: string | null;
  companyName_not_in?: string[] | null;
  companyName_not_starts_with?: string | null;
  companyName_starts_with?: string | null;
  dateOfEmployment?: any | null;
  dateOfEmployment_gt?: any | null;
  dateOfEmployment_gte?: any | null;
  dateOfEmployment_in?: any[] | null;
  dateOfEmployment_lt?: any | null;
  dateOfEmployment_lte?: any | null;
  dateOfEmployment_not?: any | null;
  dateOfEmployment_not_in?: any[] | null;
  dateOfQuit?: any | null;
  dateOfQuit_gt?: any | null;
  dateOfQuit_gte?: any | null;
  dateOfQuit_in?: any[] | null;
  dateOfQuit_lt?: any | null;
  dateOfQuit_lte?: any | null;
  dateOfQuit_not?: any | null;
  dateOfQuit_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  incomeConfirmationFiles_every?: FileWhereInput | null;
  incomeConfirmationFiles_none?: FileWhereInput | null;
  incomeConfirmationFiles_some?: FileWhereInput | null;
  incomeInsuranceSum?: number | null;
  incomeInsuranceSum_gt?: number | null;
  incomeInsuranceSum_gte?: number | null;
  incomeInsuranceSum_in?: number[] | null;
  incomeInsuranceSum_lt?: number | null;
  incomeInsuranceSum_lte?: number | null;
  incomeInsuranceSum_not?: number | null;
  incomeInsuranceSum_not_in?: number[] | null;
  lifeInsuranceContribution?: number | null;
  lifeInsuranceContribution_gt?: number | null;
  lifeInsuranceContribution_gte?: number | null;
  lifeInsuranceContribution_in?: number[] | null;
  lifeInsuranceContribution_lt?: number | null;
  lifeInsuranceContribution_lte?: number | null;
  lifeInsuranceContribution_not?: number | null;
  lifeInsuranceContribution_not_in?: number[] | null;
  NOT?: PreviousEmployerWhereInput[] | null;
  OR?: PreviousEmployerWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: PreviousEmployerStatus | null;
  status_in?: PreviousEmployerStatus[] | null;
  status_not?: PreviousEmployerStatus | null;
  status_not_in?: PreviousEmployerStatus[] | null;
  totalMonthlyTaxBonuses?: number | null;
  totalMonthlyTaxBonuses_gt?: number | null;
  totalMonthlyTaxBonuses_gte?: number | null;
  totalMonthlyTaxBonuses_in?: number[] | null;
  totalMonthlyTaxBonuses_lt?: number | null;
  totalMonthlyTaxBonuses_lte?: number | null;
  totalMonthlyTaxBonuses_not?: number | null;
  totalMonthlyTaxBonuses_not_in?: number[] | null;
  totalRevenue?: number | null;
  totalRevenue_gt?: number | null;
  totalRevenue_gte?: number | null;
  totalRevenue_in?: number[] | null;
  totalRevenue_lt?: number | null;
  totalRevenue_lte?: number | null;
  totalRevenue_not?: number | null;
  totalRevenue_not_in?: number[] | null;
  totalTaxAdvance?: number | null;
  totalTaxAdvance_gt?: number | null;
  totalTaxAdvance_gte?: number | null;
  totalTaxAdvance_in?: number[] | null;
  totalTaxAdvance_lt?: number | null;
  totalTaxAdvance_lte?: number | null;
  totalTaxAdvance_not?: number | null;
  totalTaxAdvance_not_in?: number[] | null;
}

export interface PreviousEmployerWhereUniqueInput {
  id?: string | null;
}

export interface SettlementRequestCreateManyWithoutAnnualRebateArchiveInput {
  connect?: SettlementRequestWhereUniqueInput[] | null;
  create?: SettlementRequestCreateWithoutAnnualRebateArchiveInput[] | null;
}

export interface SettlementRequestCreateManyWithoutEmployeeInput {
  connect?: SettlementRequestWhereUniqueInput[] | null;
  create?: SettlementRequestCreateWithoutEmployeeInput[] | null;
}

export interface SettlementRequestCreateManyWithoutMonthlyRebateArchiveInput {
  connect?: SettlementRequestWhereUniqueInput[] | null;
  create?: SettlementRequestCreateWithoutMonthlyRebateArchiveInput[] | null;
}

export interface SettlementRequestCreateOneWithoutAnnualRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutAnnualRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutBasicRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutBasicRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutChildrenRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutChildrenRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutDisabilityRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutDisabilityRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutEmployersInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutEmployersInput | null;
}

export interface SettlementRequestCreateOneWithoutExamRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutExamRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutForeclosureRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutForeclosureRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutGiftRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutGiftRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutInvestmentRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutInvestmentRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutLifeInsuranceRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutLifeInsuranceRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutLoanRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutLoanRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutLongTermCareRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutLongTermCareRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutMonthlyRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutMonthlyRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutMonthlyTaxQuestionnaireInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutMonthlyTaxQuestionnaireInput | null;
}

export interface SettlementRequestCreateOneWithoutPensionInsuranceRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutPensionInsuranceRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutPreschoolRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutPreschoolRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutPrevEmployersInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutPrevEmployersInput | null;
}

export interface SettlementRequestCreateOneWithoutSpouseRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutSpouseRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutStudentRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutStudentRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutTaxQuestionnaireInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutTaxQuestionnaireInput | null;
}

export interface SettlementRequestCreateOneWithoutUnionRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutUnionRebateInput | null;
}

export interface SettlementRequestCreateOneWithoutZtppRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutZtppRebateInput | null;
}

export interface SettlementRequestCreateWithoutAnnualRebateArchiveInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutAnnualRebateInput {
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutBasicRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutChildrenRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutDisabilityRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutEmployeeInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutEmployersInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutExamRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutForeclosureRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutGiftRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutInvestmentRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutLifeInsuranceRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutLoanRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutLongTermCareRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutMonthlyRebateArchiveInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutMonthlyRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutMonthlyTaxQuestionnaireInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutPensionInsuranceRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutPreschoolRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutPrevEmployersInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutSpouseRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutStudentRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutTaxQuestionnaireInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutUnionRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  year: number;
  ztppRebate?: ZtppRebateCreateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestCreateWithoutZtppRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateCreateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateCreateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateCreateOneWithoutSettlementRequestInput | null;
  customer?: CustomerCreateOneInput | null;
  disabilityRebate?: DisabilityRebateCreateOneWithoutSettlementRequestInput | null;
  employee: UserCreateOneWithoutSettlementRequestsInput;
  employers?: PreviousEmployerCreateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateCreateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateCreateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateCreateOneWithoutSettlementRequestInput | null;
  id?: string | null;
  investmentRebate?: InvestmentRebateCreateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateCreateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateCreateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateCreateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateCreateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapCreateManyWithoutSettlementRequestInput | null;
  requestedAt: any;
  residenceAuthorisationDocumentApproval?: DocumentApprovalCreateOneInput | null;
  spouseRebate?: SpouseRebateCreateOneWithoutSettlementRequestInput | null;
  status: SettlementRequestStatus;
  studentRebate?: StudentRebateCreateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserCreateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalCreateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput | null;
  type: SettlementRequestType;
  unionRebate?: UnionRebateCreateOneWithoutSettlementRequestInput | null;
  year: number;
}

export interface SettlementRequestScalarWhereInput {
  AND?: SettlementRequestScalarWhereInput[] | null;
  canceledAt?: any | null;
  canceledAt_gt?: any | null;
  canceledAt_gte?: any | null;
  canceledAt_in?: any[] | null;
  canceledAt_lt?: any | null;
  canceledAt_lte?: any | null;
  canceledAt_not?: any | null;
  canceledAt_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: SettlementRequestScalarWhereInput[] | null;
  OR?: SettlementRequestScalarWhereInput[] | null;
  requestedAt?: any | null;
  requestedAt_gt?: any | null;
  requestedAt_gte?: any | null;
  requestedAt_in?: any[] | null;
  requestedAt_lt?: any | null;
  requestedAt_lte?: any | null;
  requestedAt_not?: any | null;
  requestedAt_not_in?: any[] | null;
  status?: SettlementRequestStatus | null;
  status_in?: SettlementRequestStatus[] | null;
  status_not?: SettlementRequestStatus | null;
  status_not_in?: SettlementRequestStatus[] | null;
  submittedAt?: any | null;
  submittedAt_gt?: any | null;
  submittedAt_gte?: any | null;
  submittedAt_in?: any[] | null;
  submittedAt_lt?: any | null;
  submittedAt_lte?: any | null;
  submittedAt_not?: any | null;
  submittedAt_not_in?: any[] | null;
  synthetic?: boolean | null;
  synthetic_not?: boolean | null;
  type?: SettlementRequestType | null;
  type_in?: SettlementRequestType[] | null;
  type_not?: SettlementRequestType | null;
  type_not_in?: SettlementRequestType[] | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
  year?: number | null;
  year_gt?: number | null;
  year_gte?: number | null;
  year_in?: number[] | null;
  year_lt?: number | null;
  year_lte?: number | null;
  year_not?: number | null;
  year_not_in?: number[] | null;
}

export interface SettlementRequestUpdateManyDataInput {
  canceledAt?: any | null;
  requestedAt?: any | null;
  status?: SettlementRequestStatus | null;
  submittedAt?: any | null;
  synthetic?: boolean | null;
  type?: SettlementRequestType | null;
  year?: number | null;
}

export interface SettlementRequestUpdateManyWithWhereNestedInput {
  data: SettlementRequestUpdateManyDataInput;
  where: SettlementRequestScalarWhereInput;
}

export interface SettlementRequestUpdateManyWithoutAnnualRebateArchiveInput {
  connect?: SettlementRequestWhereUniqueInput[] | null;
  create?: SettlementRequestCreateWithoutAnnualRebateArchiveInput[] | null;
  delete?: SettlementRequestWhereUniqueInput[] | null;
  deleteMany?: SettlementRequestScalarWhereInput[] | null;
  disconnect?: SettlementRequestWhereUniqueInput[] | null;
  set?: SettlementRequestWhereUniqueInput[] | null;
  update?: SettlementRequestUpdateWithWhereUniqueWithoutAnnualRebateArchiveInput[] | null;
  updateMany?: SettlementRequestUpdateManyWithWhereNestedInput[] | null;
  upsert?: SettlementRequestUpsertWithWhereUniqueWithoutAnnualRebateArchiveInput[] | null;
}

export interface SettlementRequestUpdateManyWithoutEmployeeInput {
  connect?: SettlementRequestWhereUniqueInput[] | null;
  create?: SettlementRequestCreateWithoutEmployeeInput[] | null;
  delete?: SettlementRequestWhereUniqueInput[] | null;
  deleteMany?: SettlementRequestScalarWhereInput[] | null;
  disconnect?: SettlementRequestWhereUniqueInput[] | null;
  set?: SettlementRequestWhereUniqueInput[] | null;
  update?: SettlementRequestUpdateWithWhereUniqueWithoutEmployeeInput[] | null;
  updateMany?: SettlementRequestUpdateManyWithWhereNestedInput[] | null;
  upsert?: SettlementRequestUpsertWithWhereUniqueWithoutEmployeeInput[] | null;
}

export interface SettlementRequestUpdateManyWithoutMonthlyRebateArchiveInput {
  connect?: SettlementRequestWhereUniqueInput[] | null;
  create?: SettlementRequestCreateWithoutMonthlyRebateArchiveInput[] | null;
  delete?: SettlementRequestWhereUniqueInput[] | null;
  deleteMany?: SettlementRequestScalarWhereInput[] | null;
  disconnect?: SettlementRequestWhereUniqueInput[] | null;
  set?: SettlementRequestWhereUniqueInput[] | null;
  update?: SettlementRequestUpdateWithWhereUniqueWithoutMonthlyRebateArchiveInput[] | null;
  updateMany?: SettlementRequestUpdateManyWithWhereNestedInput[] | null;
  upsert?: SettlementRequestUpsertWithWhereUniqueWithoutMonthlyRebateArchiveInput[] | null;
}

export interface SettlementRequestUpdateOneRequiredWithoutMonthlyTaxQuestionnaireInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutMonthlyTaxQuestionnaireInput | null;
  update?: SettlementRequestUpdateWithoutMonthlyTaxQuestionnaireDataInput | null;
  upsert?: SettlementRequestUpsertWithoutMonthlyTaxQuestionnaireInput | null;
}

export interface SettlementRequestUpdateOneRequiredWithoutTaxQuestionnaireInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutTaxQuestionnaireInput | null;
  update?: SettlementRequestUpdateWithoutTaxQuestionnaireDataInput | null;
  upsert?: SettlementRequestUpsertWithoutTaxQuestionnaireInput | null;
}

export interface SettlementRequestUpdateOneWithoutAnnualRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutAnnualRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutAnnualRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutAnnualRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutBasicRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutBasicRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutBasicRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutBasicRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutChildrenRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutChildrenRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutChildrenRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutChildrenRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutDisabilityRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutDisabilityRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutDisabilityRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutDisabilityRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutEmployersInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutEmployersInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutEmployersDataInput | null;
  upsert?: SettlementRequestUpsertWithoutEmployersInput | null;
}

export interface SettlementRequestUpdateOneWithoutExamRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutExamRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutExamRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutExamRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutForeclosureRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutForeclosureRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutForeclosureRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutForeclosureRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutGiftRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutGiftRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutGiftRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutGiftRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutInvestmentRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutInvestmentRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutInvestmentRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutInvestmentRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutLifeInsuranceRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutLifeInsuranceRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutLifeInsuranceRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutLifeInsuranceRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutLoanRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutLoanRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutLoanRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutLoanRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutLongTermCareRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutLongTermCareRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutLongTermCareRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutLongTermCareRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutMonthlyRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutMonthlyRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutMonthlyRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutMonthlyRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutPensionInsuranceRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutPensionInsuranceRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutPensionInsuranceRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutPensionInsuranceRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutPreschoolRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutPreschoolRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutPreschoolRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutPreschoolRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutPrevEmployersInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutPrevEmployersInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutPrevEmployersDataInput | null;
  upsert?: SettlementRequestUpsertWithoutPrevEmployersInput | null;
}

export interface SettlementRequestUpdateOneWithoutSpouseRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutSpouseRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutSpouseRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutSpouseRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutStudentRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutStudentRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutStudentRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutStudentRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutUnionRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutUnionRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutUnionRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutUnionRebateInput | null;
}

export interface SettlementRequestUpdateOneWithoutZtppRebateInput {
  connect?: SettlementRequestWhereUniqueInput | null;
  create?: SettlementRequestCreateWithoutZtppRebateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SettlementRequestUpdateWithoutZtppRebateDataInput | null;
  upsert?: SettlementRequestUpsertWithoutZtppRebateInput | null;
}

export interface SettlementRequestUpdateWithWhereUniqueWithoutAnnualRebateArchiveInput {
  data: SettlementRequestUpdateWithoutAnnualRebateArchiveDataInput;
  where: SettlementRequestWhereUniqueInput;
}

export interface SettlementRequestUpdateWithWhereUniqueWithoutEmployeeInput {
  data: SettlementRequestUpdateWithoutEmployeeDataInput;
  where: SettlementRequestWhereUniqueInput;
}

export interface SettlementRequestUpdateWithWhereUniqueWithoutMonthlyRebateArchiveInput {
  data: SettlementRequestUpdateWithoutMonthlyRebateArchiveDataInput;
  where: SettlementRequestWhereUniqueInput;
}

export interface SettlementRequestUpdateWithoutAnnualRebateArchiveDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutAnnualRebateDataInput {
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutBasicRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutChildrenRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutDisabilityRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutEmployeeDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutEmployersDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutExamRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutForeclosureRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutGiftRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutInvestmentRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutLifeInsuranceRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutLoanRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutLongTermCareRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutMonthlyRebateArchiveDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutMonthlyRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutMonthlyTaxQuestionnaireDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutPensionInsuranceRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutPreschoolRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutPrevEmployersDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutSpouseRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutStudentRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutTaxQuestionnaireDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutUnionRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  year?: number | null;
  ztppRebate?: ZtppRebateUpdateOneWithoutSettlementRequestInput | null;
}

export interface SettlementRequestUpdateWithoutZtppRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSettlementRequestInput | null;
  annualRebateArchive?: AnnualRebateUpdateOneWithoutSettlementRequestsInput | null;
  basicRebate?: BasicRebateUpdateOneWithoutSettlementRequestInput | null;
  canceledAt?: any | null;
  childrenRebate?: ChildrenRebateUpdateOneWithoutSettlementRequestInput | null;
  customer?: CustomerUpdateOneInput | null;
  disabilityRebate?: DisabilityRebateUpdateOneWithoutSettlementRequestInput | null;
  employee?: UserUpdateOneRequiredWithoutSettlementRequestsInput | null;
  employers?: PreviousEmployerUpdateManyWithoutSettlementRequestInput | null;
  examRebate?: ExamRebateUpdateOneWithoutSettlementRequestInput | null;
  foreclosureRebate?: ForeclosureRebateUpdateOneWithoutSettlementRequestInput | null;
  giftRebate?: GiftRebateUpdateOneWithoutSettlementRequestInput | null;
  investmentRebate?: InvestmentRebateUpdateOneWithoutSettlementRequestInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  loanRebate?: LoanRebateUpdateOneWithoutSettlementRequestInput | null;
  longTermCareRebate?: LongTermCareRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutSettlementRequestInput | null;
  monthlyRebateArchive?: MonthlyRebateUpdateOneWithoutSettlementRequestsInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  pensionInsuranceRebate?: PensionInsuranceRebateUpdateOneWithoutSettlementRequestInput | null;
  preschoolRebate?: PreschoolRebateUpdateOneWithoutSettlementRequestInput | null;
  prevEmployers?: PrevEmployerSnapUpdateManyWithoutSettlementRequestInput | null;
  requestedAt?: any | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  spouseRebate?: SpouseRebateUpdateOneWithoutSettlementRequestInput | null;
  status?: SettlementRequestStatus | null;
  studentRebate?: StudentRebateUpdateOneWithoutSettlementRequestInput | null;
  submittedAt?: any | null;
  submittedBy?: UserUpdateOneInput | null;
  synthetic?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalUpdateOneInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput | null;
  type?: SettlementRequestType | null;
  unionRebate?: UnionRebateUpdateOneWithoutSettlementRequestInput | null;
  year?: number | null;
}

export interface SettlementRequestUpsertWithWhereUniqueWithoutAnnualRebateArchiveInput {
  create: SettlementRequestCreateWithoutAnnualRebateArchiveInput;
  update: SettlementRequestUpdateWithoutAnnualRebateArchiveDataInput;
  where: SettlementRequestWhereUniqueInput;
}

export interface SettlementRequestUpsertWithWhereUniqueWithoutEmployeeInput {
  create: SettlementRequestCreateWithoutEmployeeInput;
  update: SettlementRequestUpdateWithoutEmployeeDataInput;
  where: SettlementRequestWhereUniqueInput;
}

export interface SettlementRequestUpsertWithWhereUniqueWithoutMonthlyRebateArchiveInput {
  create: SettlementRequestCreateWithoutMonthlyRebateArchiveInput;
  update: SettlementRequestUpdateWithoutMonthlyRebateArchiveDataInput;
  where: SettlementRequestWhereUniqueInput;
}

export interface SettlementRequestUpsertWithoutAnnualRebateInput {
  create: SettlementRequestCreateWithoutAnnualRebateInput;
  update: SettlementRequestUpdateWithoutAnnualRebateDataInput;
}

export interface SettlementRequestUpsertWithoutBasicRebateInput {
  create: SettlementRequestCreateWithoutBasicRebateInput;
  update: SettlementRequestUpdateWithoutBasicRebateDataInput;
}

export interface SettlementRequestUpsertWithoutChildrenRebateInput {
  create: SettlementRequestCreateWithoutChildrenRebateInput;
  update: SettlementRequestUpdateWithoutChildrenRebateDataInput;
}

export interface SettlementRequestUpsertWithoutDisabilityRebateInput {
  create: SettlementRequestCreateWithoutDisabilityRebateInput;
  update: SettlementRequestUpdateWithoutDisabilityRebateDataInput;
}

export interface SettlementRequestUpsertWithoutEmployersInput {
  create: SettlementRequestCreateWithoutEmployersInput;
  update: SettlementRequestUpdateWithoutEmployersDataInput;
}

export interface SettlementRequestUpsertWithoutExamRebateInput {
  create: SettlementRequestCreateWithoutExamRebateInput;
  update: SettlementRequestUpdateWithoutExamRebateDataInput;
}

export interface SettlementRequestUpsertWithoutForeclosureRebateInput {
  create: SettlementRequestCreateWithoutForeclosureRebateInput;
  update: SettlementRequestUpdateWithoutForeclosureRebateDataInput;
}

export interface SettlementRequestUpsertWithoutGiftRebateInput {
  create: SettlementRequestCreateWithoutGiftRebateInput;
  update: SettlementRequestUpdateWithoutGiftRebateDataInput;
}

export interface SettlementRequestUpsertWithoutInvestmentRebateInput {
  create: SettlementRequestCreateWithoutInvestmentRebateInput;
  update: SettlementRequestUpdateWithoutInvestmentRebateDataInput;
}

export interface SettlementRequestUpsertWithoutLifeInsuranceRebateInput {
  create: SettlementRequestCreateWithoutLifeInsuranceRebateInput;
  update: SettlementRequestUpdateWithoutLifeInsuranceRebateDataInput;
}

export interface SettlementRequestUpsertWithoutLoanRebateInput {
  create: SettlementRequestCreateWithoutLoanRebateInput;
  update: SettlementRequestUpdateWithoutLoanRebateDataInput;
}

export interface SettlementRequestUpsertWithoutLongTermCareRebateInput {
  create: SettlementRequestCreateWithoutLongTermCareRebateInput;
  update: SettlementRequestUpdateWithoutLongTermCareRebateDataInput;
}

export interface SettlementRequestUpsertWithoutMonthlyRebateInput {
  create: SettlementRequestCreateWithoutMonthlyRebateInput;
  update: SettlementRequestUpdateWithoutMonthlyRebateDataInput;
}

export interface SettlementRequestUpsertWithoutMonthlyTaxQuestionnaireInput {
  create: SettlementRequestCreateWithoutMonthlyTaxQuestionnaireInput;
  update: SettlementRequestUpdateWithoutMonthlyTaxQuestionnaireDataInput;
}

export interface SettlementRequestUpsertWithoutPensionInsuranceRebateInput {
  create: SettlementRequestCreateWithoutPensionInsuranceRebateInput;
  update: SettlementRequestUpdateWithoutPensionInsuranceRebateDataInput;
}

export interface SettlementRequestUpsertWithoutPreschoolRebateInput {
  create: SettlementRequestCreateWithoutPreschoolRebateInput;
  update: SettlementRequestUpdateWithoutPreschoolRebateDataInput;
}

export interface SettlementRequestUpsertWithoutPrevEmployersInput {
  create: SettlementRequestCreateWithoutPrevEmployersInput;
  update: SettlementRequestUpdateWithoutPrevEmployersDataInput;
}

export interface SettlementRequestUpsertWithoutSpouseRebateInput {
  create: SettlementRequestCreateWithoutSpouseRebateInput;
  update: SettlementRequestUpdateWithoutSpouseRebateDataInput;
}

export interface SettlementRequestUpsertWithoutStudentRebateInput {
  create: SettlementRequestCreateWithoutStudentRebateInput;
  update: SettlementRequestUpdateWithoutStudentRebateDataInput;
}

export interface SettlementRequestUpsertWithoutTaxQuestionnaireInput {
  create: SettlementRequestCreateWithoutTaxQuestionnaireInput;
  update: SettlementRequestUpdateWithoutTaxQuestionnaireDataInput;
}

export interface SettlementRequestUpsertWithoutUnionRebateInput {
  create: SettlementRequestCreateWithoutUnionRebateInput;
  update: SettlementRequestUpdateWithoutUnionRebateDataInput;
}

export interface SettlementRequestUpsertWithoutZtppRebateInput {
  create: SettlementRequestCreateWithoutZtppRebateInput;
  update: SettlementRequestUpdateWithoutZtppRebateDataInput;
}

export interface SettlementRequestWhereInput {
  AND?: SettlementRequestWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  annualRebateArchive?: AnnualRebateWhereInput | null;
  basicRebate?: BasicRebateWhereInput | null;
  canceledAt?: any | null;
  canceledAt_gt?: any | null;
  canceledAt_gte?: any | null;
  canceledAt_in?: any[] | null;
  canceledAt_lt?: any | null;
  canceledAt_lte?: any | null;
  canceledAt_not?: any | null;
  canceledAt_not_in?: any[] | null;
  childrenRebate?: ChildrenRebateWhereInput | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  disabilityRebate?: DisabilityRebateWhereInput | null;
  employee?: UserWhereInput | null;
  employers_every?: PreviousEmployerWhereInput | null;
  employers_none?: PreviousEmployerWhereInput | null;
  employers_some?: PreviousEmployerWhereInput | null;
  examRebate?: ExamRebateWhereInput | null;
  foreclosureRebate?: ForeclosureRebateWhereInput | null;
  giftRebate?: GiftRebateWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  investmentRebate?: InvestmentRebateWhereInput | null;
  lifeInsuranceRebate?: LifeInsuranceRebateWhereInput | null;
  loanRebate?: LoanRebateWhereInput | null;
  longTermCareRebate?: LongTermCareRebateWhereInput | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  monthlyRebateArchive?: MonthlyRebateWhereInput | null;
  monthlyTaxQuestionnaire?: MonthlyTaxQuestionnaireSnapWhereInput | null;
  NOT?: SettlementRequestWhereInput[] | null;
  OR?: SettlementRequestWhereInput[] | null;
  pensionInsuranceRebate?: PensionInsuranceRebateWhereInput | null;
  preschoolRebate?: PreschoolRebateWhereInput | null;
  prevEmployers_every?: PrevEmployerSnapWhereInput | null;
  prevEmployers_none?: PrevEmployerSnapWhereInput | null;
  prevEmployers_some?: PrevEmployerSnapWhereInput | null;
  requestedAt?: any | null;
  requestedAt_gt?: any | null;
  requestedAt_gte?: any | null;
  requestedAt_in?: any[] | null;
  requestedAt_lt?: any | null;
  requestedAt_lte?: any | null;
  requestedAt_not?: any | null;
  requestedAt_not_in?: any[] | null;
  residenceAuthorisationDocumentApproval?: DocumentApprovalWhereInput | null;
  spouseRebate?: SpouseRebateWhereInput | null;
  status?: SettlementRequestStatus | null;
  status_in?: SettlementRequestStatus[] | null;
  status_not?: SettlementRequestStatus | null;
  status_not_in?: SettlementRequestStatus[] | null;
  studentRebate?: StudentRebateWhereInput | null;
  submittedAt?: any | null;
  submittedAt_gt?: any | null;
  submittedAt_gte?: any | null;
  submittedAt_in?: any[] | null;
  submittedAt_lt?: any | null;
  submittedAt_lte?: any | null;
  submittedAt_not?: any | null;
  submittedAt_not_in?: any[] | null;
  submittedBy?: UserWhereInput | null;
  synthetic?: boolean | null;
  synthetic_not?: boolean | null;
  taxDomicilDocumentApproval?: DocumentApprovalWhereInput | null;
  taxQuestionnaire?: TaxQuestionnaireSnapWhereInput | null;
  type?: SettlementRequestType | null;
  type_in?: SettlementRequestType[] | null;
  type_not?: SettlementRequestType | null;
  type_not_in?: SettlementRequestType[] | null;
  unionRebate?: UnionRebateWhereInput | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
  year?: number | null;
  year_gt?: number | null;
  year_gte?: number | null;
  year_in?: number[] | null;
  year_lt?: number | null;
  year_lte?: number | null;
  year_not?: number | null;
  year_not_in?: number[] | null;
  ztppRebate?: ZtppRebateWhereInput | null;
}

export interface SettlementRequestWhereUniqueInput {
  id: string;
}

export interface SpouseRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutSpouseRebatesInput | null;
  applyInMonths?: SpouseRebateCreateapplyInMonthsInput | null;
  childBirthCertificateFiles?: FileCreateManyInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  commentOfDenied?: string | null;
  firstname?: string | null;
  id?: string | null;
  lastname?: string | null;
  marriageCertificateFiles?: FileCreateManyInput | null;
  nationalIDNumber?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutSpouseRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  spousesIncomeConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
  ztppMonths?: SpouseRebateCreateztppMonthsInput | null;
}

export interface SpouseRebateCreateManyWithoutAnnualRebateInput {
  connect?: SpouseRebateWhereUniqueInput[] | null;
  create?: SpouseRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface SpouseRebateCreateOneInput {
  connect?: SpouseRebateWhereUniqueInput | null;
  create?: SpouseRebateCreateInput | null;
}

export interface SpouseRebateCreateOneWithoutSettlementRequestInput {
  connect?: SpouseRebateWhereUniqueInput | null;
  create?: SpouseRebateCreateWithoutSettlementRequestInput | null;
}

export interface SpouseRebateCreateWithoutAnnualRebateInput {
  applyInMonths?: SpouseRebateCreateapplyInMonthsInput | null;
  childBirthCertificateFiles?: FileCreateManyInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  commentOfDenied?: string | null;
  firstname?: string | null;
  id?: string | null;
  lastname?: string | null;
  marriageCertificateFiles?: FileCreateManyInput | null;
  nationalIDNumber?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutSpouseRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  spousesIncomeConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
  ztppMonths?: SpouseRebateCreateztppMonthsInput | null;
}

export interface SpouseRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutSpouseRebatesInput | null;
  applyInMonths?: SpouseRebateCreateapplyInMonthsInput | null;
  childBirthCertificateFiles?: FileCreateManyInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  commentOfDenied?: string | null;
  firstname?: string | null;
  id?: string | null;
  lastname?: string | null;
  marriageCertificateFiles?: FileCreateManyInput | null;
  nationalIDNumber?: string | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  spousesIncomeConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
  ztppMonths?: SpouseRebateCreateztppMonthsInput | null;
}

export interface SpouseRebateCreateapplyInMonthsInput {
  set?: Month[] | null;
}

export interface SpouseRebateCreateztppMonthsInput {
  set?: Month[] | null;
}

export interface SpouseRebateScalarWhereInput {
  AND?: SpouseRebateScalarWhereInput[] | null;
  childBirthNumberOrDate?: string | null;
  childBirthNumberOrDate_contains?: string | null;
  childBirthNumberOrDate_ends_with?: string | null;
  childBirthNumberOrDate_gt?: string | null;
  childBirthNumberOrDate_gte?: string | null;
  childBirthNumberOrDate_in?: string[] | null;
  childBirthNumberOrDate_lt?: string | null;
  childBirthNumberOrDate_lte?: string | null;
  childBirthNumberOrDate_not?: string | null;
  childBirthNumberOrDate_not_contains?: string | null;
  childBirthNumberOrDate_not_ends_with?: string | null;
  childBirthNumberOrDate_not_in?: string[] | null;
  childBirthNumberOrDate_not_starts_with?: string | null;
  childBirthNumberOrDate_starts_with?: string | null;
  childFirstname?: string | null;
  childFirstname_contains?: string | null;
  childFirstname_ends_with?: string | null;
  childFirstname_gt?: string | null;
  childFirstname_gte?: string | null;
  childFirstname_in?: string[] | null;
  childFirstname_lt?: string | null;
  childFirstname_lte?: string | null;
  childFirstname_not?: string | null;
  childFirstname_not_contains?: string | null;
  childFirstname_not_ends_with?: string | null;
  childFirstname_not_in?: string[] | null;
  childFirstname_not_starts_with?: string | null;
  childFirstname_starts_with?: string | null;
  childLastname?: string | null;
  childLastname_contains?: string | null;
  childLastname_ends_with?: string | null;
  childLastname_gt?: string | null;
  childLastname_gte?: string | null;
  childLastname_in?: string[] | null;
  childLastname_lt?: string | null;
  childLastname_lte?: string | null;
  childLastname_not?: string | null;
  childLastname_not_contains?: string | null;
  childLastname_not_ends_with?: string | null;
  childLastname_not_in?: string[] | null;
  childLastname_not_starts_with?: string | null;
  childLastname_starts_with?: string | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  firstname?: string | null;
  firstname_contains?: string | null;
  firstname_ends_with?: string | null;
  firstname_gt?: string | null;
  firstname_gte?: string | null;
  firstname_in?: string[] | null;
  firstname_lt?: string | null;
  firstname_lte?: string | null;
  firstname_not?: string | null;
  firstname_not_contains?: string | null;
  firstname_not_ends_with?: string | null;
  firstname_not_in?: string[] | null;
  firstname_not_starts_with?: string | null;
  firstname_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  lastname?: string | null;
  lastname_contains?: string | null;
  lastname_ends_with?: string | null;
  lastname_gt?: string | null;
  lastname_gte?: string | null;
  lastname_in?: string[] | null;
  lastname_lt?: string | null;
  lastname_lte?: string | null;
  lastname_not?: string | null;
  lastname_not_contains?: string | null;
  lastname_not_ends_with?: string | null;
  lastname_not_in?: string[] | null;
  lastname_not_starts_with?: string | null;
  lastname_starts_with?: string | null;
  nationalIDNumber?: string | null;
  nationalIDNumber_contains?: string | null;
  nationalIDNumber_ends_with?: string | null;
  nationalIDNumber_gt?: string | null;
  nationalIDNumber_gte?: string | null;
  nationalIDNumber_in?: string[] | null;
  nationalIDNumber_lt?: string | null;
  nationalIDNumber_lte?: string | null;
  nationalIDNumber_not?: string | null;
  nationalIDNumber_not_contains?: string | null;
  nationalIDNumber_not_ends_with?: string | null;
  nationalIDNumber_not_in?: string[] | null;
  nationalIDNumber_not_starts_with?: string | null;
  nationalIDNumber_starts_with?: string | null;
  NOT?: SpouseRebateScalarWhereInput[] | null;
  OR?: SpouseRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
  ztpp?: boolean | null;
  ztpp_not?: boolean | null;
}

export interface SpouseRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSpouseRebatesInput | null;
  applyInMonths?: SpouseRebateUpdateapplyInMonthsInput | null;
  childBirthCertificateFiles?: FileUpdateManyInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  commentOfDenied?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  marriageCertificateFiles?: FileUpdateManyInput | null;
  nationalIDNumber?: string | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutSpouseRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  spousesIncomeConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
  ztppMonths?: SpouseRebateUpdateztppMonthsInput | null;
}

export interface SpouseRebateUpdateManyDataInput {
  applyInMonths?: SpouseRebateUpdateapplyInMonthsInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  commentOfDenied?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  nationalIDNumber?: string | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztpp?: boolean | null;
  ztppMonths?: SpouseRebateUpdateztppMonthsInput | null;
}

export interface SpouseRebateUpdateManyWithWhereNestedInput {
  data: SpouseRebateUpdateManyDataInput;
  where: SpouseRebateScalarWhereInput;
}

export interface SpouseRebateUpdateManyWithoutAnnualRebateInput {
  connect?: SpouseRebateWhereUniqueInput[] | null;
  create?: SpouseRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: SpouseRebateWhereUniqueInput[] | null;
  deleteMany?: SpouseRebateScalarWhereInput[] | null;
  disconnect?: SpouseRebateWhereUniqueInput[] | null;
  set?: SpouseRebateWhereUniqueInput[] | null;
  update?: SpouseRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: SpouseRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: SpouseRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface SpouseRebateUpdateOneRequiredInput {
  connect?: SpouseRebateWhereUniqueInput | null;
  create?: SpouseRebateCreateInput | null;
  update?: SpouseRebateUpdateDataInput | null;
  upsert?: SpouseRebateUpsertNestedInput | null;
}

export interface SpouseRebateUpdateOneWithoutSettlementRequestInput {
  connect?: SpouseRebateWhereUniqueInput | null;
  create?: SpouseRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: SpouseRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: SpouseRebateUpsertWithoutSettlementRequestInput | null;
}

export interface SpouseRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: SpouseRebateUpdateWithoutAnnualRebateDataInput;
  where: SpouseRebateWhereUniqueInput;
}

export interface SpouseRebateUpdateWithoutAnnualRebateDataInput {
  applyInMonths?: SpouseRebateUpdateapplyInMonthsInput | null;
  childBirthCertificateFiles?: FileUpdateManyInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  commentOfDenied?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  marriageCertificateFiles?: FileUpdateManyInput | null;
  nationalIDNumber?: string | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutSpouseRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  spousesIncomeConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
  ztppMonths?: SpouseRebateUpdateztppMonthsInput | null;
}

export interface SpouseRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutSpouseRebatesInput | null;
  applyInMonths?: SpouseRebateUpdateapplyInMonthsInput | null;
  childBirthCertificateFiles?: FileUpdateManyInput | null;
  childBirthNumberOrDate?: string | null;
  childFirstname?: string | null;
  childLastname?: string | null;
  commentOfDenied?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  marriageCertificateFiles?: FileUpdateManyInput | null;
  nationalIDNumber?: string | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  spousesIncomeConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztpp?: boolean | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
  ztppMonths?: SpouseRebateUpdateztppMonthsInput | null;
}

export interface SpouseRebateUpdateapplyInMonthsInput {
  set?: Month[] | null;
}

export interface SpouseRebateUpdateztppMonthsInput {
  set?: Month[] | null;
}

export interface SpouseRebateUpsertNestedInput {
  create: SpouseRebateCreateInput;
  update: SpouseRebateUpdateDataInput;
}

export interface SpouseRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: SpouseRebateCreateWithoutAnnualRebateInput;
  update: SpouseRebateUpdateWithoutAnnualRebateDataInput;
  where: SpouseRebateWhereUniqueInput;
}

export interface SpouseRebateUpsertWithoutSettlementRequestInput {
  create: SpouseRebateCreateWithoutSettlementRequestInput;
  update: SpouseRebateUpdateWithoutSettlementRequestDataInput;
}

export interface SpouseRebateWhereInput {
  AND?: SpouseRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  childBirthCertificateFiles_every?: FileWhereInput | null;
  childBirthCertificateFiles_none?: FileWhereInput | null;
  childBirthCertificateFiles_some?: FileWhereInput | null;
  childBirthNumberOrDate?: string | null;
  childBirthNumberOrDate_contains?: string | null;
  childBirthNumberOrDate_ends_with?: string | null;
  childBirthNumberOrDate_gt?: string | null;
  childBirthNumberOrDate_gte?: string | null;
  childBirthNumberOrDate_in?: string[] | null;
  childBirthNumberOrDate_lt?: string | null;
  childBirthNumberOrDate_lte?: string | null;
  childBirthNumberOrDate_not?: string | null;
  childBirthNumberOrDate_not_contains?: string | null;
  childBirthNumberOrDate_not_ends_with?: string | null;
  childBirthNumberOrDate_not_in?: string[] | null;
  childBirthNumberOrDate_not_starts_with?: string | null;
  childBirthNumberOrDate_starts_with?: string | null;
  childFirstname?: string | null;
  childFirstname_contains?: string | null;
  childFirstname_ends_with?: string | null;
  childFirstname_gt?: string | null;
  childFirstname_gte?: string | null;
  childFirstname_in?: string[] | null;
  childFirstname_lt?: string | null;
  childFirstname_lte?: string | null;
  childFirstname_not?: string | null;
  childFirstname_not_contains?: string | null;
  childFirstname_not_ends_with?: string | null;
  childFirstname_not_in?: string[] | null;
  childFirstname_not_starts_with?: string | null;
  childFirstname_starts_with?: string | null;
  childLastname?: string | null;
  childLastname_contains?: string | null;
  childLastname_ends_with?: string | null;
  childLastname_gt?: string | null;
  childLastname_gte?: string | null;
  childLastname_in?: string[] | null;
  childLastname_lt?: string | null;
  childLastname_lte?: string | null;
  childLastname_not?: string | null;
  childLastname_not_contains?: string | null;
  childLastname_not_ends_with?: string | null;
  childLastname_not_in?: string[] | null;
  childLastname_not_starts_with?: string | null;
  childLastname_starts_with?: string | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  firstname?: string | null;
  firstname_contains?: string | null;
  firstname_ends_with?: string | null;
  firstname_gt?: string | null;
  firstname_gte?: string | null;
  firstname_in?: string[] | null;
  firstname_lt?: string | null;
  firstname_lte?: string | null;
  firstname_not?: string | null;
  firstname_not_contains?: string | null;
  firstname_not_ends_with?: string | null;
  firstname_not_in?: string[] | null;
  firstname_not_starts_with?: string | null;
  firstname_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  lastname?: string | null;
  lastname_contains?: string | null;
  lastname_ends_with?: string | null;
  lastname_gt?: string | null;
  lastname_gte?: string | null;
  lastname_in?: string[] | null;
  lastname_lt?: string | null;
  lastname_lte?: string | null;
  lastname_not?: string | null;
  lastname_not_contains?: string | null;
  lastname_not_ends_with?: string | null;
  lastname_not_in?: string[] | null;
  lastname_not_starts_with?: string | null;
  lastname_starts_with?: string | null;
  marriageCertificateFiles_every?: FileWhereInput | null;
  marriageCertificateFiles_none?: FileWhereInput | null;
  marriageCertificateFiles_some?: FileWhereInput | null;
  nationalIDNumber?: string | null;
  nationalIDNumber_contains?: string | null;
  nationalIDNumber_ends_with?: string | null;
  nationalIDNumber_gt?: string | null;
  nationalIDNumber_gte?: string | null;
  nationalIDNumber_in?: string[] | null;
  nationalIDNumber_lt?: string | null;
  nationalIDNumber_lte?: string | null;
  nationalIDNumber_not?: string | null;
  nationalIDNumber_not_contains?: string | null;
  nationalIDNumber_not_ends_with?: string | null;
  nationalIDNumber_not_in?: string[] | null;
  nationalIDNumber_not_starts_with?: string | null;
  nationalIDNumber_starts_with?: string | null;
  NOT?: SpouseRebateWhereInput[] | null;
  OR?: SpouseRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  socialConfirmationFiles_every?: FileWhereInput | null;
  socialConfirmationFiles_none?: FileWhereInput | null;
  socialConfirmationFiles_some?: FileWhereInput | null;
  spousesIncomeConfirmationFiles_every?: FileWhereInput | null;
  spousesIncomeConfirmationFiles_none?: FileWhereInput | null;
  spousesIncomeConfirmationFiles_some?: FileWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
  ztpp?: boolean | null;
  ztpp_not?: boolean | null;
  ztppConfirmationFiles_every?: FileWhereInput | null;
  ztppConfirmationFiles_none?: FileWhereInput | null;
  ztppConfirmationFiles_some?: FileWhereInput | null;
}

export interface SpouseRebateWhereUniqueInput {
  id?: string | null;
}

export interface StudentRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutStudentRebatesInput | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutStudentRebateInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateCreateManyWithoutAnnualRebateInput {
  connect?: StudentRebateWhereUniqueInput[] | null;
  create?: StudentRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface StudentRebateCreateManyWithoutMonthlyRebateInput {
  connect?: StudentRebateWhereUniqueInput[] | null;
  create?: StudentRebateCreateWithoutMonthlyRebateInput[] | null;
}

export interface StudentRebateCreateOneInput {
  connect?: StudentRebateWhereUniqueInput | null;
  create?: StudentRebateCreateInput | null;
}

export interface StudentRebateCreateOneWithoutPrevYearPeriodsInput {
  connect?: StudentRebateWhereUniqueInput | null;
  create?: StudentRebateCreateWithoutPrevYearPeriodsInput | null;
}

export interface StudentRebateCreateOneWithoutSettlementRequestInput {
  connect?: StudentRebateWhereUniqueInput | null;
  create?: StudentRebateCreateWithoutSettlementRequestInput | null;
}

export interface StudentRebateCreateWithoutAnnualRebateInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutStudentRebatesInput | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutStudentRebateInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateCreateWithoutMonthlyRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutStudentRebateInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateCreateWithoutPrevYearPeriodsInput {
  annualRebate?: AnnualRebateCreateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutStudentRebatesInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutStudentRebateInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutStudentRebatesInput | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  status: RebateStatus;
  studentConfirmationFiles?: FileCreateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebatePrevYearPeriodCreateInput {
  from?: any | null;
  id?: string | null;
  rebate?: StudentRebateCreateOneWithoutPrevYearPeriodsInput | null;
  to?: any | null;
}

export interface StudentRebatePrevYearPeriodCreateManyInput {
  connect?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: StudentRebatePrevYearPeriodCreateInput[] | null;
}

export interface StudentRebatePrevYearPeriodCreateManyWithoutRebateInput {
  connect?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: StudentRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
}

export interface StudentRebatePrevYearPeriodCreateWithoutRebateInput {
  from?: any | null;
  id?: string | null;
  to?: any | null;
}

export interface StudentRebatePrevYearPeriodScalarWhereInput {
  AND?: StudentRebatePrevYearPeriodScalarWhereInput[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: StudentRebatePrevYearPeriodScalarWhereInput[] | null;
  OR?: StudentRebatePrevYearPeriodScalarWhereInput[] | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface StudentRebatePrevYearPeriodUpdateDataInput {
  from?: any | null;
  rebate?: StudentRebateUpdateOneWithoutPrevYearPeriodsInput | null;
  to?: any | null;
}

export interface StudentRebatePrevYearPeriodUpdateManyDataInput {
  from?: any | null;
  to?: any | null;
}

export interface StudentRebatePrevYearPeriodUpdateManyInput {
  connect?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: StudentRebatePrevYearPeriodCreateInput[] | null;
  delete?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  deleteMany?: StudentRebatePrevYearPeriodScalarWhereInput[] | null;
  disconnect?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  set?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  update?: StudentRebatePrevYearPeriodUpdateWithWhereUniqueNestedInput[] | null;
  updateMany?: StudentRebatePrevYearPeriodUpdateManyWithWhereNestedInput[] | null;
  upsert?: StudentRebatePrevYearPeriodUpsertWithWhereUniqueNestedInput[] | null;
}

export interface StudentRebatePrevYearPeriodUpdateManyWithWhereNestedInput {
  data: StudentRebatePrevYearPeriodUpdateManyDataInput;
  where: StudentRebatePrevYearPeriodScalarWhereInput;
}

export interface StudentRebatePrevYearPeriodUpdateManyWithoutRebateInput {
  connect?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: StudentRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
  delete?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  deleteMany?: StudentRebatePrevYearPeriodScalarWhereInput[] | null;
  disconnect?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  set?: StudentRebatePrevYearPeriodWhereUniqueInput[] | null;
  update?: StudentRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput[] | null;
  updateMany?: StudentRebatePrevYearPeriodUpdateManyWithWhereNestedInput[] | null;
  upsert?: StudentRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput[] | null;
}

export interface StudentRebatePrevYearPeriodUpdateWithWhereUniqueNestedInput {
  data: StudentRebatePrevYearPeriodUpdateDataInput;
  where: StudentRebatePrevYearPeriodWhereUniqueInput;
}

export interface StudentRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput {
  data: StudentRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: StudentRebatePrevYearPeriodWhereUniqueInput;
}

export interface StudentRebatePrevYearPeriodUpdateWithoutRebateDataInput {
  from?: any | null;
  to?: any | null;
}

export interface StudentRebatePrevYearPeriodUpsertWithWhereUniqueNestedInput {
  create: StudentRebatePrevYearPeriodCreateInput;
  update: StudentRebatePrevYearPeriodUpdateDataInput;
  where: StudentRebatePrevYearPeriodWhereUniqueInput;
}

export interface StudentRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput {
  create: StudentRebatePrevYearPeriodCreateWithoutRebateInput;
  update: StudentRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: StudentRebatePrevYearPeriodWhereUniqueInput;
}

export interface StudentRebatePrevYearPeriodWhereInput {
  AND?: StudentRebatePrevYearPeriodWhereInput[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: StudentRebatePrevYearPeriodWhereInput[] | null;
  OR?: StudentRebatePrevYearPeriodWhereInput[] | null;
  rebate?: StudentRebateWhereInput | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface StudentRebatePrevYearPeriodWhereUniqueInput {
  id?: string | null;
}

export interface StudentRebateScalarWhereInput {
  AND?: StudentRebateScalarWhereInput[] | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationDoctoralStudies_not?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityFrom_gt?: any | null;
  confirmationValidityFrom_gte?: any | null;
  confirmationValidityFrom_in?: any[] | null;
  confirmationValidityFrom_lt?: any | null;
  confirmationValidityFrom_lte?: any | null;
  confirmationValidityFrom_not?: any | null;
  confirmationValidityFrom_not_in?: any[] | null;
  confirmationValidityTo?: any | null;
  confirmationValidityTo_gt?: any | null;
  confirmationValidityTo_gte?: any | null;
  confirmationValidityTo_in?: any[] | null;
  confirmationValidityTo_lt?: any | null;
  confirmationValidityTo_lte?: any | null;
  confirmationValidityTo_not?: any | null;
  confirmationValidityTo_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: StudentRebateScalarWhereInput[] | null;
  OR?: StudentRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface StudentRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutStudentRebatesInput | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutStudentRebateInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateUpdateManyDataInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface StudentRebateUpdateManyWithWhereNestedInput {
  data: StudentRebateUpdateManyDataInput;
  where: StudentRebateScalarWhereInput;
}

export interface StudentRebateUpdateManyWithoutAnnualRebateInput {
  connect?: StudentRebateWhereUniqueInput[] | null;
  create?: StudentRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: StudentRebateWhereUniqueInput[] | null;
  deleteMany?: StudentRebateScalarWhereInput[] | null;
  disconnect?: StudentRebateWhereUniqueInput[] | null;
  set?: StudentRebateWhereUniqueInput[] | null;
  update?: StudentRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: StudentRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: StudentRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface StudentRebateUpdateManyWithoutMonthlyRebateInput {
  connect?: StudentRebateWhereUniqueInput[] | null;
  create?: StudentRebateCreateWithoutMonthlyRebateInput[] | null;
  delete?: StudentRebateWhereUniqueInput[] | null;
  deleteMany?: StudentRebateScalarWhereInput[] | null;
  disconnect?: StudentRebateWhereUniqueInput[] | null;
  set?: StudentRebateWhereUniqueInput[] | null;
  update?: StudentRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput[] | null;
  updateMany?: StudentRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: StudentRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput[] | null;
}

export interface StudentRebateUpdateOneRequiredInput {
  connect?: StudentRebateWhereUniqueInput | null;
  create?: StudentRebateCreateInput | null;
  update?: StudentRebateUpdateDataInput | null;
  upsert?: StudentRebateUpsertNestedInput | null;
}

export interface StudentRebateUpdateOneWithoutPrevYearPeriodsInput {
  connect?: StudentRebateWhereUniqueInput | null;
  create?: StudentRebateCreateWithoutPrevYearPeriodsInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: StudentRebateUpdateWithoutPrevYearPeriodsDataInput | null;
  upsert?: StudentRebateUpsertWithoutPrevYearPeriodsInput | null;
}

export interface StudentRebateUpdateOneWithoutSettlementRequestInput {
  connect?: StudentRebateWhereUniqueInput | null;
  create?: StudentRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: StudentRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: StudentRebateUpsertWithoutSettlementRequestInput | null;
}

export interface StudentRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: StudentRebateUpdateWithoutAnnualRebateDataInput;
  where: StudentRebateWhereUniqueInput;
}

export interface StudentRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput {
  data: StudentRebateUpdateWithoutMonthlyRebateDataInput;
  where: StudentRebateWhereUniqueInput;
}

export interface StudentRebateUpdateWithoutAnnualRebateDataInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutStudentRebatesInput | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutStudentRebateInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateUpdateWithoutMonthlyRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutStudentRebateInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateUpdateWithoutPrevYearPeriodsDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutStudentRebatesInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutStudentRebateInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutStudentRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutStudentRebatesInput | null;
  prevYearPeriods?: StudentRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  status?: RebateStatus | null;
  studentConfirmationFiles?: FileUpdateManyInput | null;
  type?: RequestType | null;
}

export interface StudentRebateUpsertNestedInput {
  create: StudentRebateCreateInput;
  update: StudentRebateUpdateDataInput;
}

export interface StudentRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: StudentRebateCreateWithoutAnnualRebateInput;
  update: StudentRebateUpdateWithoutAnnualRebateDataInput;
  where: StudentRebateWhereUniqueInput;
}

export interface StudentRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput {
  create: StudentRebateCreateWithoutMonthlyRebateInput;
  update: StudentRebateUpdateWithoutMonthlyRebateDataInput;
  where: StudentRebateWhereUniqueInput;
}

export interface StudentRebateUpsertWithoutPrevYearPeriodsInput {
  create: StudentRebateCreateWithoutPrevYearPeriodsInput;
  update: StudentRebateUpdateWithoutPrevYearPeriodsDataInput;
}

export interface StudentRebateUpsertWithoutSettlementRequestInput {
  create: StudentRebateCreateWithoutSettlementRequestInput;
  update: StudentRebateUpdateWithoutSettlementRequestDataInput;
}

export interface StudentRebateWhereInput {
  AND?: StudentRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationDoctoralStudies?: boolean | null;
  confirmationDoctoralStudies_not?: boolean | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityFrom_gt?: any | null;
  confirmationValidityFrom_gte?: any | null;
  confirmationValidityFrom_in?: any[] | null;
  confirmationValidityFrom_lt?: any | null;
  confirmationValidityFrom_lte?: any | null;
  confirmationValidityFrom_not?: any | null;
  confirmationValidityFrom_not_in?: any[] | null;
  confirmationValidityTo?: any | null;
  confirmationValidityTo_gt?: any | null;
  confirmationValidityTo_gte?: any | null;
  confirmationValidityTo_in?: any[] | null;
  confirmationValidityTo_lt?: any | null;
  confirmationValidityTo_lte?: any | null;
  confirmationValidityTo_not?: any | null;
  confirmationValidityTo_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  NOT?: StudentRebateWhereInput[] | null;
  OR?: StudentRebateWhereInput[] | null;
  prevYearPeriods_every?: StudentRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_none?: StudentRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_some?: StudentRebatePrevYearPeriodWhereInput | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  studentConfirmationFiles_every?: FileWhereInput | null;
  studentConfirmationFiles_none?: FileWhereInput | null;
  studentConfirmationFiles_some?: FileWhereInput | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface StudentRebateWhereUniqueInput {
  id?: string | null;
}

export interface SuparadminUnitCreateInput {
  color: string;
  customerId: string;
  name: string;
}

export interface SuparadminUnitWhereInput {
  customerId: string;
}

export interface SuperadminUserCreateInput {
  customerId: string;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  email: string;
  employmentType?: EmploymentType | null;
  firstname: string;
  language?: Language | null;
  lastname: string;
  managedUnits?: UnitCreateManyInput | null;
  nickname?: string | null;
  personalNumber?: string | null;
  role: Role;
  signedConfirmation?: boolean | null;
  unit?: UnitCreateOneInput | null;
}

export interface SuperadminUserWhereInput {
  blockedAt?: any | null;
  customerId: string;
  role?: Role | null;
  role_in?: Role | null;
  role_not?: Role | null;
}

export interface TaxQuestionnaireCreateOneWithoutAnnualRebateInput {
  connect?: TaxQuestionnaireWhereUniqueInput | null;
  create?: TaxQuestionnaireCreateWithoutAnnualRebateInput | null;
}

export interface TaxQuestionnaireCreateOneWithoutHistoryInput {
  connect?: TaxQuestionnaireWhereUniqueInput | null;
  create?: TaxQuestionnaireCreateWithoutHistoryInput | null;
}

export interface TaxQuestionnaireCreateWithoutAnnualRebateInput {
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  history?: TaxQuestionnaireSnapCreateManyWithoutSnapRootInput | null;
  id?: string | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileCreateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireCreatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileCreateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireCreateWithoutHistoryInput {
  annualRebate: AnnualRebateCreateOneWithoutTaxQuestionnaireInput;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  id?: string | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileCreateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireCreatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileCreateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireCreatelaborOfficeMonthsInput {
  set?: Month[] | null;
}

export interface TaxQuestionnaireSnapCreateManyWithoutSnapHistoryInput {
  connect?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: TaxQuestionnaireSnapCreateWithoutSnapHistoryInput[] | null;
}

export interface TaxQuestionnaireSnapCreateManyWithoutSnapRootInput {
  connect?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: TaxQuestionnaireSnapCreateWithoutSnapRootInput[] | null;
}

export interface TaxQuestionnaireSnapCreateOneWithoutSettlementRequestInput {
  connect?: TaxQuestionnaireSnapWhereUniqueInput | null;
  create?: TaxQuestionnaireSnapCreateWithoutSettlementRequestInput | null;
}

export interface TaxQuestionnaireSnapCreateWithoutSettlementRequestInput {
  annualRebate: AnnualRebateCreateOneInput;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  id?: string | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileCreateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireSnapCreatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileCreateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  snapHistory?: TaxQuestionnaireSnapCreateManyWithoutSnapHistoryInput | null;
  snapRoot?: TaxQuestionnaireCreateOneWithoutHistoryInput | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireSnapCreateWithoutSnapHistoryInput {
  annualRebate: AnnualRebateCreateOneInput;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  id?: string | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileCreateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireSnapCreatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileCreateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  settlementRequest: SettlementRequestCreateOneWithoutTaxQuestionnaireInput;
  snapRoot?: TaxQuestionnaireCreateOneWithoutHistoryInput | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireSnapCreateWithoutSnapRootInput {
  annualRebate: AnnualRebateCreateOneInput;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  id?: string | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileCreateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireSnapCreatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileCreateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  settlementRequest: SettlementRequestCreateOneWithoutTaxQuestionnaireInput;
  snapHistory?: TaxQuestionnaireSnapCreateManyWithoutSnapHistoryInput | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireSnapCreatelaborOfficeMonthsInput {
  set?: Month[] | null;
}

export interface TaxQuestionnaireSnapScalarWhereInput {
  AND?: TaxQuestionnaireSnapScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  concurrentEmployers?: boolean | null;
  concurrentEmployers_not?: boolean | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  foreignIncome?: boolean | null;
  foreignIncome_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  laborOffice?: boolean | null;
  laborOffice_not?: boolean | null;
  NOT?: TaxQuestionnaireSnapScalarWhereInput[] | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncome_not?: boolean | null;
  OR?: TaxQuestionnaireSnapScalarWhereInput[] | null;
  otherIncomes?: boolean | null;
  otherIncomes_not?: boolean | null;
  samePreviousEmployer?: boolean | null;
  samePreviousEmployer_not?: boolean | null;
  selfEmployed?: boolean | null;
  selfEmployed_not?: boolean | null;
  solidarityTax?: boolean | null;
  solidarityTax_not?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
  status_in?: TaxQuestionnaireStatus[] | null;
  status_not?: TaxQuestionnaireStatus | null;
  status_not_in?: TaxQuestionnaireStatus[] | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface TaxQuestionnaireSnapUpdateManyDataInput {
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeMonths?: TaxQuestionnaireSnapUpdatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireSnapUpdateManyWithWhereNestedInput {
  data: TaxQuestionnaireSnapUpdateManyDataInput;
  where: TaxQuestionnaireSnapScalarWhereInput;
}

export interface TaxQuestionnaireSnapUpdateManyWithoutSnapHistoryInput {
  connect?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: TaxQuestionnaireSnapCreateWithoutSnapHistoryInput[] | null;
  delete?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  deleteMany?: TaxQuestionnaireSnapScalarWhereInput[] | null;
  disconnect?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  set?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  update?: TaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapHistoryInput[] | null;
  updateMany?: TaxQuestionnaireSnapUpdateManyWithWhereNestedInput[] | null;
  upsert?: TaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapHistoryInput[] | null;
}

export interface TaxQuestionnaireSnapUpdateManyWithoutSnapRootInput {
  connect?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  create?: TaxQuestionnaireSnapCreateWithoutSnapRootInput[] | null;
  delete?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  deleteMany?: TaxQuestionnaireSnapScalarWhereInput[] | null;
  disconnect?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  set?: TaxQuestionnaireSnapWhereUniqueInput[] | null;
  update?: TaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapRootInput[] | null;
  updateMany?: TaxQuestionnaireSnapUpdateManyWithWhereNestedInput[] | null;
  upsert?: TaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapRootInput[] | null;
}

export interface TaxQuestionnaireSnapUpdateOneWithoutSettlementRequestInput {
  connect?: TaxQuestionnaireSnapWhereUniqueInput | null;
  create?: TaxQuestionnaireSnapCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TaxQuestionnaireSnapUpdateWithoutSettlementRequestDataInput | null;
  upsert?: TaxQuestionnaireSnapUpsertWithoutSettlementRequestInput | null;
}

export interface TaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapHistoryInput {
  data: TaxQuestionnaireSnapUpdateWithoutSnapHistoryDataInput;
  where: TaxQuestionnaireSnapWhereUniqueInput;
}

export interface TaxQuestionnaireSnapUpdateWithWhereUniqueWithoutSnapRootInput {
  data: TaxQuestionnaireSnapUpdateWithoutSnapRootDataInput;
  where: TaxQuestionnaireSnapWhereUniqueInput;
}

export interface TaxQuestionnaireSnapUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneRequiredInput | null;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileUpdateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireSnapUpdatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileUpdateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  snapHistory?: TaxQuestionnaireSnapUpdateManyWithoutSnapHistoryInput | null;
  snapRoot?: TaxQuestionnaireUpdateOneWithoutHistoryInput | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireSnapUpdateWithoutSnapHistoryDataInput {
  annualRebate?: AnnualRebateUpdateOneRequiredInput | null;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileUpdateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireSnapUpdatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileUpdateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  settlementRequest?: SettlementRequestUpdateOneRequiredWithoutTaxQuestionnaireInput | null;
  snapRoot?: TaxQuestionnaireUpdateOneWithoutHistoryInput | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireSnapUpdateWithoutSnapRootDataInput {
  annualRebate?: AnnualRebateUpdateOneRequiredInput | null;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileUpdateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireSnapUpdatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileUpdateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  settlementRequest?: SettlementRequestUpdateOneRequiredWithoutTaxQuestionnaireInput | null;
  snapHistory?: TaxQuestionnaireSnapUpdateManyWithoutSnapHistoryInput | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireSnapUpdatelaborOfficeMonthsInput {
  set?: Month[] | null;
}

export interface TaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapHistoryInput {
  create: TaxQuestionnaireSnapCreateWithoutSnapHistoryInput;
  update: TaxQuestionnaireSnapUpdateWithoutSnapHistoryDataInput;
  where: TaxQuestionnaireSnapWhereUniqueInput;
}

export interface TaxQuestionnaireSnapUpsertWithWhereUniqueWithoutSnapRootInput {
  create: TaxQuestionnaireSnapCreateWithoutSnapRootInput;
  update: TaxQuestionnaireSnapUpdateWithoutSnapRootDataInput;
  where: TaxQuestionnaireSnapWhereUniqueInput;
}

export interface TaxQuestionnaireSnapUpsertWithoutSettlementRequestInput {
  create: TaxQuestionnaireSnapCreateWithoutSettlementRequestInput;
  update: TaxQuestionnaireSnapUpdateWithoutSettlementRequestDataInput;
}

export interface TaxQuestionnaireSnapWhereInput {
  AND?: TaxQuestionnaireSnapWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  concurrentEmployers?: boolean | null;
  concurrentEmployers_not?: boolean | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  foreignIncome?: boolean | null;
  foreignIncome_not?: boolean | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  laborOffice?: boolean | null;
  laborOffice_not?: boolean | null;
  laborOfficeFiles_every?: FileWhereInput | null;
  laborOfficeFiles_none?: FileWhereInput | null;
  laborOfficeFiles_some?: FileWhereInput | null;
  NOT?: TaxQuestionnaireSnapWhereInput[] | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncome_not?: boolean | null;
  noTaxableIncomeFiles_every?: FileWhereInput | null;
  noTaxableIncomeFiles_none?: FileWhereInput | null;
  noTaxableIncomeFiles_some?: FileWhereInput | null;
  OR?: TaxQuestionnaireSnapWhereInput[] | null;
  otherIncomes?: boolean | null;
  otherIncomes_not?: boolean | null;
  samePreviousEmployer?: boolean | null;
  samePreviousEmployer_not?: boolean | null;
  selfEmployed?: boolean | null;
  selfEmployed_not?: boolean | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  snapHistory_every?: TaxQuestionnaireSnapWhereInput | null;
  snapHistory_none?: TaxQuestionnaireSnapWhereInput | null;
  snapHistory_some?: TaxQuestionnaireSnapWhereInput | null;
  snapRoot?: TaxQuestionnaireWhereInput | null;
  solidarityTax?: boolean | null;
  solidarityTax_not?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
  status_in?: TaxQuestionnaireStatus[] | null;
  status_not?: TaxQuestionnaireStatus | null;
  status_not_in?: TaxQuestionnaireStatus[] | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface TaxQuestionnaireSnapWhereUniqueInput {
  id?: string | null;
}

export interface TaxQuestionnaireUpdateInput {
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileUpdateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireUpdatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileUpdateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
}

export interface TaxQuestionnaireUpdateOneRequiredWithoutAnnualRebateInput {
  connect?: TaxQuestionnaireWhereUniqueInput | null;
  create?: TaxQuestionnaireCreateWithoutAnnualRebateInput | null;
  update?: TaxQuestionnaireUpdateWithoutAnnualRebateDataInput | null;
  upsert?: TaxQuestionnaireUpsertWithoutAnnualRebateInput | null;
}

export interface TaxQuestionnaireUpdateOneWithoutHistoryInput {
  connect?: TaxQuestionnaireWhereUniqueInput | null;
  create?: TaxQuestionnaireCreateWithoutHistoryInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: TaxQuestionnaireUpdateWithoutHistoryDataInput | null;
  upsert?: TaxQuestionnaireUpsertWithoutHistoryInput | null;
}

export interface TaxQuestionnaireUpdateWithoutAnnualRebateDataInput {
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  history?: TaxQuestionnaireSnapUpdateManyWithoutSnapRootInput | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileUpdateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireUpdatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileUpdateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireUpdateWithoutHistoryDataInput {
  annualRebate?: AnnualRebateUpdateOneRequiredWithoutTaxQuestionnaireInput | null;
  commentOfDenied?: string | null;
  concurrentEmployers?: boolean | null;
  createByActualEmployer?: boolean | null;
  foreignIncome?: boolean | null;
  laborOffice?: boolean | null;
  laborOfficeFiles?: FileUpdateManyInput | null;
  laborOfficeMonths?: TaxQuestionnaireUpdatelaborOfficeMonthsInput | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncomeFiles?: FileUpdateManyInput | null;
  otherIncomes?: boolean | null;
  samePreviousEmployer?: boolean | null;
  selfEmployed?: boolean | null;
  solidarityTax?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
}

export interface TaxQuestionnaireUpdatelaborOfficeMonthsInput {
  set?: Month[] | null;
}

export interface TaxQuestionnaireUpsertWithoutAnnualRebateInput {
  create: TaxQuestionnaireCreateWithoutAnnualRebateInput;
  update: TaxQuestionnaireUpdateWithoutAnnualRebateDataInput;
}

export interface TaxQuestionnaireUpsertWithoutHistoryInput {
  create: TaxQuestionnaireCreateWithoutHistoryInput;
  update: TaxQuestionnaireUpdateWithoutHistoryDataInput;
}

export interface TaxQuestionnaireWhereInput {
  AND?: TaxQuestionnaireWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  concurrentEmployers?: boolean | null;
  concurrentEmployers_not?: boolean | null;
  createByActualEmployer?: boolean | null;
  createByActualEmployer_not?: boolean | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  foreignIncome?: boolean | null;
  foreignIncome_not?: boolean | null;
  history_every?: TaxQuestionnaireSnapWhereInput | null;
  history_none?: TaxQuestionnaireSnapWhereInput | null;
  history_some?: TaxQuestionnaireSnapWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  laborOffice?: boolean | null;
  laborOffice_not?: boolean | null;
  laborOfficeFiles_every?: FileWhereInput | null;
  laborOfficeFiles_none?: FileWhereInput | null;
  laborOfficeFiles_some?: FileWhereInput | null;
  NOT?: TaxQuestionnaireWhereInput[] | null;
  noTaxableIncome?: boolean | null;
  noTaxableIncome_not?: boolean | null;
  noTaxableIncomeFiles_every?: FileWhereInput | null;
  noTaxableIncomeFiles_none?: FileWhereInput | null;
  noTaxableIncomeFiles_some?: FileWhereInput | null;
  OR?: TaxQuestionnaireWhereInput[] | null;
  otherIncomes?: boolean | null;
  otherIncomes_not?: boolean | null;
  samePreviousEmployer?: boolean | null;
  samePreviousEmployer_not?: boolean | null;
  selfEmployed?: boolean | null;
  selfEmployed_not?: boolean | null;
  solidarityTax?: boolean | null;
  solidarityTax_not?: boolean | null;
  status?: TaxQuestionnaireStatus | null;
  status_in?: TaxQuestionnaireStatus[] | null;
  status_not?: TaxQuestionnaireStatus | null;
  status_not_in?: TaxQuestionnaireStatus[] | null;
  updatedAt?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_lt?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_not?: any | null;
  updatedAt_not_in?: any[] | null;
}

export interface TaxQuestionnaireWhereUniqueInput {
  id?: string | null;
}

export interface UnionRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutUnionRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutUnionRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface UnionRebateCreateManyWithoutAnnualRebateInput {
  connect?: UnionRebateWhereUniqueInput[] | null;
  create?: UnionRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface UnionRebateCreateOneInput {
  connect?: UnionRebateWhereUniqueInput | null;
  create?: UnionRebateCreateInput | null;
}

export interface UnionRebateCreateOneWithoutSettlementRequestInput {
  connect?: UnionRebateWhereUniqueInput | null;
  create?: UnionRebateCreateWithoutSettlementRequestInput | null;
}

export interface UnionRebateCreateWithoutAnnualRebateInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  settlementRequest?: SettlementRequestCreateOneWithoutUnionRebateInput | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface UnionRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutUnionRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileCreateManyInput | null;
  id?: string | null;
  status: RebateStatus;
  type?: RequestType | null;
}

export interface UnionRebateScalarWhereInput {
  AND?: UnionRebateScalarWhereInput[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: UnionRebateScalarWhereInput[] | null;
  OR?: UnionRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface UnionRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutUnionRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutUnionRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface UnionRebateUpdateManyDataInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface UnionRebateUpdateManyWithWhereNestedInput {
  data: UnionRebateUpdateManyDataInput;
  where: UnionRebateScalarWhereInput;
}

export interface UnionRebateUpdateManyWithoutAnnualRebateInput {
  connect?: UnionRebateWhereUniqueInput[] | null;
  create?: UnionRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: UnionRebateWhereUniqueInput[] | null;
  deleteMany?: UnionRebateScalarWhereInput[] | null;
  disconnect?: UnionRebateWhereUniqueInput[] | null;
  set?: UnionRebateWhereUniqueInput[] | null;
  update?: UnionRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: UnionRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: UnionRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface UnionRebateUpdateOneRequiredInput {
  connect?: UnionRebateWhereUniqueInput | null;
  create?: UnionRebateCreateInput | null;
  update?: UnionRebateUpdateDataInput | null;
  upsert?: UnionRebateUpsertNestedInput | null;
}

export interface UnionRebateUpdateOneWithoutSettlementRequestInput {
  connect?: UnionRebateWhereUniqueInput | null;
  create?: UnionRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: UnionRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: UnionRebateUpsertWithoutSettlementRequestInput | null;
}

export interface UnionRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: UnionRebateUpdateWithoutAnnualRebateDataInput;
  where: UnionRebateWhereUniqueInput;
}

export interface UnionRebateUpdateWithoutAnnualRebateDataInput {
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutUnionRebateInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface UnionRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutUnionRebatesInput | null;
  commentOfDenied?: string | null;
  feeAmount?: number | null;
  feeConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface UnionRebateUpsertNestedInput {
  create: UnionRebateCreateInput;
  update: UnionRebateUpdateDataInput;
}

export interface UnionRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: UnionRebateCreateWithoutAnnualRebateInput;
  update: UnionRebateUpdateWithoutAnnualRebateDataInput;
  where: UnionRebateWhereUniqueInput;
}

export interface UnionRebateUpsertWithoutSettlementRequestInput {
  create: UnionRebateCreateWithoutSettlementRequestInput;
  update: UnionRebateUpdateWithoutSettlementRequestDataInput;
}

export interface UnionRebateWhereInput {
  AND?: UnionRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  feeAmount?: number | null;
  feeAmount_gt?: number | null;
  feeAmount_gte?: number | null;
  feeAmount_in?: number[] | null;
  feeAmount_lt?: number | null;
  feeAmount_lte?: number | null;
  feeAmount_not?: number | null;
  feeAmount_not_in?: number[] | null;
  feeConfirmationFiles_every?: FileWhereInput | null;
  feeConfirmationFiles_none?: FileWhereInput | null;
  feeConfirmationFiles_some?: FileWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: UnionRebateWhereInput[] | null;
  OR?: UnionRebateWhereInput[] | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface UnionRebateWhereUniqueInput {
  id?: string | null;
}

export interface UnitCreateInput {
  color: string;
  name: string;
}

export interface UnitCreateManyInput {
  connect?: UnitWhereUniqueInput[] | null;
}

export interface UnitCreateManyWithoutCustomerInput {
  connect?: UnitWhereUniqueInput[] | null;
  create?: UnitCreateWithoutCustomerInput[] | null;
}

export interface UnitCreateOneInput {
  connect?: UnitWhereUniqueInput | null;
}

export interface UnitCreateWithoutCustomerInput {
  color: string;
  id?: string | null;
  name: string;
}

export interface UnitScalarWhereInput {
  AND?: UnitScalarWhereInput[] | null;
  color?: string | null;
  color_contains?: string | null;
  color_ends_with?: string | null;
  color_gt?: string | null;
  color_gte?: string | null;
  color_in?: string[] | null;
  color_lt?: string | null;
  color_lte?: string | null;
  color_not?: string | null;
  color_not_contains?: string | null;
  color_not_ends_with?: string | null;
  color_not_in?: string[] | null;
  color_not_starts_with?: string | null;
  color_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  name?: string | null;
  name_contains?: string | null;
  name_ends_with?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_not?: string | null;
  name_not_contains?: string | null;
  name_not_ends_with?: string | null;
  name_not_in?: string[] | null;
  name_not_starts_with?: string | null;
  name_starts_with?: string | null;
  NOT?: UnitScalarWhereInput[] | null;
  OR?: UnitScalarWhereInput[] | null;
}

export interface UnitUpdateInput {
  color?: string | null;
  id?: string | null;
  name?: string | null;
}

export interface UnitUpdateManyDataInput {
  color?: string | null;
  name?: string | null;
}

export interface UnitUpdateManyInput {
  connect?: UnitWhereUniqueInput[] | null;
  disconnect?: UnitWhereUniqueInput[] | null;
}

export interface UnitUpdateManyWithWhereNestedInput {
  data: UnitUpdateManyDataInput;
  where: UnitScalarWhereInput;
}

export interface UnitUpdateManyWithoutCustomerInput {
  connect?: UnitWhereUniqueInput[] | null;
  create?: UnitCreateWithoutCustomerInput[] | null;
  delete?: UnitWhereUniqueInput[] | null;
  deleteMany?: UnitScalarWhereInput[] | null;
  disconnect?: UnitWhereUniqueInput[] | null;
  set?: UnitWhereUniqueInput[] | null;
  update?: UnitUpdateWithWhereUniqueWithoutCustomerInput[] | null;
  updateMany?: UnitUpdateManyWithWhereNestedInput[] | null;
  upsert?: UnitUpsertWithWhereUniqueWithoutCustomerInput[] | null;
}

export interface UnitUpdateOneInput {
  connect?: UnitWhereUniqueInput | null;
  disconnect?: boolean | null;
}

export interface UnitUpdateWithWhereUniqueWithoutCustomerInput {
  data: UnitUpdateWithoutCustomerDataInput;
  where: UnitWhereUniqueInput;
}

export interface UnitUpdateWithoutCustomerDataInput {
  color?: string | null;
  name?: string | null;
}

export interface UnitUpsertWithWhereUniqueWithoutCustomerInput {
  create: UnitCreateWithoutCustomerInput;
  update: UnitUpdateWithoutCustomerDataInput;
  where: UnitWhereUniqueInput;
}

export interface UnitWhereInput {
  AND?: UnitWhereInput[] | null;
  color?: string | null;
  color_contains?: string | null;
  color_ends_with?: string | null;
  color_gt?: string | null;
  color_gte?: string | null;
  color_in?: string[] | null;
  color_lt?: string | null;
  color_lte?: string | null;
  color_not?: string | null;
  color_not_contains?: string | null;
  color_not_ends_with?: string | null;
  color_not_in?: string[] | null;
  color_not_starts_with?: string | null;
  color_starts_with?: string | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  name?: string | null;
  name_contains?: string | null;
  name_ends_with?: string | null;
  name_gt?: string | null;
  name_gte?: string | null;
  name_in?: string[] | null;
  name_lt?: string | null;
  name_lte?: string | null;
  name_not?: string | null;
  name_not_contains?: string | null;
  name_not_ends_with?: string | null;
  name_not_in?: string[] | null;
  name_not_starts_with?: string | null;
  name_starts_with?: string | null;
  NOT?: UnitWhereInput[] | null;
  OR?: UnitWhereInput[] | null;
}

export interface UnitWhereUniqueInput {
  id: string;
}

export interface UpdateOwnLoginInput {
  email?: string | null;
  oldPassword?: string | null;
  password?: string | null;
}

export interface UpdateOwnUserInput {
  birthdate?: any | null;
  czechTaxResidence?: boolean | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  lastname?: string | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  permanentAddress?: string | null;
  residenceAuthorisationFiles?: FileUpdateManyInput | null;
  taxDomicilFiles?: FileUpdateManyInput | null;
}

export interface UserCreateInput {
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  email: string;
  employmentType?: EmploymentType | null;
  firstname?: string | null;
  language?: Language | null;
  lastname?: string | null;
  managedUnits?: UnitCreateManyInput | null;
  nickname?: string | null;
  personalNumber?: string | null;
  role: Role;
  signedConfirmation?: boolean | null;
  unit?: UnitCreateOneInput | null;
}

export interface UserCreateOneInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateInput | null;
}

export interface UserCreateOneWithoutAnnualRebatesInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateWithoutAnnualRebatesInput | null;
}

export interface UserCreateOneWithoutMonthlyRebatesInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateWithoutMonthlyRebatesInput | null;
}

export interface UserCreateOneWithoutSettlementRequestsInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateWithoutSettlementRequestsInput | null;
}

export interface UserCreateWithoutAnnualRebatesInput {
  account: AccountCreateOneWithoutUsersInput;
  annualtax?: AnnualtaxCreateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  customer: CustomerCreateOneWithoutCreatedByInput;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email: string;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  id?: string | null;
  importLog?: ImportLogCreateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitCreateManyInput | null;
  monthlyRebates?: MonthlyRebateCreateManyWithoutUserInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  residenceAuthorisationFiles?: FileCreateManyInput | null;
  role: Role;
  search: string;
  settlementRequests?: SettlementRequestCreateManyWithoutEmployeeInput | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  taxDomicilFiles?: FileCreateManyInput | null;
  unit?: UnitCreateOneInput | null;
}

export interface UserCreateWithoutCustomerInput {
  account: AccountCreateOneWithoutUsersInput;
  annualRebates?: AnnualRebateCreateManyWithoutUserInput | null;
  annualtax?: AnnualtaxCreateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email: string;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  id?: string | null;
  importLog?: ImportLogCreateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitCreateManyInput | null;
  monthlyRebates?: MonthlyRebateCreateManyWithoutUserInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  residenceAuthorisationFiles?: FileCreateManyInput | null;
  role: Role;
  search: string;
  settlementRequests?: SettlementRequestCreateManyWithoutEmployeeInput | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  taxDomicilFiles?: FileCreateManyInput | null;
  unit?: UnitCreateOneInput | null;
}

export interface UserCreateWithoutMonthlyRebatesInput {
  account: AccountCreateOneWithoutUsersInput;
  annualRebates?: AnnualRebateCreateManyWithoutUserInput | null;
  annualtax?: AnnualtaxCreateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  customer: CustomerCreateOneWithoutCreatedByInput;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email: string;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  id?: string | null;
  importLog?: ImportLogCreateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitCreateManyInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  residenceAuthorisationFiles?: FileCreateManyInput | null;
  role: Role;
  search: string;
  settlementRequests?: SettlementRequestCreateManyWithoutEmployeeInput | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  taxDomicilFiles?: FileCreateManyInput | null;
  unit?: UnitCreateOneInput | null;
}

export interface UserCreateWithoutSettlementRequestsInput {
  account: AccountCreateOneWithoutUsersInput;
  annualRebates?: AnnualRebateCreateManyWithoutUserInput | null;
  annualtax?: AnnualtaxCreateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  customer: CustomerCreateOneWithoutCreatedByInput;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email: string;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  id?: string | null;
  importLog?: ImportLogCreateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitCreateManyInput | null;
  monthlyRebates?: MonthlyRebateCreateManyWithoutUserInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  residenceAuthorisationFiles?: FileCreateManyInput | null;
  role: Role;
  search: string;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalCreateManyInput | null;
  taxDomicilFiles?: FileCreateManyInput | null;
  unit?: UnitCreateOneInput | null;
}

export interface UserLogWhereInput {
  action?: UserLogAction | null;
  action_in?: UserLogAction[] | null;
  action_not?: UserLogAction | null;
  action_not_in?: UserLogAction[] | null;
  affectedUser?: UserWhereInput | null;
  AND?: UserLogWhereInput[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  createdBy?: UserWhereInput | null;
  customer?: CustomerWhereInput | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: UserLogWhereInput[] | null;
  OR?: UserLogWhereInput[] | null;
  search?: string | null;
  search_contains?: string | null;
  search_ends_with?: string | null;
  search_gt?: string | null;
  search_gte?: string | null;
  search_in?: string[] | null;
  search_lt?: string | null;
  search_lte?: string | null;
  search_not?: string | null;
  search_not_contains?: string | null;
  search_not_ends_with?: string | null;
  search_not_in?: string[] | null;
  search_not_starts_with?: string | null;
  search_starts_with?: string | null;
  source?: UserLogSource | null;
  source_in?: UserLogSource[] | null;
  source_not?: UserLogSource | null;
  source_not_in?: UserLogSource[] | null;
}

export interface UserUniquenessInput {
  email: string;
  personalNumber?: string | null;
  unit?: UnitCreateOneInput | null;
}

export interface UserUpdateDataInput {
  account?: AccountUpdateOneRequiredWithoutUsersInput | null;
  annualRebates?: AnnualRebateUpdateManyWithoutUserInput | null;
  annualtax?: AnnualtaxUpdateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  customer?: CustomerUpdateOneRequiredWithoutCreatedByInput | null;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email?: string | null;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  importLog?: ImportLogUpdateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitUpdateManyInput | null;
  monthlyRebates?: MonthlyRebateUpdateManyWithoutUserInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  residenceAuthorisationFiles?: FileUpdateManyInput | null;
  role?: Role | null;
  search?: string | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutEmployeeInput | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  taxDomicilFiles?: FileUpdateManyInput | null;
  unit?: UnitUpdateOneInput | null;
}

export interface UserUpdateInput {
  birthdate?: any | null;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  email?: string | null;
  employmentType?: EmploymentType | null;
  firstname?: string | null;
  gender?: Gender | null;
  language?: Language | null;
  lastname?: string | null;
  managedUnits?: UnitUpdateManyInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationFiles?: FileUpdateManyInput | null;
  role?: Role | null;
  signedConfirmation?: boolean | null;
  taxDomicilFiles?: FileUpdateManyInput | null;
  unit?: UnitUpdateOneInput | null;
}

export interface UserUpdateOneInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: UserUpdateDataInput | null;
  upsert?: UserUpsertNestedInput | null;
}

export interface UserUpdateOneRequiredInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateInput | null;
  update?: UserUpdateDataInput | null;
  upsert?: UserUpsertNestedInput | null;
}

export interface UserUpdateOneRequiredWithoutAnnualRebatesInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateWithoutAnnualRebatesInput | null;
  update?: UserUpdateWithoutAnnualRebatesDataInput | null;
  upsert?: UserUpsertWithoutAnnualRebatesInput | null;
}

export interface UserUpdateOneRequiredWithoutMonthlyRebatesInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateWithoutMonthlyRebatesInput | null;
  update?: UserUpdateWithoutMonthlyRebatesDataInput | null;
  upsert?: UserUpsertWithoutMonthlyRebatesInput | null;
}

export interface UserUpdateOneRequiredWithoutSettlementRequestsInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateWithoutSettlementRequestsInput | null;
  update?: UserUpdateWithoutSettlementRequestsDataInput | null;
  upsert?: UserUpsertWithoutSettlementRequestsInput | null;
}

export interface UserUpdateOneWithoutCustomerInput {
  connect?: UserWhereUniqueInput | null;
  create?: UserCreateWithoutCustomerInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: UserUpdateWithoutCustomerDataInput | null;
  upsert?: UserUpsertWithoutCustomerInput | null;
}

export interface UserUpdateWithoutAnnualRebatesDataInput {
  account?: AccountUpdateOneRequiredWithoutUsersInput | null;
  annualtax?: AnnualtaxUpdateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  customer?: CustomerUpdateOneRequiredWithoutCreatedByInput | null;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email?: string | null;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  importLog?: ImportLogUpdateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitUpdateManyInput | null;
  monthlyRebates?: MonthlyRebateUpdateManyWithoutUserInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  residenceAuthorisationFiles?: FileUpdateManyInput | null;
  role?: Role | null;
  search?: string | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutEmployeeInput | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  taxDomicilFiles?: FileUpdateManyInput | null;
  unit?: UnitUpdateOneInput | null;
}

export interface UserUpdateWithoutCustomerDataInput {
  account?: AccountUpdateOneRequiredWithoutUsersInput | null;
  annualRebates?: AnnualRebateUpdateManyWithoutUserInput | null;
  annualtax?: AnnualtaxUpdateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email?: string | null;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  importLog?: ImportLogUpdateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitUpdateManyInput | null;
  monthlyRebates?: MonthlyRebateUpdateManyWithoutUserInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  residenceAuthorisationFiles?: FileUpdateManyInput | null;
  role?: Role | null;
  search?: string | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutEmployeeInput | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  taxDomicilFiles?: FileUpdateManyInput | null;
  unit?: UnitUpdateOneInput | null;
}

export interface UserUpdateWithoutMonthlyRebatesDataInput {
  account?: AccountUpdateOneRequiredWithoutUsersInput | null;
  annualRebates?: AnnualRebateUpdateManyWithoutUserInput | null;
  annualtax?: AnnualtaxUpdateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  customer?: CustomerUpdateOneRequiredWithoutCreatedByInput | null;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email?: string | null;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  importLog?: ImportLogUpdateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitUpdateManyInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  residenceAuthorisationFiles?: FileUpdateManyInput | null;
  role?: Role | null;
  search?: string | null;
  settlementRequests?: SettlementRequestUpdateManyWithoutEmployeeInput | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  taxDomicilFiles?: FileUpdateManyInput | null;
  unit?: UnitUpdateOneInput | null;
}

export interface UserUpdateWithoutSettlementRequestsDataInput {
  account?: AccountUpdateOneRequiredWithoutUsersInput | null;
  annualRebates?: AnnualRebateUpdateManyWithoutUserInput | null;
  annualtax?: AnnualtaxUpdateOneInput | null;
  birthdate?: any | null;
  blockedAnnualOverride?: number | null;
  blockedAt?: any | null;
  customer?: CustomerUpdateOneRequiredWithoutCreatedByInput | null;
  czechTaxResidence?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfQuit?: any | null;
  deletedAt?: any | null;
  email?: string | null;
  employmentType?: EmploymentType | null;
  entryFormDoneAt?: any | null;
  firstname?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDNumber?: string | null;
  foreignIDType?: IDType | null;
  foreignTaxIdentification?: string | null;
  foreignTaxResidentOfState?: Country | null;
  gdprAgreementAt?: any | null;
  gender?: Gender | null;
  importLog?: ImportLogUpdateOneWithoutImportedByInput | null;
  invitedAt?: any | null;
  lastActivityAt?: any | null;
  lastname?: string | null;
  managedUnits?: UnitUpdateManyInput | null;
  monthlyRebates?: MonthlyRebateUpdateManyWithoutUserInput | null;
  nationalIDNumber?: string | null;
  nationality?: Country | null;
  nickname?: string | null;
  oldUserId?: string | null;
  permanentAddress?: string | null;
  personalNumber?: string | null;
  residenceAuthorisationDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  residenceAuthorisationFiles?: FileUpdateManyInput | null;
  role?: Role | null;
  search?: string | null;
  signedConfirmation?: boolean | null;
  taxDomicilDocumentApprovals?: DocumentApprovalUpdateManyInput | null;
  taxDomicilFiles?: FileUpdateManyInput | null;
  unit?: UnitUpdateOneInput | null;
}

export interface UserUpsertNestedInput {
  create: UserCreateInput;
  update: UserUpdateDataInput;
}

export interface UserUpsertWithoutAnnualRebatesInput {
  create: UserCreateWithoutAnnualRebatesInput;
  update: UserUpdateWithoutAnnualRebatesDataInput;
}

export interface UserUpsertWithoutCustomerInput {
  create: UserCreateWithoutCustomerInput;
  update: UserUpdateWithoutCustomerDataInput;
}

export interface UserUpsertWithoutMonthlyRebatesInput {
  create: UserCreateWithoutMonthlyRebatesInput;
  update: UserUpdateWithoutMonthlyRebatesDataInput;
}

export interface UserUpsertWithoutSettlementRequestsInput {
  create: UserCreateWithoutSettlementRequestsInput;
  update: UserUpdateWithoutSettlementRequestsDataInput;
}

export interface UserWhereInput {
  account?: AccountWhereInput | null;
  AND?: UserWhereInput[] | null;
  annualRebates_every?: AnnualRebateWhereInput | null;
  annualRebates_none?: AnnualRebateWhereInput | null;
  annualRebates_some?: AnnualRebateWhereInput | null;
  annualtax?: AnnualtaxWhereInput | null;
  birthdate?: any | null;
  birthdate_gt?: any | null;
  birthdate_gte?: any | null;
  birthdate_in?: any[] | null;
  birthdate_lt?: any | null;
  birthdate_lte?: any | null;
  birthdate_not?: any | null;
  birthdate_not_in?: any[] | null;
  blockedAnnualOverride?: number | null;
  blockedAnnualOverride_gt?: number | null;
  blockedAnnualOverride_gte?: number | null;
  blockedAnnualOverride_in?: number[] | null;
  blockedAnnualOverride_lt?: number | null;
  blockedAnnualOverride_lte?: number | null;
  blockedAnnualOverride_not?: number | null;
  blockedAnnualOverride_not_in?: number[] | null;
  blockedAt?: any | null;
  blockedAt_gt?: any | null;
  blockedAt_gte?: any | null;
  blockedAt_in?: any[] | null;
  blockedAt_lt?: any | null;
  blockedAt_lte?: any | null;
  blockedAt_not?: any | null;
  blockedAt_not_in?: any[] | null;
  czechTaxResidence?: boolean | null;
  czechTaxResidence_not?: boolean | null;
  dateOfEmployment?: any | null;
  dateOfEmployment_gt?: any | null;
  dateOfEmployment_gte?: any | null;
  dateOfEmployment_in?: any[] | null;
  dateOfEmployment_lt?: any | null;
  dateOfEmployment_lte?: any | null;
  dateOfEmployment_not?: any | null;
  dateOfEmployment_not_in?: any[] | null;
  dateOfQuit?: any | null;
  dateOfQuit_gt?: any | null;
  dateOfQuit_gte?: any | null;
  dateOfQuit_in?: any[] | null;
  dateOfQuit_lt?: any | null;
  dateOfQuit_lte?: any | null;
  dateOfQuit_not?: any | null;
  dateOfQuit_not_in?: any[] | null;
  deletedAt?: any | null;
  deletedAt_gt?: any | null;
  deletedAt_gte?: any | null;
  deletedAt_in?: any[] | null;
  deletedAt_lt?: any | null;
  deletedAt_lte?: any | null;
  deletedAt_not?: any | null;
  deletedAt_not_in?: any[] | null;
  email?: string | null;
  email_contains?: string | null;
  email_ends_with?: string | null;
  email_gt?: string | null;
  email_gte?: string | null;
  email_in?: string[] | null;
  email_lt?: string | null;
  email_lte?: string | null;
  email_not?: string | null;
  email_not_contains?: string | null;
  email_not_ends_with?: string | null;
  email_not_in?: string[] | null;
  email_not_starts_with?: string | null;
  email_starts_with?: string | null;
  employmentType?: EmploymentType | null;
  employmentType_in?: EmploymentType[] | null;
  employmentType_not?: EmploymentType | null;
  employmentType_not_in?: EmploymentType[] | null;
  entryFormDoneAt?: any | null;
  entryFormDoneAt_gt?: any | null;
  entryFormDoneAt_gte?: any | null;
  entryFormDoneAt_in?: any[] | null;
  entryFormDoneAt_lt?: any | null;
  entryFormDoneAt_lte?: any | null;
  entryFormDoneAt_not?: any | null;
  entryFormDoneAt_not_in?: any[] | null;
  firstname?: string | null;
  firstname_contains?: string | null;
  firstname_ends_with?: string | null;
  firstname_gt?: string | null;
  firstname_gte?: string | null;
  firstname_in?: string[] | null;
  firstname_lt?: string | null;
  firstname_lte?: string | null;
  firstname_not?: string | null;
  firstname_not_contains?: string | null;
  firstname_not_ends_with?: string | null;
  firstname_not_in?: string[] | null;
  firstname_not_starts_with?: string | null;
  firstname_starts_with?: string | null;
  foreignIDCountry?: Country | null;
  foreignIDCountry_in?: Country[] | null;
  foreignIDCountry_not?: Country | null;
  foreignIDCountry_not_in?: Country[] | null;
  foreignIDNumber?: string | null;
  foreignIDNumber_contains?: string | null;
  foreignIDNumber_ends_with?: string | null;
  foreignIDNumber_gt?: string | null;
  foreignIDNumber_gte?: string | null;
  foreignIDNumber_in?: string[] | null;
  foreignIDNumber_lt?: string | null;
  foreignIDNumber_lte?: string | null;
  foreignIDNumber_not?: string | null;
  foreignIDNumber_not_contains?: string | null;
  foreignIDNumber_not_ends_with?: string | null;
  foreignIDNumber_not_in?: string[] | null;
  foreignIDNumber_not_starts_with?: string | null;
  foreignIDNumber_starts_with?: string | null;
  foreignIDType?: IDType | null;
  foreignIDType_in?: IDType[] | null;
  foreignIDType_not?: IDType | null;
  foreignIDType_not_in?: IDType[] | null;
  foreignTaxIdentification?: string | null;
  foreignTaxIdentification_contains?: string | null;
  foreignTaxIdentification_ends_with?: string | null;
  foreignTaxIdentification_gt?: string | null;
  foreignTaxIdentification_gte?: string | null;
  foreignTaxIdentification_in?: string[] | null;
  foreignTaxIdentification_lt?: string | null;
  foreignTaxIdentification_lte?: string | null;
  foreignTaxIdentification_not?: string | null;
  foreignTaxIdentification_not_contains?: string | null;
  foreignTaxIdentification_not_ends_with?: string | null;
  foreignTaxIdentification_not_in?: string[] | null;
  foreignTaxIdentification_not_starts_with?: string | null;
  foreignTaxIdentification_starts_with?: string | null;
  foreignTaxResidentOfState?: Country | null;
  foreignTaxResidentOfState_in?: Country[] | null;
  foreignTaxResidentOfState_not?: Country | null;
  foreignTaxResidentOfState_not_in?: Country[] | null;
  gdprAgreementAt?: any | null;
  gdprAgreementAt_gt?: any | null;
  gdprAgreementAt_gte?: any | null;
  gdprAgreementAt_in?: any[] | null;
  gdprAgreementAt_lt?: any | null;
  gdprAgreementAt_lte?: any | null;
  gdprAgreementAt_not?: any | null;
  gdprAgreementAt_not_in?: any[] | null;
  gender?: Gender | null;
  gender_in?: Gender[] | null;
  gender_not?: Gender | null;
  gender_not_in?: Gender[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  importLog?: ImportLogWhereInput | null;
  invitedAt?: any | null;
  invitedAt_gt?: any | null;
  invitedAt_gte?: any | null;
  invitedAt_in?: any[] | null;
  invitedAt_lt?: any | null;
  invitedAt_lte?: any | null;
  invitedAt_not?: any | null;
  invitedAt_not_in?: any[] | null;
  lastActivityAt?: any | null;
  lastActivityAt_gt?: any | null;
  lastActivityAt_gte?: any | null;
  lastActivityAt_in?: any[] | null;
  lastActivityAt_lt?: any | null;
  lastActivityAt_lte?: any | null;
  lastActivityAt_not?: any | null;
  lastActivityAt_not_in?: any[] | null;
  lastname?: string | null;
  lastname_contains?: string | null;
  lastname_ends_with?: string | null;
  lastname_gt?: string | null;
  lastname_gte?: string | null;
  lastname_in?: string[] | null;
  lastname_lt?: string | null;
  lastname_lte?: string | null;
  lastname_not?: string | null;
  lastname_not_contains?: string | null;
  lastname_not_ends_with?: string | null;
  lastname_not_in?: string[] | null;
  lastname_not_starts_with?: string | null;
  lastname_starts_with?: string | null;
  managedUnits_every?: UnitWhereInput | null;
  managedUnits_none?: UnitWhereInput | null;
  managedUnits_some?: UnitWhereInput | null;
  monthlyRebates_every?: MonthlyRebateWhereInput | null;
  monthlyRebates_none?: MonthlyRebateWhereInput | null;
  monthlyRebates_some?: MonthlyRebateWhereInput | null;
  nationalIDNumber?: string | null;
  nationalIDNumber_contains?: string | null;
  nationalIDNumber_ends_with?: string | null;
  nationalIDNumber_gt?: string | null;
  nationalIDNumber_gte?: string | null;
  nationalIDNumber_in?: string[] | null;
  nationalIDNumber_lt?: string | null;
  nationalIDNumber_lte?: string | null;
  nationalIDNumber_not?: string | null;
  nationalIDNumber_not_contains?: string | null;
  nationalIDNumber_not_ends_with?: string | null;
  nationalIDNumber_not_in?: string[] | null;
  nationalIDNumber_not_starts_with?: string | null;
  nationalIDNumber_starts_with?: string | null;
  nationality?: Country | null;
  nationality_in?: Country[] | null;
  nationality_not?: Country | null;
  nationality_not_in?: Country[] | null;
  nickname?: string | null;
  nickname_contains?: string | null;
  nickname_ends_with?: string | null;
  nickname_gt?: string | null;
  nickname_gte?: string | null;
  nickname_in?: string[] | null;
  nickname_lt?: string | null;
  nickname_lte?: string | null;
  nickname_not?: string | null;
  nickname_not_contains?: string | null;
  nickname_not_ends_with?: string | null;
  nickname_not_in?: string[] | null;
  nickname_not_starts_with?: string | null;
  nickname_starts_with?: string | null;
  NOT?: UserWhereInput[] | null;
  oldUserId?: string | null;
  oldUserId_contains?: string | null;
  oldUserId_ends_with?: string | null;
  oldUserId_gt?: string | null;
  oldUserId_gte?: string | null;
  oldUserId_in?: string[] | null;
  oldUserId_lt?: string | null;
  oldUserId_lte?: string | null;
  oldUserId_not?: string | null;
  oldUserId_not_contains?: string | null;
  oldUserId_not_ends_with?: string | null;
  oldUserId_not_in?: string[] | null;
  oldUserId_not_starts_with?: string | null;
  oldUserId_starts_with?: string | null;
  OR?: UserWhereInput[] | null;
  permanentAddress?: string | null;
  permanentAddress_contains?: string | null;
  permanentAddress_ends_with?: string | null;
  permanentAddress_gt?: string | null;
  permanentAddress_gte?: string | null;
  permanentAddress_in?: string[] | null;
  permanentAddress_lt?: string | null;
  permanentAddress_lte?: string | null;
  permanentAddress_not?: string | null;
  permanentAddress_not_contains?: string | null;
  permanentAddress_not_ends_with?: string | null;
  permanentAddress_not_in?: string[] | null;
  permanentAddress_not_starts_with?: string | null;
  permanentAddress_starts_with?: string | null;
  personalNumber?: string | null;
  personalNumber_contains?: string | null;
  personalNumber_ends_with?: string | null;
  personalNumber_gt?: string | null;
  personalNumber_gte?: string | null;
  personalNumber_in?: string[] | null;
  personalNumber_lt?: string | null;
  personalNumber_lte?: string | null;
  personalNumber_not?: string | null;
  personalNumber_not_contains?: string | null;
  personalNumber_not_ends_with?: string | null;
  personalNumber_not_in?: string[] | null;
  personalNumber_not_starts_with?: string | null;
  personalNumber_starts_with?: string | null;
  residenceAuthorisationDocumentApprovals_every?: DocumentApprovalWhereInput | null;
  residenceAuthorisationDocumentApprovals_none?: DocumentApprovalWhereInput | null;
  residenceAuthorisationDocumentApprovals_some?: DocumentApprovalWhereInput | null;
  residenceAuthorisationFiles_every?: FileWhereInput | null;
  residenceAuthorisationFiles_none?: FileWhereInput | null;
  residenceAuthorisationFiles_some?: FileWhereInput | null;
  role?: Role | null;
  role_in?: Role[] | null;
  role_not?: Role | null;
  role_not_in?: Role[] | null;
  search?: string | null;
  search_contains?: string | null;
  search_ends_with?: string | null;
  search_gt?: string | null;
  search_gte?: string | null;
  search_in?: string[] | null;
  search_lt?: string | null;
  search_lte?: string | null;
  search_not?: string | null;
  search_not_contains?: string | null;
  search_not_ends_with?: string | null;
  search_not_in?: string[] | null;
  search_not_starts_with?: string | null;
  search_starts_with?: string | null;
  settlementRequests_every?: SettlementRequestWhereInput | null;
  settlementRequests_none?: SettlementRequestWhereInput | null;
  settlementRequests_some?: SettlementRequestWhereInput | null;
  signedConfirmation?: boolean | null;
  signedConfirmation_not?: boolean | null;
  taxDomicilDocumentApprovals_every?: DocumentApprovalWhereInput | null;
  taxDomicilDocumentApprovals_none?: DocumentApprovalWhereInput | null;
  taxDomicilDocumentApprovals_some?: DocumentApprovalWhereInput | null;
  taxDomicilFiles_every?: FileWhereInput | null;
  taxDomicilFiles_none?: FileWhereInput | null;
  taxDomicilFiles_some?: FileWhereInput | null;
  unit?: UnitWhereInput | null;
}

export interface UserWhereUniqueInput {
  id: string;
}

export interface WhereIdInput {
  id: string;
}

export interface ZtppRebateCreateInput {
  annualRebate?: AnnualRebateCreateOneWithoutZtppRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutZtppRebatesInput | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutZtppRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ZtppRebateCreateManyWithoutAnnualRebateInput {
  connect?: ZtppRebateWhereUniqueInput[] | null;
  create?: ZtppRebateCreateWithoutAnnualRebateInput[] | null;
}

export interface ZtppRebateCreateManyWithoutMonthlyRebateInput {
  connect?: ZtppRebateWhereUniqueInput[] | null;
  create?: ZtppRebateCreateWithoutMonthlyRebateInput[] | null;
}

export interface ZtppRebateCreateOneInput {
  connect?: ZtppRebateWhereUniqueInput | null;
  create?: ZtppRebateCreateInput | null;
}

export interface ZtppRebateCreateOneWithoutSettlementRequestInput {
  connect?: ZtppRebateWhereUniqueInput | null;
  create?: ZtppRebateCreateWithoutSettlementRequestInput | null;
}

export interface ZtppRebateCreateWithoutAnnualRebateInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutZtppRebatesInput | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutZtppRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ZtppRebateCreateWithoutMonthlyRebateInput {
  annualRebate?: AnnualRebateCreateOneWithoutZtppRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestCreateOneWithoutZtppRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ZtppRebateCreateWithoutSettlementRequestInput {
  annualRebate?: AnnualRebateCreateOneWithoutZtppRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  id?: string | null;
  monthlyRebate?: MonthlyRebateCreateOneWithoutZtppRebatesInput | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodCreateManyWithoutRebateInput | null;
  socialConfirmationFiles?: FileCreateManyInput | null;
  status: RebateStatus;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileCreateManyInput | null;
}

export interface ZtppRebatePrevYearPeriodCreateManyWithoutRebateInput {
  connect?: ZtppRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: ZtppRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
}

export interface ZtppRebatePrevYearPeriodCreateWithoutRebateInput {
  from?: any | null;
  id?: string | null;
  to?: any | null;
}

export interface ZtppRebatePrevYearPeriodScalarWhereInput {
  AND?: ZtppRebatePrevYearPeriodScalarWhereInput[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ZtppRebatePrevYearPeriodScalarWhereInput[] | null;
  OR?: ZtppRebatePrevYearPeriodScalarWhereInput[] | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface ZtppRebatePrevYearPeriodUpdateManyDataInput {
  from?: any | null;
  to?: any | null;
}

export interface ZtppRebatePrevYearPeriodUpdateManyWithWhereNestedInput {
  data: ZtppRebatePrevYearPeriodUpdateManyDataInput;
  where: ZtppRebatePrevYearPeriodScalarWhereInput;
}

export interface ZtppRebatePrevYearPeriodUpdateManyWithoutRebateInput {
  connect?: ZtppRebatePrevYearPeriodWhereUniqueInput[] | null;
  create?: ZtppRebatePrevYearPeriodCreateWithoutRebateInput[] | null;
  delete?: ZtppRebatePrevYearPeriodWhereUniqueInput[] | null;
  deleteMany?: ZtppRebatePrevYearPeriodScalarWhereInput[] | null;
  disconnect?: ZtppRebatePrevYearPeriodWhereUniqueInput[] | null;
  set?: ZtppRebatePrevYearPeriodWhereUniqueInput[] | null;
  update?: ZtppRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput[] | null;
  updateMany?: ZtppRebatePrevYearPeriodUpdateManyWithWhereNestedInput[] | null;
  upsert?: ZtppRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput[] | null;
}

export interface ZtppRebatePrevYearPeriodUpdateWithWhereUniqueWithoutRebateInput {
  data: ZtppRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: ZtppRebatePrevYearPeriodWhereUniqueInput;
}

export interface ZtppRebatePrevYearPeriodUpdateWithoutRebateDataInput {
  from?: any | null;
  to?: any | null;
}

export interface ZtppRebatePrevYearPeriodUpsertWithWhereUniqueWithoutRebateInput {
  create: ZtppRebatePrevYearPeriodCreateWithoutRebateInput;
  update: ZtppRebatePrevYearPeriodUpdateWithoutRebateDataInput;
  where: ZtppRebatePrevYearPeriodWhereUniqueInput;
}

export interface ZtppRebatePrevYearPeriodWhereInput {
  AND?: ZtppRebatePrevYearPeriodWhereInput[] | null;
  from?: any | null;
  from_gt?: any | null;
  from_gte?: any | null;
  from_in?: any[] | null;
  from_lt?: any | null;
  from_lte?: any | null;
  from_not?: any | null;
  from_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ZtppRebatePrevYearPeriodWhereInput[] | null;
  OR?: ZtppRebatePrevYearPeriodWhereInput[] | null;
  rebate?: ZtppRebateWhereInput | null;
  to?: any | null;
  to_gt?: any | null;
  to_gte?: any | null;
  to_in?: any[] | null;
  to_lt?: any | null;
  to_lte?: any | null;
  to_not?: any | null;
  to_not_in?: any[] | null;
}

export interface ZtppRebatePrevYearPeriodWhereUniqueInput {
  id?: string | null;
}

export interface ZtppRebateScalarWhereInput {
  AND?: ZtppRebateScalarWhereInput[] | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityFrom_gt?: any | null;
  confirmationValidityFrom_gte?: any | null;
  confirmationValidityFrom_in?: any[] | null;
  confirmationValidityFrom_lt?: any | null;
  confirmationValidityFrom_lte?: any | null;
  confirmationValidityFrom_not?: any | null;
  confirmationValidityFrom_not_in?: any[] | null;
  confirmationValidityTo?: any | null;
  confirmationValidityTo_gt?: any | null;
  confirmationValidityTo_gte?: any | null;
  confirmationValidityTo_in?: any[] | null;
  confirmationValidityTo_lt?: any | null;
  confirmationValidityTo_lte?: any | null;
  confirmationValidityTo_not?: any | null;
  confirmationValidityTo_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  NOT?: ZtppRebateScalarWhereInput[] | null;
  OR?: ZtppRebateScalarWhereInput[] | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
}

export interface ZtppRebateUpdateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutZtppRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutZtppRebatesInput | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutZtppRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ZtppRebateUpdateManyDataInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
}

export interface ZtppRebateUpdateManyWithWhereNestedInput {
  data: ZtppRebateUpdateManyDataInput;
  where: ZtppRebateScalarWhereInput;
}

export interface ZtppRebateUpdateManyWithoutAnnualRebateInput {
  connect?: ZtppRebateWhereUniqueInput[] | null;
  create?: ZtppRebateCreateWithoutAnnualRebateInput[] | null;
  delete?: ZtppRebateWhereUniqueInput[] | null;
  deleteMany?: ZtppRebateScalarWhereInput[] | null;
  disconnect?: ZtppRebateWhereUniqueInput[] | null;
  set?: ZtppRebateWhereUniqueInput[] | null;
  update?: ZtppRebateUpdateWithWhereUniqueWithoutAnnualRebateInput[] | null;
  updateMany?: ZtppRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: ZtppRebateUpsertWithWhereUniqueWithoutAnnualRebateInput[] | null;
}

export interface ZtppRebateUpdateManyWithoutMonthlyRebateInput {
  connect?: ZtppRebateWhereUniqueInput[] | null;
  create?: ZtppRebateCreateWithoutMonthlyRebateInput[] | null;
  delete?: ZtppRebateWhereUniqueInput[] | null;
  deleteMany?: ZtppRebateScalarWhereInput[] | null;
  disconnect?: ZtppRebateWhereUniqueInput[] | null;
  set?: ZtppRebateWhereUniqueInput[] | null;
  update?: ZtppRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput[] | null;
  updateMany?: ZtppRebateUpdateManyWithWhereNestedInput[] | null;
  upsert?: ZtppRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput[] | null;
}

export interface ZtppRebateUpdateOneRequiredInput {
  connect?: ZtppRebateWhereUniqueInput | null;
  create?: ZtppRebateCreateInput | null;
  update?: ZtppRebateUpdateDataInput | null;
  upsert?: ZtppRebateUpsertNestedInput | null;
}

export interface ZtppRebateUpdateOneWithoutSettlementRequestInput {
  connect?: ZtppRebateWhereUniqueInput | null;
  create?: ZtppRebateCreateWithoutSettlementRequestInput | null;
  delete?: boolean | null;
  disconnect?: boolean | null;
  update?: ZtppRebateUpdateWithoutSettlementRequestDataInput | null;
  upsert?: ZtppRebateUpsertWithoutSettlementRequestInput | null;
}

export interface ZtppRebateUpdateWithWhereUniqueWithoutAnnualRebateInput {
  data: ZtppRebateUpdateWithoutAnnualRebateDataInput;
  where: ZtppRebateWhereUniqueInput;
}

export interface ZtppRebateUpdateWithWhereUniqueWithoutMonthlyRebateInput {
  data: ZtppRebateUpdateWithoutMonthlyRebateDataInput;
  where: ZtppRebateWhereUniqueInput;
}

export interface ZtppRebateUpdateWithoutAnnualRebateDataInput {
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutZtppRebatesInput | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutZtppRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ZtppRebateUpdateWithoutMonthlyRebateDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutZtppRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  settlementRequest?: SettlementRequestUpdateOneWithoutZtppRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ZtppRebateUpdateWithoutSettlementRequestDataInput {
  annualRebate?: AnnualRebateUpdateOneWithoutZtppRebatesInput | null;
  closePeriodAt?: any | null;
  commentOfDenied?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityTo?: any | null;
  monthlyRebate?: MonthlyRebateUpdateOneWithoutZtppRebatesInput | null;
  prevYearPeriods?: ZtppRebatePrevYearPeriodUpdateManyWithoutRebateInput | null;
  socialConfirmationFiles?: FileUpdateManyInput | null;
  status?: RebateStatus | null;
  type?: RequestType | null;
  ztppConfirmationFiles?: FileUpdateManyInput | null;
}

export interface ZtppRebateUpsertNestedInput {
  create: ZtppRebateCreateInput;
  update: ZtppRebateUpdateDataInput;
}

export interface ZtppRebateUpsertWithWhereUniqueWithoutAnnualRebateInput {
  create: ZtppRebateCreateWithoutAnnualRebateInput;
  update: ZtppRebateUpdateWithoutAnnualRebateDataInput;
  where: ZtppRebateWhereUniqueInput;
}

export interface ZtppRebateUpsertWithWhereUniqueWithoutMonthlyRebateInput {
  create: ZtppRebateCreateWithoutMonthlyRebateInput;
  update: ZtppRebateUpdateWithoutMonthlyRebateDataInput;
  where: ZtppRebateWhereUniqueInput;
}

export interface ZtppRebateUpsertWithoutSettlementRequestInput {
  create: ZtppRebateCreateWithoutSettlementRequestInput;
  update: ZtppRebateUpdateWithoutSettlementRequestDataInput;
}

export interface ZtppRebateWhereInput {
  AND?: ZtppRebateWhereInput[] | null;
  annualRebate?: AnnualRebateWhereInput | null;
  closePeriodAt?: any | null;
  closePeriodAt_gt?: any | null;
  closePeriodAt_gte?: any | null;
  closePeriodAt_in?: any[] | null;
  closePeriodAt_lt?: any | null;
  closePeriodAt_lte?: any | null;
  closePeriodAt_not?: any | null;
  closePeriodAt_not_in?: any[] | null;
  commentOfDenied?: string | null;
  commentOfDenied_contains?: string | null;
  commentOfDenied_ends_with?: string | null;
  commentOfDenied_gt?: string | null;
  commentOfDenied_gte?: string | null;
  commentOfDenied_in?: string[] | null;
  commentOfDenied_lt?: string | null;
  commentOfDenied_lte?: string | null;
  commentOfDenied_not?: string | null;
  commentOfDenied_not_contains?: string | null;
  commentOfDenied_not_ends_with?: string | null;
  commentOfDenied_not_in?: string[] | null;
  commentOfDenied_not_starts_with?: string | null;
  commentOfDenied_starts_with?: string | null;
  confirmationValidityFrom?: any | null;
  confirmationValidityFrom_gt?: any | null;
  confirmationValidityFrom_gte?: any | null;
  confirmationValidityFrom_in?: any[] | null;
  confirmationValidityFrom_lt?: any | null;
  confirmationValidityFrom_lte?: any | null;
  confirmationValidityFrom_not?: any | null;
  confirmationValidityFrom_not_in?: any[] | null;
  confirmationValidityTo?: any | null;
  confirmationValidityTo_gt?: any | null;
  confirmationValidityTo_gte?: any | null;
  confirmationValidityTo_in?: any[] | null;
  confirmationValidityTo_lt?: any | null;
  confirmationValidityTo_lte?: any | null;
  confirmationValidityTo_not?: any | null;
  confirmationValidityTo_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_gt?: any | null;
  createdAt_gte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_lt?: any | null;
  createdAt_lte?: any | null;
  createdAt_not?: any | null;
  createdAt_not_in?: any[] | null;
  id?: string | null;
  id_contains?: string | null;
  id_ends_with?: string | null;
  id_gt?: string | null;
  id_gte?: string | null;
  id_in?: string[] | null;
  id_lt?: string | null;
  id_lte?: string | null;
  id_not?: string | null;
  id_not_contains?: string | null;
  id_not_ends_with?: string | null;
  id_not_in?: string[] | null;
  id_not_starts_with?: string | null;
  id_starts_with?: string | null;
  monthlyRebate?: MonthlyRebateWhereInput | null;
  NOT?: ZtppRebateWhereInput[] | null;
  OR?: ZtppRebateWhereInput[] | null;
  prevYearPeriods_every?: ZtppRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_none?: ZtppRebatePrevYearPeriodWhereInput | null;
  prevYearPeriods_some?: ZtppRebatePrevYearPeriodWhereInput | null;
  settlementRequest?: SettlementRequestWhereInput | null;
  socialConfirmationFiles_every?: FileWhereInput | null;
  socialConfirmationFiles_none?: FileWhereInput | null;
  socialConfirmationFiles_some?: FileWhereInput | null;
  status?: RebateStatus | null;
  status_in?: RebateStatus[] | null;
  status_not?: RebateStatus | null;
  status_not_in?: RebateStatus[] | null;
  type?: RequestType | null;
  type_in?: RequestType[] | null;
  type_not?: RequestType | null;
  type_not_in?: RequestType[] | null;
  ztppConfirmationFiles_every?: FileWhereInput | null;
  ztppConfirmationFiles_none?: FileWhereInput | null;
  ztppConfirmationFiles_some?: FileWhereInput | null;
}

export interface ZtppRebateWhereUniqueInput {
  id?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
