import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { NexusGenEnums } from 'kubik-server'
import { OK, Wrong } from '../../components/icons/KubikIcons'
import { useRebateOverviewStatusEnum } from '../../hooks/useEnumList'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Currency from '../../components/Currency'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import HelpAdornment from '../../components/form/HelpAdornment'
import {IRebate} from "../../../../server/src/fragments/User";
import moment from "moment";

interface IRebateRowProps {
  icon?: React.ReactElement
  label: string
  info?: string | null
  amount?: number | null
  status: NexusGenEnums['RebateOverviewStatus']
  closePeriodAt?: moment.MomentInput
  commentOfDenied?: string | null
  settlementRequestStatus?: NexusGenEnums['SettlementRequestStatus']
  applied?: boolean | null
  subHeading?: string
  help?: string
  rebate?: IRebate
}

type TStylesProps = IRebateRowProps & {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, TStylesProps>((theme: Theme) => ({
  row: {
    padding: theme.spacing(2, 3),
    '&:nth-child(even)': { backgroundColor: '#F9F9F9' },
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  commentOfDeniedWrapper: {
    marginTop: '0.5rem'
  },
  commentOfDenied: {
    color: 'red',
    textAlign: 'justify',
    fontWeight: 900,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical"
  },
}))

const RebateRow: React.FC<IRebateRowProps> = (props) => {
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen, ...props })

  const { t } = useTranslation()
  const { translate } = useRebateOverviewStatusEnum()
  const waiting =
    props.settlementRequestStatus &&
    props.settlementRequestStatus !== 'APPLY' &&
    props.status === 'APPLY'

  const icon = props.icon
    ? React.cloneElement(props.icon, {
        fontSize: 'inherit',
      })
    : null

  const validTo = !!props.rebate && !!props.rebate.closePeriodAt && moment(props.rebate.closePeriodAt)

  return (
    <>
      <Grid
        container
        className={classes.row}
        justifyContent={isNotSmallScreen ? 'space-between' : 'center'}
      >
        <Grid
          item
          container
          xs={isNotSmallScreen ? true : 12}
          alignItems="center"
          justifyContent={isNotSmallScreen ? undefined : 'center'}
        >
          <Box
            display={isNotSmallScreen ? 'flex' : 'flex'}
            flexDirection={isNotSmallScreen ? 'row' : 'column'}
            alignItems="center"
            flexShrink={0}
          >
            {icon ? (
              <Box display="flex" fontSize="2.2rem" mr={isNotSmallScreen ? 3 : 0} minWidth={32}>
                {icon}
              </Box>
            ) : null}
            <Box>
              <Typography variant="h3">{props.label}</Typography>
              {props.subHeading ? (
                <Box display="flex" alignItems="center" mt={0.5}>
                  <Typography variant="body2">{props.subHeading}</Typography>
                  {props.help ? (
                    <HelpAdornment text={props.help} inline />
                  ) : null}
                </Box>
              ) : null}
            </Box>
          </Box>
        </Grid>

        {typeof props.applied === 'boolean' && (
          <Grid
            item
            container
            xs={isNotSmallScreen ? true : 12}
            justifyContent={isNotSmallScreen ? 'flex-end' : 'center'}
            alignItems="center"
          >
            <Typography>
              {props.applied ? (
                t('common.applied') + (!!validTo ? (' ' + t('common.to').toLowerCase() + ' ' + validTo.format('DD.MM.YYYY')) : '')
              ) : (props.status === 'DENIED' ? t('common.notApplied') : '')}
            </Typography>
          </Grid>
        )}

        <Grid
          item
          container
          xs={isNotSmallScreen ? true : 12}
          justifyContent={isNotSmallScreen ? 'space-between' : 'center'}
          alignItems="center"
        >
          <Typography>
            {props.status === 'CONFIRMED' && (
              <Currency value={props.amount} postfix="Kč" />
            )}
          </Typography>

          <Typography
            className={classes.status}
            variant="h4"
            color={
              props.status === 'CONFIRMED'
                ? 'primary'
                : props.status === 'DENIED'
                ? 'error'
                : 'initial'
            }
          >
            {waiting
              ? t('taxRebates.waitingToBeSent')
              : translate(props.status)}
            {props.status === 'CONFIRMED' && <OK className={classes.icon} />}
            {props.status === 'DENIED' && <Wrong className={classes.icon} />}
          </Typography>
        </Grid>

        {props.status === 'DENIED' && props.commentOfDenied && (
          <Grid item xs={12} className={classes.commentOfDeniedWrapper}>
            <Typography variant="body2" className={classes.commentOfDenied}>
              {t('taxRebates.accountantsComment') + ": " + props.commentOfDenied}
            </Typography>
          </Grid>
        )}
      </Grid>
      {props.info && (
        <Grid item container xs={12} alignItems="center">
          <Box mr={'80px'} />
          <Typography variant="caption">{props.info}</Typography>
        </Grid>
      )}
    </>
  )
}

export default RebateRow
