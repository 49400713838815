import {useTranslation} from 'react-i18next'
import RebateRow from '../../../../../shared/RebatesOverview/RebateRow'
import React from 'react'
import {DanoveResidenstvi} from '../../../../../components/icons/KubikIcons'
import useUser from '../../../../../hooks/useUser'

const TaxResidence: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()

  const lastResidenceStatus = user.getLastResidenceFilesStatus()
  const shouldShowResidence =
    lastResidenceStatus &&
    ['CONFIRMED', 'DENIED', 'APPLY'].includes(lastResidenceStatus)
  const hasNewResidence = user.hasNewResidenceFiles()
  const commentOfDenied = user.lastResienceCommentOfDenied()

  return user.shouldHaveResidenceFiles() && (shouldShowResidence || hasNewResidence) ? (
    <RebateRow
      label={t('taxRebates.taxResidenceTitle')}
      info={
        lastResidenceStatus === 'APPLY'
          ? null
          : user.prevDocumentsIsDenied()
          ? t('annualRebateTaxResidence.someRebatesNotApplied')
          : null
      }
      status={lastResidenceStatus || 'UNFINISHED'}
      subHeading={t('taxRebates.residence.subHeading')}
      help={t('taxRebates.residence.help')}
      icon={<DanoveResidenstvi />}
      commentOfDenied={commentOfDenied}
    />
  ) : null
}

export default TaxResidence
