import React from 'react'
import {Trans} from "react-i18next"

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import {Green} from '../../../../../../../components/CommonBox'
import {Dotaznik, Dulezite, KalendarInput, SlevyGreen, Smazat40} from '../../../../../../../components/icons/KubikIcons'
import {useStyles} from "../../../../AnnualRebates/pages/Intro/components/Information"
import moment from "moment";
import {User} from "../../../../../../../containers/User";

export const Information: React.FC<{user: User}> = (props) => {
    const classes = useStyles()
  const { user } = props
  const isAnnualRebate = moment(user.data.dateOfEmployment).year() !== user.data.customer.yearOfAnnualRebates && moment().isBetween(moment(user.data.customer.annualRebatesPeriodStart), moment(user.data.customer.annualRebatesPeriodEnd))

    const items = [
        {
            icon: <Dulezite color="primary" />,
            text: (
                <Trans i18nKey="monthlyRebate.point.important">
                  <Green>Důležité:</Green>
                  <br/>
                  Je potřeba projít všechny kroky, aby se žádost poslala na mzdovou účetní.
                </Trans>
            )
        },
        {
            icon: <SlevyGreen />,
            text: (
                <Trans i18nKey="monthlyRebate.point.contentSale">
                  <Green>Obsahuje daňové slevy:</Green>
                  <br/>
                  Základní poplatník, Děti, Invalidita a ZTP/P
                </Trans>
            )
        },
        {
            icon: <KalendarInput color="primary" />,
            text: (
              (isAnnualRebate) ?
              <Trans i18nKey="monthlyRebate.point.termAnnual">
                <Green>Termín:</Green>
                <br/>
                Při RZ: Do 17. února
                <br/>
                Mimo RZ: Do 30 dní od data nástupu
              </Trans>
              :

              <Trans i18nKey="monthlyRebate.point.term">
                <Green>Termín:</Green>
                <br/>
                Mimo RZ: Do 30 dní od data nástupu
              </Trans>
            )
        },
        {
            icon: <Dotaznik />,
            text: (
                <Trans i18nKey="monthlyRebate.point.officialName">
                    <Green>Oficiální název formuláre:</Green>
                    <br/>
                    Prohlášení poplatníka daně z příjmů fyzických osob
                </Trans>
            )
        },
        {
            icon: <Smazat40 />,
            text: (
                <Trans i18nKey="monthlyRebate.point.rejectFillForm">
                  <Green>Nechci vyplnit formulář:</Green>
                  <br/>
                  Nechcete-li uplatňovat měsíční slevy, v kroku Dotazník vyberte danou možnost.
                  Tím informujete mzdovou účetní o této skutečnosti a zároveň Vám aplikace přestane zasílat e-mailová upozornění.
                </Trans>
            )
        }
    ]    

    return (
        <List className={classes.list}>
            {items.map((item, i) => {
                return (
                    <ListItem className={classes.item} key={i} alignItems='flex-start'>
                        <ListItemIcon className={classes.icon}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText className={classes.text}>
                            {item.text}
                        </ListItemText>
                    </ListItem>
                )
            })}
        </List>
    )
}
