import { gql } from 'apollo-boost'
import { NexusGenFieldTypes } from 'kubik-server'

export type TTaxQuestionnaire = NexusGenFieldTypes['TaxQuestionnaire']
export type TMonthlyTaxQuestionnaire = NexusGenFieldTypes['MonthlyTaxQuestionnaire']

export const TAX_QUESTIONNAIRE_WITH_HISTORY = gql`
  fragment TaxQuestionnaireWithHistory on TaxQuestionnaire {
    id
    pozp
    status
    commentOfDenied
    createByActualEmployer
    samePreviousEmployer
    otherIncomes
    selfEmployed
    concurrentEmployers
    solidarityTax
    foreignIncome
    laborOffice
    laborOfficeMonths
    laborOfficeFiles {
      id
      filename
      size
      createdAt
    }
    noTaxableIncome
    noTaxableIncomeFiles {
      id
      filename
      size
      createdAt
    }
    history(orderBy: createdAt_DESC) {
      id
      status
      commentOfDenied
      createByActualEmployer
      samePreviousEmployer
      otherIncomes
      selfEmployed
      concurrentEmployers
      solidarityTax
      foreignIncome
      laborOffice
      laborOfficeMonths
      laborOfficeFiles {
        id
        filename
        size
        createdAt
      }
      noTaxableIncome
      noTaxableIncomeFiles {
        id
        filename
        size
        createdAt
      }
      settlementRequest {
        id
        status
        requestedAt
        submittedAt
      }
    }
  }
`

export const MONTHLY_TAX_QUESTIONNAIRE_WITH_HISTORY = gql`
  fragment MonthlyTaxQuestionnaireWithHistory on MonthlyTaxQuestionnaire {
    id
    status
    dontCreate
    commentOfDenied
    createByActualEmployer
    madeByAnotherEmployer
    history(orderBy: createdAt_DESC) {
      id
      status
      commentOfDenied
      createByActualEmployer
      madeByAnotherEmployer
      settlementRequest {
        id
        status
        requestedAt
        submittedAt
      }
    }
  }
`
