import {
  REQUEST_LOGIN,
  IRequestLoginData,
  IRequestLoginVariables,
} from '../gql'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Observer } from 'mobx-react'
import { useMutation } from 'react-apollo-hooks'
import { useTranslation, Trans } from 'react-i18next'
import { withRouter, RouteComponentProps, Link, Route } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import React from 'react'
import TextField from '../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../hooks/useForm'
import Divider from '../../../components/Divider'
import Fade from '@material-ui/core/Fade'
import { Zamecek, Microsoft } from '../../../components/icons/KubikIcons'
import logoPng from '../../../assets/logo.png'
import prihlaseniLeft from '../../../assets/images/Ilustrace-mzdova-ucetni.svg'
import prihlaseniRight from '../../../assets/images/Ilustrace-zamestnanec.svg'
import AppState from '../../../components/AppState'
import Dialog from '../../../components/Dialog'
import DialogActions from '../../../components/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import BoxBackground from "../../../components/BoxBackground";
import router from "../../../../../server/src/api";
import {useRouter} from "../../../hooks";
import OpenIdLogo from "../../../components/icons/OpenIdLogo";
import useRedirectAfterLogin from "../../../hooks/useRedirectAfterLogin";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => ({
  container: {
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
    width: "100%",
    backgroundColor: '#ffffff',
  },
  backgroundLeft: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    width: 660,
    backgroundImage: `url('${prihlaseniLeft}')`,
    //backgroundColor: '#f8f8f8',
    backgroundRepeat: 'no-repeat',
    minHeight: 544,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(8),
  },
  backgroundRight: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    width: 622,
    backgroundImage: `url('${prihlaseniRight}')`,
    //backgroundColor: '#f8f8f8',
    backgroundRepeat: 'no-repeat',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(8),
  },
  backgroundFooter: {
    display: (props) => (props.compactScreen ? 'none' : undefined),
    background: '#f8f8f8',
    width: '100%',
    height: 90,
  },
  green: { color: theme.palette.primary.main },
  greenBold: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  logo: {
    marginBottom: theme.spacing(3),
  },
  backgroundGray: {
    backgroundColor: '#f8f8f8',
  },
}))

interface Props extends RouteComponentProps<{redirectAfterLogin: string}> {}

const Login: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })
  const { history } = useRouter()
  const redirectAfterLogin = useRedirectAfterLogin()

  redirectAfterLogin.onLoginPage(props.location.search)

  const requestLogin = useMutation<IRequestLoginData, IRequestLoginVariables>(
    REQUEST_LOGIN,
  )

  const ssoRedirect = () => {
    window.location.assign('/sso')
  }

  const { bind, form } = useForm<IRequestLoginVariables>(
    {
      email: '',
      password: '',
    },
    {
      email: {
        label: t('common.email'),
        placeholder: t('common.emailPlaceholder'),
        rule: 'required',
      },
      password: {
        label: t('common.password'),
        placeholder: t('common.passwordPlaceholder'),
        rule: 'required',
        onEnter: 'submit',
      },
    },
    {
      async onSubmit(data, form) {
        if (!form.state.isValid) {
          form.setTouchedAll()
          return
        }

        try {
          const result = await requestLogin({
            variables: { email: data.email, password: data.password },
          })

          if (result && result.data) {
            props.history.push({
              pathname: `/loginconfirm/${result.data.requestLogin.token}`,
              state: {
                from: props.location.pathname,
              },
            })
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  return (
    <Observer>
      {() => (
        <BoxBackground left={prihlaseniLeft} right={prihlaseniRight} topBackgroundColor={'#ffffff'} bottomBackgroundColor={'#f8f8f8'}>
          <Box p={6} className={classes.container}>
            <Route path="/login-again">
              {({ match }) => (
                <Dialog
                  open={Boolean(match)}
                  fullWidth
                  maxWidth="sm"
                  scroll="body"
                >
                  <DialogTitle>{t('login.loginAgainTitle')}</DialogTitle>
                  <DialogContent>
                    <Typography align="center">
                      {t('login.loginAgainContent')}
                    </Typography>
                  </DialogContent>
                  <DialogActions dividerProps={{ mt: 2 }} gutterBottom>
                    <Box display="flex" justifyContent="center">
                      <Button
                        component={Link}
                        to="/"
                        variant="contained"
                        size="large"
                        color="primary"
                      >
                        {t('login.loginAgain')}
                      </Button>
                    </Box>
                  </DialogActions>
                </Dialog>
              )}
            </Route>
            <AppState loading={form.state.loading} />

            <Box display="flex" justifyContent="center">
              <img
                alt=""
                className={classes.logo}
                src={logoPng}
                width={250}
                height={48}
              />
            </Box>

            <Typography align="center" variant="h1" gutterBottom>
              {t('login.heading')}
            </Typography>

            <Box textAlign="center" fontFamily="fontFamily" mb={4}>
              <Trans i18nKey="login.subHeading">
                Vítejte! Ke vstupu do
                <strong className={classes.green}>Payminatora</strong>
                <br />
                použijte své přihlašovací údaje
              </Trans>
            </Box>

            <TextField fullWidth gutterBottom {...bind('email')} />

            <TextField
              fullWidth
              gutterBottom
              type="password"
              {...bind('password')}
            />

            <Box mb={4}>
              <Typography align="left" className={classes.greenBold}>
                <Link to="/resetpassword">
                  {t('login.forgottenPassword')}
                </Link>
              </Typography>
            </Box>

            <Fade in={form.state.errors.length > 0}>
              <Box mb={2}>
                {form.state.errors.map((err, idx) => (
                  <Typography align="center" color="error" key={err + idx}>
                    <strong>{err}</strong>
                  </Typography>
                ))}
              </Box>
            </Fade>

            <Box display="flex" justifyContent="center">
              <Button
                onClick={form.submit}
                size="large"
                color="primary"
                variant="contained"
              >
                {t('common.signin')}
                <span
                  style={{
                    fontSize: '0.75rem',
                    marginLeft: '8px',
                    marginTop: '3px',
                  }}
                >
                  <Zamecek fontSize="inherit" />
                </span>
              </Button>
            </Box>

            <Divider spacing={4} />

            <Box display="flex" justifyContent="center" style={{marginBottom: '1rem'}}>
              <Button
                onClick={ssoRedirect}
                size={isNotSmallScreen ? 'large' : 'medium'}
                color="secondary"
                variant="outlined"
                style={{ background: "white" }}
              >
                <Box display="flex" justifyContent="center" alignItems="center" style={{ gap: "0.5rem" }}>
                  <Microsoft />
                  {isNotSmallScreen
                    ? t('common.ssoMicrosoft')
                    : t('common.ssoMicrosoftShort')}
                </Box>
                <span
                  style={{
                    fontSize: '0.75rem',
                    marginLeft: '8px',
                    marginTop: '3px',
                  }}
                ></span>
              </Button>
            </Box>

            <Box display="flex" justifyContent="center">
              <Button
                onClick={() => {
                  history.push('/openid')
                }}
                size={isNotSmallScreen ? 'large' : 'medium'}
                color="secondary"
                variant="outlined"
                style={{background: "white"}}
              >
                <Box display="flex" justifyContent="center" alignItems="center" style={{ gap: "0.5rem" }}>
                  <OpenIdLogo />
                  {isNotSmallScreen
                    ? t('common.ssoOpenID')
                    : t('common.ssoOpenIDShort')}
                </Box>
                <span
                  style={{
                    fontSize: '0.75rem',
                    marginLeft: '57px',
                    marginTop: '3px',
                  }}
                ></span>
              </Button>
            </Box>

            <Divider spacing={4}/>

            <Box textAlign="center" fontFamily="fontFamily" /*mb={2}*/>
              <Trans i18nKey="common.needHelp">
                Potřebujete pomoct?
                <a href="mailto:helpdesk@payminator.cz">Napište nám.</a>
              </Trans>
            </Box>
          </Box>
        </BoxBackground>
      )}
    </Observer>
  )
}

export default withRouter(Login)
