import { ApolloQueryResult, OperationVariables } from 'apollo-boost'
import { orderByCreatedAtDesc, userWorkedWholeYear } from '../utils'
import { RebateStatus } from '../../../server/src/generated/prisma-client'
import {TUser, IMeData, DEREINCARNATE, TDereincarnateData} from '../pages/gql'
import { useMutation } from 'react-apollo'
import get from 'lodash/get'
import moment from 'moment'
import React from 'react'

type TRefetch = (
  variables?: OperationVariables | undefined,
) => Promise<ApolloQueryResult<IMeData>>

interface IUnit {
  id: string
  name: string
  color: string
}

export class User {
  public constructor(public readonly data: TUser) {}

  /**
   * Roční slevy jsou přístupné, dotyčný je zablokovaný, ale má vyjímku pro tento rok
   */
  public blockedAnnualOverride = (): boolean =>
    Boolean(
      this.data.allowAnnual &&
        this.data.blockedAt &&
        this.data.blockedAnnualOverride ===
          this.data.customer.yearOfAnnualRebates,
    )
  public isKeeper = (): boolean => this.data.role === 'KEEPER'
  public isSupport = (): boolean => this.data.role === 'SUPPORT'
  public isSuperadmin = (): boolean => this.data.role === 'SUPERADMIN'
  public isEmployee = (): boolean => this.data.role === 'EMPLOYEE'
  public isAccountant = (): boolean => this.data.role === 'ACCOUNTANT'
  public isImplementator = (): boolean => this.data.role === 'IMPLEMENTATOR'
  public isViewer = (): boolean => this.data.role === 'VIEWER'
  public allowDocumentsForViewer = (): boolean => this.data.customer.allowDocumentsForViewer
  public dontRequireBasicRebateAffidavitFiles = (): boolean => this.data.customer.dontRequireBasicRebateAffidavitFiles
  public allowMessages = (): boolean => {
    if (this.isViewer()) {
      return this.data.customer.allowMessagesForViewer
    }

    if (this.isEmployee()) {
      return this.data.customer.allowMessages
    }

    return true
  }

  public isReincarnation = (): boolean => this.data.reincarnation
  public allowAlter = (): boolean => {
    if (this.isReincarnation() && this.data.reincarnatedUser) {
      return (
        this.data.reincarnatedUser.role === 'KEEPER' ||
        this.data.reincarnatedUser.role === 'ACCOUNTANT' ||
        this.data.reincarnatedUser.role === 'SUPPORT'
      )
    }

    return false
  }
  public isSupportReincarnation = (): boolean => this.data.reincarnation && !!this.data.reincarnatedUser && this.data.reincarnatedUser.role === 'SUPPORT'
  public managedUnits = (): IUnit[] => {
    return this.data.managedUnits || []
  }

  // pokud není občan ČR, ale má daňové residenství
  // tak musí dodat podklady k residenství
  public shouldHaveResidenceFiles = (): boolean =>
    this.data.nationality !== 'CZ' && this.data.czechTaxResidence === true

  public shouldHaveResidenceStep = (): boolean =>
    (this.data.nationality !== 'CZ' && this.data.czechTaxResidence === true) ||
    this.data.czechTaxResidence === false

  public noProvidedFiles = (): boolean =>
    (this.data.taxDomicilFiles || []).length === 0 &&
    (this.data.residenceAuthorisationFiles || []).length === 0

  // pokud už jednou prošly domicily schvalovacím procesem,
  // pak nepůjdou měnit v profilu
  public hasAppliedResidenceFlies = (): boolean => {
    const previousDomicils = (this.data.taxDomicilDocumentApprovals || [])
      .filter(
        (appr) =>
          appr.status === 'DENIED' ||
          appr.status === 'CONFIRMED' ||
          appr.status === 'APPLY',
      )
      .sort(orderByCreatedAtDesc)

    const previousResidence = (
      this.data.residenceAuthorisationDocumentApprovals || []
    )
      .filter(
        (appr) =>
          appr.status === 'DENIED' ||
          appr.status === 'CONFIRMED' ||
          appr.status === 'APPLY',
      )
      .sort(orderByCreatedAtDesc)

    return previousDomicils.length > 0 || previousResidence.length > 0
  }

  public lastResienceCommentOfDenied = (): string | null => {
    const previousDomicils = (this.data.taxDomicilDocumentApprovals || [])
      .filter((appr) => appr.status === 'DENIED')
      .sort(orderByCreatedAtDesc)

    const previousResidence = (
      this.data.residenceAuthorisationDocumentApprovals || []
    )
      .filter((appr) => appr.status === 'DENIED')
      .sort(orderByCreatedAtDesc)

    if (previousDomicils.length > 0) {
      return previousDomicils[0].commentOfDenied || null
    }

    if (previousResidence.length > 0) {
      return previousResidence[0].commentOfDenied || null
    }

    return null
  }

  public prevDocumentsIsDenied = (): boolean => {
    const previousDomicils = (this.data.taxDomicilDocumentApprovals || [])
      .filter((appr) => appr.status === 'DENIED' || appr.status === 'CONFIRMED')
      .sort(orderByCreatedAtDesc)

    const previousResidence = (
      this.data.residenceAuthorisationDocumentApprovals || []
    )
      .filter((appr) => appr.status === 'DENIED' || appr.status === 'CONFIRMED')
      .sort(orderByCreatedAtDesc)

    // musí být zamítnuté oba poslední typy, kdyby byl jen jeden, tak je to
    // bráno jako platné potvrzení
    // navíc musíme brát poslední DocumentApproval v čase
    const prevDocumentsDenied = () => {
      // pokud existují oba, tak musí být zamítnuté oba dva,
      // jinak stačí že jeden je schválený
      if (previousDomicils.length > 0 && previousResidence.length > 0) {
        return (
          previousDomicils[0] &&
          previousDomicils[0].status === 'DENIED' &&
          previousResidence[0] &&
          previousResidence[0].status === 'DENIED'
        )
      } else {
        // v případě, že je jen jeden a je zamítnutý
        return (
          (previousDomicils.length > 0 &&
            previousDomicils[0] &&
            previousDomicils[0].status === 'DENIED') ||
          (previousResidence.length > 0 &&
            previousResidence[0] &&
            previousResidence[0].status === 'DENIED')
        )
      }
    }

    return prevDocumentsDenied()
  }

  public getLastResidenceFilesStatus = ():
    | 'DENIED'
    | 'CONFIRMED'
    | 'APPLY'
    | null => {
    const previousDomicils = (this.data.taxDomicilDocumentApprovals || []).sort(
      orderByCreatedAtDesc,
    )

    const previousResidence = (
      this.data.residenceAuthorisationDocumentApprovals || []
    ).sort(orderByCreatedAtDesc)

    // musí být zamítnuté oba poslední typy, kdyby byl jen jeden, tak je to
    // bráno jako platné potvrzení
    // navíc musíme brát poslední DocumentApproval v čase
    const prevDocumentsDenied = () => {
      // pokud existují oba, tak musí být zamítnuté oba dva,
      // jinak stačí že jeden je schválený
      if (previousDomicils.length > 0 && previousResidence.length > 0) {
        return (
          previousDomicils[0] &&
          previousDomicils[0].status === 'DENIED' &&
          previousResidence[0] &&
          previousResidence[0].status === 'DENIED'
        )
      } else {
        // v případě, že je jen jeden a je zamítnutý
        return (
          (previousDomicils.length > 0 &&
            previousDomicils[0] &&
            previousDomicils[0].status === 'DENIED') ||
          (previousResidence.length > 0 &&
            previousResidence[0] &&
            previousResidence[0].status === 'DENIED')
        )
      }
    }

    const prevDocumentConfirmed = () => {
      if (previousDomicils.length > 0 && previousResidence.length > 0) {
        return (
          previousDomicils[0] &&
          previousDomicils[0].status === 'CONFIRMED' &&
          previousResidence[0] &&
          previousResidence[0].status === 'CONFIRMED'
        )
      } else {
        // v případě, že je jen jeden a je schválený pak je vše schváleno
        return (
          (previousDomicils.length > 0 &&
            previousDomicils[0] &&
            previousDomicils[0].status === 'CONFIRMED') ||
          (previousResidence.length > 0 &&
            previousResidence[0] &&
            previousResidence[0].status === 'CONFIRMED')
        )
      }
    }

    const prevDocumentApply = () => {
      // v případě, že je jen jeden tak celý stav bude APPLY
      return (
        (previousDomicils.length > 0 &&
          previousDomicils[0] &&
          previousDomicils[0].status === 'APPLY') ||
        (previousResidence.length > 0 &&
          previousResidence[0] &&
          previousResidence[0].status === 'APPLY')
      )
    }

    if (prevDocumentApply()) return 'APPLY'
    if (prevDocumentsDenied()) return 'DENIED'
    if (prevDocumentConfirmed()) return 'CONFIRMED'

    return null
  }

  public hasNewResidenceFiles = (): boolean => {
    return Boolean(
      this.data.nationality !== 'CZ' &&
        this.data.czechTaxResidence === true &&
        this.data.taxDomicilDocumentApprovals &&
        this.data.residenceAuthorisationDocumentApprovals &&
        this.data.residenceAuthorisationDocumentApprovals
          .concat(this.data.taxDomicilDocumentApprovals)
          .some((appr) => appr.status === 'NEW'),
    )
  }

  public workedWholeYear(year: number): boolean {
    return userWorkedWholeYear(
      { dateOfEmployment: this.data.dateOfEmployment },
      year,
    )
  }

  // pokud není daňový resident ČR
  // nebo je daňový resident ČR, ale nedodal podklady
  // tak se bere, že nemůžeme provést daně (zamykáme eshop)
  public isForeignerWithNoFiles = (): boolean => {
    return (
      this.data.czechTaxResidence === false ||
      (this.data.nationality !== 'CZ' &&
        this.data.czechTaxResidence === true &&
        (this.noProvidedFiles() ||
          (this.prevDocumentsIsDenied() && !this.hasNewResidenceFiles())))
    )
  }

  public hasDeniedFiles = (): boolean => {
    const approvals =
      this.data.taxDomicilDocumentApprovals &&
      this.data.residenceAuthorisationDocumentApprovals &&
      this.data.residenceAuthorisationDocumentApprovals.concat(
        this.data.taxDomicilDocumentApprovals,
      )
    return Boolean(
      approvals &&
        approvals.some((appr) => appr.status === 'DENIED') &&
        approvals.every((appr) => appr.status !== 'CONFIRMED'),
    )
  }

  public isAnnualPeriod() {
    const {
      annualRebatesPeriodStart,
      annualRebatesPeriodEnd,
    } = this.data.customer

    if (
      annualRebatesPeriodStart &&
      annualRebatesPeriodEnd &&
      moment(annualRebatesPeriodStart).isValid() &&
      moment(annualRebatesPeriodEnd).isValid()
    ) {
      return moment().isBetween(
        annualRebatesPeriodStart,
        annualRebatesPeriodEnd,
      )
    }
    return false
  }

  public firstRound = () => {
    if (!this.isEmployee()) {
      return false
    }

    const {
      monthlyHistoryRequired,
      monthlyHistory,
      allowAnnual,
      annualRebate,
      monthlyRebate,
    } = this.data

    // V období m-history - nemá odeslanou žádost
    if (
      monthlyHistoryRequired &&
      !(monthlyHistory && monthlyHistory.settlementRequest)
    ) {
      return true
    }

    // V období RZD - nemá odeslanou žádost
    if (
      allowAnnual &&
      !(annualRebate && annualRebate.settlementRequest) // && this.data.annualRebate.settlementRequest.status !== 'NEW'
    ) {
      return true
    }

    // Nemá odeslanou žádost o měsíční slevy
    if (
      !monthlyHistoryRequired &&
      !this.blockedAnnualOverride() && // Není blokovaný s přístupem jen k ročním slevám
      !(monthlyRebate && monthlyRebate.settlementRequest) // && this.data.monthlyRebate.settlementRequest.status !== 'NEW'
    ) {
      return true
    }

    return false
  }

  public rebateStatus = () => {
    const getStatusFactory = (src: object) => (r: string) => {
      const rebate = get(src, r, null) as {
        id: string
        status: RebateStatus
        children?: {
          status: RebateStatus
        }[]
        otherParentStatus?: RebateStatus
      } | null

      if (!rebate) return null

      let statuses = [rebate.status]
      if (rebate.children) {
        statuses.push(...rebate.children.map((ch) => ch.status))
      }
      if (rebate.otherParentStatus) {
        statuses.push(rebate.otherParentStatus)
      }

      if (statuses.includes('DENIED')) return 'DENIED'
      if (statuses.includes('APPLY')) return 'APPLY'
      if (statuses.includes('APPLY')) return 'APPLY'
      return 'NEW'
    }

    const monthlyStatus = (): RebateStatus => {
      const { monthlyRebate } = this.data
      if (monthlyRebate) {
        const getStatus = getStatusFactory(monthlyRebate)
        const basicRebate = getStatus('basicRebate')
        const studentRebate = getStatus('studentRebate')
        const childrenRebate = getStatus('childrenRebate')
        const disabilityRebate = getStatus('disabilityRebate')
        const ztppRebate = getStatus('ztppRebate')
        const statuses = [
          basicRebate,
          studentRebate,
          childrenRebate,
          disabilityRebate,
          ztppRebate,
        ].filter((s) => s !== null) as RebateStatus[]
        if (statuses.includes('APPLY')) return 'APPLY'
        if (statuses.includes('DENIED')) return 'DENIED'
        if (statuses.includes('CONFIRMED')) return 'CONFIRMED'
      }
      return 'NEW'
    }
    const historyStatus = (): RebateStatus => {
      const { monthlyHistory } = this.data
      if (monthlyHistory) {
        const getStatus = getStatusFactory(monthlyHistory)
        const basicRebate = getStatus('basicRebate')
        const studentRebate = getStatus('studentRebate')
        const childrenRebate = getStatus('childrenRebate')
        const disabilityRebate = getStatus('disabilityRebate')
        const ztppRebate = getStatus('ztppRebate')
        const statuses = [
          basicRebate,
          studentRebate,
          childrenRebate,
          disabilityRebate,
          ztppRebate,
        ].filter((s) => s !== null) as RebateStatus[]
        if (statuses.includes('APPLY')) return 'APPLY'
        if (statuses.includes('DENIED')) return 'DENIED'
        if (statuses.includes('CONFIRMED')) return 'CONFIRMED'
      }
      return 'NEW'
    }

    const annualStatus = (): RebateStatus => {
      const { annualRebate } = this.data
      if (annualRebate) {
        const getStatus = getStatusFactory(annualRebate)
        const basicRebate = getStatus('basicRebate')
        const studentRebate = getStatus('studentRebate')
        const childrenRebate = getStatus('childrenRebate')
        const disabilityRebate = getStatus('disabilityRebate')
        const ztppRebate = getStatus('ztppRebate')
        const giftRebate = getStatus('giftRebate')
        const preschoolRebate = getStatus('preschoolRebate')
        const spouseRebate = getStatus('spouseRebate')
        const lifeInsuranceRebate = getStatus('lifeInsuranceRebate')
        const pensionInsuranceRebate = getStatus('pensionInsuranceRebate')
        const loanRebate = getStatus('loanRebate')
        const unionRebate = getStatus('unionRebate')
        const examRebate = getStatus('examRebate')
        const foreclosureRebate = getStatus('foreclosureRebate')
        const investmentRebate = getStatus('investmentRebate')
        const longTermCareRebate = getStatus('longTermCareRebate')
        const statuses = [
          basicRebate,
          studentRebate,
          childrenRebate,
          disabilityRebate,
          ztppRebate,
          giftRebate,
          preschoolRebate,
          spouseRebate,
          lifeInsuranceRebate,
          pensionInsuranceRebate,
          loanRebate,
          unionRebate,
          examRebate,
          foreclosureRebate,
          longTermCareRebate,
          investmentRebate
        ].filter((s) => s !== null) as RebateStatus[]
        if (statuses.includes('APPLY')) return 'APPLY'
        if (statuses.includes('DENIED')) return 'DENIED'
        if (statuses.includes('CONFIRMED')) return 'CONFIRMED'
      }
      return 'NEW'
    }

    // do budoucna možno rozšířit na jednotlivé slevy
    return {
      annualRebate: {
        status: annualStatus(),
      },
      monthlyRebate: {
        status: monthlyStatus(),
      },
      monthlyHistory: {
        status: historyStatus(),
      },
    }
  }

  public showStudentRebate(year: number) {
    return year < 2024
  }

  public getDocumentsList = () => {
    if (!this.data.documents) {
      return []
    }

    const result = this.data.documents.map((doc) => {
      const finalDocument = { ...doc, text: doc.name }
      return finalDocument
    })

    return result
  }
}

export interface IUserContext {
  user: User
  users: TUser[]
  dereincarnate: () => void
  refetch: () => Promise<void>
  update: (user: TUser) => Promise<void>
}
const UserContext = React.createContext<IUserContext>({
  user: new User(({} as unknown) as TUser),
  dereincarnate: () => {},
  refetch: async () => {},
  update: async () => {},
  users: [],
})
const UserConsumer = UserContext.Consumer

interface Props {
  user: TUser
  users: TUser[]
  refetch: TRefetch,
  afterDereincarnate: (dereincarnate: TDereincarnateData) => void,
  onUserUpdate: (newUser: TUser) => Promise<void>
}
const UserProvider: React.FC<Props> = (props) => {
  const user: User = new User(props.user)

  const [dereincarnate] = useMutation<TDereincarnateData>(DEREINCARNATE, {
    onCompleted(data) {
      if(props.afterDereincarnate) {
        props.afterDereincarnate(data)
      }
    },
  })

  // @ts-ignore
  // window.xuser = user
  return (
    <UserContext.Provider
      value={{
        user,
        dereincarnate,
        users: props.users,
        refetch: async () => {
          await props.refetch()
        },
        update: async (newData: TUser) => {
          await props.onUserUpdate(newData)
        }
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export { UserProvider, UserConsumer, UserContext }
