import {
  IPrevEmployersData,
  IResetPrevEmployersData,
  IUpdateOwnAnnualPrevEmployersData,
  OWN_ANNUAL_PREV_EMLOYERS,
  RESET_PREV_EMPLOYERS,
  TResetPrevEmployersVariables,
  TUpdateOwnAnnualPrevEmployersVariables,
  UPDATE_OWN_ANNUAL_PREV_EMPLOYERS,
} from './gql'
import {Divider, Error, Green, UpperHint} from '../../../../../../components/CommonBox'
import {Observer} from 'mobx-react'
import {UPDATE_ANNUAL_WIZARD_STEP} from '../../gql/updateAnnualWizardStepMutation'
import {useMutation, useQuery} from 'react-apollo'
import {Question} from '../../../../../../shared/Questionnaire'
import {userWorkedWholeYear} from '../../../../../../utils'
import {Trans, useTranslation} from 'react-i18next'
import AnnualStepper from '../../components/Stepper'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Collapse from '@material-ui/core/Collapse'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import PrevEmployerSnapView from '../../components/PrevEmployerSnapView'
import PreviousEmployers from './PreviousEmployers'
import PrevRebate from '../../components/PrevRebate'
import React, {useMemo, useEffect, useState} from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useForm, {FormProvider} from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import moment from 'moment'
import {AnnualWizardStep} from '../../../../../../generated/globalTypes'
import {makeStyles} from '@material-ui/core/styles'
import PreviousEmployersRebatesForm from "./PreviousEmployersRebatesForm";
import { isEmpty } from 'lodash'

const useStyles = makeStyles(() => ({
  question: {
    maxWidth: '100%',
    width: 850,
    margin: 'auto',
  },
  wrapper: {
    padding: "64px 0 32px 0",
    borderRadius: '5px',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
  }
}))

const PreviousEmployersIndex: React.FC = () => {
  const classes = useStyles()
  const { user } = useUser()
  const { history } = useRouter()
  const { t } = useTranslation()

  const [updateAnnualWizardStep] = useMutation(UPDATE_ANNUAL_WIZARD_STEP)

  const { data, loading, error } = useQuery<IPrevEmployersData>(
    OWN_ANNUAL_PREV_EMLOYERS,
    {
      fetchPolicy: 'cache-and-network',
      onError() {},
      onCompleted(response) {
        const wizardStep = get(response, 'user.annualRebate.wizardStep')
        if (wizardStep !== 'PREV_EMPLOYER') {
          updateAnnualWizardStep({
            variables: { wizardStep: AnnualWizardStep.PREV_EMPLOYER },
          })
        }
      },
    },
  )

  const [
    resetPrevEmployer,
    { loading: loadingReset, error: errorReset },
  ] = useMutation<IResetPrevEmployersData, TResetPrevEmployersVariables>(
      RESET_PREV_EMPLOYERS,
  )
  const [
    updatePrevEmployers,
    { loading: loadingUpdate, error: errorUpdate },
  ] = useMutation<
    IUpdateOwnAnnualPrevEmployersData,
    TUpdateOwnAnnualPrevEmployersVariables
  >(UPDATE_OWN_ANNUAL_PREV_EMPLOYERS)

  const [invalid, setInvalid] = useState(false)

  useEffect(() => {
    if (invalid) {
      setTimeout(() => setInvalid(false), 5000)
    }
  }, [invalid])

  const annualRebate = data && data.user.annualRebate
  const year = get(data, 'user.annualRebate.year') || ''

  const { form, bind } = useForm<
    TUpdateOwnAnnualPrevEmployersVariables['data']
  >(
    annualRebate || {},
    {
      hasPrevEmployers: {
        label: t('annualRebatePreviousEmployers.hadPreviousEmployers', {
          context: user.data.gender,
          year,
          companyName: user.data.customer.name,
        }),
        help: t('annualRebatePreviousEmployers.hadPreviousEmployersHelp', {
          context: `2023_${user.data.gender}`,
        }),
        rule: 'required',
      },
      prevEmployers: {
        type: 'multi_relation',
        label: t('annualRebatePreviousEmployers.employer_plural'),
        rule: (data) => (data.hasPrevEmployers === true ? 'required' : ''),
        omitOnSubmit: (data) => data.hasPrevEmployers === false,
      },

      prevEmployersSummaryInfo: {
        label: '',
        rule: (data) => (data.hasPrevEmployers === true ? 'required' : ''),
        omitOnSubmit: (data) => data.hasPrevEmployers === false,
      },
    },
    {
      onFieldChange(fieldPath, value, form) {
        if (fieldPath === 'hasPrevEmployers' && value === true) {
          const employers = form.getValue('prevEmployers')
          if (employers.length === 0) {
            form.relationCreate('prevEmployers', {})
          }
        }
      },
      async onSubmit(formData, form) {
        try {
          if (isEmpty(formData)) {
            if (user.shouldHaveResidenceFiles()) {
              history.push(`/${user.data.id}/annual-rebates/taxResidence`, {
                from: 'questionnaire',
              })
            } else {
              history.push(`/${user.data.id}/annual-rebates/rootMonthly`, {
                from: 'previousEmployers',
              })
            }
          }

          const { data } = await updatePrevEmployers({
            variables: {
              data: {...formData, prevEmployersTouched: true},
            },
          })
          if (data) {
            // form.onSuccess(data.updatePreviousEmployer)
            if (user.shouldHaveResidenceFiles()) {
              history.push(`/${user.data.id}/annual-rebates/taxResidence`, {
                from: 'questionnaire',
              })
            } else {
              history.push(`/${user.data.id}/annual-rebates/rootMonthly`, {
                from: 'previousEmployers',
              })
            }
          } else {
            form.onFail('nepovedl se update dat')
          }
        } catch (err) {
          form.onFail(err)
        }
      },
      submitNested: true,
    },
  )

  const handleSubmit = () => {
    form.submit()
    setInvalid(!form.isValid())
  }

  const workedWholeYear = userWorkedWholeYear(user.data, year)

  React.useLayoutEffect(() => {
    if (workedWholeYear && history) {
      const from = get(history, 'location.state.from')
      history.push(
        `/${user.data.id}/annual-rebates/${
          from === 'questionnaire' ? 'root' : 'questionnaire'
        }`,
      )
    }
  }, [workedWholeYear, history, user])

  const hasDeniedEmployer =
    annualRebate &&
    annualRebate.prevEmployers &&
    annualRebate.prevEmployers.some((empl) => empl.status === 'DENIED')

  const allEmployersConfirmed =
    annualRebate &&
    annualRebate.prevEmployers &&
    annualRebate.prevEmployers.length > 0 &&
    annualRebate.prevEmployers.every((empl) => empl.status === 'CONFIRMED')

  const prevEmployersHistory = useMemo(() => {
    return ((annualRebate && annualRebate.prevEmployersHistory) || []).sort(
      (a, b) => {
        if (a.snapRootId === b.snapRootId) {
          return moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1
        } else {
          return a.snapRootId < b.snapRootId ? -1 : 1
        }
      },
    )
  }, [annualRebate])

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || loadingUpdate || loadingReset} />
      <GraphQLErrorSnackbar error={error || errorUpdate || errorReset} />
      {data && !loading && (
        <Box
          maxWidth="100%"
          width={1080}
          ml="auto"
          mr="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <UpperHint>
            {t('common.form')} {t('common.annualRebates')} {year}
          </UpperHint>

          <AnnualStepper activeStep={2} />

          <Box maxWidth="100%" width={600}>
            <Typography variant="h1" align="center" gutterBottom>
              {t('annualRebatePreviousEmployers.heading')}
            </Typography>

            <Typography align="center">
              <Trans i18nKey='annualRebatePreviousEmployers.subheading' tOptions={{ context: `2023_${user.data.gender}` }} components={[<Green />]} />
            </Typography>
          </Box>

          <Divider maxWidth={180} mt={4} mb={2} />

          <Box maxWidth="100%" width={1080}>
            <Observer>
              {() => {
                const hasPrevEmployers = form.getValue('hasPrevEmployers')
                const prevEmployers: IPrevEmployersData['user']['annualRebate']['prevEmployers'] = form.getValue(
                  'prevEmployers',
                )

                return (
                  <>
                    <Question
                      {...bind('hasPrevEmployers')}
                      hideDivider
                      helpStyle='collapse'
                      className={classes.question}
                      hardSelected={
                        prevEmployers.length > 0 && prevEmployers.every(emp => "status" in emp)
                          ? true
                          : undefined
                      }
                    />
                    {hasPrevEmployers && (
                      <>
                        <Divider mb={4} mt={1.5} />
                        <Box className={classes.wrapper}>
                          {hasDeniedEmployer && (
                            <Box
                              my={2}
                              fontFamily="fontFamily"
                              fontWeight="600"
                              color="#f44336"
                              textAlign="center"
                            >
                              {t('annualRebatePreviousEmployers.deniedEmployerWarning')}
                            </Box>
                          )}
                          <FormProvider form={form}>
                            <Box maxWidth="100%" width={820} style={{margin: '0 auto'}}>
                              <PreviousEmployersRebatesForm
                                allEmployersConfirmed={allEmployersConfirmed}
                                {...bind('prevEmployersSummaryInfo')}
                              />
                            </Box>

                            <PreviousEmployers
                              {...bind('prevEmployers')}
                              onResetPrevEmployer={async (id) => {
                                await resetPrevEmployer({
                                  variables: { where: { id } },
                                })

                                window.location.reload()
                              }}
                            />
                          </FormProvider>
                        </Box>
                      </>
                    )}
                  </>
                )
              }}
            </Observer>
          </Box>

          {prevEmployersHistory.length > 0 ? (
            <Box mt={3} alignItems="center" display="flex" flexDirection="column">
              <ArchiveChanges>
                {prevEmployersHistory.map((emp) => (
                  <PrevRebate
                    key={emp.id}
                    status={emp.status}
                    settlementRequest={emp.settlementRequest}
                    commentOfDenied={emp.commentOfDenied}
                  >
                    <PrevEmployerSnapView key={emp.id} data={emp} />
                  </PrevRebate>
                ))}
              </ArchiveChanges>
            </Box>
          ) : null}

          <Divider my={4} />

          <ButtonStepContainer
            backButton={{
              to: `/${user.data.id}/annual-rebates/questionnaire`,
              text: t('annualRebateWizard.stepper.questionnaire'),
            }}
          >
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={handleSubmit}
            >
              {t('common.continue')}
            </Button>
          </ButtonStepContainer>

          <Collapse in={invalid}>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Error>{t('error.fillTheForm')}</Error>
            </Box>
          </Collapse>
        </Box>
      )}
    </InsideLayout>
  )
}

export default PreviousEmployersIndex
