import {IMeData, ME, TDereincarnateData, TUser} from './gql'
import {AccountProvider} from '../containers/AccountProvider'
import {Redirect, Route, Switch as SwitchDom} from 'react-router-dom'
import {useApolloClient, useQuery} from 'react-apollo'
import {UserProvider} from '../containers/User'
import AppState from '../components/AppState'
import AuthManager from '../components/AuthManager'
import Inside from './Inside'
import Outside from './Outside'
import OutsideLayout from '../components/layouts/OutsideLayout'
import React, {useState} from 'react'
import SelectUser from '../pages/Inside/pages/SelectUser'
import SetGlobalState from '../components/SetGlobalState'
import SelectedUser from '../containers/SelectedUser'
import Smartsupp from '../components/Smartsupp'
import withGa from '../containers/withGa'
import CircularProgress from "@material-ui/core/CircularProgress";
import {ApolloQueryResult} from "apollo-boost";
import {me, me_me, me_me_user} from "../generated/me";
import useRedirectAfterLogin from "../hooks/useRedirectAfterLogin";

const Switch = withGa(SwitchDom)


const App: React.FC = () => {

  const selectedUser = SelectedUser.getInstance()

  const {data, loading: queryLoading, refetch, called, client} = useQuery<IMeData>(ME, { // loading of initial user - if am I signed in
    fetchPolicy: 'network-only',
    variables: {
      userId: selectedUser.userId || undefined, // can be in local storage at initial load
    },
    onCompleted: (newData: IMeData) => {
      setMe(newData.me)
    }
  })
  const [me, setMe] = useState<me_me | null>(data? data.me : null)
  const { resetStore } = useApolloClient()
  const redirectAfterLogin = useRedirectAfterLogin()



  const refetchMe = async (): Promise<me_me | null> => {
    return new Promise((resolve, reject) => {
      refetch({
        userId: SelectedUser.getInstance().userId
      }).then((newData: ApolloQueryResult<me>) => {
        setMe(newData.data.me)
        resolve(newData.data.me)
      })
    })
  }
  // to distinguish user id from another routes or wrong ids
  const canUserBePicked = (userId: string) => {
    return !!me && !!me.users && !!me.users.find(u => u.id === userId)
  }
  const loading = queryLoading || !data

  if (loading) {
    return (
      <OutsideLayout>
        <Smartsupp/>
        <AppState loading/>
      </OutsideLayout>
    )
  }


  const isUserLoggedIn: boolean = !!me
  const isUserSelected: boolean = !!selectedUser.userId
  const isUserLoaded: boolean = !!me && !!me.user
  const isUserLoading: boolean = isUserSelected && !isUserLoaded

  // console.log(me, isUserLoggedIn, isUserSelected, isUserLoaded, isUserLoading, selectedUser.userId)

  const loadingInsideComponent = <div  key={'user-' + (me && me.user? me.user.id : '')}
    style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '40vh', width: 'auto'}}>
    <CircularProgress/>
  </div>

  if (isUserLoggedIn && redirectAfterLogin.onLoggedIn()) {
    return loadingInsideComponent
  }

  return (
    <AccountProvider account={me} refetch={(async () => {
      selectedUser.resetLocalStorage()
      await refetchMe()
    })} afterLogout={() => {
      selectedUser.setUserId()
      selectedUser.resetLocalStorage()
      setMe(null)
      window.location.assign('/')
    }}
    afterReincarnate={(reincarnatedId) => {
      selectedUser.setUserId(reincarnatedId)
      window.location.assign('/' + reincarnatedId)
    }}>
      {!isUserLoggedIn &&
        <Smartsupp>
          <Outside/>
        </Smartsupp>
      }
      {isUserLoggedIn && <>
        <AuthManager/>
        <Switch>
          <Route
            path="/users"
            render={() => {
              selectedUser.setUserId()
              return (
                <Smartsupp>
                  <SelectUser/>
                </Smartsupp>
              )
            }}
          />
          <Route
            path="/:userId"
            render={({match}) => {
              const userId = match.params.userId
              if (loading || isUserLoading) {
                return loadingInsideComponent
              }
              if (!canUserBePicked(userId)) {
                return <Redirect to="/users"/>
              }
              // @ts-ignore
              if (selectedUser.userId !== userId || !isUserLoaded || selectedUser.userId !== me.user.id) { // pick another user or initial loading or is loaded wrong user
                client.stop()
                selectedUser.setUserId(userId)
                resetStore().then(() => {
                  refetchMe()
                })
                return loadingInsideComponent
              }

              // @ts-ignore
              return (
                <UserProvider
                  user={me.user}
                  users={me.users}
                  refetch={refetchMe}
                  onUserUpdate={async (newUser: TUser) => {
                    if(me && me.user && newUser.id === selectedUser.userId) {
                      console.info('Updating user to: ', newUser)
                      setMe({...me, user: {...me.user, ...newUser}})
                    }
                  }}
                  afterDereincarnate={(data: TDereincarnateData) => {
                    if (data.dereincarnate && data.dereincarnate.id) {
                      selectedUser.setUserId(data.dereincarnate.id)
                      window.location.assign(`/${data.dereincarnate.id}/employees`)
                    } else {
                      selectedUser.setUserId()
                      window.location.assign('/')
                    }
                  }}
                >
                  <SetGlobalState
                    property="lovers"
                    value={me.user.customer.name}
                  />
                  <Inside/>
                  <Smartsupp/>
                </UserProvider>
              )

            }}
          />
          {isUserSelected && isUserLoaded && !isUserLoading && me && me.user && (selectedUser.userId === me.user.id) && <Redirect to={'/' + selectedUser.userId}/>}
          <Redirect to="/users"/>
        </Switch>
      </>}
    </AccountProvider>
  )
}

export default App
