import {makeStyles, Theme} from '@material-ui/core/styles'
import {Observer} from 'mobx-react'
import {CREATE_CUSTOMER, CUSTOMER_UPDATE, ICustomerData, ICustomerUpdateData, ICustomerUpdateVariables,} from './gql'
import {createNewCustomer, createNewCustomerVariables,} from '../../../../generated/createNewCustomer'
//import { useMutation } from 'react-apollo-hooks'
import {useMutation} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {PokracovatBtn} from '../../../../components/icons/KubikIcons'
import React from 'react'
import TextField from '../../../../components/form/TextField'
import Typography from '@material-ui/core/Typography'
import useForm from '../../../../hooks/useForm'
import Divider from '../../../../components/Divider'
import {Green} from '../../../../components/CommonBox'
import AppState from '../../../../components/AppState'
import ScrollToTop from '../../../../components/ScrollToTop'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 300,
  },
  marginBottom4: {
    marginBottom: theme.spacing(4),
  },
}))

interface IProps {
  nextStep: (newData?: createNewCustomer['createNewCustomer']) => void
  data?: ICustomerData
  newCustomer?: boolean
  customer:
    | ICustomerData['customer']
    | createNewCustomer['createNewCustomer']
    | undefined
    | null
}

const StepOne: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { newCustomer } = props

  const stepDone = props.data && props.data.customer.activationFormStep > 0

  const [customerUpdate] = useMutation<
    ICustomerUpdateData,
    ICustomerUpdateVariables
  >(CUSTOMER_UPDATE)

  const [customerCreate] = useMutation<
    createNewCustomer,
    createNewCustomerVariables
  >(CREATE_CUSTOMER)

  const formData = (props.data && props.data.customer) || props.customer

  const { bind, form } = useForm<
    ICustomerUpdateVariables['data'] | createNewCustomerVariables['data']
  >(
    formData || {},
    {
      name: {
        help: t('common.customerNameHelp'),
        label: t('common.customerName'),
        placeholder: t('common.customerNamePlaceholder'),
        rule: 'required',
      },
      ico: {
        help: t('common.icoHelp'),
        label: t('common.ico'),
        placeholder: t('common.icoPlaceholder'),
        rule: 'required',
      },
      dic: {
        help: t('common.dicHelp'),
        label: t('common.dic'),
        placeholder: t('common.dicPlaceholder'),
        rule: 'required',
      },
      address: {
        help: t('common.customerAddressHelp'),
        label: t('common.customerAddress'),
        placeholder: t('common.customerAddressPlaceholder'),
        rule: 'required',
      },
      keeperEmail: {
        help: t('common.email'),
        label: t('role.keeper'),
        placeholder: t('common.email'),
        rule: () => (newCustomer ? 'required' : ''),
      },
      keeperFirstName: {
        help: t('common.firstname'),
        label: t('common.firstname'),
        placeholder: t('common.firstname'),
        rule: () => (newCustomer ? 'required' : ''),
      },
      keeperLastName: {
        help: t('common.lastname'),
        label: t('common.lastname'),
        placeholder: t('common.lastname'),
        rule: () => (newCustomer ? 'required' : ''),
      },
    },
    {
      async onSubmit(formData, form) {
        if (!form.state.isDirty) {
          props.nextStep()
        } else {
          try {
            if (newCustomer) {
              const { data: newCustomerData } = await customerCreate({
                variables: {
                  data: {
                    ...formData,
                    activationFormStep: 1,
                  } as createNewCustomerVariables['data'],
                },
              })
              if (newCustomerData && newCustomerData.createNewCustomer) {
                form.onSuccess(newCustomerData.createNewCustomer)
                props.nextStep(newCustomerData.createNewCustomer)
              }
            } else {
              const { data } = await customerUpdate({
                variables: { data: { ...formData, activationFormStep: 1 } },
              })
              if (data && data.customerUpdate) {
                form.onSuccess(data.customerUpdate)
                props.nextStep()
              }
            }
          } catch (err) {
            form.onFail(err)
          }
        }
      },
    },
  )

  return (
    <Observer>
      {() => {
        return (
          <Grid
            direction="column"
            container
            justifyContent="center"
            alignItems="center"
          >
            <AppState loading={form.state.loading} />
            <ScrollToTop />

            <Typography variant="h1" gutterBottom>
              {t('customerActivation.step1Heading')}
            </Typography>

            <Typography
              gutterBottom
              align="center"
              className={classes.marginBottom4}
            >
              <Trans i18nKey="customerActivation.step1SubHeading">
                Vyplňte údaje o registrované společnosti. Údaje o společnosti
                <Green>můžete kdykoliv v aplikaci později změnit.</Green>
              </Trans>
            </Typography>

            <TextField
              className={classes.container}
              gutterBottom
              showValidationIcon
              showValidationUntouched={stepDone}
              {...bind('name')}
            />
            <TextField
              className={classes.container}
              gutterBottom
              showValidationIcon
              showValidationUntouched={stepDone}
              {...bind('ico')}
            />
            <TextField
              className={classes.container}
              gutterBottom
              showValidationIcon
              showValidationUntouched={stepDone}
              {...bind('dic')}
            />
            <TextField
              className={classes.container}
              gutterBottom
              showValidationIcon
              showValidationUntouched={stepDone}
              {...bind('address')}
            />
            {newCustomer && (
              <>
                <TextField
                  className={classes.container}
                  gutterBottom
                  showValidationIcon
                  showValidationUntouched={stepDone}
                  {...bind('keeperEmail')}
                />
                <TextField
                  className={classes.container}
                  gutterBottom
                  showValidationIcon
                  showValidationUntouched={stepDone}
                  {...bind('keeperFirstName')}
                />
                <TextField
                  className={classes.container}
                  gutterBottom
                  showValidationIcon
                  showValidationUntouched={stepDone}
                  {...bind('keeperLastName')}
                />
              </>
            )}

            <Divider spacing={6} />

            <Button
              onClick={form.submit}
              color="primary"
              size="large"
              variant="contained"
            >
              {t('common.continue')}

              <span
                style={{
                  fontSize: '0.75rem',
                  marginLeft: '8px',
                  marginTop: '3px',
                }}
              >
                <PokracovatBtn fontSize="inherit" />
              </span>
            </Button>
          </Grid>
        )
      }}
    </Observer>
  )
}

export default StepOne
