import { gql } from 'apollo-boost'
import {
  NexusGenFieldTypes,
  NexusGenArgTypes,
  NexusGenRootTypes,
} from 'kubik-server'
import { Omit } from '../../../../../utils'
import {
  TAX_QUESTIONNAIRE_WITH_HISTORY,
  MONTHLY_TAX_QUESTIONNAIRE_WITH_HISTORY,
} from '../../../../../fragments/TaxQuestionnaire'
import {
  TAX_QUESTIONNAIRE_SNAP_WITH_HISTORY,
  MONTHLY_TAX_QUESTIONNAIRE_SNAP_WITH_HISTORY,
} from '../../../../../fragments/TaxQuestionnaireSnap'
import {
  settlementRequest_settlementRequest,
  settlementRequest_settlementRequest_annualRebate_taxQuestionnaire,
  settlementRequest_settlementRequest_monthlyRebate_taxQuestionnaire,
  settlementRequest_settlementRequest_taxQuestionnaire,
  settlementRequest_settlementRequest_monthlyTaxQuestionnaire,
  settlementRequest_settlementRequest_annualRebate_prevEmployers,
  settlementRequest_settlementRequest_prevEmployers,
} from '../../../../../generated/settlementRequest'

export type TRebateType =
  | NexusGenFieldTypes['SettlementRequest']['type']
  | 'MONTHLY_HISTORY'

const BASIC_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment BasicRebateSettlement on BasicRebate {
    id
    createdAt
    commentOfDenied
    status
    type
    applyFrom
    applyTo
    closePeriodAt
    prevYearPeriods {
      id
      from
      to
    }
    affidavitFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const STUDENT_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment StudentRebateSettlement on StudentRebate {
    id
    createdAt
    closePeriodAt
    prevYearPeriods {
      id
      from
      to
    }
    type
    status
    commentOfDenied
    confirmationValidityFrom
    confirmationValidityTo
    confirmationDoctoralStudies
    studentConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const CHILDREN_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment ChildrenRebateSettlement on ChildrenRebate {
    id
    createdAt
    status
    type
    commentOfDenied
    otherParentStatus
    otherParentCommentOfDenied
    otherParentAddress
    otherParentNourishes
    otherParentApplying
    otherParentFirstname
    otherParentLastname
    otherParentNationalIDNumber
    otherParentAddress
    otherParentWork
    otherParentWorkName
    otherParentWorkAddress
    children {
      id
      tag
      type
      status
      commentOfDenied
      birthdate
      closePeriodAt
      firstname
      lastname
      nationalIDNumber
      order
      applyRebate
      applyInMonths
      student
      studentFrom
      studentTo
      ztpp
      ztppFrom
      ztppTo
      birthCertificateFiles {
        id
        filename
      }
      studentConfirmationFiles {
        id
        filename
      }
      ztppConfirmationFiles {
        id
        filename
      }
      socialConfirmationFiles {
        id
        filename
      }
    }
    otherParentConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const DISABILITY_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment DisabilityRebateSettlement on DisabilityRebate {
    id
    createdAt
    closePeriodAt
    prevYearPeriods {
      id
      from
      to
      disabilityLevel
    }
    type
    status
    commentOfDenied
    disabilityLevel
    confirmationValidityFrom
    confirmationValidityTo
    disabilityConfirmationFiles {
      id
      filename
      createdAt
      size
    }
    disabilityOtherFiles {
        id
        type
        file {
            id
            filename
            createdAt
            size
        }
    }
  }
`
const ZTPP_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment ZtppRebateSettlement on ZtppRebate {
    id
    createdAt
    closePeriodAt
    prevYearPeriods {
      id
      from
      to
    }
    type
    status
    commentOfDenied
    confirmationValidityFrom
    confirmationValidityTo
    ztppConfirmationFiles {
      id
      filename
      createdAt
      size
    }
    socialConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const SPOUSE_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment SpouseRebateSettlement on SpouseRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    firstname
    lastname
    nationalIDNumber
    applyInMonths
    ztpp
    ztppMonths
    childFirstname
    childLastname
    childBirthNumberOrDate
    marriageCertificateFiles {
      id
      filename
      createdAt
      size
    }
    ztppConfirmationFiles {
      id
      filename
      createdAt
      size
    }
    childBirthCertificateFiles {
        id
        filename
        createdAt
        size        
    }
  }
`
const GIFT_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment GiftRebateSettlement on GiftRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    gifts {
      id
      description
      amount
    }
    giftConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const PRESCHOOL_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment PreschoolRebateSettlement on PreschoolRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    preschools {
      id
      firstname
      lastname
      nationalIDNumber
      feeAmount
    }
    feeConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const LIFE_INSURANCE_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment LifeInsuranceRebateSettlement on LifeInsuranceRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    feeAmount
    feeConfirmationFiles {
      id
      filename
      createdAt
      size
    }
    contractConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const PENSION_INSURANCE_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment PensionInsuranceRebateSettlement on PensionInsuranceRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    feeAmount
    feeAmountAdditionalPart
    feeAmountContributionPart
    feeAmountSpecializedPart
    feeConfirmationFiles {
      id
      filename
      createdAt
      size
    }
    contractConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const LOAN_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment LoanRebateSettlement on LoanRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    interestAmount
    purchaseDate
    applyInMonths
    addresses
    otherPersonApplying
    otherPersonFullname
    otherPersonNationalIDNumber
    otherAddress
    interestAmountConfirmationFiles {
      id
      filename
      createdAt
      size
    }
    loanContractFiles {
      id
      filename
      createdAt
      size
    }
    propertyConfirmationFiles {
      id
      filename
      createdAt
      size
    }
    startConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const UNION_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment UnionRebateSettlement on UnionRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    feeAmount
    feeConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`

const FORECLOSURE_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment ForeclosureRebateSettlement on ForeclosureRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    amount
    confirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`

const INVESTMENT_REBATE_SETTLEMENT_FRAGMENT = gql`
    fragment InvestmentRebateSettlement on InvestmentRebate {
        id
        createdAt
        type
        status
        commentOfDenied
        amount
        confirmationAgreementFiles {
            id
            filename
            createdAt
            size
        }
        confirmationPaymentFiles {
            id
            filename
            createdAt
            size
        }
    }
`

const LONG_TERM_CARE_REBATE_SETTLEMENT_FRAGMENT = gql`
    fragment LongTermCareRebateSettlement on LongTermCareRebate {
        id
        createdAt
        type
        status
        commentOfDenied
        amount
        confirmationAgreementFiles {
            id
            filename
            createdAt
            size
        }
        confirmationPaymentFiles {
            id
            filename
            createdAt
            size
        }
    }
`

const EXAM_REBATE_SETTLEMENT_FRAGMENT = gql`
  fragment ExamRebateSettlement on ExamRebate {
    id
    createdAt
    type
    status
    commentOfDenied
    feeAmount
    feeConfirmationFiles {
      id
      filename
      createdAt
      size
    }
  }
`
const PREV_EMPLOYER_SNAP = gql`
  fragment PrevEmployerSnapFragment on PrevEmployerSnap {
    id
    status
    commentOfDenied
    deleteFlag
    companyName
    dateOfEmployment
    dateOfQuit
    totalRevenue
    incomeInsuranceSum
    actualDeductedTax
    totalTaxAdvance
    totalMonthlyTaxBonuses
    lifeInsuranceContribution
    incomeConfirmationFiles {
      id
      filename
      size
      createdAt
    }
  }
`
const PREV_EMPLOYER = gql`
  fragment PrevEmployerFragment on PrevEmployer {
    id
    status
    commentOfDenied
    deleteFlag
    companyName
    dateOfEmployment
    dateOfQuit
    totalRevenue
    incomeInsuranceSum
    actualDeductedTax
    totalTaxAdvance
    totalMonthlyTaxBonuses
    lifeInsuranceContribution
    incomeConfirmationFiles {
      id
      filename
      size
      createdAt
    }
  }
`

export interface IMonthlyRebates {
  basicRebate: NexusGenFieldTypes['BasicRebate']
  studentRebate: NexusGenFieldTypes['StudentRebate']
  childrenRebate: NexusGenFieldTypes['ChildrenRebate']
  disabilityRebate: NexusGenFieldTypes['DisabilityRebate']
  ztppRebate: NexusGenFieldTypes['ZtppRebate']
}
export interface IRebates {
  basicRebate?: NexusGenFieldTypes['BasicRebate']
  studentRebate?: NexusGenFieldTypes['StudentRebate']
  childrenRebate?: NexusGenFieldTypes['ChildrenRebate']
  disabilityRebate?: NexusGenFieldTypes['DisabilityRebate']
  ztppRebate?: NexusGenFieldTypes['ZtppRebate']
  lifeInsuranceRebate?: NexusGenFieldTypes['LifeInsuranceRebate']
  pensionInsuranceRebate?: NexusGenFieldTypes['PensionInsuranceRebate']
  unionRebate?: NexusGenFieldTypes['UnionRebate']
  foreclosureRebate?: NexusGenFieldTypes['ForeclosureRebate']
  investmentRebate?: NexusGenFieldTypes['InvestmentRebate']
  longTermCareRebate?: NexusGenFieldTypes['LongTermCareRebate']
  examRebate?: NexusGenFieldTypes['ExamRebate']
  giftRebate?: NexusGenFieldTypes['GiftRebate']
  preschoolRebate?: NexusGenFieldTypes['PreschoolRebate']
  spouseRebate?: NexusGenFieldTypes['SpouseRebate']
  loanRebate?: NexusGenFieldTypes['LoanRebate']
  annualRebate?: {
    basicRebates: NexusGenFieldTypes['BasicRebate'][]
    studentRebates: NexusGenFieldTypes['StudentRebate'][]
    childrenRebates: NexusGenFieldTypes['ChildrenRebate'][]
    disabilityRebates: NexusGenFieldTypes['DisabilityRebate'][]
    ztppRebates: NexusGenFieldTypes['ZtppRebate'][]
    spouseRebates: NexusGenFieldTypes['SpouseRebate'][]
    giftRebates: NexusGenFieldTypes['GiftRebate'][]
    preschoolRebates: NexusGenFieldTypes['PreschoolRebate'][]
    lifeInsuranceRebates: NexusGenFieldTypes['LifeInsuranceRebate'][]
    pensionInsuranceRebates: NexusGenFieldTypes['PensionInsuranceRebate'][]
    loanRebates: NexusGenFieldTypes['LoanRebate'][]
    unionRebates: NexusGenFieldTypes['UnionRebate'][]
    foreclosureRebates: NexusGenFieldTypes['ForeclosureRebate'][]
    examRebates: NexusGenFieldTypes['ExamRebate'][]
    file: NexusGenFieldTypes['File']
    fileMonthly: NexusGenFieldTypes['File']
  }
  monthlyRebate?: {
    basicRebates: NexusGenFieldTypes['BasicRebate'][]
    studentRebates: NexusGenFieldTypes['StudentRebate'][]
    childrenRebates: NexusGenFieldTypes['ChildrenRebate'][]
    disabilityRebates: NexusGenFieldTypes['DisabilityRebate'][]
    ztppRebates: NexusGenFieldTypes['ZtppRebate'][]
    file: NexusGenFieldTypes['File']
  }
}

type TFile = Pick<
  NexusGenRootTypes['File'],
  'id' | 'filename' | 'size' | 'createdAt'
>

type PrevEmployerFields =
  | 'id'
  | 'status'
  | 'commentOfDenied'
  | 'companyName'
  | 'deleteFlag'
  | 'dateOfEmployment'
  | 'dateOfQuit'
  | 'totalRevenue'
  | 'incomeInsuranceSum'
  | 'actualDeductedTax'
  | 'totalTaxAdvance'
  | 'totalMonthlyTaxBonuses'
  | 'lifeInsuranceContribution'

// type TPrevEmployerSnap = Pick<
//   NexusGenFieldTypes['PrevEmployerSnap'],
//   PrevEmployerFields
// > & {
//   incomeConfirmationFiles: TFile[]
// }
// export type TPrevEmployerSnapWithHistory = TPrevEmployerSnap & {
//   snapHistory: (TPrevEmployerSnap & {
//     settlementRequest: Pick<
//       NexusGenFieldTypes['SettlementRequest'],
//       'id' | 'requestedAt' | 'submittedAt'
//     >
//   })[]
// }

// type TPrevEmployer = Pick<
//   NexusGenFieldTypes['PrevEmployer'],
//   PrevEmployerFields
// > & {
//   incomeConfirmationFiles: TFile[]
// }
// export type TPrevEmployerWithHistory = TPrevEmployer & {
//   history: (TPrevEmployerSnap & {
//     settlementRequest: Pick<
//       NexusGenFieldTypes['SettlementRequest'],
//       'id' | 'requestedAt' | 'submittedAt'
//     >
//   })[]
// }

export type TPrevEmployerWithHistory = settlementRequest_settlementRequest_annualRebate_prevEmployers
export type TPrevEmployerSnapWithHistory = settlementRequest_settlementRequest_prevEmployers

export const isPrevEmployerSnap = (
  data: TPrevEmployerSnapWithHistory | TPrevEmployerWithHistory,
): data is TPrevEmployerSnapWithHistory => {
  return 'snapHistory' in data
}

export type TTaxQuestionnaireWithHistory = settlementRequest_settlementRequest_annualRebate_taxQuestionnaire
export type TTaxQuestionnaireSnapWithHistory = settlementRequest_settlementRequest_taxQuestionnaire

export const isTaxQuestionnaireSnap = (
  data: TTaxQuestionnaireSnapWithHistory | TTaxQuestionnaireWithHistory,
): data is TTaxQuestionnaireSnapWithHistory => {
  return 'snapHistory' in data
}

export type TMonthlyTaxQuestionnaireWithHistory = settlementRequest_settlementRequest_monthlyRebate_taxQuestionnaire
export type TMonthlyTaxQuestionnaireSnapWithHistory = settlementRequest_settlementRequest_monthlyTaxQuestionnaire

export const isMonthlyTaxQuestionnaireSnap = (
  data:
    | TMonthlyTaxQuestionnaireSnapWithHistory
    | TMonthlyTaxQuestionnaireWithHistory,
): data is TMonthlyTaxQuestionnaireSnapWithHistory => {
  return 'snapHistory' in data
}

export type TSettlementRequest = settlementRequest_settlementRequest
export interface ISettlementRequestData {
  settlementRequest: TSettlementRequest
}

export type TSettlementRequestVariables = NexusGenArgTypes['Query']['settlementRequest']

export const SETTLEMENT_REQUEST = gql`
  query settlementRequest($where: SettlementRequestWhereUniqueInput!) {
    settlementRequest(where: $where) {
      id
      type
      status
      createdAt
      requestedAt
      status
      submittedAt
      year
      employee {
        id
        firstname
        lastname
        gender
        nationalIDNumber
        permanentAddress
        nationality
        dateOfEmployment
        czechTaxResidence
        customer {
          id
          name
          yearOfAnnualRebates
          yearOfMonthlyRebates
        }
        personalNumber
        language
        unit {
          name
          color
          id
        }
        residenceAuthorisationFiles {
          id
          filename
        }
        residenceAuthorisationDocumentApprovals {
          commentOfDenied
          createdAt
          id
          solvedAt
          status
          files {
            id
            filename
          }
        }
        taxDomicilFiles {
          id
          filename
        }
        taxDomicilDocumentApprovals {
          commentOfDenied
          createdAt
          id
          solvedAt
          status
          files {
            id
            filename
          }
        }
      }
      taxDomicilDocumentApproval {
        id
        createdAt
        status
        commentOfDenied
        files {
          id
          filename
        }
      }
      residenceAuthorisationDocumentApproval {
        id
        createdAt
        status
        commentOfDenied
        files {
          id
          filename
        }
      }
      prevEmployers(orderBy: createdAt_DESC) {
        ...PrevEmployerSnapFragment
        snapHistory(orderBy: createdAt_DESC) {
          ...PrevEmployerSnapFragment
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
      }
      taxQuestionnaire {
        ...TaxQuestionnaireSnapWithHistory
      }
      monthlyTaxQuestionnaire {
        ...MonthlyTaxQuestionnaireSnapWithHistory
      }

      annualRebate {
        id
        year
        hasMonthlyInSameYear
        firstRequestAt
        file {
          id
          createdAt
        }
        fileMonthly {
          id
          createdAt
        }
        overview {
          pozp {
            status
          }
          basicRebate {
            status
          }
          studentRebate {
            status
          }
          childrenRebate {
            status
          }
          disabilityRebate {
            status
          }
          ztppRebate {
            status
          }
          lifeInsuranceRebate {
            status
          }
          pensionInsuranceRebate {
            status
          }
          unionRebate {
            status
          }
          foreclosureRebate {
            status
          }
          investmentRebate {
            status
          }
          longTermCareRebate {
            status
          }
          examRebate {
            status
          }
          giftRebate {
            status
          }
          preschoolRebate {
            status
          }
          spouseRebate {
            status
          }
          loanRebate {
            status
          }
        }
        hasPrevEmployers
        prevEmployersTouched
        prevEmployersSummaryInfo {
          usedBasicRebate
          usedBasicRebateInMonths

          usedStudentRebate
          usedStudentRebateInMonths

          usedChildrenRebate
          usedChildren {
            id
            ztpp
            usedInMonths
          }

          usedDisabilityRebate
          usedDisabilityRebateInMonths
          usedDisabilityRebateLevel

          usedZtppRebate
          usedZtppRebateInMonths
        }
        prevEmployers(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...PrevEmployerFragment
          history(orderBy: createdAt_DESC) {
            ...PrevEmployerSnapFragment
            settlementRequest {
              id
              requestedAt
              submittedAt
            }
          }
        }
        taxQuestionnaire {
          ...TaxQuestionnaireWithHistory
        }

        basicRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...BasicRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        studentRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...StudentRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        childrenRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...ChildrenRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        disabilityRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...DisabilityRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        ztppRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...ZtppRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        spouseRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...SpouseRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        giftRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...GiftRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        preschoolRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...PreschoolRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        lifeInsuranceRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...LifeInsuranceRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        pensionInsuranceRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...PensionInsuranceRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        loanRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...LoanRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        unionRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...UnionRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        foreclosureRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...ForeclosureRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        investmentRebates(
            where: { status_in: [CONFIRMED, DENIED] }
            orderBy: createdAt_DESC
        ) {
            ...InvestmentRebateSettlement
            settlementRequest {
                id
                requestedAt
                submittedAt
            }
        }
        longTermCareRebates(
            where: { status_in: [CONFIRMED, DENIED] }
            orderBy: createdAt_DESC
        ) {
            ...LongTermCareRebateSettlement
            settlementRequest {
                id
                requestedAt
                submittedAt
            }
        }
        examRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...ExamRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
      }

      monthlyRebate {
        id
        year
        variant
        firstRequestAt
        file {
          id
          createdAt
        }
        overview {
          dontCreate {
            status
          }
          basicRebate {
            status
            applied
          }
          studentRebate {
            status
            applied
          }
          childrenRebate {
            status
            applied
          }
          disabilityRebate {
            status
            applied
          }
          ztppRebate {
            status
            applied
          }
        }
        taxQuestionnaire {
          ...MonthlyTaxQuestionnaireWithHistory
        }
        basicRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...BasicRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        studentRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...StudentRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        childrenRebates(
          where: {
            otherParentStatus_not: APPLY
            children_every: { status_not: APPLY }
          }
          orderBy: createdAt_DESC
        ) {
          ...ChildrenRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        disabilityRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...DisabilityRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
        ztppRebates(
          where: { status_in: [CONFIRMED, DENIED] }
          orderBy: createdAt_DESC
        ) {
          ...ZtppRebateSettlement
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
      }
      basicRebate {
        ...BasicRebateSettlement
      }
      studentRebate {
        ...StudentRebateSettlement
      }
      childrenRebate {
        ...ChildrenRebateSettlement
      }
      disabilityRebate {
        ...DisabilityRebateSettlement
      }
      ztppRebate {
        ...ZtppRebateSettlement
      }
      lifeInsuranceRebate {
        ...LifeInsuranceRebateSettlement
      }
      pensionInsuranceRebate {
        ...PensionInsuranceRebateSettlement
      }
      unionRebate {
        ...UnionRebateSettlement
      }
      foreclosureRebate {
        ...ForeclosureRebateSettlement
      }

      investmentRebate {
        ...InvestmentRebateSettlement
      }
      longTermCareRebate {
        ...LongTermCareRebateSettlement
      }
      examRebate {
        ...ExamRebateSettlement
      }
      giftRebate {
        ...GiftRebateSettlement
      }
      preschoolRebate {
        ...PreschoolRebateSettlement
      }
      spouseRebate {
        ...SpouseRebateSettlement
      }
      loanRebate {
        ...LoanRebateSettlement
      }
    }
  }
  ${BASIC_REBATE_SETTLEMENT_FRAGMENT}
  ${STUDENT_REBATE_SETTLEMENT_FRAGMENT}
  ${CHILDREN_REBATE_SETTLEMENT_FRAGMENT}
  ${DISABILITY_REBATE_SETTLEMENT_FRAGMENT}
  ${ZTPP_REBATE_SETTLEMENT_FRAGMENT}
  ${SPOUSE_REBATE_SETTLEMENT_FRAGMENT}
  ${GIFT_REBATE_SETTLEMENT_FRAGMENT}
  ${PRESCHOOL_REBATE_SETTLEMENT_FRAGMENT}
  ${LIFE_INSURANCE_REBATE_SETTLEMENT_FRAGMENT}
  ${PENSION_INSURANCE_REBATE_SETTLEMENT_FRAGMENT}
  ${LOAN_REBATE_SETTLEMENT_FRAGMENT}
  ${UNION_REBATE_SETTLEMENT_FRAGMENT}
  ${FORECLOSURE_REBATE_SETTLEMENT_FRAGMENT}
  ${INVESTMENT_REBATE_SETTLEMENT_FRAGMENT}
  ${LONG_TERM_CARE_REBATE_SETTLEMENT_FRAGMENT}
  ${EXAM_REBATE_SETTLEMENT_FRAGMENT}
  ${PREV_EMPLOYER_SNAP}
  ${PREV_EMPLOYER}
  ${TAX_QUESTIONNAIRE_WITH_HISTORY}
  ${TAX_QUESTIONNAIRE_SNAP_WITH_HISTORY}
  ${MONTHLY_TAX_QUESTIONNAIRE_WITH_HISTORY}
  ${MONTHLY_TAX_QUESTIONNAIRE_SNAP_WITH_HISTORY}
`

export interface ISubmitSettlementRequestData {
  submitSettlementRequest: Pick<TSettlementRequest, 'id' | 'status'>
}

export type TSubmitSettlementRequestVariables = NexusGenArgTypes['Mutation']['submitSettlementRequest']

export const SUBMIT_SETTLEMENT_REQUEST = gql`
  mutation submitSettlementRequest(
    $where: SettlementRequestWhereUniqueInput!
    $data: AccountantsSettlementRequestUpdateInput!
  ) {
    submitSettlementRequest(where: $where, data: $data) {
      id
      status
    }
  }
`

