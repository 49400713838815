import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {ZTPP_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/ZtppRebate'

export type TZtppRebate = NexusGenFieldTypes['ZtppRebate']

export interface IOwnAnnualZtppRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      ztppRebate: TZtppRebate
      ztppRebates: TZtppRebate[]
    }
  }
}
export const OWN_ANNUAL_ZTPP_REBATE = gql`
  query ownAnnualZtppRebate {
    user {
      id
      annualRebate {
        id
        year
        ztppRebate {
          ...ZtppRebateFull
        }
        ztppRebates(orderBy: createdAt_DESC) {
          ...ZtppRebateFull
        }
      }
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`

export interface IUpdateOwnAnnualZtppRebateData {
  updateOwnAnnualZtppRebate: NexusGenFieldTypes['ZtppRebate']
}
export type TUpdateOwnAnnualZtppRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualZtppRebate']
export const UPDATE_OWN_ANNUAL_ZTPP_REBATE = gql`
  mutation updateOwnAnnualZtppRebate($data: OwnZtppAnnualRebateUpdateInput!) {
    updateOwnAnnualZtppRebate(data: $data) {
      ...ZtppRebateFull
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`


export interface IResetOwnAnnualZtppData {
  resetOwnAnnualZtppRebate: TZtppRebate
}
export const RESET_OWN_ANNUAL_ZTPP_REBATE = gql`
  mutation resetOwnAnnualZtppRebate {
    resetOwnAnnualZtppRebate {
      ...ZtppRebateFull
    }
  }
  ${ZTPP_REBATE_FULL_FRAGMENT}
`
