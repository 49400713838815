import {
  IOwnMonthlyBasicRebateData,
  IUpdateOwnMonthlyBasicRebateData,
  OWN_MONTHLY_BASIC_REBATE,
  TUpdateOwnMonthlyBasicRebateVariables,
  UPDATE_OWN_MONTHLY_BASIC_REBATE,
} from './gql'
import {Bold, Center, Divider, Green,} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {ChangeRebateChoice,} from '../../../AnnualRebates/components/ChangeRebateButton'
import {ILimiterWithMessage} from '../../../../../../components/form/DatePicker'
import YearMonthPicker from '../../../../../../components/form/YearMonthPicker'
import {getApplyFromInYear, getPrevRebates, getRebateStats} from '../../utils'
import {Observer} from 'mobx-react'
import {TBasicRebate} from '../../../../../../fragments/BasicRebate'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import {pickId, yearRange} from '../../../../../../utils'
import AppState from '../../../../../../components/AppState'
import BasicRebateOverview from '../../../AnnualRebates/components/BasicRebateOverview'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../../AnnualRebates/components/CancelRebateSection'
import CloseMonthlyPeriodSection from '../../../AnnualRebates/components/CloseMonthlyPeriodSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import moment, {Moment} from 'moment'
import Poplatnik from '../../../../../../components/icons/Poplatnik'
import PrevRebates from '../../../AnnualRebates/components/PrevRebates'
import React from 'react'
import RebateActions from '../../../AnnualRebates/components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import useForm, {Form} from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import Uploader from '../../../../../../components/form/Uploader'
import {BasicRebateConditions} from "../../../../../../shared/BasicWithPeriodsFormSection/BasicRebateConditions"
import {
  DateLabel,
  DatepickerWrapper,
  DateWrapper,
  GridContainer,
  InlineLabel
} from '../../../../../../shared/PrevPeriods/PrevPeriods'
import Grid from '@material-ui/core/Grid'
import MessageBar from '../../../../../../components/MessageBar'
import {
  CANCEL_OWN_LAST_MONTHLY_REBATE,
  TCancelOwnLastMonthlyRebateVariables
} from '../../gql/cancelOwnLastMonthlyRebate'
import { SET_MONTHLY_REBATE_SETTINGS, ISetMonthlyRebateSettingsVariables, ISetMonthlyRebateSettings } from '../../../../../../pages/Inside/pages/Support/components/gql'
import { AppliedRebates } from '../../components/AppliedRebates'
import { computed } from 'mobx'

const BasicRebate: React.FC = () => {
  const { t } = useTranslation()
  const { user, refetch } = useUser()
  const { history } = useRouter()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnMonthlyBasicRebateData,
    TUpdateOwnMonthlyBasicRebateVariables
  >(UPDATE_OWN_MONTHLY_BASIC_REBATE)

  const { data, loading, error } = useQuery<IOwnMonthlyBasicRebateData>(
    OWN_MONTHLY_BASIC_REBATE,
    { fetchPolicy: 'network-only' },
  )

  const [
    setRebateSettings,
    { loading: settingsLoading, error: settingsError },
  ] = useMutation<ISetMonthlyRebateSettings, ISetMonthlyRebateSettingsVariables>(
    SET_MONTHLY_REBATE_SETTINGS
  )

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastMonthlyRebateVariables>(
    CANCEL_OWN_LAST_MONTHLY_REBATE,
  )

  const year: number = get(data, 'user.monthlyRebate.year')
  const formData = get(data, 'user.monthlyRebate.basicRebate')
  const [yearStart, yearEnd] = yearRange(year)

  const dateOfEmployment = moment(user.data.dateOfEmployment)
  const dontRequireBasicRebateAffidavitFiles = user.dontRequireBasicRebateAffidavitFiles()

  const employmentConflict =
    !dontRequireBasicRebateAffidavitFiles &&
    dateOfEmployment.year() === year &&
    dateOfEmployment.date() > 1

  const nextMonthEmploymentDate = dateOfEmployment
    .clone()
    .add(1, 'month')
    .startOf('month')
  if(formData && !formData.applyTo) {
    formData.applyTo = year + '-12-31'
  }
  const { bind, form } = useForm<TUpdateOwnMonthlyBasicRebateVariables['data']>(
    formData || {},
    {
      applyFrom: {
        label: t('monthlyBasicRebate.applyFromLabel'),
        rule: (data) => (data.type !== 'CLOSE_PERIOD' ? 'required|date' : ''),
        placeholder: moment(`${year}-01-31`).format('MMMM YYYY')
      },
      applyTo: {
        placeholder: moment(`${year}-12-31`).format('MMMM YYYY'),
        help: t('monthlyBasicRebate.applyFromHelp', { context: "2023" })
      },
      closePeriodAt: {
        label: t('common.closePeriodAt'),
        rule: (data) => (data.type === 'CLOSE_PERIOD' ? 'required|date' : ''),
      },
      affidavitFiles: {
        type: 'multi_relation',
        label: t('monthlyBasicRebate.uploadLabel'),
        help: t('common.uploadHelp'),
        rule: (data) =>
          employmentConflict &&
          moment(data.applyFrom).isBefore(nextMonthEmploymentDate, 'day')
            ? 'required'
            : '',
      },
    },
    {
      onFieldChange(field, value, form) {
        if (field === 'affidavitFiles' && employmentConflict) {
          const affidavitFiles = form.getValue('affidavitFiles')
          if (affidavitFiles && affidavitFiles.length > 0) {
            form.setField('applyFrom', moment(user.data.dateOfEmployment).startOf('month'))
          } else {
            const applyFrom = form.getValue('applyFrom')

            if (
              applyFrom &&
              nextMonthEmploymentDate.isAfter(applyFrom, 'day')
            ) {
              form.setField('applyFrom', null)
            }
          }
        }
      },
      async onSubmit(data, form) {
        try {
          if (
            user.allowAlter() &&
            data.type === 'ALTER' &&
            user.data.monthlyRebate
          ) {
            await setRebateSettings({
              variables: {
                id: user.data.monthlyRebate.id,
                allowSendAnythingOnce: true
              }
            })
          }

          await updateRebate({
            variables: {
              data: {
                ...data,
                status: 'APPLY',
              },
            },
          })
          history.push(`/${user.data.id}/monthly-rebates/root`)
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const prevRebates = getPrevRebates<TBasicRebate>(
    get(data, 'user.monthlyRebate.basicRebates'),
  )

  const {
    isFirstRequest,
    isPrevDenied,
    isPrevPositiveClosedPeriod,
    showingCommentOfDenied,
    isCancelable,
    isPrevCanceled,
    prevPositiveRebate,
    prevPositiveRebates,
    closePeriodTookEffect,
    prevRebate,
  } = getRebateStats<TBasicRebate>(get(data, 'user.monthlyRebate.basicRebates'))

  const cleanRebate = async () => {
    if (formData) {
      const files = formData.affidavitFiles || []

      await updateRebate({
        variables: {
          data: {
            applyFrom: null,
            applyTo: null,
            status: 'NEW',
            type: 'NEW',
            closePeriodAt: null,
            affidavitFiles: {
              delete: files.map(pickId),
            },
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.monthlyRebate.basicRebate.status') !== 'APPLY') {
      await Promise.all(
        [
          cancelRebate({
            variables: {
              rebate: 'BASIC',
            },
          }),
          refetch()
        ]
      )
    }

    await cleanRebate()

    history.push(`/${user.data.id}/monthly-rebates/root`)
  }

  const handleNewPeriod = async () => {
    await cleanRebate()
    form.setField('type', 'NEW_PERIOD')
  }

  const handleAlterPeriod = async () => {
    await cleanRebate()
    form.setField('type', 'ALTER')
  }

  const handleClosePeriod = async () => {
    form.setField('type', 'CLOSE_PERIOD')
  }

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')

    return (
      isFirstRequest ||
      isPrevDenied ||
      isPrevCanceled ||
      closePeriodTookEffect ||
      type === 'NEW_PERIOD' ||
      type === 'ALTER' ||
      (prevRebate && prevRebate.type === 'REMOVE')
    )
  })

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || cancelLoading || settingsLoading} />
      <GraphQLErrorSnackbar error={error || updateError || cancelError || settingsError} />

      {data && (
        <Fade>
          <RebateLayout
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey="monthlyBasicRebate.sideHint" tOptions={{ context: "2023" }}>
                <Bold>Základní slevu na poplatníka</Bold> můžete uplatňovat <Bold>měsíčně</Bold> nebo <Bold>jednou ročně v rámci ročního zúčtování</Bold> (formulář Roční zúčtování &gt; krok Měsíční slevy dodatečné uplatnění). Daňové sleva je ve výši <Bold>2 570 Kč měsíčně</Bold> neboli <Bold>30 840 Kč ročně</Bold>.
              </Trans>
            }
            upperHint={`${t('common.monthlyRebates')} ${year}`}
            heading={t('monthlyBasicRebate.heading')}
            subHeading={<Trans i18nKey="monthlyBasicRebate.subHeading">Chcete-li uplatňovat Základní slevu na poplatníka, <Green>vyplňte datum uplatňování</Green></Trans>}
            icon={<Poplatnik fontSize="large" />}
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog
                  onSubmit={onCancelRebate}
                  isCancelOfDeniedRebate={get(data, 'user.monthlyRebate.basicRebate.status') !== 'APPLY'}
                />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelBasic')}
                    </CancelRebateSection>
                  )
                }

                if (type === 'CLOSE_PERIOD') {
                  return (
                      <CloseMonthlyPeriodSection
                          bind={bind}
                          yearStart={yearStart}
                          yearEnd={yearEnd}
                          confirmationValidityFrom={
                              prevPositiveRebate && prevPositiveRebate.applyFrom
                          }
                      >
                        {t('monthlyBasicRebate.closePeriodLabel')}
                      </CloseMonthlyPeriodSection>
                  )
                }

                if (showForm.get()) {
                  const affidavitFiles = form.getValue('affidavitFiles')
                  const hasAffidavit = affidavitFiles && affidavitFiles.length > 0
                  const confirmationValidityTo = prevPositiveRebate && prevPositiveRebate.applyTo

                  let minApplyFrom = moment(getApplyFromInYear(user, year, hasAffidavit))

                  // NOTE: Pokud uplatňujeme nové období a existuje předchozí schválená sleva, tak začínáme až po jejím konci.
                  if (type === "NEW_PERIOD" && confirmationValidityTo) {
                    const confirmationValidityToEnd = moment(confirmationValidityTo).endOf('month')
                    minApplyFrom = confirmationValidityToEnd.isAfter(minApplyFrom) ? confirmationValidityToEnd.add(1, "month").startOf("month") : minApplyFrom
                  }

                  // NOTE: Pokud jsme předčasně ukončili slevu, tak chceme navazovat na slevu na dalším měsíci.
                  if (prevPositiveRebate && prevPositiveRebate.type === 'CLOSE_PERIOD') {
                    minApplyFrom = moment(prevPositiveRebate.closePeriodAt).add(1, 'month').startOf('month')
                  }

                  return (
                    <FormSection
                      bind={bind}
                      form={form}
                      year={year}
                      disabled={moment(minApplyFrom).year() > year}
                      forceApply={(type === 'ALTER')}
                      minDates={[
                        {
                          date: minApplyFrom,
                          message: t('error.minDateCurrentMonthStart'),
                        },
                      ]}
                      maxDates={[
                        {
                          date: yearEnd,
                          message: t('error.maxDateMonthlyYearEnd'),
                        },
                      ]}
                    />
                  )
                }

                return (
                  <Center maxWidth="100%" width={680} flexDirection="column">
                      {isPrevPositiveClosedPeriod ? (
                        <ChangeRebateButton>
                          <ChangeRebateChoice
                            variant="ALTER"
                            onClick={handleAlterPeriod}
                          />
                        </ChangeRebateButton>
                      ) : (
                        <ChangeRebateButton>
                          <ChangeRebateChoice
                            label={t("common.monthlyRebateNewPeriod")}
                            onClick={handleNewPeriod}
                          />
                          <ChangeRebateChoice
                            variant="CLOSE_PERIOD"
                            onClick={handleClosePeriod}
                          />
                          {user.allowAlter() ? (
                            <ChangeRebateChoice
                              variant="ALTER"
                              onClick={handleAlterPeriod}
                            />
                          ) : null}
                        </ChangeRebateButton>
                      )}

                      <AppliedRebates
                        rebates={prevPositiveRebates.map(r => ({
                          from: r.applyFrom,
                          to: r.applyTo || yearEnd,
                          type: r.type,
                          closePeriodAt: r.closePeriodAt
                        }))}
                      />
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const showFormSection =
                  isFirstRequest ||
                  isPrevDenied ||
                  closePeriodTookEffect ||
                  type === 'NEW_PERIOD' ||
                  type === 'ALTER' ||
                  type === 'CLOSE_PERIOD' ||
                  type === 'REMOVE'

                return (
                  <Center maxWidth="100%" width={680} mt={3}>
                    <PrevRebates
                      rebates={prevRebates}
                      overviewComponent={BasicRebateOverview}
                      expand={!showFormSection}
                    />
                  </Center>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'CLOSE_PERIOD' &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    submitToBack={submitToBack}
                    backTo={`/${user.data.id}/monthly-rebates/root`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}

export const shouldShowBasicAffidavitUpload = (dateOfEmployment: Moment, year: number, customer: {allowApplyCurrentMonth: boolean, dontRequireBasicRebateAffidavitFiles: boolean}) => {
  const isFreshman = (moment().diff(dateOfEmployment, 'days') <= 30)
  if (dateOfEmployment.date() === 1) {
    return false // no need
  }

  return moment().year() === year
    && !customer.dontRequireBasicRebateAffidavitFiles
    && ((dateOfEmployment.date() !== 1 && isFreshman) || dateOfEmployment.isSame(moment(), 'month'))
}

export default BasicRebate

interface FormSectionProps {
  bind: (fieldPath: string) => any
  year: number
  form: Form,
  forceApply?: boolean,
  disabled?: boolean,
  minDates: ILimiterWithMessage[]
  maxDates: ILimiterWithMessage[]
}

const width = 680

const FormSection: React.FC<FormSectionProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { bind, disabled, minDates, maxDates, form, year } = props

  const dontRequireBasicRebateAffidavitFiles = user.dontRequireBasicRebateAffidavitFiles()
  const dateOfEmployment = moment(user.data.dateOfEmployment)
  const employmentConflict =
    !dontRequireBasicRebateAffidavitFiles &&
    dateOfEmployment.year() === year &&
    dateOfEmployment.date() > 1

  const nextMonthEmploymentDate = dateOfEmployment
    .clone()
    .add(1, 'month')
    .startOf('month')

  const showAffidavitUpload = shouldShowBasicAffidavitUpload(dateOfEmployment, year, user.data.customer)

  return (
    <>
      <Box maxWidth="100%" width={width}>
        <BasicRebateConditions showDownload={showAffidavitUpload} />
      </Box>

      <Divider maxWidth="100%" width={width} my={4} />

      <Observer>
        {() => {
          const applyFrom = form.getValue('applyFrom')
          const affidavitFiles = form.getValue('affidavitFiles')
          const hasAffidavit = affidavitFiles && affidavitFiles.length > 0

          const showWarning =
            employmentConflict &&
            !hasAffidavit &&
            moment(applyFrom).isBefore(nextMonthEmploymentDate, 'day')

          const minDateOfEmplomentLimiter =
            employmentConflict && !hasAffidavit
              ? {
                  date: nextMonthEmploymentDate,
                  message: t('error.minDateOfEmploment_NextMonth'),
                }
              : {
                  date: moment(user.data.dateOfEmployment).startOf('month'),
                  message: t('error.minDateOfEmploment'),
                }

          return (
            <>
              <Box
                maxWidth="100%"
                width={width}
                display="flex"
                flexDirection="column"
                my={2}
              >
                <GridContainer
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item xs>
                    <InlineLabel>
                      {t('monthlyStudentRebate.validityPeriodLabel')}
                    </InlineLabel>
                  </Grid>

                  <DateWrapper
                    item
                    sm={12}
                    md
                    container
                    alignItems="flex-start"
                    spacing={1}
                  >
                    <Grid item sm={12} md={6} container wrap="nowrap">
                      <DateLabel>{t('common.from')}</DateLabel>

                      <DatepickerWrapper>
                        <YearMonthPicker
                          fullWidth
                          disabled={disabled}
                          minDates={[
                            ...minDates
                          ]}
                          maxDates={maxDates}
                          {...bind('applyFrom')}
                        />
                      </DatepickerWrapper>
                    </Grid>

                    <Grid item sm={12} md={6} container wrap="nowrap">
                      <DateLabel>{t('common.to')}</DateLabel>

                      <DatepickerWrapper>
                        <YearMonthPicker
                          fullWidth
                          disabled={disabled}
                          pickEndOfTheMonth
                          minDates={[
                            ...minDates,
                            minDateOfEmplomentLimiter
                          ]}
                          maxDates={maxDates}
                          {...bind('applyTo')}
                        />
                      </DatepickerWrapper>
                    </Grid>
                  </DateWrapper>
                </GridContainer>

                <MessageBar type="WARNING" hide={!showWarning} style={{marginTop: '2rem'}}>
                  {t('error.basicRebateAfterEmployment', { date: nextMonthEmploymentDate.format('DD.MM.YYYY') })}
                </MessageBar>
              </Box>

              {showAffidavitUpload && (
                  <Box maxWidth="100%" width={width} mb={4}>
                    <Divider my={4} />
                    <Uploader {...bind('affidavitFiles')} showLabel multiple />
                  </Box>
                )}
            </>
          )
        }}
      </Observer>
    </>
  )
}
