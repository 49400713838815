import { useTranslation, Trans } from 'react-i18next'
import { Center, IconCircle, Bold } from './CommonBox'
import { OpravduSmazat } from './icons/KubikIcons'
import Box from '@material-ui/core/Box'
import Dialog from './Dialog'
import DialogActions from './DialogActions'
import DialogTitle from './DialogTitle'
import ErrorButton from './ErrorButton'
import React, { useState } from 'react'
import { Typography } from '@material-ui/core';

interface IProps {
  onSubmit?: () => void
  buttonText?: string | React.ReactNode
  isCancelOfDeniedRebate?: boolean
}

const CancelRebateDialog: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  const onSubmit = () => {
    onClose()
    if (props.onSubmit) {
      props.onSubmit()
    }
  }

  return (
    <>
      <ErrorButton onClick={onOpen}>{props.buttonText || t('common.cancelApply')}</ErrorButton>

      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle onClose={onClose} style={{ padding: '32px 64px' }}>
          <Center mb={2}>
            <IconCircle fontSize="3rem">
              <OpravduSmazat fontSize="inherit" color="primary" />
            </IconCircle>
          </Center>
          {t('rebate.cancelRebateDialogTitle', { context: props.isCancelOfDeniedRebate ? 'ALT' : '' })}
        </DialogTitle>

        {props.isCancelOfDeniedRebate && (
          <Box px={4} pb={2}>
            <Typography align='center'>
              <Trans i18nKey="rebate.cancelRebateDialogContent" components={[<Bold key={0} />]} />
            </Typography>
          </Box>
        )}

        <DialogActions gutterBottom dividerProps={{ mt: 1 }}>
          <Box display="flex" justifyContent="center">
            <ErrorButton onClick={onSubmit}>{t('common.erase')}</ErrorButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CancelRebateDialog
