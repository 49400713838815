import React from "react"
import moment from 'moment'
import {Link} from 'react-router-dom'
import {Trans, useTranslation} from "react-i18next"
import {useMutation, useQuery} from '@apollo/react-hooks'
import get from "lodash/get"

import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from '@material-ui/core/styles'

import {OWN_ANNUAL_REBATE_MONTHLY} from "./gql"

import {isOlderThen, userWorkedWholeYear} from '../../../../../../utils'
import {Deti, Invalida, Poplatnik, Student, ZTPP} from '../../../../../../components/icons/KubikIcons'
import {Divider, Green, UpperHint} from '../../../../../../components/CommonBox'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import AnnualStepper from '../../components/Stepper'
import AppState from '../../../../../../components/AppState'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Fade from '../../../../../../components/Fade'
import useUser from '../../../../../../hooks/useUser'
import RebateTile from "../../components/RebateTile"
import { TRebate, TRebateName, getRebateData } from '../../../../../../fragments/utils'
import { userWithAnnualRebateMonthly } from '../../../../../../generated/userWithAnnualRebateMonthly'
import { UPDATE_ANNUAL_WIZARD_STEP } from "../../gql/updateAnnualWizardStepMutation"
import { updateAnnualRebateWizardStep, updateAnnualRebateWizardStepVariables } from "../../../../../../generated/updateAnnualRebateWizardStep"
import { AnnualWizardStep } from "../../../../../../generated/globalTypes"
import InfoBox from "../../../../../../components/InfoBox"

const useStyles = makeStyles({
    tileContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '1rem',
        maxWidth: 650,
        "& > div": {
          width: 'calc(33% - 2rem)',
          minWidth: 175,
          aspectRatio: '1/1'
        }
      }
})

const MonthlyRebatesRoot: React.FC = () => {
    const { t } = useTranslation()
    const { user } = useUser()
    const classes = useStyles()

    const [updateAnnualWizardStep] = useMutation<
        updateAnnualRebateWizardStep,
        updateAnnualRebateWizardStepVariables
    >(UPDATE_ANNUAL_WIZARD_STEP)

    const { data, loading, error } = useQuery<userWithAnnualRebateMonthly>(
        OWN_ANNUAL_REBATE_MONTHLY,
        {
          fetchPolicy: 'network-only',
          onCompleted(response) {
            const wizardStep = get(response, 'user.annualRebate.wizardStep')
            
            if (wizardStep !== 'REBATE_MONTHLY') {
              updateAnnualWizardStep({
                variables: { wizardStep: AnnualWizardStep.REBATE_MONTHLY },
              })
            }
          },
        },
      )

    const year = get(data, 'user.annualRebate.year') || ''

    const foreignerNoFiles = user.isForeignerWithNoFiles()
    const annualRebate = data && data.user && data.user.annualRebate
  
    const workedWholeYear = userWorkedWholeYear(user.data, year)

    const parseRebate = (rebate: TRebateName) =>
      get(data, `user.annualRebate.${rebate}s`) as TRebate[]
  
    const prevEmployerDenied = Boolean(
      annualRebate &&
      annualRebate.prevEmployers &&
      annualRebate.prevEmployers.some(({ status }) => status === 'DENIED'),
    )

    const { isOverallConfirmed: basicRebateOverallConfirmed } = getRebateData(data && data.user && data.user.annualRebate, 'basicRebate')
    const { isOverallConfirmed: childrenRebateOverallConfirmed } = getRebateData(data && data.user && data.user.annualRebate, 'childrenRebate')
    const { isOverallConfirmed: disabilityRebateOverallConfirmed } = getRebateData(data && data.user && data.user.annualRebate, 'disabilityRebate')
    const { isOverallConfirmed: ztppRebateOverallConfirmed } = getRebateData(data && data.user && data.user.annualRebate, 'ztppRebate')

    return (
        <InsideLayout sidebar>
            <ScrollToTop />
            <AppState loading={loading} />
            <GraphQLErrorSnackbar error={error} />

            {data && (
                <Fade>
                    <Box
                        maxWidth="100%"
                        ml="auto"
                        mr="auto"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <UpperHint>
                            {t('common.form')} {t('common.annualRebates')} {year}
                        </UpperHint>

                        <AnnualStepper activeStep={4} />

                        <Typography variant="h1" align="center" gutterBottom>
                            {t('annualRebateWizard.monthly.heading')}
                        </Typography>

                        <Box maxWidth={500}>
                            <Typography align="center">
                                <Trans i18nKey='annualRebateWizard.monthly.description' components={[<Green key="0" />]} />
                            </Typography>
                        </Box>

                        <InfoBox title={t('rebateChanges.reapply')} maxWidth={480} mt={4}>
                            {t('annualRebateWizard.monthly.warning')}
                        </InfoBox>

                        {prevEmployerDenied && (
                            <InfoBox title={t('annualRebateWizard.stepper.previousEmployer')} type='warning' maxWidth={480} mt={4}>
                                <Trans i18nKey={'annualRebateWizard.prevEmployerDenied'}>
                                Z důvodu zamítnutí předchozího zaměstnavatele, nelze provést
                                roční zúčtování. Proveďte prosím opravu v kroku
                                <Link
                                    to={`/${user.data.id}/annual-rebates/previousEmployers`}
                                >
                                    Předchozí zaměstnavatel
                                </Link>
                                .
                                </Trans>
                            </InfoBox>
                        )}

                        {foreignerNoFiles && (
                            <InfoBox title={t('annualRebateWizard.stepper.taxResidence')} type='warning' maxWidth={480} mt={4}>
                                <Trans
                                    i18nKey={
                                        user.noProvidedFiles()
                                        ? 'annualRebateWizard.foreignerNoFiles'
                                        : 'annualRebateWizard.foreignerDeniedFiles'
                                    }
                                    tOptions={{ context: 'MONTHLY' }}
                                    components={[<Link to={`/${user.data.id}/annual-rebates/taxResidence`} key="1" />]}
                                />
                            </InfoBox>
                        )}

                        <Divider maxWidth={180} my={4} />

                        <Grid item lg={8} xs={12}>
                            <div className={classes.tileContainer}>
                                <RebateTile
                                    to={`/${user.data.id}/annual-rebates/basic-rebate`}
                                    icon={<Poplatnik />}
                                    label={t('rebate.basicRebate')}
                                    data={parseRebate('basicRebate')}
                                    confirmed={basicRebateOverallConfirmed}
                                />

                                {user.showStudentRebate(year) ? (
                                    <RebateTile
                                        to={`/${user.data.id}/annual-rebates/student-rebate`}
                                        icon={<Student />}
                                        label={t('rebate.studentRebate')}
                                        data={parseRebate('studentRebate')}
                                        locked={isOlderThen(user.data.birthdate, 28, moment([year, 0])) || prevEmployerDenied}
                                    />
                                ) : null}

                                <RebateTile
                                    to={`/${user.data.id}/annual-rebates/children-rebate`}
                                    icon={<Deti />}
                                    label={t('rebate.childrenRebate')}
                                    data={parseRebate('childrenRebate')}
                                    locked={foreignerNoFiles || prevEmployerDenied}
                                    confirmed={childrenRebateOverallConfirmed}
                                />

                                <RebateTile
                                    to={`/${user.data.id}/annual-rebates/disability-rebate`}
                                    icon={<Invalida />}
                                    label={t('rebate.disabilityRebate')}
                                    data={parseRebate('disabilityRebate')}
                                    locked={foreignerNoFiles || prevEmployerDenied}
                                    confirmed={disabilityRebateOverallConfirmed}
                                />

                                <RebateTile
                                    to={`/${user.data.id}/annual-rebates/ztpp-rebate`}
                                    icon={<ZTPP />}
                                    label={t('rebate.ztppRebate')}
                                    data={parseRebate('ztppRebate')}
                                    locked={foreignerNoFiles || prevEmployerDenied}
                                    confirmed={ztppRebateOverallConfirmed}
                                />
                            </div>
                        </Grid>

                        <Divider maxWidth={1080} mt={8} mb={4} position="relative">
                            <Box
                                position="absolute"
                                left={5}
                                fontSize={14}
                                fontWeight="fontWeightBold"
                                bottom={16}
                                fontFamily="fontFamily"
                                color="text.hint"
                            >
                                {t('common.monthlyRebates')}
                            </Box>
                        </Divider>

                        <ButtonStepContainer
                         backButton={
                                user.shouldHaveResidenceStep()
                                ? {
                                    to: `/${user.data.id}/annual-rebates/taxResidence`,
                                    text: t('annualRebateWizard.stepper.taxResidence'),
                                    }
                                : workedWholeYear
                                ? {
                                    to: `/${user.data.id}/annual-rebates/questionnaire`,
                                    text: t('annualRebateQuestionnaire.heading'),
                                    }
                                : {
                                    to: `/${user.data.id}/annual-rebates/previousEmployers`,
                                    text: t('annualRebateWizard.stepper.previousEmployer'),
                                    }
                            }
                        >
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                component={Link}
                                to={`/${user.data.id}/annual-rebates/root`}
                            >
                                {t('common.continue')}
                            </Button>
                        </ButtonStepContainer>
                    </Box>
                </Fade>
            )}
        </InsideLayout>
    )
}

export default MonthlyRebatesRoot
