import {
  updateTaxQuestionnaire,
  updateTaxQuestionnaireVariables,
} from '../../../../../../generated/updateTaxQuestionnaire'
import {AnnualWizardStep, TaxQuestionnaireStatus} from '../../../../../../generated/globalTypes'
import {
  userWithAnnualTaxQuestionnaire,
  userWithAnnualTaxQuestionnaire_user_annualRebate_taxQuestionnaire_history,
} from '../../../../../../generated/userWithAnnualTaxQuestionnaire'
import {compareMonthsArray} from '../../../../../../utils'
import {DeniedRebateNotice} from '../../../../../../components/layouts/RebateLayout'
import {Center, Divider, Green, UpperHint} from '../../../../../../components/CommonBox'
import {Observer} from 'mobx-react'
import {OWN_ANNUAL_TAX_QUESTIONNAIRE, UPDATE_TAX_QUESTIONNAIRE} from './gql'
import {Question, QuestionnaireReadonlyView} from '../../../../../../shared/Questionnaire'
import {UPDATE_ANNUAL_WIZARD_STEP} from '../../gql/updateAnnualWizardStepMutation'
import {computed, toJS} from 'mobx'
import {useForm, useRouter, useUser} from '../../../../../../hooks'
import {useMutation, useQuery} from 'react-apollo'
import {Trans, useTranslation} from 'react-i18next'
import AnnualStepper from '../../components/Stepper'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import MonthPicker from '../../../../../../components/form/MonthPicker'
import omit from 'lodash/omit'
import PrevRebate from '../../components/PrevRebate'
import React, {useCallback, useEffect, useState} from 'react'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import Uploader from '../../../../../../components/form/Uploader'
import {makeStyles} from '@material-ui/core/styles'
import InfoIcon from "@material-ui/icons/Info";
import {BlueButton} from '../../../../../../components/BlueButton'
import InfoBox from '../../../../../../components/InfoBox';
import { ExternalLink } from '../../../../../../pages/Inside/pages/MonthlyRebates/components/ExternalLink';

const useQuestionnaireFieldProp = (annualRebateYear: number) => {
  const { user } = useUser()

  return useCallback(
    (
      field: keyof updateTaxQuestionnaireVariables['data'],
      data: updateTaxQuestionnaireVariables['data'],
    ): { required: boolean; omit: boolean; hide: boolean } => {
      const {
        createByActualEmployer,
        samePreviousEmployer,
        otherIncomes,
        selfEmployed,
        concurrentEmployers,
        solidarityTax,
        foreignIncome,
        laborOffice,
        noTaxableIncome
      } = data

      const version1 = createByActualEmployer === false

      const version2 =
        samePreviousEmployer === false ||
        otherIncomes === true ||
        selfEmployed === true ||
        concurrentEmployers === true ||
        solidarityTax === true ||
        foreignIncome === true

      let required = false,
        omit = false,
        hide = false

      const workedWholeYear = user.workedWholeYear(annualRebateYear)

      switch (field) {
        case 'createByActualEmployer':
          required = !version2
          omit = !required
          hide = omit && createByActualEmployer !== false
          break
        case 'samePreviousEmployer':
          required = version2 ? false : !version1
          omit = !required && samePreviousEmployer !== false
          hide = omit
          break
        case 'otherIncomes':
          required = version2 ? false : !version1
          omit = !required && otherIncomes !== true
          hide = omit
          break
        case 'selfEmployed':
          required = version2 ? false : !version1
          omit = !required && selfEmployed !== true
          hide = omit
          break
        case 'concurrentEmployers':
          required = version2 ? false : !version1
          omit = !required && concurrentEmployers !== true
          hide = omit
          break
        case 'solidarityTax':
          required = version2 ? false : !version1
          omit = !required && solidarityTax !== true
          hide = omit
          break
        case 'foreignIncome':
          required = version2 ? false : !version1
          omit = !required && foreignIncome !== true
          hide = omit
          break
        case 'laborOffice':
          required = (workedWholeYear && !user.blockedAnnualOverride()) ? false : version2 ? false : !version1
          omit = !required // TBD schovat pokud pracoval celý rok
          hide = omit
          break
        case 'laborOfficeFiles':
          required = version2 ? false : laborOffice === true
          omit = !required
          break
        case 'laborOfficeMonths':
          required = version2 ? false : laborOffice === true
          omit = !required
          break
        case 'noTaxableIncome':
          required = (workedWholeYear && !user.blockedAnnualOverride()) ? false : version2 ? false : !version1
          required = user.data.customer.requireNoTaxableIncomeAffidavit ? required : false
          omit = !required
          hide = omit
          break
        case 'noTaxableIncomeFiles':
          required = version2 ? false : noTaxableIncome === true
          omit = !required
          break
      }

      return { required, omit, hide }
    },
    [user, annualRebateYear],
  )
}

const useNavigateToNextStep = (annualRebateYear: number) => {
  const { user } = useUser()
  const { history } = useRouter()

  const navigateToNextStep = useCallback(
    (taxQuestionnaire: {
      createByActualEmployer?: boolean | null
      samePreviousEmployer?: boolean | null
      otherIncomes?: boolean | null
      selfEmployed?: boolean | null
      concurrentEmployers?: boolean | null
      solidarityTax?: boolean | null
      foreignIncome?: boolean | null
      laborOffice?: boolean | null
    }) => {
      const {
        createByActualEmployer,
        samePreviousEmployer,
        otherIncomes,
        selfEmployed,
        concurrentEmployers,
        solidarityTax,
        foreignIncome,
      } = taxQuestionnaire

      // version1 = nechce vytvořit Roční zúčtování = má POZD
      const version1 = createByActualEmployer === false

      // version2 = nemůže si nechat udělat RZD ==> má POZD
      const version2 =
        samePreviousEmployer === false ||
        otherIncomes === true ||
        selfEmployed === true ||
        concurrentEmployers === true ||
        solidarityTax === true ||
        foreignIncome === true

      const workedWholeYear = user.workedWholeYear(annualRebateYear)

      if (version1 || version2) {
        history.push(`/${user.data.id}/annual-rebates/summary`)
      } else {
        if (!workedWholeYear) {
          history.push(`/${user.data.id}/annual-rebates/previousEmployers`, {
            from: 'questionnaire',
          })
        } else if (user.shouldHaveResidenceStep()) {
          history.push(`/${user.data.id}/annual-rebates/taxResidence`, {
            from: 'questionnaire',
          })
        } else {
          history.push(`/${user.data.id}/annual-rebates/rootMonthly`, {
            from: 'questionnaire',
          })
        }
      }
    },
    [user, history, annualRebateYear],
  )
  return navigateToNextStep
}

/**
 *
 * @description Pokud se jedná o POZP a ve formu nedošlo ke změně oproti poslední žádosti (snapu),
 * tak nelze pokračovat dál
 */
const useDisableProceed = (
  data: userWithAnnualTaxQuestionnaire | undefined,
) => {
  const [
    initialData,
    setInitialData,
  ] = useState<userWithAnnualTaxQuestionnaire_user_annualRebate_taxQuestionnaire_history | null>(
    null,
  )
  useEffect(() => {
    if (
      data &&
      data.user &&
      data.user.annualRebate &&
      data.user.annualRebate.taxQuestionnaire &&
      data.user.annualRebate.taxQuestionnaire.history &&
      data.user.annualRebate.taxQuestionnaire.history[0]
    ) {
      setInitialData(data.user.annualRebate.taxQuestionnaire.history[0])
    }
  }, [data])

  return useCallback(
    (formData: updateTaxQuestionnaireVariables['data']) => {
      if (initialData && formData) {
        const questionsChanged =
          initialData.createByActualEmployer ==
            formData.createByActualEmployer &&
          initialData.samePreviousEmployer == formData.samePreviousEmployer &&
          initialData.otherIncomes == formData.otherIncomes &&
          initialData.selfEmployed == formData.selfEmployed &&
          initialData.concurrentEmployers == formData.concurrentEmployers &&
          //initialData.solidarityTax == formData.solidarityTax &&
          initialData.foreignIncome == formData.foreignIncome &&
          initialData.laborOffice == formData.laborOffice

        if (initialData.status !== 'DENIED') {
          const pozp =
            formData.createByActualEmployer === false ||
            formData.samePreviousEmployer === false ||
            formData.otherIncomes === true ||
            formData.selfEmployed === true ||
            formData.concurrentEmployers === true ||
            //formData.solidarityTax === true ||
            formData.foreignIncome === true

          if (!pozp) return false

          if (
            formData.createByActualEmployer === false &&
            initialData.createByActualEmployer === false
          ) {
            return true
          }

          if (
            formData.samePreviousEmployer === false &&
            initialData.samePreviousEmployer === false
          ) {
            return true
          }

          if (
            formData.otherIncomes === true &&
            initialData.otherIncomes === true
          ) {
            return true
          }

          if (
            formData.selfEmployed === true &&
            initialData.selfEmployed === true
          ) {
            return true
          }

          if (
            formData.concurrentEmployers === true &&
            initialData.concurrentEmployers === true
          ) {
            return true
          }

          // if (
          //   formData.solidarityTax === true &&
          //   initialData.solidarityTax === true
          // ) {
          //   return true
          // }

          if (
            formData.foreignIncome === true &&
            initialData.foreignIncome === true
          ) {
            return true
          }

          if (formData.laborOffice) {
            const laborOfficeFiles = toJS(formData.laborOfficeFiles)
            const laborOfficeMonths = get(
              formData,
              'laborOfficeMonths.prisma_set',
            )

            if (laborOfficeMonths) {
              const areSame = compareMonthsArray(
                initialData.laborOfficeMonths,
                toJS(laborOfficeMonths),
              )
              if (!areSame) {
                return false
              }
            }

            if (laborOfficeFiles) {
              if (
                (laborOfficeFiles.delete &&
                  laborOfficeFiles.delete.length > 0) ||
                (laborOfficeFiles.create &&
                  laborOfficeFiles.create.length > 0) ||
                (laborOfficeFiles.connect &&
                  laborOfficeFiles.connect.length > 0)
              ) {
                return false
              }
            }
          }

          return questionsChanged
        }

        if (initialData.status === 'DENIED') {
          return false
        }
      }

      return false
    },
    [initialData],
  )
}

const useStyles = makeStyles((theme) => ({
  laborOffice: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    display: 'flex',
    minWidth: 'fit-content',
    marginRight: theme.spacing(2),
    lineHeight: `${theme.spacing(5.3)}px`,
  },
  heading: {
    // marginTop: '2rem',
    marginBottom: '1rem'
  },
  infoIcon: {
    verticalAlign: "top"
  }
}))

const Questionnaire: React.FC = () => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()

  const [status, setStatus] = useState<TaxQuestionnaireStatus | null>(null)

  const isConfirmed = status === TaxQuestionnaireStatus.CONFIRMED

  // const localizeAmount = (amount: string) => {
  //   switch (language) {
  //     case 'EN':
  //       return amount.replace(/ /g, ',')
  //     default:
  //       return amount
  //   }
  // }
  // const solidarityAmount = localizeAmount('1 701 168') // TBD single source of truth? (+ Summary)
  // const solidarityAmountAverage = localizeAmount('141 764') // TBD single source of truth?
  const isFirstRound = user.firstRound()

  const [updateAnnualWizardStep] = useMutation(UPDATE_ANNUAL_WIZARD_STEP)

  const { data, loading, error } = useQuery<userWithAnnualTaxQuestionnaire>(
    OWN_ANNUAL_TAX_QUESTIONNAIRE,
    {
      fetchPolicy: 'cache-and-network',
      onError() {},
      onCompleted(response) {
        setStatus(get(response, 'user.annualRebate.taxQuestionnaire.status') || null)

        const wizardStep = get(response, 'user.annualRebate.wizardStep')
        if (wizardStep !== 'QUESTIONNAIRE') {
          updateAnnualWizardStep({
            variables: { wizardStep: AnnualWizardStep.QUESTIONNAIRE },
          })
        }
      },
    },
  )

  const annualRebate = data && data.user && data.user.annualRebate
  const taxQuestionnaire = annualRebate && annualRebate.taxQuestionnaire
  const year = (annualRebate && annualRebate.year) || Infinity
  const snap =
    taxQuestionnaire && taxQuestionnaire.history && taxQuestionnaire.history[0]

  const questionProps = useQuestionnaireFieldProp(year)
  const navigateToNextStep = useNavigateToNextStep(year)
  const disableProceed = useDisableProceed(data)

  const [
    updateQuestionnaire,
    { loading: updateLoading, error: updateError },
  ] = useMutation<updateTaxQuestionnaire, updateTaxQuestionnaireVariables>(
    UPDATE_TAX_QUESTIONNAIRE
  )

  const { bind, form } = useForm<updateTaxQuestionnaireVariables['data']>(
    (taxQuestionnaire && omit(taxQuestionnaire, 'history')) || {},
    {
      createByActualEmployer: {
        rule: (data) =>
          questionProps('createByActualEmployer', data).required
            ? 'required'
            : '',
        label: t('annualRebateQuestionnaire.createByActualEmployer', {
          company: user.data.customer.name,
        }),
        help: t('annualRebateQuestionnaire.createByActualEmployerHelp'),
      },/*
      samePreviousEmployer: {
        rule: (data) =>
          questionProps('samePreviousEmployer', data).required
            ? 'required'
            : '',
        label: t('annualRebateQuestionnaire.samePreviousEmployer', {
          company: user.data.customer.name,
        }),
        help: t('annualRebateQuestionnaire.samePreviousEmployerHelp', {
          context: user.data.gender,
          company: user.data.customer.name,
        }),
        omitOnSubmit: (data) =>
          questionProps('samePreviousEmployer', data).omit,
      },*/
      otherIncomes: {
        rule: (data) =>
          questionProps('otherIncomes', data).required ? 'required' : '',
        label: t('annualRebateQuestionnaire.otherIncomes', {
          context: user.data.gender,
        }),
        help: t('annualRebateQuestionnaire.otherIncomesHelp'),
        omitOnSubmit: (data) => questionProps('otherIncomes', data).omit,
      },
      selfEmployed: {
        rule: (data) =>
          questionProps('selfEmployed', data).required ? 'required' : '',
        label: t('annualRebateQuestionnaire.selfEmployed', {
          context: user.data.gender,
        }),
        help: t('annualRebateQuestionnaire.selfEmployedHelp', {
          context: user.data.gender,
        }),
        omitOnSubmit: (data) => questionProps('selfEmployed', data).omit,
      },
      concurrentEmployers: {
        rule: (data) =>
          questionProps('concurrentEmployers', data).required ? 'required' : '',
        label: t('annualRebateQuestionnaire.concurrentEmployers', {
          context: user.data.gender,
        }),
        help: t('annualRebateQuestionnaire.concurrentEmployersHelp', {
          context: user.data.gender,
        }),
        omitOnSubmit: (data) => questionProps('concurrentEmployers', data).omit,
      },
      // solidarityTax: {
      //   // rule: (data) =>
      //   //   questionProps('solidarityTax', data).required ? 'required' : '',
      //   label: t('annualRebateQuestionnaire.solidarityTax', {
      //     amount: solidarityAmount,
      //   }),
      //   help: t('annualRebateQuestionnaire.solidarityTaxHelp', {
      //     context: user.data.gender,
      //     annualRebateYear: year,
      //     amount: solidarityAmount,
      //     amountAverage: solidarityAmountAverage,
      //   }),
      //   omitOnSubmit: (data) => questionProps('solidarityTax', data).omit,
      // },
      foreignIncome: {
        rule: (data) =>
          questionProps('foreignIncome', data).required ? 'required' : '',
        label: t('annualRebateQuestionnaire.foreignIncome', {
          context: user.data.gender,
        }),
        help: t('annualRebateQuestionnaire.foreignIncomeHelp', {
          context: user.data.gender,
        }),
        omitOnSubmit: (data) => questionProps('foreignIncome', data).omit,
      },
      laborOffice: {
        rule: (data) =>
          questionProps('laborOffice', data).required ? 'required' : '',
        label: t('annualRebateQuestionnaire.laborOffice', {
          context: user.data.gender,
        }),
        help: t('annualRebateQuestionnaire.laborOfficeHelp', {
          context: user.data.gender,
        }),
        omitOnSubmit: (data) => questionProps('laborOffice', data).omit,
      },
      laborOfficeMonths: {
        type: 'list',
        placeholder: t('common.monthOfApply') + '...',
        label: t('annualRebateQuestionnaire.laborOfficeMonths', {
          context: user.data.gender,
        }),
        //help: t('annualRebateQuestionnaire.laborOfficeMonthsHelp'),
        rule: (data) =>
          questionProps('laborOfficeMonths', data).required ? 'required' : '',
        omitOnSubmit: (data) => questionProps('laborOfficeMonths', data).omit,
      },
      laborOfficeFiles: {
        type: 'multi_relation',
        label: t('annualRebateQuestionnaire.laborOfficeFiles'),
        //help: t('annualRebateQuestionnaire.laborOfficeFilesHelp'),
        rule: (data) =>
          questionProps('laborOfficeFiles', data).required ? 'required' : '',
        omitOnSubmit: (data) => questionProps('laborOfficeFiles', data).omit,
      },
      noTaxableIncome: {
        rule: (data) => questionProps('noTaxableIncome', data).required ? 'required' : '',
        label: t('annualRebateQuestionnaire.noTaxableIncome', { context: user.data.gender }),
        help: t('annualRebateQuestionnaire.noTaxableIncomeHelp', { context: user.data.gender }),
        omitOnSubmit: (data) => questionProps('noTaxableIncome', data).omit,
      },
      noTaxableIncomeFiles: {
        type: 'multi_relation',
        label: t('annualRebateQuestionnaire.noTaxableIncomeFiles'),
        rule: (data) => questionProps('noTaxableIncomeFiles', data).required ? 'required' : '',
        omitOnSubmit: (data) => questionProps('noTaxableIncomeFiles', data).omit,
      },
    },
    {
      async onSubmit(formData) {
        function hasChanged() {
          if (status === TaxQuestionnaireStatus.ALTER || status === TaxQuestionnaireStatus.DENIED) {
            return true
          }

          const values = [
            'concurrentEmployers',
            'createByActualEmployer',
            'foreignIncome',
            'laborOffice',
            'laborOfficeFiles',
            'laborOfficeMonths',
            'otherIncomes',
            'samePreviousEmployer',
            'selfEmployed',
            'solidarityTax',
            'noTaxableIncome',
            'noTaxableIncomeFiles'
          ]

          function isValidKey(key: string): key is keyof updateTaxQuestionnaireVariables['data'] {
            return values.includes(key);
          }

          return Object.keys(formData).some(key => {
            if (isValidKey(key)) {
              return (
                taxQuestionnaire &&
                taxQuestionnaire[key] !== formData[key]
              )
            }
          })
        }

        if (taxQuestionnaire && !hasChanged()) {
          navigateToNextStep(taxQuestionnaire)

          return
        }
        
        const result = await updateQuestionnaire({
          variables: {
            data: formData,
          },
        })

        if (result && result.data && result.data.updateTaxQuestionnaire) {
          navigateToNextStep(result.data.updateTaxQuestionnaire)
        } else {
          form.onFail('Nepovedlo se to') // TBD Translate?
        }
      },
    },
  )

  const handleAlter = async () => {
    setStatus(TaxQuestionnaireStatus.ALTER)
  }

  const isPozp = computed(() => {
    const formData = form.getData()

    return (
      formData.createByActualEmployer === false ||
      formData.samePreviousEmployer === false ||
      formData.otherIncomes === true ||
      formData.selfEmployed === true ||
      formData.concurrentEmployers === true ||
      formData.foreignIncome === true
    )
  })

  return (
    <InsideLayout sidebar>
      <ScrollToTop />
      <AppState loading={loading || updateLoading} />
      <GraphQLErrorSnackbar error={error || updateError} />
      <Box
        maxWidth="100%"
        width={1080}
        ml="auto"
        mr="auto"
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingBottom={12}
      >
        <UpperHint>
          {t('common.form')} {t('common.annualRebates')} {year}
        </UpperHint>

        <Observer>
          {() => <AnnualStepper activeStep={1} isPozp={isPozp.get()} />}
        </Observer>

        <Box maxWidth="100%">
          <Typography variant="h1" align="center" className={classes.heading}>
            {t('annualRebateQuestionnaire.heading')}
          </Typography>
          <Typography align="center">
            <Trans i18nKey={'annualRebateQuestionnaire.subheading'}>
              Odpovězte na otázky níže, zdali Vám může být zpracovné
              <Green>roční zúčtování na dani</Green> za minulý rok. Pro <Green>více informací</Green>
              k otázkám vyberte <InfoIcon color={"primary"} className={classes.infoIcon} />
            </Trans>
          </Typography>
        </Box>

        <Divider mb={1.5} mt={4} maxWidth={180} />

        {isConfirmed ? (
          <Center my={2}>
            <BlueButton
              color="primary"
              size="large"
              variant="contained"
              onClick={handleAlter}
              >
              {t('rebateChanges.wantToMakeChange')}
            </BlueButton>
          </Center>
        ) : null}

        {!loading && (
          <Fade>
            <Box maxWidth="100%" width={850}>
              {snap && snap.status === 'DENIED' && (
                <DeniedRebateNotice variant="employer" fullwidth gutterBottom>
                  {snap.commentOfDenied}
                </DeniedRebateNotice>
              )}
              <Observer>
                {() => {
                  const data = form.getData()

                  return (
                    <>
                      <Question
                        helpStyle="collapse"
                        {...bind('createByActualEmployer')}
                        hide={questionProps('createByActualEmployer', data).hide}
                        hideDivider
                        disabled={isConfirmed}
                      />

                      {/* {false && <Question
                        helpStyle="collapse"
                        {...bind('samePreviousEmployer')}
                        hide={questionProps('samePreviousEmployer', data).hide}
                        disabled={isConfirmed}
                      />} */}

                      <Question
                        hideDivider={!questionProps('otherIncomes', data).required}
                        helpStyle="collapse"
                        {...bind('otherIncomes')}
                        hide={questionProps('otherIncomes', data).hide}
                        disabled={isConfirmed}
                      />

                      <Question
                        hideDivider={!questionProps('selfEmployed', data).required}
                        helpStyle="collapse"
                        {...bind('selfEmployed')}
                        hide={questionProps('selfEmployed', data).hide}
                        disabled={isConfirmed}
                      />

                      <Question
                        hideDivider={!questionProps('concurrentEmployers', data).required}
                        helpStyle="collapse"
                        {...bind('concurrentEmployers')}
                        hide={questionProps('concurrentEmployers', data).hide}
                        disabled={isConfirmed}
                      />

                      {/* <Question
                        {...bind('solidarityTax')}
                        hide={questionProps('solidarityTax', data).hide}
                      /> */}

                      <Question
                        hideDivider={!questionProps('foreignIncome', data).required}
                        helpStyle="collapse"
                        {...bind('foreignIncome')}
                        hide={questionProps('foreignIncome', data).hide}
                        disabled={isConfirmed}
                      />

                      <Question
                        helpStyle="collapse"
                        {...bind('laborOffice')}
                        hide={questionProps('laborOffice', data).hide}
                        childrenIn={form.getValue('laborOffice') === true}
                        disabled={isConfirmed}
                      >
                        <Divider my={2} maxWidth="25%" />
                        <Box maxWidth="100%" width={650} margin="auto">
                          <Box display="flex">
                            <Typography variant="h4" className={classes.label}>
                              {t('annualRebateQuestionnaire.laborOfficeFor', {
                                context: user.data.gender,
                              })}
                            </Typography>
                            <MonthPicker
                              disabled={isConfirmed}
                              fullWidth
                              hideLabel
                              header={t('common.monthPickerHeader', {
                                year,
                              })}
                              {...bind('laborOfficeMonths')}
                            />
                          </Box>

                          <Divider my={2} />

                          <Typography
                            variant="h4"
                            className={classes.label}
                            style={{ lineHeight: 'normal', marginTop: 32, marginBottom: 12 }}
                          >
                            {bind('laborOfficeFiles').label}
                          </Typography>

                          <Uploader {...bind('laborOfficeFiles')} disabled={isConfirmed} />
                          <br />
                        </Box>
                    </Question>

                    {user.data.customer.requireNoTaxableIncomeAffidavit ? (
                      <Question
                        helpStyle="collapse"
                        {...bind('noTaxableIncome')}
                        hide={questionProps('noTaxableIncome', data).hide}
                        childrenIn={form.getValue('noTaxableIncome') === true}
                        disabled={isConfirmed}
                      >
                        <Divider my={2} maxWidth="25%" />

                        <Box maxWidth="100%" width={650} margin="auto">
                          <Typography
                            variant="h4"
                            className={classes.label}
                            style={{ lineHeight: 'normal', marginTop: 32, marginBottom: 12 }}
                          >
                            {bind('noTaxableIncomeFiles').label}
                          </Typography>

                          <Uploader {...bind('noTaxableIncomeFiles')} disabled={isConfirmed} />

                          <InfoBox title={t("common.documentTemplate")} my={2}>
                            <Trans
                              i18nKey="annualRebateQuestionnaire.noTaxableIncomeFilesTemplate"
                              components={[<ExternalLink key="0" href={process.env.PUBLIC_URL + '/documents/vzor_cestne_prohlaseni_na_dane.docx'} />]}
                            />
                          </InfoBox>
                        </Box>
                      </Question>
                    ) : null}
                  </>
                )
              }}
            </Observer>

              {taxQuestionnaire && taxQuestionnaire.history && (
                <ArchiveChanges boxProps={{ mb: 2 }}>
                  {taxQuestionnaire.history.map((questionnaireSnap) => (
                    <PrevRebate
                      key={questionnaireSnap.id}
                      status={questionnaireSnap.status}
                      settlementRequest={questionnaireSnap.settlementRequest}
                      commentOfDenied={questionnaireSnap.commentOfDenied}
                    >
                      <QuestionnaireReadonlyView
                        data={questionnaireSnap}
                        year={year}
                        dateOfEmployment={user.data.dateOfEmployment}
                        customerName={user.data.customer.name}
                        employeeGender={user.data.gender}
                      />
                    </PrevRebate>
                  ))}
                </ArchiveChanges>
              )}
            </Box>
          </Fade>
        )}

        <Divider mt={1.5} mb={4} />

        {!loading && (
          <Fade>
            <ButtonStepContainer
              {...(isFirstRound && {
                backButton: {
                  to: `/${user.data.id}/annual-rebates/intro`,
                },
              })}
            >
              <Observer>
                {() => {
                  const data = form.getData()
                  return (
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={form.submit}
                      disabled={disableProceed(data)}
                    >
                      {isPozp.get()
                        ? t('common.summary')
                        : t('common.continue')}
                    </Button>
                  )
                }}
              </Observer>
            </ButtonStepContainer>
          </Fade>
        )}
      </Box>
    </InsideLayout>
  )
}

export default Questionnaire
