import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes} from 'kubik-server'
import {BASIC_REBATE_FULL_FRAGMENT} from '../../../../../../fragments/BasicRebate'

export type TBasicRebate = NexusGenFieldTypes['BasicRebate']

export interface IOwnAnnualBasicRebateData {
  user: {
    annualRebate: NexusGenFieldTypes['AnnualRebate'] & {
      basicRebate: TBasicRebate
      basicRebates: TBasicRebate[]
    }
  }
}
export const OWN_ANNUAL_BASIC_REBATE = gql`
  query ownAnnualBasicRebate {
    user {
      id
      annualRebate {
        id
        year
        basicRebate {
          ...BasicRebateFull
        }
        basicRebates(orderBy: createdAt_DESC) {
          ...BasicRebateFull
        }
      }
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`

export type TUpdateOwnAnnualBasicRebateVariables = NexusGenArgTypes['Mutation']['updateOwnAnnualBasicRebate']
export interface IUpdateOwnAnnualBasicRebateData {
  updateOwnAnnualBasicRebate: NexusGenFieldTypes['BasicRebate']
}
export const UPDATE_OWN_ANNUAL_BASIC_REBATE = gql`
  mutation updateOwnAnnualBasicRebate($data: OwnBasicAnnualRebateUpdateInput!) {
    updateOwnAnnualBasicRebate(data: $data) {
      ...BasicRebateFull
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`


export interface IResetOwnAnnualBasicData {
  resetOwnAnnualBasicRebate: TBasicRebate
}
export const RESET_OWN_ANNUAL_BASIC_REBATE = gql`
  mutation resetOwnAnnualBasicRebate {
    resetOwnAnnualBasicRebate {
      ...BasicRebateFull
    }
  }
  ${BASIC_REBATE_FULL_FRAGMENT}
`

