import {gql} from 'apollo-boost'
import { NexusGenArgTypes, NexusGenFieldTypes } from 'kubik-server'

export interface IRegisterToTaxReturnPromo {
  hasAnsweredTaxReturnPromotion: boolean | null;
}

export const HAS_ANSWERED_TAX_RETURN_PROMO = gql`
  query hasAnsweredTaxReturnPromo {
    hasAnsweredTaxReturnPromotion
  }
`

export type TRegisterToTaxReturnPromo = NexusGenFieldTypes['TaxReturnPromo']
export type TRegisterToTaxReturnPromoVariables = NexusGenArgTypes['Mutation']['registerToPromotion']

export const REGISTER_TO_TAX_RETURN_PROMO = gql`
  mutation registerToPromotion($interested: Boolean!) {
    registerToPromotion(interested: $interested) {
      id
    }
  }
`
