/* eslint-disable react/jsx-key */
import * as React from "react"
import { get } from "lodash"
import { useMutation, useQuery } from "react-apollo"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import {makeStyles, useTheme} from "@material-ui/core/styles"

import {
    HAS_ANSWERED_TAX_RETURN_PROMO,
    REGISTER_TO_TAX_RETURN_PROMO,
    TRegisterToTaxReturnPromoVariables,
    IRegisterToTaxReturnPromo,
    TRegisterToTaxReturnPromo
} from "../gql/taxReturnPromo"

import DialogTitle from '../../../../../components/DialogTitle'
import {Green} from "../../../../../components/CommonBox"
import useSnackbar from "../../../../../hooks/useSnackbar"
import useUser from "../../../../../hooks/useUser";
import { useGlobalStateAtom } from "../../../../../hooks/useGlobalState";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        maxWidth: "1080px",
    },
    dialogTitle: {
        padding: theme.spacing(8),
        paddingBottom: 0
    },
    dialogActions: {
        flexWrap: "wrap-reverse",
        gap: 8
    },
    dialogContent: {
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        padding: `0 ${theme.spacing(2)}px`,
        [theme.breakpoints.up("sm")]: {
            padding: `0 ${theme.spacing(8)}px`,
        }
    },
    illustration: {
        width: "100%",
        height: "auto",
        maxWidth: 480,
        margin: `${theme.spacing(8)}px 0`
    }
}))

export const TaxReturnPromoDialog: React.FC = () => {
    const timeout = React.useRef<NodeJS.Timeout | null>(null)

    const [loading, setLoading] = React.useState(false)
    const [actionsDisabled, setActionsDisabled] = React.useState(true)

    const [isAnnualFinishWizarDialogVisible] = useGlobalStateAtom("annualFinishWizardDialog", null)

    const { data, refetch } = useQuery<
        IRegisterToTaxReturnPromo
    >(HAS_ANSWERED_TAX_RETURN_PROMO)

    const [registerToPromo] = useMutation<
        TRegisterToTaxReturnPromo,
        TRegisterToTaxReturnPromoVariables
    >(REGISTER_TO_TAX_RETURN_PROMO)

    const theme = useTheme()
    const classes = useStyles()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { addMessage } = useSnackbar()
    const { user } = useUser()

    const hasPOZPSettlementRequest = React.useMemo(() => {
        return get(user, 'data.annualRebate.settlementRequest.type') === 'ANNUAL_POZP'
    }, [user])

    const handleSubmit = async (interested: boolean) => {
        setLoading(true)

        try {
            await registerToPromo({
                variables: {
                    interested
                }
            })

            if (interested) {
                addMessage("Děkujeme za zájem, budeme Vás kontaktovat.")
            }

            await refetch()
        } catch (error) {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        timeout.current = setTimeout(() => {
            setActionsDisabled(false)
        }, 1000)

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current)
                timeout.current = null
            }
        }
    }, [])

    return (
        <Dialog
            scroll="body"
            classes={{
                paper: classes.dialogPaper
            }}
            fullScreen={fullScreen}
            open={!Boolean(data && data.hasAnsweredTaxReturnPromotion) && hasPOZPSettlementRequest && !isAnnualFinishWizarDialogVisible}
            aria-labelledby="annual-wizard-dialog-title"
            aria-describedby="annual-wizard-dialog-description"
        >
            <DialogTitle id="annual-wizard-dialog-title" titleVariant="h1" className={classes.dialogTitle} onClose={() => handleSubmit(false)}>
                Daňové přiznání jednoduše
            </DialogTitle>

            <DialogContent style={{ marginTop: 16 }}>
                <Box flexDirection="column" display="flex" alignItems="center" justifyContent="center" style={{ gap: 16 }}>
                    <Typography align="center" style={{ maxWidth: "60ch" }}>Připravujeme novou službu online zpracování <Green>daňového přiznání intuitivně a jednoduše</Green>, která bude spuštěna následující měsíc.</Typography>
                    <Typography align="center">Máte-li zájem budeme Vás o spuštění informovat.</Typography>
                </Box>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button variant="text" color="secondary" size="large" onClick={() => handleSubmit(false)} disabled={actionsDisabled || loading}>
                    Ne, děkuji
                </Button>
                <Button variant="contained" color="primary" size="large" onClick={() => handleSubmit(true)} disabled={actionsDisabled || loading}>
                    Mám zájem vědět více
                </Button>
            </DialogActions>
        </Dialog>
    )
}
