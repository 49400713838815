import {
  TUploadPayslipData,
  TUploadPayslipVariables,
  TUploadSingleDocumentData,
  TUploadSingleDocumentVariables,
  UPLOAD_PAYSLIP,
  UPLOAD_SINGLE_DOCUMENT,
} from './gql'
import  {
  IProps as IDocumentDialogProps,
} from './components/DocumentDialog'
import {  useMutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import GraphQLErrorSnackbar from '../../../../components/GraphQLErrorSnackbar'
import React from 'react'
import useForm from '../../../../hooks/useForm'
import DialogTitle from "../../../../components/DialogTitle";
import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import {Observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import {Divider, Error} from "../../../../components/CommonBox";
import Uploader from "../../../../components/form/Uploader";
import DialogActions from "../../../../components/DialogActions";
import SubmitButton from "../../../../components/form/SubmitButton";
import Dialog from "../../../../components/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Check, Close} from "@material-ui/icons";
import {NexusGenEnums, NexusGenFieldTypes} from "kubik-server";
import {createDocumentTypeFormSetting} from "./CreateDocument";
import Select from "../../../../components/form/Select";

interface IProps extends IDocumentDialogProps {
}

type TProcessingFile = {
  file: any,
  loading?: boolean,
  result?: NexusGenFieldTypes['PayslipResult']
}

const MultiUploadDocument: React.FC<IProps> = props => {
  const { t } = useTranslation()
  const {onClose, open} = props

  const [filesToProcess, setFilesToProcess] = React.useState<TProcessingFile[]>([])


  const [uploadSingleDocument, { error: errorCreate }] = useMutation<
    TUploadSingleDocumentData,
    TUploadSingleDocumentVariables
  >(UPLOAD_SINGLE_DOCUMENT, {
    onCompleted() {
    },
  })
  const {form, bind} = useForm(
    {},
    {
      files: {
        label: t('documents.document'),
        rule: 'required',
        type: 'multi_relation',
      },
      type: createDocumentTypeFormSetting(t),
    },
    {
      async onSubmit(data: any) {
        if(!data) {
          return
        }
        let toProcess: TProcessingFile[] = []
        for(let file of (data && data.files && data.files.create || [])) {
          let processing: TProcessingFile = {
            file: file,
            loading: true
          }
          toProcess.push(processing)
        }
        setFilesToProcess(toProcess)
        for (let proc of toProcess) {
          let result = await uploadSingleDocument({
            variables: { file: proc.file, type: data.type || null },
          })
          proc.loading = false
          proc.result = result.data && result.data.uploadSingleDocument
          setFilesToProcess([...toProcess])
        }

        form.reset()
        form.setUntouchedAll()
      },
    },
  )

  const handleClose = () => {
    onClose()
    form.reset()
    form.setUntouchedAll()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        scroll="body"
      >
        <DialogTitle onClose={handleClose}>
          <Box
            display="flex"
            justifyContent="center"
          >
            {t('documents.multiUpload')}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Observer>
            {() => (
              <Grid container spacing={1}>

                <Grid xs={12} item>
                  <List>
                    <ListItem style={{flexDirection: 'column'}}>Nahrajte soubory a potvrďte. Lze vybrat <b>více souborů najednou</b></ListItem>
                    <ListItem style={{flexDirection: 'column'}}>
                      Název souboru musí být ve formátu <div><strong>456_název souboru.pdf</strong></div> kde 456 je osobní číslo zaměstnance. Název a přípona mohou být libovolné.
                    </ListItem>
                  </List>
                </Grid>
                <Divider mt={1} mb={3} />
                {filesToProcess.map((process: TProcessingFile, idx) => {
                  const errors = !!process.result && !!process.result.errors && process.result.errors || []
                  return (<div key={'processingFile' + idx} style={{width: '100%', ...(!!errors.length? { color: '#f44336' } : {} )}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '0.2rem 0 0 0', width: '100%'}}>
                      {process.file.name}
                      {process.loading && <CircularProgress size={16} />}
                      {process.result && process.result.document && process.result.document.id && <Check fontSize={'small'} />}
                      {!!errors.length && <Close fontSize={'small'} />}
                    </div>
                    <div style={{padding: '0 0 0.2rem 0'}}>
                      {errors.map((err, errIdx) => (
                        <Error key={'processingFile' + idx + 'err' + errIdx}>{err}</Error>
                      ))}
                    </div>

                  </div>)
                })}
                <Grid xs={12} item>
                  <Select fullWidth gutterBottom {...bind('type')} emptyValue={null} emptyValueLabel={t('documents.type')} />
                </Grid>
                <Grid xs={12} item>
                  <Uploader
                    {...bind('files')}
                    itemGridProps={{ xs: 12 }}
                    multiple
                    contentText={'Nahrát soubory'}
                    contentTextSub={'Vyberte jeden nebo více najednou'}
                    filelist={false}
                  />
                </Grid>
              </Grid>
            )}
          </Observer>
        </DialogContent>
        <DialogActions dividerProps={{ mt: 1 }}>
          <SubmitButton label={t('common.upload')} form={form} disabled={!!filesToProcess.length && !!filesToProcess.find(p => p.loading)} />
        </DialogActions>
      </Dialog>
      <GraphQLErrorSnackbar error={errorCreate} />
    </>
  )
}

export default MultiUploadDocument
