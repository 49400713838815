import {Search} from "history";


const useRedirectAfterLogin = () => {
  const { sessionStorage } = window

  const removeQueryParam = (params: URLSearchParams) => {
    params.delete('redirectAfterLogin');
    let url = new URL(window.location.href)
    url.searchParams.delete('redirectAfterLogin')
    window.history.pushState({}, '', url.href)
  }

  const onLoginPage = (searchParams: Search) => {
    const params = new URLSearchParams(searchParams)
    const redirectAfterLogin = params.get('redirectAfterLogin')
    if (redirectAfterLogin) {
      sessionStorage.setItem('redirectAfterLogin', redirectAfterLogin);
      removeQueryParam(params)
    }
  }

  const onLoggedIn = () => {
    const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin')
    sessionStorage.setItem('redirectAfterLogin', '')
    if (redirectAfterLogin) {
      window.location.assign(redirectAfterLogin)
      return true
    }
    return false
  }

  return { onLoginPage, onLoggedIn }
}

export default useRedirectAfterLogin
