// import { Link } from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {Trans, useTranslation} from 'react-i18next'
import InsideLayout from '../../../../components/layouts/InsideLayout'
import React from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import useUser from '../../../../hooks/useUser'
import {Link} from "react-router-dom";
import BackButton from "../../../../components/BackButton";

const useStyles = makeStyles((theme) => ({
}))


const SupportTile: React.FC<{
  title: string,
  link?: string,
}> = (props) => {
  const tile = <div style={{
    display: 'block',
    maxWidth: '320px',
    margin: '1rem',
    border: '#4ebe4f solid 1px',
    borderRadius: '0.5rem',
    padding: '0.5rem 1rem',
    cursor: props.link ? 'pointer' : 'default'
  }}>
    <h4>{props.title}</h4>

    <div style={{color: '#666', textDecoration: 'none'}}>{props.children}</div>
  </div>
  if(props.link) {
    return <Link to={props.link} style={{textDecoration: 'none', fontWeight: 'inherit'}}>{tile}</Link>
  }
  return tile
}

const Support: React.FC = () => {
  const classes = useStyles()
  const { user } = useUser()
  const { t } = useTranslation()

  return (
    <InsideLayout sidebar>
      <div style={{padding: '1rem 2rem'}}>
        <p><Trans i18nKey={'support.doesNotSupportEnglish'}/></p>
        <h2><Trans i18nKey={'common.support'}/></h2>
        <h3 style={{color: '#888', fontStyle: 'italic'}}>Neomezená moc!</h3>
        <div style={{display: "flex", flexWrap: 'wrap', margin: '0 -1rem'}}>
          <SupportTile title={'Detail formuláře'} link={`/${user.data.id}/support/formdetail`}>
            <p>Proč se negeneruje PDF?</p>
          </SupportTile>
          <SupportTile title={'Úpravy v průvodci'} link={`/${user.data.id}/support/wizard`}>
            <p>Uplatnění nedostupných měsíců</p>
            <p>Odeslání úpravy jménem uživatele</p>
          </SupportTile>
          <SupportTile title={'Končící slevy'} link={`/${user.data.id}/support/ending-rebates`}>
            <p>Vyhodnocení končících slev pro zadaný měsíc</p>
            <p><small>Totožné s výběrem emailů k odeslání</small></p>
          </SupportTile>
          <SupportTile title={'Hromadné nahrávání dokumentů k zaměstnancům podle osobního čísla'} link={`/${user.data.id}/documents`}>
            <p>Stiskněte Hromadně nahrát dokumenty a postupujte podle instrukcí</p>
          </SupportTile>

        </div>
      </div>
    </InsideLayout>
  )
}

export const SupportLayout: React.FC<{ title: string }> = (props) => {
  const {user} = useUser()
  return <InsideLayout sidebar>
    <ScrollToTop/>
    <div style={{padding: '1rem 2rem'}}>

      <div style={{display: "flex", flexWrap: 'wrap', alignItems: 'center'}}>
        <div style={{color: '#898989', padding: '0.5rem'}}>
          <BackButton to={`/${user.data.id}/support`}/>
        </div>
        <h3>{props.title}</h3>
      </div>
      {props.children}
    </div>
  </InsideLayout>
}

export default Support
