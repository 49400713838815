import { gql } from 'apollo-boost'

export const TAX_QUESTIONNAIRE_SNAP_WITH_HISTORY = gql`
  fragment TaxQuestionnaireSnapWithHistory on TaxQuestionnaireSnap {
    id
    pozp
    status
    commentOfDenied
    createByActualEmployer
    samePreviousEmployer
    otherIncomes
    selfEmployed
    concurrentEmployers
    solidarityTax
    foreignIncome
    laborOffice
    laborOfficeMonths
    laborOfficeFiles {
      id
      filename
      size
      createdAt
    }
    noTaxableIncome
    noTaxableIncomeFiles {
      id
      filename
      size
      createdAt
    }
    snapHistory(orderBy: createdAt_DESC) {
      id
      status
      commentOfDenied
      createByActualEmployer
      samePreviousEmployer
      otherIncomes
      selfEmployed
      concurrentEmployers
      solidarityTax
      foreignIncome
      laborOffice
      laborOfficeMonths
      laborOfficeFiles {
        id
        filename
        size
        createdAt
      }
      settlementRequest {
        id
        status
        requestedAt
        submittedAt
      }
    }
  }
`

export const MONTHLY_TAX_QUESTIONNAIRE_SNAP_WITH_HISTORY = gql`
  fragment MonthlyTaxQuestionnaireSnapWithHistory on MonthlyTaxQuestionnaireSnap {
    id
    status
    dontCreate
    commentOfDenied
    createByActualEmployer
    madeByAnotherEmployer
    snapHistory(orderBy: createdAt_DESC) {
      id
      status
      commentOfDenied
      createByActualEmployer
      madeByAnotherEmployer
      settlementRequest {
        id
        status
        requestedAt
        submittedAt
      }
    }
  }
`
