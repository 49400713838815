import {EMAILS, TEmail, TEmailsData, TEmailsVariables, TEmailType,} from './gql'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Datatable from '../../../../components/Datatable'
import moment from 'moment'
import React, {useState} from 'react'
import ScrollToTop from '../../../../components/ScrollToTop'
import Typography from '@material-ui/core/Typography'
import useDatatableVariables from '../../../../hooks/useDatatableVariables'
import useUser from '../../../../hooks/useUser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Check, Delete} from "@material-ui/icons";
import BackButton from "../../../../components/BackButton";
import EmailDetailsDialog from "./EmailDetailsDialog";
import {RouteComponentProps} from "react-router";
import DatePicker from "../../../../components/form/DatePicker";

interface IStylesProps {
  compactScreen?: boolean
  isNotSmallScreen?: boolean
}

const useStyles = makeStyles<Theme, IStylesProps>((theme: Theme) => {
  return {

  }
})

const defaultVariables: TEmailsVariables = {
  skip: 0,
  first: 20,
}

interface IProps extends RouteComponentProps{
  globalKey: string
}

const EmailsTable: React.FC<IProps> = (props) => {
  const { user } = useUser()
  const { globalKey: GLOBAL_UNIQUE_KEY } = props

  const theme = useTheme()
  const mdDown = theme.breakpoints.down('md')
  const compactScreen = useMediaQuery(mdDown)
  const minSmall = theme.breakpoints.up('sm')
  const isNotSmallScreen = useMediaQuery(minSmall)
  const classes = useStyles({ compactScreen, isNotSmallScreen })

  const [emailDetailed, setEmailDetailed] = useState<TEmail | null>(null)
  const [from, setFrom] = useState<string>(moment().subtract(3, 'months').format('YYYY-MM-DD'))
  const [to, setTo] = useState<string>(moment().format('YYYY-MM-DD'))

  const [datatableVariables, setDatatableVariables] = useDatatableVariables(
    GLOBAL_UNIQUE_KEY,
    defaultVariables,
  )

  const { t } = useTranslation()
  const handleTabChange = (type: TEmailType) => {
    setDatatableVariables(
      () => ({
        ...defaultVariables,
        type,
      }),
      { resetSearch: true },
    )
  }

  return (
    <Box
      maxWidth="100%"
      width={1077}
      ml="auto"
      mr="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <ScrollToTop />
      <Typography variant="h1">{t('common.emails')} {!!user.data.customer && user.data.customer.name}</Typography>
      <br />
        <EmailDetailsDialog email={emailDetailed} open={!!emailDetailed} onClose={() => {
            setEmailDetailed(null)
        }} />
      <Datatable<TEmailsData['emails']['items'], TEmailsVariables>
        globalKey={GLOBAL_UNIQUE_KEY}
        disableEven
        searchPlaceholder={'Hledat dle e-mailu...'}
        key={from + to}

        renderToolbar={(elements) => {
            return <Box display={'flex'} style={{justifyContent: 'space-between', padding: '1rem 0', alignItems: 'center'}}>
              <Box display={'flex'} alignItems={'flex-end'}>
                <DatePicker fullWidth gutterBottom autoOk label={'Vytvořeno od'} value={from} onBlur={() => {
              }} onChange={(val) => {
                if (!val) {
                  return
                }
                setFrom(moment(val).format('YYYY-MM-DD'))
                if(Math.abs(moment(val).diff(moment(to), 'months')) > 3) {
                  setTo(moment(val).add(3, 'months').format('YYYY-MM-DD'))
                }
              }}/>
                &nbsp;
                <DatePicker fullWidth gutterBottom autoOk label={'do'} value={to} onBlur={() => {
                }} onChange={(val) => {
                  if (!val) {
                    return
                  }
                  setTo(moment(val).format('YYYY-MM-DD'))
                  if(Math.abs(moment(val).diff(moment(from), 'months')) > 3) {
                    setFrom(moment(val).subtract(3, 'months').format('YYYY-MM-DD'))
                  }
                }}/>
              </Box>
                <Box maxWidth={'320px'} marginLeft={'auto'}>{elements.searchElement}</Box>
            </Box>
        }}
        where={{
          createdAt_lte: moment(to).add(1, 'days').toISOString(),
          createdAt_gte: moment(from).toISOString()
        }}
        query={{
          query: EMAILS,
          dataField: 'emails',
          options: {
            fetchPolicy: 'cache-and-network',
          },
        }}
        columns={[
          {
            key: 'sent',
            shrink: true,
            align: 'center',
            render(row) {
              return <>
                {row.sent && <Check color={'primary'}/>}
                {!row.sent && <Delete color={'error'} />}
              </>
            },
          },
            {
                key: 'subject',
                label: t('common.subject'),
                render(row: TEmail) {
                    return (
                        <Box>
                            {row.subject}
                        </Box>
                    )
                },
            },

          {
            key: 'to',
            label: t('common.email'),
            render(row: TEmail) {
              return (
                <Box fontWeight={'bolder'}>
                    <span>{row.to}</span>
                </Box>
              )
            },
          },

            {
                key: 'sentAt',
                label: t('common.sentAt'),
                render(row) {
                    return <span>{row.sentAt
                        ? moment(row.sentAt).format('DD.MM.YYYY HH:mm')
                        : 'DD.MM.YYYY HH:mm'}</span>
                },
            },
            {
                key: 'user',
                label: t('common.userName'),
                render(row) {
                    return (
                        <Box
                            component="span"
                            whiteSpace="pre-line"
                            minHeight={40}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            {row.user && <><Box>{row.user.firstname} {row.user.lastname}</Box>
                                {row.user.personalNumber && (
                                    <Box color="#8c8c8c">@{row.user.personalNumber}</Box>
                                )
                                }
                            </>}
                        </Box>
                    )
                },
            },
            {
                key: 'customer',
                label: t('common.customer'),
                render(row) {
                    return (
                        <Box
                            component="span"
                            whiteSpace="pre-line"
                            minHeight={40}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            {row.customer && <>
                                <Box>{row.customer.name}</Box>
                            </>}
                        </Box>
                    )
                },
            },
            // {
            //     key: 'createdAt',
            //     label: t('common.createdAt'),
            //     shrink: true,
            //     render(row) {
            //         return <span>{row.createdAt
            //             ? moment(row.createdAt).format('DD.MM.YYYY HH:mm')
            //             : 'DD.MM.YYYY HH:mm'}</span>
            //     },
            // },

            {
                key: 'actions',
                align: 'right',
                shrink: true,
                render(row) {
                    return (
                        <Box
                            display="flex"
                            alignItems="normal"
                            justifyContent="flex-end"
                        >
                            <Button
                                size={"small"}
                                color={"primary"}
                                onClick={() => {
                                    setEmailDetailed(row)
                                }}
                            >
                                {t('common.detail')}
                            </Button>
                        </Box>
                    )
                },
            },
        ]}
        orderByList={[
        ]}
        searchFields={['to_contains']}
      />
        <Box padding='1rem' color='#898989'>
            <BackButton color="inherit" onClick={() =>
            user.isSuperadmin()
                ? props.history.push(`/${user.data.id}/superadmin/settings`)
                : props.history.push(
                    `/${user.data.id}/company-settings`,
                )
        }/>
        </Box>
    </Box>
  )
}

export default EmailsTable
