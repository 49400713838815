import { styled } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Pozor from '../../icons/Pozor'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const Title = styled(Typography)(() => ({
  color: '#E60B0B',
  marginLeft: 16,
  marginBottom: 10,
  fontSize: '1.15rem',
}))
const SubTitle = styled(Typography)(() => ({
  marginBottom: 4,
  fontSize: '1.15rem',
}))

const StyledPozor = styled(Pozor)(() => ({
  fill: '#E60B0B',
}))

export const DeniedRebateNotice: React.FC<{
  variant?: 'rebate' | 'employer'
  fullwidth?: boolean
  gutterBottom?: boolean
  showTitle?: boolean
}> = (props) => {
  const { t } = useTranslation()
  const { variant, fullwidth, gutterBottom, showTitle = true } = props
  return (
    <Box
      position="relative"
      width={fullwidth ? '100%' : 260}
      mb={gutterBottom ? 2 : undefined}
    >
      {showTitle ? (
        <Title variant="h2">
          {variant === 'rebate'
            ? t('taxRebates.theRebateIsDenied')
            : t('taxRebates.theInformationIsDenied')}
        </Title>
      ) : null}
      <Box
        border={'2px solid #E60B0B'}
        bgcolor="#FFD7D7"
        p={2}
        borderRadius={6}
        fontFamily="fontFamily"
        fontSize="0.9rem"
        lineHeight="1.4rem"
      >
        <Box fontSize={'1.25rem'} color={'#E60B0B'} mb={0.5}>
          <StyledPozor fontSize="inherit" />
        </Box>
        <SubTitle variant="h2">{t('taxRebates.accountantsComment')}</SubTitle>
        <span style={{whiteSpace: 'pre-line'}}>{props.children}</span>
      </Box>
    </Box>
  )
}

export default DeniedRebateNotice
