import {NexusGenFieldTypes, NexusGenRootTypes} from 'kubik-server'
import {makeStyles} from '@material-ui/core/styles'
import {Bold} from '../../../../../components/CommonBox'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FileLink from '../../../../../components/FileLink'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Paper } from '@material-ui/core'

type TFile = Pick<
  NexusGenRootTypes['File'],
  'id' | 'filename' | 'size' | 'createdAt'
>

export type TPrevEmployerSnap = Pick<
  NexusGenFieldTypes['PrevEmployerSnap'],
  | 'id'
  | 'status'
  | 'commentOfDenied'
  | 'companyName'
  | 'deleteFlag'
  | 'dateOfEmployment'
  | 'dateOfQuit'
  | 'totalRevenue'
  | 'incomeInsuranceSum'
  | 'actualDeductedTax'
  | 'totalTaxAdvance'
  | 'totalMonthlyTaxBonuses'
  | 'lifeInsuranceContribution'
> & {
  incomeConfirmationFiles: TFile[] | null
}

export type TPrevEmployer = Pick<
  NexusGenFieldTypes['PrevEmployer'],
  | 'id'
  | 'status'
  | 'commentOfDenied'
  | 'deleteFlag'
  | 'companyName'
  | 'dateOfEmployment'
  | 'dateOfQuit'
  | 'totalRevenue'
  | 'incomeInsuranceSum'
  | 'actualDeductedTax'
  | 'totalTaxAdvance'
  | 'totalMonthlyTaxBonuses'
  | 'lifeInsuranceContribution'
> & {
  incomeConfirmationFiles: TFile[] | null
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4)
  },
  info: {
    display: 'flex',
    color: '#9E9E9E',
    '& p:not(:last-child)': {
      paddingRight: theme.spacing(0.6),
    },
    '& p:nth-child(even)': {
      color: '#000000',
      fontWeight: 'bold',
    },
  },
}))

interface IProps {
  data: TPrevEmployerSnap | TPrevEmployer
}

const PrevEmployerSnapView: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data } = props

  const [open, setOpen] = React.useState<boolean>(false)

  const sum = (text: number | null | undefined) => `${text || '0'} Kč`

  if (data.deleteFlag) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Bold>{data.companyName}</Bold>
        </Grid>
        <Grid item xs={5}>
          <Bold>{t('annualRebatePreviousEmployers.cancelPrevEmployer2')}</Bold>
        </Grid>
        <Grid item xs={7}>
          <Typography>{t('common.yes')}</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Box my={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
      <Button
        onClick={() => setOpen((prev) => !prev)}
        size="medium"
        color="primary"
      >
        {open ? t('common.hide') : t('common.showMore')}
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>

        <Box className={classes.info}>
          <Typography>{t('common.from')}</Typography>
          <Typography>
            {moment(data.dateOfEmployment).format('DD.MM.YYYY')}
          </Typography>
          <Typography>{t('common.to')}</Typography>
          <Typography>
            {moment(data.dateOfQuit).format('DD.MM.YYYY')}
          </Typography>
        </Box>
      </Box>

      <Collapse in={open}>
        <Paper variant="outlined" className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Bold>{t('annualRebatePreviousEmployers.companyName')}</Bold>
            </Grid>
            <Grid item xs={2}>
              <Typography>{data.companyName}</Typography>
            </Grid>

            <Grid item xs={10}>
              <Bold>{t('annualRebatePreviousEmployers.totalRevenue')}</Bold>
            </Grid>
            <Grid item xs={2}>
              <Typography>{sum(data.totalRevenue)}</Typography>
            </Grid>

            {/* <Grid item xs={10}>
              <Bold>{t('annualRebatePreviousEmployers.incomeInsuranceSum')}</Bold>
            </Grid>
            <Grid item xs={2}>
              <Typography>{sum(data.incomeInsuranceSum)}</Typography>
            </Grid> */}

            <Grid item xs={10}>
              <Bold>{t('annualRebatePreviousEmployers.totalTaxAdvance')}</Bold>
            </Grid>
            <Grid item xs={2}>
              <Typography>{sum(data.totalTaxAdvance)}</Typography>
            </Grid>

            <Grid item xs={10}>
              <Bold>
                {t('annualRebatePreviousEmployers.totalMonthlyTaxBonuses')}
              </Bold>
            </Grid>
            <Grid item xs={2}>
              <Typography>{sum(data.totalMonthlyTaxBonuses)}</Typography>
            </Grid>

            <Grid item xs={10}>
              <Bold>
                {t('annualRebatePreviousEmployers.lifeInsuranceContribution')}
              </Bold>
            </Grid>
            <Grid item xs={2}>
              <Typography>{sum(data.lifeInsuranceContribution)}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Bold mb={1} mt={1} display="block">
                {t('annualRebatePreviousEmployers.incomeConfirmationFiles')}
              </Bold>
              <Box pl={4}>
                {data.incomeConfirmationFiles &&
                  data.incomeConfirmationFiles.map((file) => (
                    <FileLink key={file.id} file={file} />
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </Box>
  )
}

export default React.memo<IProps>(PrevEmployerSnapView)
