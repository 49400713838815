import { Bold, Divider, Green } from '../../../../../../components/CommonBox'
import { Observer, inject } from 'mobx-react'
import { Store } from '../Store'
import { useTranslation, Trans } from 'react-i18next'
import Button from '@material-ui/core/Button'
import React from 'react'
import useUser from '../../../../../../hooks/useUser'
import ButtonStepContainer from '../../../../../../components/ButtonStepContainer'

interface IProps {
  store?: Store
  onSubmit: () => void
  onValidationFail?: () => void
  className?: string
}

const Submit: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { user } = useUser()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const store = props.store!

  const handleSubmit = () => {
    if (store.validate()) {
      props.onSubmit()
    } else {
      if (props.onValidationFail) {
        props.onValidationFail()
      }
    }
  }

  return (
    <Observer>
      {() =>
        store.status === 'APPLY' ? (
          <>
            <Bold width={800} display="block" maxWidth="100%">
              <Trans i18nKey="settlementRequest.submitDescription">
                Máte-li všechny daňové slevy zkontrolované, tak vyberte „
                <Green>Dokončit žádost</Green>“. Tímto se uživateli pošle
                oznámení o zkontrolování žádosti a případně má možnost provést
                úpravy.
              </Trans>
            </Bold>

            <Divider mt={6} mb={4} />

            <ButtonStepContainer
              backButton={{ to: `/${user.data.id}/settlement-requests` }}
            >
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleSubmit}
                className={props.className}
              >
                {t('common.finish')}
              </Button>
            </ButtonStepContainer>
          </>
        ) : (
          <>
          </>
        )
      }
    </Observer>
  )
}

export default inject('store')(Submit)
