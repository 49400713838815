import React from 'react'
import { useTranslation } from 'react-i18next'
import { NexusGenRootTypes } from 'kubik-server'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FileLink from '../../components/FileLink'

interface IProps {
  noTaxableIncomeFiles: NexusGenRootTypes['File'][]
}

const NoTaxableIncomeSection: React.FC<IProps> = ({ noTaxableIncomeFiles }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={4}
        mb={2}
      >
        <Typography variant="h2">{t('common.noTaxableIncome')}</Typography>
      </Box>
      {noTaxableIncomeFiles.map(file => <FileLink key={file.id} file={file} />)}
    </>
  )
}

export default NoTaxableIncomeSection