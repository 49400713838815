import {
  IOwnAnnualPensionInsuranceRebateData,
  IResetOwnAnnualPensionInsuranceData,
  IUpdateOwnAnnualPensionInsuranceRebateData,
  OWN_ANNUAL_PENSION_INSURANCE_REBATE,
  RESET_OWN_ANNUAL_PENSION_INSURANCE_REBATE,
  TPensionInsuranceRebate,
  TUpdateOwnAnnualPensionInsuranceRebateVariables,
  UPDATE_OWN_ANNUAL_PENSION_INSURANCE_REBATE,
} from './gql'
import {
  Green,
  Divider,
  Center, Error,
} from '../../../../../../components/CommonBox'
import ChangeRebateButton, {
  ChangeRebateChoice,
} from '../../components/ChangeRebateButton'
import { getRebatesWithExtra } from '../../../../../../fragments/utils'
import { Observer } from 'mobx-react'
import {PenzijniPojisteni} from '../../../../../../components/icons/KubikIcons'
import { pickId } from '../../../../../../utils'
import { Bold } from '../../../../../../components/CommonBox'
import { useQuery, useMutation } from 'react-apollo'
import { useTranslation, Trans } from 'react-i18next'
import AppState from '../../../../../../components/AppState'
import ArchiveChanges from '../../../../../../components/ArchiveChanges'
import Box from '@material-ui/core/Box'
import CancelRebateDialog from '../../../../../../components/CancelRebateDialog'
import CancelRebateSection from '../../components/CancelRebateSection'
import Fade from '../../../../../../components/Fade'
import get from 'lodash/get'
import GraphQLErrorSnackbar from '../../../../../../components/GraphQLErrorSnackbar'
import InsideLayout from '../../../../../../components/layouts/InsideLayout'
import NumberField from '../../../../../../components/form/NumberField'
import PensionInsuranceRebateOverview from '../../components/PensionInsuranceRebateOverview'
import PrevRebate from '../../components/PrevRebate'
import React, {useState, useMemo, useRef, Fragment} from 'react'
import RebateActions from '../../components/RebateActions'
import RebateLayout from '../../../../../../components/layouts/RebateLayout'
import ScrollToTop from '../../../../../../components/ScrollToTop'
import Uploader from '../../../../../../components/form/Uploader'
import useForm, { FormProvider } from '../../../../../../hooks/useForm'
import useRouter from '../../../../../../hooks/useRouter'
import useUser from '../../../../../../hooks/useUser'
import {PensionInsuranceRebateConditions} from "./PensionInsuranceRebateConditions"
import HelpAdornment from "../../../../../../components/form/HelpAdornment";
import {CANCEL_OWN_LAST_ANNUAL_REBATE, TCancelOwnLastAnnualRebateVariables} from '../../gql/cancelOwnLastAnnualRebate'
import {calculateSharedMaximumFor, getSharedLimits, TSharedLimits} from "../Root";
import SharedRebateLimitInfo from "../../components/SharedRebateLimitInfo";
import Button from "@material-ui/core/Button";
import InlineFormContainer from "../../../../../../components/form/InlineFormContainer";
import Select from "../../../../../../components/form/Select";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { computed } from 'mobx'
import MessageBar from '../../../../../../components/MessageBar';
import { IconsWrapper, useStyles } from '../LoanRebate/Addresses';

// interface ISectionsProps {
//   formData: TPensionInsuranceRebate | null
//   updateRebate: TMutationFunction<
//     IUpdateOwnAnnualPensionInsuranceRebateData,
//     TUpdateOwnAnnualPensionInsuranceRebateVariables
//   >
// }

const innerLayoutWidth = 680

const PART_NAMES = ['feeAmountAdditionalPart', 'feeAmountContributionPart', 'feeAmountSpecializedPart'] as const

function isPart(part: string): part is TPartName {
  return PART_NAMES.includes(part as TPartName)
}

type TPartName = typeof PART_NAMES[number]

const PensionInsuranceRebate: React.FC = () => {
  const { history } = useRouter()
  const { t } = useTranslation()
  const { user, refetch } = useUser()

  const [
    updateRebate,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    IUpdateOwnAnnualPensionInsuranceRebateData,
    TUpdateOwnAnnualPensionInsuranceRebateVariables
  >(UPDATE_OWN_ANNUAL_PENSION_INSURANCE_REBATE, { onError() {} })

  const [
    resetRebate,
    { loading: resetLoading, error: resetError },
  ] = useMutation<IResetOwnAnnualPensionInsuranceData>(
    RESET_OWN_ANNUAL_PENSION_INSURANCE_REBATE,
    { onError() {} },
  )

  const [
    cancelRebate,
    { loading: cancelLoading, error: cancelError },
  ] = useMutation<boolean, TCancelOwnLastAnnualRebateVariables>(CANCEL_OWN_LAST_ANNUAL_REBATE)

  const { data, loading, error } = useQuery<IOwnAnnualPensionInsuranceRebateData>(
    OWN_ANNUAL_PENSION_INSURANCE_REBATE,
    {
      fetchPolicy: 'cache-and-network'
    }
  )

  const formData = get(data, 'user.annualRebate.pensionInsuranceRebate') as TPensionInsuranceRebate | null
  const sharedLimits = getSharedLimits(get(data, 'user.annualRebate'), ['lifeInsuranceRebate', 'pensionInsuranceRebate', 'investmentRebate', 'longTermCareRebate'])
  const sharedMaximum = calculateSharedMaximumFor('pensionInsuranceRebate', sharedLimits)

  const parts = computed(() => {
    return PART_NAMES.reduce<Partial<Record<TPartName, any>>>((fields, partName) => {
      fields[partName] = {
        label: t('annualPensionInsuranceRebate.' + partName),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rule: (data: any) => (data.type !== 'REMOVE' ? `max:${sharedMaximum}` : ''),
        placeholder: sharedMaximum.toLocaleString('cs-CZ') + ' Kč',
        help: t('annualPensionInsuranceRebate.help', { context: "2023" }),
      };

      return fields;
    }, {});
  })

  const { bind, form } = useForm<
    TUpdateOwnAnnualPensionInsuranceRebateVariables['data']
  >(
    formData,
    {
      ...parts.get(),
      feeConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualPensionInsuranceRebate.uploadLabel'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
      contractConfirmationFiles: {
        type: 'multi_relation',
        label: t('annualPensionInsuranceRebate.contractConfirmationFiles'),
        rule: (data) => (data.type !== 'REMOVE' ? 'required' : ''),
        isFileRelation: true
      },
    },
    {
      onFieldChange(field, value, form) {
        if(isPart(field)){
          const total = PART_NAMES.reduce((sum, partName) => sum + (form.getValue(partName) || 0), 0)
          form.setField('feeAmount', total)
        }
      },
      async onSubmit(data, form) {
        try {
          const { errors } = await updateRebate({
            variables: { data: { ...data, status: 'APPLY' } },
          })

          if (!errors || (errors && errors.length > 0)) {
            history.push(`/${user.data.id}/annual-rebates/root`)
          }
        } catch (err) {
          form.onFail(err)
        }
      },
    },
  )

  const [
    prevRebates,
    {
      isFirstRequest,
      isPrevDenied,
      isPrevRemoved,
      showingCommentOfDenied,
      isCancelable,
      isPrevCancelled
    },
  ] = getRebatesWithExtra(
    data && data.user.annualRebate,
    'pensionInsuranceRebate',
  )

  const cleanRebate = async () => {
    if (formData) {
      const feeConfirmationFiles = formData.feeConfirmationFiles || []
      const contractConfirmationFiles = formData.contractConfirmationFiles || []

      await updateRebate({
        variables: {
          data: {
            status: 'NEW',
            type: 'NEW',
            feeAmount: null,
            feeAmountAdditionalPart: null,
            feeAmountContributionPart: null,
            feeAmountSpecializedPart: null,
            feeConfirmationFiles: {
              delete: feeConfirmationFiles.map(pickId),
            },
            contractConfirmationFiles: {
              delete: contractConfirmationFiles.map(pickId),
            }
          },
        },
      })
    }
  }

  const onCancelRebate = async () => {
    if (get(data, 'user.annualRebate.pensionInsuranceRebate.status') !== 'APPLY') {
      await Promise.all([
        cancelRebate({
          variables: { rebate: 'PENSION_INSURANCE' }
        }),
        refetch()
      ])
    }

    await cleanRebate()

    history.push(`/${user.data.id}/annual-rebates/root`)
  }

  const handleAlter = async () => {
    await resetRebate()
    form.setField('type', 'ALTER')
  }

  const handleRemove = async () => {
    await resetRebate()
    form.setField('type', 'REMOVE')
  }

  const showForm = computed<boolean>(() => {
    const type = form.getValue('type')
    const status = form.getValue('status')

    return (
      isFirstRequest ||
      isPrevRemoved ||
      isPrevDenied ||
      status === 'DENIED' ||
      type === 'ALTER' ||
      type === 'NEW_PERIOD' ||
      isPrevCancelled
    )
  })

  return (
    <InsideLayout sidebar moveLoversLeft={-190}>
      <ScrollToTop />
      <AppState loading={loading || updateLoading || resetLoading || cancelLoading} />
      <GraphQLErrorSnackbar error={error || updateError || resetError || cancelError} />

      {formData && (
        <Fade>
          <RebateLayout
            icon={<PenzijniPojisteni fontSize="large" />}
            heading={t('annualPensionInsuranceRebate.heading')}
            upperHint={`${t('common.annualRebates')} ${user.data.customer.yearOfAnnualRebates}`}
            commentOfDenied={showingCommentOfDenied}
            sideHint={
              <Trans i18nKey={'annualPensionInsuranceRebate.sideHint'}>
                <strong>Slevu na penzijní připojištění</strong> můžete uplatňovat pouze <strong>jednou ročně v rámci ročního zúčtování</strong> (formulář Roční zúčtování). Při vlastním spoření máte možnost získat nejen <strong>nárok na uplatnění daňové slevy</strong>, ale <strong>také příspěvek od státu</strong>.
              </Trans>
            }
            subHeading={
              <Trans i18nKey="annualPensionInsuranceRebate.subHeading">
                Chcete-li uplatňovat Slevu na penzijní připojištění, <Green>vyplňte výši zaplaceného pojistného</Green> a <Green>nahrajte potvrzení</Green>
              </Trans>
            }
          >
            {isCancelable && (
              <Box display="flex" justifyContent="center" mb={4}>
                <CancelRebateDialog
                  onSubmit={onCancelRebate}
                  isCancelOfDeniedRebate={get(data, 'user.annualRebate.pensionInsuranceRebate.status') !== 'APPLY'}
                />
              </Box>
            )}

            <Observer>
              {() => {
                const type = form.getValue('type')

                if (type === 'REMOVE') {
                  return (
                    <CancelRebateSection
                      onUndo={() => form.setField('type', 'NEW')}
                    >
                      {t('rebateChanges.cancelPensionInsurance')}
                    </CancelRebateSection>
                  )
                }

                if (showForm.get()) {
                  return (
                    <FormProvider form={form}>
                      <FormSection
                        initialData={formData}
                        form={form}
                        bind={bind}
                        sharedLimits={sharedLimits}
                        sharedMaximum={sharedMaximum}
                      />
                    </FormProvider>
                  )
                }

                return (
                  <Center maxWidth="100%" width={680}>
                    <ChangeRebateButton>
                      <ChangeRebateChoice
                        variant="REMOVE"
                        onClick={handleRemove}
                      />
                      <ChangeRebateChoice
                        label={t('rebateChanges.alterChange', { context: user.allowAlter() ? "2023" : "" })}
                        onClick={handleAlter}
                      />
                    </ChangeRebateButton>
                  </Center>
                )
              }}
            </Observer>

            <Observer>
              {() => {
                if (prevRebates.length < 1) return null

                const type = form.getValue('type')
                const status = form.getValue('status')
                const showFormSection =
                  isFirstRequest ||
                  isPrevRemoved ||
                  isPrevDenied ||
                  status === 'DENIED' ||
                  type === 'ALTER' ||
                  type === 'NEW_PERIOD' ||
                  type === 'REMOVE'

                return (
                  <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
                    <ArchiveChanges expand={!showFormSection}>
                      {prevRebates.map((rebate) => (
                        <PrevRebate
                          key={rebate.id}
                          settlementRequest={rebate.settlementRequest}
                          status={rebate.status}
                          commentOfDenied={rebate.commentOfDenied}
                        >
                          <PensionInsuranceRebateOverview data={rebate} />
                        </PrevRebate>
                      ))}
                    </ArchiveChanges>
                  </Box>
                )
              }}
            </Observer>

            <Divider maxWidth={1080} my={4} />

            <Observer>
              {() => {
                const type = form.getValue('type')

                const submitToBack = (
                  !showForm.get() &&
                  type !== 'REMOVE'
                )

                return (
                  <RebateActions
                    submitToBack={submitToBack}
                    backTo={`/${user.data.id}/annual-rebates/root`}
                    onSubmit={form.submit}
                    isDirty={form.state.isDirty}
                    formLoading={form.state.loading}
                  />
                )
              }}
            </Observer>
          </RebateLayout>
        </Fade>
      )}
    </InsideLayout>
  )
}
export default PensionInsuranceRebate

interface FormSectionProps {
  form: ReturnType<typeof useForm>['form']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind: (fieldPath: string) => any
  sharedLimits: TSharedLimits
  sharedMaximum: number
  initialData: TPensionInsuranceRebate | null
}

const FormSection: React.FC<FormSectionProps> = ({ form, bind, sharedLimits, sharedMaximum, initialData }) => {
  const { t } = useTranslation()

  const [parts, setParts] = useState<(TPartName | null)[]>(() => {
    if (initialData) {
      const existingPartNames = PART_NAMES.filter(part => Boolean(initialData[part]))

      if (existingPartNames.length > 0) {
        return existingPartNames
      }

      return [null]
    }

    return [null]
  })

  const isTotalLargerThanSharedMaximum = computed(() => {
    return parts.reduce((sum, partName) => {
      if (partName) {
        return sum + (form.getValue(partName) || 0)
      }

      return sum
    }, 0) > sharedMaximum
  })

  return (
    <>
      <Box maxWidth="100%" width={innerLayoutWidth}>
        <PensionInsuranceRebateConditions />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Bold textAlign="center" maxWidth={480}>{t("annualPensionInsuranceRebate.text2")}</Bold>

      <Box maxWidth="100%" width={innerLayoutWidth} mt={4}>
        <Observer>
          {() => {
            return parts.map((_, i) => {
              let adjustedSharedMaximum = sharedMaximum - parts.reduce((sum, partName, index) => {
                if (index !== i && partName) {
                  return sum + (form.getValue(partName) || 0)
                }

                return sum
              }, 0)

              const amount = form.getValue(parts[i] as TPartName) || (initialData ? initialData[parts[i] as TPartName] : null)

              adjustedSharedMaximum = amount > sharedMaximum ? sharedMaximum : Math.min(sharedMaximum, adjustedSharedMaximum)

              return (
                <Fragment key={i}>
                  <FormPart
                    type={parts[i]}
                    amount={amount}

                    showPlusButton={(i === parts.length - 1) && parts.length < PART_NAMES.length}

                    onAdd={() => setParts([...parts, null])}
                    onRemove={() => {
                      const part = parts[i]

                      if (parts.length > 1) {
                        const newParts = [...parts]
                        newParts.splice(i, 1)
                        setParts(newParts)
                      }
                      
                      if (part) {
                        form.setField(part, 0)
                      }
                    }}

                    onPartChange={(part) => {
                      const old_part = parts[i]

                      if (old_part && old_part !== part) {
                        form.setField(old_part, 0)
                      }

                      const newParts = [...parts]
                      newParts[i] = part
                      setParts(newParts)
                    }}
                    onAmountChange={(part, amount) => bind(part).onChange(amount)}

                    availableParts={PART_NAMES.filter(part => !parts.includes(part))}
                    sharedLimits={sharedLimits}
                    sharedMaximum={adjustedSharedMaximum < 0 ? 0 : adjustedSharedMaximum}
                  />
                  {i !== parts.length - 1 ? <Divider maxWidth="100%" width={innerLayoutWidth} my={4} /> : null}
                </Fragment>
              )
            })
          }}
        </Observer>

        <Observer>
          {() => (
            <Box my={2}>
            <MessageBar type="ERROR" hide={!isTotalLargerThanSharedMaximum.get()}>
              {t('annualPensionInsuranceRebate.maximumExceededError')} {sharedMaximum.toLocaleString('cs-CZ') + ' Kč'}
            </MessageBar>
            </Box>
          )}
        </Observer>
      </Box>

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <SharedRebateLimitInfo limits={sharedLimits} />
      </Box>

      <Divider maxWidth="100%" width={innerLayoutWidth} my={4} />

      <Bold maxWidth="100%" width={innerLayoutWidth} display="flex" alignItems="center">
        {t('annualPensionInsuranceRebate.text1')}
        <HelpAdornment inline text={<Trans i18nKey="common.uploadHelp" />} />
      </Bold>

      <Divider maxWidth="100%" width={100} my={4} />

      <Box maxWidth="100%" width={innerLayoutWidth}>
        <Observer>
          {() => (
            <Uploader
              {...bind('feeConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>

        <Divider my={4} />

        <Observer>
          {() => (
            <Uploader
              {...bind('contractConfirmationFiles')}
              showLabel
              multiple
              itemGridProps={{ sm: 6 }}
            />
          )}
        </Observer>
      </Box>
    </>
  )
}

interface FormPartProps {
  showPlusButton: boolean

  onAdd: () => void
  onRemove: () => void
  onPartChange: (part: TPartName) => void
  onAmountChange: (part: TPartName, amount: number) => void

  sharedLimits: TSharedLimits
  sharedMaximum: number

  type: TPartName | null
  amount: number | null
  availableParts: TPartName[]
}

interface FormPartData {
  type: FormPartProps['type']
  amount: FormPartProps['amount']
}

const FormPart: React.FC<FormPartProps> = ({ type = null, showPlusButton, amount, sharedMaximum, availableParts, onAmountChange, onPartChange, onAdd, onRemove }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { bind, form } = useForm<FormPartData>(
    { type, amount },
    {
      type: {
        label: t('annualPensionInsuranceRebate.selectType'),
        rule: 'required',
        list: PART_NAMES.map(name => ({ id: name, text: t('annualPensionInsuranceRebate.' + name) })),
        placeholder: t('annualPensionInsuranceRebate.selectTypePlaceholder'),
      },
      amount: {
        label: t('annualPensionInsuranceRebate.feeAmountLabel'),
        rule: `required|max:${sharedMaximum}`,
        placeholder: "max. " + sharedMaximum.toLocaleString('cs-CZ') + ' Kč',
        help: t('annualPensionInsuranceRebate.help', { context: "2023" }),
      }
    },
    {
      onFieldChange(field, value, form) {
        const type = form.getValue('type')

        if (field === 'type' && value) {
          onPartChange(value)
          onAmountChange(value, form.getValue('amount'))
        }

        if(field === 'amount') {
          onAmountChange(type, value)
        }
      }
    }
  )

  return (
    <Observer>
      {() => {
        const selectedType = form.getValue('type')

        return (
          <Box display='flex' flexDirection='column' style={{ gap: 16 }}>
            <InlineFormContainer inline label={t('annualPensionInsuranceRebate.selectType')} inputWidth="100%">
              <Select
                {...bind('type')}
                hideLabel
                fullWidth
                disableValue={(value) => availableParts.find(part => part === value) === undefined}
                hideEmptyValue
              />

                <IconsWrapper>
                  <IconButton size="small" onClick={() => {
                    form.setField('type', null)
                    onRemove()
                  }}>
                    <CloseIcon />
                  </IconButton>

                  {showPlusButton && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={onAdd}
                      className={classes.addButton}
                    >
                      <AddCircleIcon />
                      {t('common.add')}
                    </Button>
                  )}
                </IconsWrapper>
            </InlineFormContainer>

            {selectedType ? (
              <InlineFormContainer inline label={t('annualPensionInsuranceRebate.feeAmountLabel')}>
                <NumberField
                  {...bind('amount')}
                  hideLabel
                  fullWidth
                />
              </InlineFormContainer>
            ) : null}
          </Box>
        )
      }}
    </Observer>
  )
}