import { Bold } from '../CommonBox'
import { styled, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import HelpAdornment from './HelpAdornment'

export const Label = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}))
export const InlineContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: `${theme.spacing(1)}px`,
  position: 'relative',
  [theme.breakpoints.down('xs')]: {
    flexWrap: 'wrap',
    '&>*': {
      width: '100%',
      minWidth: 'auto',
    },
  },
}))

export const InlineLabelContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  paddingRight: 0,
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    paddingRight: theme.spacing(2),
    minHeight: 49,
    // maxWidth: 390,
  },
}))

const useStyles = makeStyles<Theme, IProps>((theme) => ({
  inlineInputContainer: (props) => ({
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      minHeight: 49,
      justifyContent: 'flex-end',
      width: props.inputWidth || 250,
    },
  }),
}))

interface IProps {
  label?: React.ReactNode
  help?: string
  inline?: boolean
  inputWidth?: number | string
  labelSemiBold?: boolean
}

const InlineFormContainer: React.FC<IProps> = (props) => {
  const classes = useStyles(props)
  if (!props.inline) return <>{props.children}</>
  return (
    <InlineContainer id="inline-container">
      <InlineLabelContainer id="inline-label-container">
        <Bold
          component="label"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          fontWeight={props.labelSemiBold ? 600 : 700}
        >
          {props.label}
          <HelpAdornment text={props.help} inline hiddenXsUp />
        </Bold>
      </InlineLabelContainer>
      <div className={classes.inlineInputContainer}>{props.children}</div>
    </InlineContainer>
  )
}

export default InlineFormContainer
