import CancelRequestDialog, {
  TCancelRequestDialogOpen,
} from './components/CancelRequestDialog'
import {
  ZpravyGrey,
  PomocGrey,
  OdejitZPruvodce,
  DokumentyGrey,
} from '../icons/KubikIcons'
import {
  updateAnnualRebateWizardStep,
  updateAnnualRebateWizardStepVariables,
} from '../../generated/updateAnnualRebateWizardStep'
import {
  AnnualWizardStep,
  MonthlyWizardStep,
} from '../../generated/globalTypes'
import {
  updateMonthlyRebateWizardStep,
  updateMonthlyRebateWizardStepVariables,
} from '../../generated/updateMonthlyRebateWizardStep'
import { userWithAnnualRebateOverview } from '../../generated/userWithAnnualRebateOverview'
import { UPDATE_MONTHLY_WIZARD_STEP } from '../../pages/Inside/pages/MonthlyRebates/gql/updateMonthlyWizardStepMutation'
import { UPDATE_ANNUAL_WIZARD_STEP } from '../../pages/Inside/pages/AnnualRebates/gql/updateAnnualWizardStepMutation'
import { IOwnMonthlyRebateData, OWN_ANNUAL_REBATE_OVERVIEW, OWN_MONTHLY_REBATE_OVERVIEW } from '../../pages/Inside/pages/TaxRebates/gql'
import { useCalcSummaryStatus } from '../../pages/Inside/pages/TaxRebates/components/Summary'
import { matchPath } from 'react-router-dom'
import { RebateStateIcon, useRebateStates } from './components/RebateState'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useUser, useTranslation, useRouter } from '../../hooks'
import Divider from './components/Divider'
import LeaveWizardDialog from './components/LeaveWizardDialog'
import NavItem from './components/NavItem'
import React, { useMemo } from 'react'
import { get } from 'lodash'

const RebatesNavigation: React.FC = () => {
  const { t } = useTranslation()
  const { user } = useUser()
  const { history, location } = useRouter()
  const { annualRebate, monthlyRebate, monthlyHistory } = useRebateStates()
  const {
    data: {
      customer: { yearOfAnnualRebates, yearOfMonthlyRebates },
      yearOfMonthlyHistory,
      monthlyHistoryRequired,
    },
  } = user
  const isFirstRound = user.firstRound()
  const isReincarnation = user.isReincarnation()

  const matchAnnual = matchPath(location.pathname, '/:userId/annual-rebates')
  const matchMonthly = matchPath(location.pathname, '/:userId/monthly-rebates')
  const activeRebate: TCancelRequestDialogOpen = matchAnnual
    ? 'ANNUAL'
    : matchMonthly
    ? 'MONTHLY'
    : null

  const [cancelDialogOpen, setCancelDialogOpen] =
    React.useState<TCancelRequestDialogOpen>(null)
  const closeCancelDialog = () => setCancelDialogOpen(null)

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    type: Exclude<TCancelRequestDialogOpen, null>,
  ) => {
    //if (activeRebate !== type) {
    const { state } = type === 'ANNUAL' ? annualRebate : monthlyRebate
    if (state === 'APPLIED') {
      e.preventDefault()
      setCancelDialogOpen(type)
    }
    //}
  }

  const [leaveDialogOpen, setLeaveDialogOpen] = React.useState<boolean>(false)
  const closeLeaveDialog = () => setLeaveDialogOpen(false)

  const [updateAnnualWizardStep] = useMutation<
    updateAnnualRebateWizardStep,
    updateAnnualRebateWizardStepVariables
  >(UPDATE_ANNUAL_WIZARD_STEP)

  const [updateMonthlyWizardStep] = useMutation<
    updateMonthlyRebateWizardStep,
    updateMonthlyRebateWizardStepVariables
  >(UPDATE_MONTHLY_WIZARD_STEP)

  const handleLeaveDialogClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault()
    setLeaveDialogOpen(true)
  }

  const handleLeaveDialog = () => {
    if (activeRebate === 'ANNUAL') {
      updateAnnualWizardStep({
        variables: { wizardStep: AnnualWizardStep.DONE },
      })
    } else {
      updateMonthlyWizardStep({
        variables: { wizardStep: MonthlyWizardStep.DONE },
      })
    }
    history.push({
      pathname: `/${user.data.id}/rebates`,
      state: { monthly: activeRebate === 'MONTHLY' },
    })
  }

  
  const skipAnnualOverview = !user || !user.data.annualRebate || !user.data.annualRebate.settlementRequest
  const annualRebateOverview = useQuery<userWithAnnualRebateOverview>(OWN_ANNUAL_REBATE_OVERVIEW, {
    fetchPolicy: 'cache-and-network',
    skip: skipAnnualOverview
  })
  
  const skipMonthlyOverview = !user || !user.data.monthlyRebate || !user.data.monthlyRebate.settlementRequest
  const monthlyRebateOverview = useQuery<IOwnMonthlyRebateData>(OWN_MONTHLY_REBATE_OVERVIEW, { 
    fetchPolicy: 'cache-and-network',
    skip: skipMonthlyOverview
  })

  const calculatedAnnualSummaryStatus = useCalcSummaryStatus(get(annualRebateOverview.data, "user.annualRebate.settlementRequest.status"), get(annualRebateOverview.data, 'user.annualRebate'))
  const calculatedMonthlySummaryStatus = useCalcSummaryStatus(get(monthlyRebateOverview.data, "user.monthlyRebate.settlementRequest.status"), get(monthlyRebateOverview.data, 'user.monthlyRebate'))

  const annualSummaryStatus = skipAnnualOverview ? annualRebate.state : calculatedAnnualSummaryStatus
  const monthlySummaryStatus = skipMonthlyOverview ? monthlyRebate.state : calculatedMonthlySummaryStatus

  const toWizard = useMemo(() => {
    const overview = get(annualRebateOverview.data, 'user.annualRebate.overview')
    const rebate = get(annualRebateOverview.data, 'user.annualRebate')

    if (!overview) {
      return "/annual-rebates"
    }

    const pozp = get(overview, 'pozp')
    const annualQuestionnaireStatus = overview && overview.questionnaire && overview.questionnaire.status
    const prevEmployerDenied = rebate && rebate.prevEmployers && rebate.prevEmployers.some((empl: any) => empl.status === 'DENIED')
    const monthlyDenied = overview &&
      [
        get(overview.basicRebate, 'status'),
        get(overview.childrenRebate, 'status'),
        get(overview.disabilityRebate, 'status'),
        get(overview.ztppRebate, 'status')
      ].some(status => status === 'DENIED')

    if (pozp || annualQuestionnaireStatus === 'DENIED') {
      return `/annual-rebates/questionnaire`
    } else if (prevEmployerDenied) {
      return `/annual-rebates/previousEmployers`
    } else if (
      user.shouldHaveResidenceStep() &&
      (
        user.noProvidedFiles() ||
        (
          user.prevDocumentsIsDenied() &&
          !user.hasNewResidenceFiles()
        )
      )
    ) {
      return `/annual-rebates/taxResidence`
    } else if (monthlyDenied) {
      return `/annual-rebates/rootMonthly`
    } else {
      return `/annual-rebates/root`
    }
  }, [annualRebateOverview.data])

  return (
    <>
      {monthlyHistoryRequired ? (
        <NavItem
          to="/monthly-history"
          text={`${t('common.monthlyRebate')} ${yearOfMonthlyHistory}`}
          endIcon={<RebateStateIcon {...monthlyHistory} />}
          onClick={(e) => handleClick(e, 'MONTHLY')}
        />
      ) : (
        <>
          {(isFirstRound || activeRebate === 'ANNUAL') &&
            annualRebate.allowed && (
              <NavItem
                to={toWizard}
                text={`${t('common.annualRebates')} ${yearOfAnnualRebates}`}
                endIcon={<RebateStateIcon state={annualSummaryStatus} />}
                onClick={(e) => handleClick(e, 'ANNUAL')}
              />
            )}
          {(isFirstRound || activeRebate === 'MONTHLY') &&
            monthlyRebate.allowed && (
              <NavItem
                to="/monthly-rebates"
                text={`${t('common.monthlyRebate')} ${yearOfMonthlyRebates}`}
                endIcon={<RebateStateIcon state={monthlySummaryStatus} />}
                onClick={(e) => handleClick(e, 'MONTHLY')}
              />
            )}
        </>
      )}

      {!isReincarnation && (
        <>
          <Divider />

          {user.allowMessages() ? (
            <NavItem
              to="/messages"
              text={t('common.messages')}
              icon={<ZpravyGrey />}
            />
          ) : null}

          <NavItem
            to="/documents"
            text={t('common.documents')}
            icon={<DokumentyGrey />}
          />

          <NavItem
            to="/help"
            text={t('common.help')}
            icon={<PomocGrey />}
          />
        </>
      )}

      {!isFirstRound && !!activeRebate && (
        <>
          <Divider />
          <NavItem
            to="/rebates"
            onClick={handleLeaveDialogClick}
            text={t('common.leaveWizard')}
            icon={<OdejitZPruvodce />}
          />
        </>
      )}
      <CancelRequestDialog
        open={cancelDialogOpen}
        onClose={closeCancelDialog}
      />
      <LeaveWizardDialog
        open={leaveDialogOpen}
        onClose={closeLeaveDialog}
        onSubmit={handleLeaveDialog}
      />
    </>
  )
}

export default RebatesNavigation
