import {gql} from 'apollo-boost'
import {NexusGenArgTypes, NexusGenFieldTypes, NexusGenRootTypes,} from 'kubik-server'

export type PrevEmployerFields =
  | 'id'
  | 'status'
  | 'commentOfDenied'
  | 'deleteFlag'
  | 'companyName'
  | 'dateOfEmployment'
  | 'dateOfQuit'
  | 'totalRevenue'
  | 'incomeInsuranceSum'
  | 'actualDeductedTax'
  | 'totalTaxAdvance'
  | 'totalMonthlyTaxBonuses'
  | 'lifeInsuranceContribution'

export type TFile = Pick<
  NexusGenRootTypes['File'],
  'id' | 'filename' | 'size' | 'createdAt'
>

export type TPrevEmployerSnap = Pick<
  NexusGenFieldTypes['PrevEmployerSnap'],
  PrevEmployerFields | 'createdAt' | 'snapRootId'
> & {
  incomeConfirmationFiles: TFile[]
  settlementRequest: Pick<
    NexusGenFieldTypes['SettlementRequest'],
    'id' | 'requestedAt' | 'submittedAt'
  >
}

export type TPrevEmployer = Pick<
  NexusGenFieldTypes['PrevEmployer'],
  PrevEmployerFields
> & {
  history: Pick<NexusGenFieldTypes['PrevEmployerSnap'], 'id' | 'status'>[]
  incomeConfirmationFiles: TFile[]
}

export interface IPrevEmployersData {
  user: Pick<NexusGenFieldTypes['User'], 'id'> & {
    annualRebate: Pick<
      NexusGenFieldTypes['AnnualRebate'],
      'id' | 'year' | 'hasPrevEmployers'
    > & {
      prevEmployers: TPrevEmployer[]
      prevEmployersHistory: TPrevEmployerSnap[]
      prevEmployersSummaryInfo: TPrevEmployersSummaryInfo
    }
  }
}

export type TPrevEmployersSummaryInfo = NexusGenFieldTypes['PrevEmployersSummaryInfo']

export const OWN_ANNUAL_PREV_EMLOYERS = gql`
  query userWithAnnualPrevEmployers {
    user {
      id
      annualRebate {
        id
        year
        hasPrevEmployers
        wizardStep
        prevEmployersSummaryInfo {
          id
          usedBasicRebate
          usedBasicRebateInMonths

          usedStudentRebate
          usedStudentRebateInMonths

          usedChildrenRebate
          usedChildren {
            id
            ztpp
            usedInMonths
          }

          usedDisabilityRebate
          usedDisabilityRebateInMonths
          usedDisabilityRebateLevel

          usedZtppRebate
          usedZtppRebateInMonths
        }
        prevEmployersTouched
        prevEmployers {
          id
          status
          commentOfDenied
          deleteFlag
          companyName
          dateOfEmployment
          dateOfQuit
          totalRevenue
          incomeInsuranceSum
          actualDeductedTax
          totalTaxAdvance
          totalMonthlyTaxBonuses
          lifeInsuranceContribution
          incomeConfirmationFiles {
            id
            filename
            size
            createdAt
          }
          history {
            id
            status
          }
        }
        prevEmployersHistory {
          id
          createdAt
          status
          commentOfDenied
          deleteFlag
          companyName
          dateOfEmployment
          dateOfQuit
          totalRevenue
          incomeInsuranceSum
          actualDeductedTax
          totalTaxAdvance
          totalMonthlyTaxBonuses
          lifeInsuranceContribution
          snapRootId
          incomeConfirmationFiles {
            id
            filename
            size
            createdAt
          }
          settlementRequest {
            id
            requestedAt
            submittedAt
          }
        }
      }
    }
  }
`

export interface IUpdateOwnAnnualPrevEmployersData {
  updatePrevEmployers: Pick<NexusGenFieldTypes['AnnualRebate'], 'id' | 'prevEmployersTouched'>
}
export type TUpdateOwnAnnualPrevEmployersVariables = NexusGenArgTypes['Mutation']['updatePrevEmployers']

export const UPDATE_OWN_ANNUAL_PREV_EMPLOYERS = gql`
  mutation updateOwnAnnualPrevEmployers($data: PrevEmployersUpdateInput!) {
    updatePrevEmployers(data: $data) {
      id
    }
  }
`

export interface IUpdateOwnAnnualPrevEmployersSummaryInfoData {
  updatePrevEmployers: Pick<NexusGenFieldTypes['AnnualRebate'], 'id'>
}
export type TUpdateOwnAnnualPrevEmployersSummaryInfoVariables = NexusGenArgTypes['Mutation']['updatePrevEmployersSummaryInfo']

export const UPDATE_OWN_ANNUAL_PREV_EMPLOYERS_SUMMARY_INFO = gql`
  mutation updateOwnAnnualPrevEmployersSummaryInfo($data: PrevEmployersSummaryInfoUpdateInput!) {
    updatePrevEmployersSummaryInfo(data: $data) {
      id
    }
  }
`
export interface IResetPrevEmployersData {
  resetPrevEmployer: TPrevEmployer
}
export type TResetPrevEmployersVariables = NexusGenArgTypes['Mutation']['resetPrevEmployer']
export const RESET_PREV_EMPLOYERS = gql`
  mutation resetPrevEmployer($where: PrevEmployerWhereUniqueInput!) {
    resetPrevEmployer(where: $where) {
      id
      status
      companyName
      dateOfEmployment
      dateOfQuit
      deleteFlag
      totalRevenue
      incomeInsuranceSum
      actualDeductedTax
      totalTaxAdvance
      totalMonthlyTaxBonuses
      lifeInsuranceContribution
      incomeConfirmationFiles {
        id
        filename
        size
        createdAt
      }
      history {
        id
        status
        commentOfDenied
        deleteFlag
        companyName
        dateOfEmployment
        dateOfQuit
        totalRevenue
        incomeInsuranceSum
        actualDeductedTax
        totalTaxAdvance
        totalMonthlyTaxBonuses
        lifeInsuranceContribution
        incomeConfirmationFiles {
          id
          filename
          size
          createdAt
        }
        settlementRequest {
          id
          requestedAt
          submittedAt
        }
      }
    }
  }
`
